export const currentPageLimit: number = 500;
export const defaultColumns: string[] = []; // defaultColumns is empty as all the columns can be selected/deselected.

export const tableSortColumnIndex = {
  1: 'account_name',
  2: 'reseller_partner_account_name',
  3: 'opp_count',
  4: 'total_opp_amount',
  5: 'high_risk_opps',
  6: 'high_cross_sell_probability',
  7: 'renewal_at_par_last_year',
  8: 'product_final_mapping',
  9: 'account_vertical_industry_name',
  10: 'deal_size',
};
export const initialDistributorColumns: string[] = [
  'Partner Account',
  '# of Opps',
  'Opp Amount',
  'Opp Amount at High Risk',
  'Opp Amount With High Cross Sell Probabilities',
  'Renewal @ Par % Last Year',
];

export const initialResellerColumns: string[] = [
  'Customer Account',
  'Est. # of Opps',
  'Est. Opp Amount',
  'Est. Opp Amount at High Risk',
  'Est. Opp Amount With High Cross Sell Probabilities',
  'Est. Renewal @ Par % Last Year',
];

export const distributorColumnDetails = {
  reseller_partner_account_name: {
    columnName: 'Partner Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  opp_count: {
    columnName: '# of Opps',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  total_opp_amount: {
    columnName: 'Opp Amount',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  high_risk_opps: {
    columnName: 'Opp Amount at High Risk',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  high_cross_sell_probability: {
    columnName: 'Opp Amount With High Cross Sell Probabilities',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  renewal_at_par_last_year: {
    columnName: 'Renewal @ Par % Last Year',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 7,
    formattable: true,
    valueInPercentage: true,
  },
  product_final_mapping: {
    columnName: 'Product Category',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
  },
  account_vertical_industry_name: {
    columnName: 'Customer Vertical',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 9,
    formattable: false,
  },
  deal_size: {
    columnName: 'Deal Size',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 10,
    formattable: false,
  },
};
export const resellerColumnDetails = {
  account_name: {
    columnName: 'Customer Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  opp_count: {
    columnName: '# of Opps',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  total_opp_amount: {
    columnName: 'Est. Opp Amount',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  high_risk_opps: {
    columnName: 'Est. Opp Amount at High Risk',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  high_cross_sell_probability: {
    columnName: 'Est. Opp Amount With High Cross Sell Probabilities',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  renewal_at_par_last_year: {
    columnName: 'Est. Renewal @ Par % Last Year',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 7,
    formattable: true,
    valueInPercentage: true,
  },
  product_final_mapping: {
    columnName: 'Product Category',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
  },
  account_vertical_industry_name: {
    columnName: 'Customer Vertical',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 9,
    formattable: false,
  },
  deal_size: {
    columnName: 'Deal Size',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 10,
    formattable: false,
  },
};
