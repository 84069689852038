import React from 'react';
import { SelectDirection, Stack, StackItem, Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem } from '@patternfly/react-core';
import { bottomPagination, topPagination } from '../../../constants/pagination';
import ManageAndExport from './ManageAndExport/ManageAndExport';
import TablePagination from './Pagination';
import TableContent from './TableContent';
import TableFilter from './TableFilter';
import styles from './Table.module.scss';

interface TableProps {
  filter: any;
  actionColumn?: any;
}

const Table = ({ filter, actionColumn }: TableProps) => {
  return (
    <Stack>
      <StackItem>
        <Toolbar className={styles.eiTableHeader} usePageInsets>
          <ToolbarContent>
            <Stack className={styles.eiToolbarActionContainer}>
              <StackItem>
                <TableFilter data={filter} />
              </StackItem>
              <StackItem>
                <ToolbarGroup className={styles.eiExportPaginationWrapper}>
                  <ManageAndExport direction={SelectDirection.down} />
                  <ToolbarItem>
                    <TablePagination position={topPagination} />
                  </ToolbarItem>
                </ToolbarGroup>
              </StackItem>
            </Stack>
          </ToolbarContent>
        </Toolbar>
      </StackItem>
      <StackItem isFilled>
        <TableContent actionColumn={actionColumn} />
      </StackItem>
      <StackItem>
        <Toolbar className={styles.eiTableHeader} usePageInsets>
          <ToolbarContent>
            <Stack className={styles.eiToolbarActionContainer}>
              <StackItem>
                <ToolbarGroup className={styles.eiExportPaginationWrapper}>
                  <ManageAndExport direction={SelectDirection.up} />
                  <ToolbarItem>
                    <TablePagination position={bottomPagination} />
                  </ToolbarItem>
                </ToolbarGroup>
              </StackItem>
            </Stack>
          </ToolbarContent>
        </Toolbar>
      </StackItem>
    </Stack>
  );
};

export default Table;
