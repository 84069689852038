import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Card, CardBody, Select, SelectOption, SelectVariant, Text, TextVariants, ToolbarItem } from '@patternfly/react-core';
import { fetchDataPostRequest, getSubfilterOptions } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
  retentionTableSortColumnIndex,
} from './RetentionPerformanceTableConfiguration';
import styles from './RetentionPerformanceDetail.module.scss';

interface RetentionPerformanceDetailProps {
  viewContext: string;
}

const RetentionPerformanceDetail: React.FC<RetentionPerformanceDetailProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request } = useFetch(fetchDataPostRequest, ApiRoutes.RETENTION_PERFORMANCE_DETAIL);
  const [retentionPerformanceDetailData, setRetentionPerformanceDetailData] = useState<any>();
  const [responseError, setResponseError] = useState(null);
  const [isCustomerGroupSelectOpen, setIsCustomerGroupSelectOpen] = useState(false);
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    isTableDataFetching,
    tableData,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortIndex,
    setActiveSortDirection,
    setTableName,
    setTableResponseError,
    setTableEmptyState,
  } = useTableContext();

  const { request: subfiltersRequest } = useFetch(getSubfilterOptions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subfiltersResponseError, setSubfiltersResponseError] = useState(null);
  const [customerGroupFilterOptions, setCustomerGroupFilterOptions] = useState([]);
  const [selectedCustomerGroupFilterOption, setSelectedCustomerGroupFilterOption] = useState(SubFilter.AllCustomers);

  const onSelectCustomerGroupFilter = (event, filterOption) => {
    setIsCustomerGroupSelectOpen(false);
    if (selectedCustomerGroupFilterOption !== filterOption) {
      setSelectedCustomerGroupFilterOption(filterOption);
    }
  };

  useEffect(() => {
    setActiveSortDirection('desc');
    setActiveSortIndex(7);

    const getRetentionPerformanceDetailFilterOptions = async () => {
      try {
        const subfiltersResponse = await subfiltersRequest(ApiRoutes.GET_SUBFILTERS, SubFilter.CustomerGroup);
        setCustomerGroupFilterOptions(subfiltersResponse?.subfilters[0]?.value_text);
      } catch (error) {
        setSubfiltersResponseError(error.message);
      }
    };

    getRetentionPerformanceDetailFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTableDataFetching(true);
    const getRetentionPerformanceTableData = async () => {
      try {
        const requestBody = {
          viewContext: viewContext,
          fetch_id: currentFetchId,
          page_limit: pageLimit,
          order_by: retentionTableSortColumnIndex[activeSortIndex],
          sort_by: activeSortDirection,
          filter_by: {},
        };

        if (selectedCustomerGroupFilterOption) {
          Object.assign(requestBody.filter_by, { [SubFilter.CustomerGroup]: selectedCustomerGroupFilterOption });
        }

        const selectedFilterValues = getAllSelectedFilterValues(globalState, requestBody);
        const response = await request(selectedFilterValues);

        setRetentionPerformanceDetailData(response);
        setTableData(response?.customer_retention_details?.data);
        setIsTableDataFetching(false);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
        setIsTableDataFetching(false);
      }
    };

    if (((globalState || {}).filterState || {}).originalFilterData && activeSortIndex && activeSortDirection) {
      getRetentionPerformanceTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSortDirection, activeSortIndex, viewContext, globalFilterAppliedOrReset, currentFetchId, filterBy, selectedCustomerGroupFilterOption]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
      setTableEmptyState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  useEffect(() => {
    const defaultColumns: string[] = [];
    unstable_batchedUpdates(() => {
      setTableName('retention-performance');
      setPageLimit(currentPageLimit);
      setDefaultColumns(defaultColumns);
      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        setInitialColumns(initialDistributorColumns);
        setColumns(distributorColumnDetails);
      } else {
        setInitialColumns(initialResellerColumns);
        setColumns(resellerColumnDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  useEffect(() => {
    setTableData(retentionPerformanceDetailData?.customer_retention_details?.data);
    setTotalRecordsCount(retentionPerformanceDetailData?.customer_retention_details?.record_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retentionPerformanceDetailData, setTableData, setTotalRecordsCount]);

  const tableFilter = (
    <ToolbarItem>
      <Text component={TextVariants.p} data-test="customer-group-filter-title">
        Customer Group
      </Text>
      <div data-test="customer-group-filter">
        <Select
          aria-label="Select Input"
          className={styles.eiToolbarSelectWidth}
          id="select-customer-group"
          isDisabled={(customerGroupFilterOptions?.length > 0 ? false : true) || isTableDataFetching}
          isOpen={isCustomerGroupSelectOpen}
          onSelect={onSelectCustomerGroupFilter}
          onToggle={() => setIsCustomerGroupSelectOpen(!isCustomerGroupSelectOpen)}
          placeholderText={<>All Customers</>}
          selections={selectedCustomerGroupFilterOption}
          variant={SelectVariant.single}
        >
          {customerGroupFilterOptions?.map((option, index) => {
            return <SelectOption key={index} value={option} />;
          })}
        </Select>
      </div>
    </ToolbarItem>
  );

  return (
    <Card className={styles.eiRetentionPerformanceDetail}>
      <CardBody>
        <Table filter={tableFilter}></Table>
      </CardBody>
    </Card>
  );
};

export default RetentionPerformanceDetail;
