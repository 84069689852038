import React, { createContext, useContext, useEffect, useState } from 'react';
import { AlertVariant } from '@patternfly/react-core';
import { cloneDeep } from 'lodash';

export interface ToastState {
  addToast: (title: string, variant: AlertVariant) => void;
  maxToastsDisplayed: number;
  onOverflowMessageClick: () => void;
  overflowMessage: string;
  removeToast: (id: number) => void;
  toasts: { title: string; variant: AlertVariant; id: number }[];
}

export const initialToastState: ToastState = {
  addToast: () => {},
  maxToastsDisplayed: 4,
  onOverflowMessageClick: () => {},
  overflowMessage: '',
  removeToast: () => {},
  toasts: [],
};

const ToastContext = createContext(initialToastState);
export const useToastContext = () => useContext(ToastContext);

const ToastContextProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const [overflowMessage, setOverflowMessage] = useState<string>('');
  const [maxToastsDisplayed, setMaxToastsDisplayed] = useState<number>(initialToastState.maxToastsDisplayed);
  const toastTimeout = 4000;
  const overflowTimeout = 10000;

  /**
   * @returns A date-based unique identifier for a toast
   */
  const getUniqueToastId = () => new Date().getTime();

  /**
   * @returns A message representing the number of addition toasts to view based on maxToastsDisplayed
   */
  const getOverflowMessage = () => {
    const overflow: number = toasts.length - maxToastsDisplayed;
    if (overflow > 0) {
      return `View ${overflow} more alerts`;
    }
    return '';
  };

  /**
   * Set the maximum toasts displayed to arbitrarily large for some number of seconds (10)
   */
  const onOverflowMessageClick = () => {
    setMaxToastsDisplayed(100);

    setTimeout(() => {
      setMaxToastsDisplayed(initialToastState.maxToastsDisplayed);
    }, overflowTimeout);
  };

  /**
   * Add a toast to the list of toasts
   * @param title - the title(text) to display for the toast
   * @param variant  - the AlertVariant type of toast to display
   */
  const addToast = (title: string, variant: AlertVariant) => {
    // clone toasts to avoid directly modifying state
    const newToasts = cloneDeep(toasts);
    const toastId = getUniqueToastId();
    let numberOfSuccessToasts: number = 0;

    // get the number of existing success toasts
    toasts.forEach((toast) => {
      if (toast.variant === AlertVariant.success) {
        numberOfSuccessToasts++;
      } else {
        return;
      }
    });

    // if toast is a success variant, insert the toast at the end of other success toasts, else just push to the array
    if (variant === AlertVariant.success) {
      newToasts.splice(numberOfSuccessToasts, 0, { title: title, variant: variant, id: toastId });
    } else {
      newToasts.push({ title: title, variant: variant, id: toastId });
    }
    setToasts(newToasts);

    if (variant === AlertVariant.success) {
      setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== toastId));
      }, toastTimeout);
    }
  };

  /**
   * Remove a toast from the list of toasts
   * @param {number} id - the id of the toast to be removed
   */
  const removeToast = (id: number) => {
    let newToasts = toasts.filter((toast) => toast.id !== id);
    setToasts(newToasts);
  };

  useEffect(() => {
    // when the maxToastsDisplaed or toasts change, the overflow message must be updated
    setOverflowMessage(getOverflowMessage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxToastsDisplayed, toasts]);

  return (
    <ToastContext.Provider value={{ addToast, onOverflowMessageClick, overflowMessage, maxToastsDisplayed, removeToast, toasts }}>
      {children}
    </ToastContext.Provider>
  );
};

export default ToastContextProvider;
