import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavExpandable, NavItem, NavList } from '@patternfly/react-core';
import pageDefinitions from '../../../../utils/pageDefinitions';
import pageRoutes from '../../../../utils/pageRoutes';
import styles from './Navbar.module.scss';

interface NavBarProps {
  currentPath: string;
  setDefinitions: Function;
}

const NavBar: React.FC<NavBarProps> = (props) => {
  const { currentPath, setDefinitions } = props;
  const [activeGroup, setActiveGroup] = useState('');
  const [activeItem, setActiveItem] = useState('');
  const [expandedNavs, setExpandedNavs] = useState<string[] | any>([]);

  /* set the active group and active item so the navbar knows what to style as selected */
  const onSelect = (result) => {
    setActiveGroup(result.groupId);
    setActiveItem(result.itemId);
  };

  /* set the expanded navs so that the nav menu expanded by the user will remain expanded unless the user collapses it */
  const onToggle = (group) => {
    if (group?.groupId !== 'home' || group?.groupId !== 'export' || group?.groupId !== 'resources') {
      if (expandedNavs?.includes(group.groupId)) {
        expandedNavs?.splice(expandedNavs?.indexOf(group?.groupId), 1);
      } else {
        setExpandedNavs([...expandedNavs, group?.groupId]);
      }
    }
  };

  /* switch on the path name in the url to populate menu group selection and other values */
  useEffect(() => {
    switch (currentPath) {
      case pageRoutes.baseUrl.path:
        setActiveGroup(pageRoutes.baseUrl.groupId);
        setActiveItem(pageRoutes.baseUrl.itemId);
        setDefinitions(null);
        break;
      case pageRoutes.reviewOverviewUrl.path:
        setActiveGroup(pageRoutes.reviewOverviewUrl.groupId);
        setActiveItem(pageRoutes.reviewOverviewUrl.itemId);
        setDefinitions(pageDefinitions.reviewOverviewDefinitions);
        break;
      case pageRoutes.retentionPerformanceUrl.path:
        setActiveGroup(pageRoutes.retentionPerformanceUrl.groupId);
        setActiveItem(pageRoutes.retentionPerformanceUrl.itemId);
        setDefinitions(pageDefinitions.retentionPerformanceDefinitions);
        break;
      case pageRoutes.bookingsPerformanceUrl.path:
        setActiveGroup(pageRoutes.bookingsPerformanceUrl.groupId);
        setActiveItem(pageRoutes.bookingsPerformanceUrl.itemId);
        setDefinitions(pageDefinitions.bookingsPerformanceDefinitions);
        break;
      case pageRoutes.topAndBottomPerformersUrl.path:
        setActiveGroup(pageRoutes.topAndBottomPerformersUrl.groupId);
        setActiveItem(pageRoutes.topAndBottomPerformersUrl.itemId);
        setDefinitions(pageDefinitions.topAndBottomPerformersDefinitions);
        break;
      case pageRoutes.dealManagementUrl.path:
        setActiveGroup(pageRoutes.dealManagementUrl.groupId);
        setActiveItem(pageRoutes.dealManagementUrl.itemId);
        setDefinitions(pageDefinitions.dealManagementDefinitions);
        break;

      case pageRoutes.renewOverviewUrl.path:
        setActiveGroup(pageRoutes.renewOverviewUrl.groupId);
        setActiveItem(pageRoutes.renewOverviewUrl.itemId);
        setDefinitions(pageDefinitions.renewOverviewDefinitions);
        break;
      case pageRoutes.pipelineByAccountUrl.path:
        setActiveGroup(pageRoutes.pipelineByAccountUrl.groupId);
        setActiveItem(pageRoutes.pipelineByAccountUrl.itemId);
        setDefinitions(pageDefinitions.pipelineByAccountDefinitions);
        break;
      case pageRoutes.opportunitiesUrl.path:
        setActiveGroup(pageRoutes.opportunitiesUrl.groupId);
        setActiveItem(pageRoutes.opportunitiesUrl.itemId);
        setDefinitions(pageDefinitions.opportunitiesDefinitions);
        break;
      case pageRoutes.infraredOverview.path:
        setActiveGroup(pageRoutes.infraredOverview.groupId);
        setActiveItem(pageRoutes.infraredOverview.itemId);
        setDefinitions(pageDefinitions.infraredOverviewDefinitions);
        break;
      case pageRoutes.crossSellProbabilities.path:
        setActiveGroup(pageRoutes.crossSellProbabilities.groupId);
        setActiveItem(pageRoutes.crossSellProbabilities.itemId);
        setDefinitions(pageDefinitions.crossSellProbabilitiesDefinitions);
        break;
      case pageRoutes.customerInsights.path:
        setActiveGroup(pageRoutes.customerInsights.groupId);
        setActiveItem(pageRoutes.customerInsights.itemId);
        setDefinitions(null);
        break;
      case pageRoutes.exportUrl.path:
        setActiveGroup(pageRoutes.exportUrl.groupId);
        setActiveItem(pageRoutes.exportUrl.itemId);
        setDefinitions(pageDefinitions.exportDefinitions);
        break;

      default:
        break;
    }
  }, [currentPath, setActiveGroup, setActiveItem, setDefinitions]);

  const renderNavigationMenuItem = (navItem) => {
    return (
      <NavItem
        className={styles.eiPFNavLinkOverrides}
        groupId={navItem.groupId}
        itemId={navItem.itemId}
        isActive={activeItem === navItem.itemId}
        key={`${navItem.groupId} + ${navItem.itemId}`}
        to={navItem.path}
      >
        <NavLink to={navItem.path} data-test={navItem.itemId}>
          {navItem.linkText}
        </NavLink>
      </NavItem>
    );
  };

  const renderNavigationExpandableMenu = (navItem, expandableMenuItems) => {
    if (activeGroup === navItem.groupId && !expandedNavs.includes(navItem.groupId)) {
      setExpandedNavs([...expandedNavs, navItem.groupId]);
    }
    return (
      <NavExpandable
        className={styles.eiPFNavLinkOverrides}
        groupId={navItem.groupId}
        isActive={activeGroup === navItem.groupId}
        isExpanded={activeGroup === navItem.groupId || expandedNavs.includes(navItem.groupId)}
        key={`${navItem.groupId} + ${navItem.itemId}`}
        srText={navItem.linkText}
        title={navItem.linkText}
        data-test={navItem.groupId}
      >
        {expandableMenuItems}
      </NavExpandable>
    );
  };

  return (
    <div className={styles.eiNavbar}>
      <Nav onToggle={onToggle} onSelect={onSelect} aria-label="Default global nav">
        <NavList>
          {/* Home */}
          {renderNavigationMenuItem(pageRoutes.baseUrl)}
          {/* Review */}
          {renderNavigationExpandableMenu(pageRoutes.reviewUrl, [
            renderNavigationMenuItem(pageRoutes.reviewOverviewUrl),
            renderNavigationMenuItem(pageRoutes.bookingsPerformanceUrl),
            renderNavigationMenuItem(pageRoutes.retentionPerformanceUrl),
            renderNavigationMenuItem(pageRoutes.topAndBottomPerformersUrl),
            renderNavigationMenuItem(pageRoutes.dealManagementUrl),
          ])}
          {/* Renew */}
          {renderNavigationExpandableMenu(pageRoutes.renewUrl, [
            renderNavigationMenuItem(pageRoutes.renewOverviewUrl),
            renderNavigationMenuItem(pageRoutes.pipelineByAccountUrl),
            renderNavigationMenuItem(pageRoutes.opportunitiesUrl),
          ])}
          {/* Infrared */}
          {renderNavigationExpandableMenu(pageRoutes.infraredUrl, [
            renderNavigationMenuItem(pageRoutes.infraredOverview),
            renderNavigationMenuItem(pageRoutes.crossSellProbabilities),
            renderNavigationMenuItem(pageRoutes.customerInsights),
          ])}
          {/* Export */}
          {renderNavigationMenuItem(pageRoutes.exportUrl)}
        </NavList>
      </Nav>
    </div>
  );
};

export default NavBar;
