import React, { useEffect, useState } from 'react';
import { Select, SelectDirection, SelectOption, SelectVariant } from '@patternfly/react-core';
import { useTableContext } from '../../../../../contexts/Table/TableContext';
import { noDataState } from '../../../../EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';

type ManageColumnsProps = {
  direction: SelectDirection;
};

const ManageColumns = (props: ManageColumnsProps) => {
  const tableContext = useTableContext();
  const { columns, defaultColumns, initialColumns, selectedColumns, tableResponseError, setSelectedColumns, setTableEmptyState } = tableContext;
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  //Set initial state of the columns dropdown
  useEffect(() => {
    setSelectedColumns(initialColumns);
    setIsSelectOpen(false);
  }, [initialColumns, setSelectedColumns]);

  //Set empty state if there are no columns selected
  useEffect(() => {
    if (!selectedColumns?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableEmptyState(null);
    }
  }, [selectedColumns, setTableEmptyState]);

  // Function to render  column names in dropdown
  const getColumnOptions = () => {
    let columnOptions = [];
    for (let key in columns) {
      const columnOption = (
        <SelectOption
          key={`select-column-option-${columns[key].sortParam}`}
          value={columns[key].columnName}
          isDisabled={defaultColumns?.includes(columns[key].columnName)}
        />
      );
      columnOptions.push(columnOption);
    }
    return columnOptions;
  };

  // Function called when user click the columns dropdown
  const handleOnSelectToggle = (isSelectOpen) => {
    setSelectedColumns(selectedColumns);
    setIsSelectOpen(isSelectOpen);
  };

  //Function called when user check/uncheck columns in the dropdown
  const handleOnColumnsSelect = (event, selectedColumn: string) => {
    if (defaultColumns?.includes(selectedColumn)) {
      setIsSelectOpen(true);
      setSelectedColumns(selectedColumns);
    } else {
      if (selectedColumns.includes(selectedColumn)) {
        setSelectedColumns(selectedColumns.filter((item) => item !== selectedColumn));
        setIsSelectOpen(true);
      } else {
        setSelectedColumns([...selectedColumns, selectedColumn]);
        setIsSelectOpen(true);
      }
    }
  };

  return (
    <Select
      id="select-column-header"
      variant={SelectVariant.checkbox}
      aria-label="Select Input"
      placeholderText={<>Columns</>}
      isOpen={isSelectOpen}
      direction={props.direction}
      selections={selectedColumns}
      onToggle={handleOnSelectToggle}
      onSelect={handleOnColumnsSelect}
      isDisabled={tableResponseError}
    >
      {getColumnOptions()}
    </Select>
  );
};

export default ManageColumns;
