import React from 'react';
import { SelectDirection, ToolbarGroup, ToolbarItem } from '@patternfly/react-core';
import ExportColumns from './ExportColumns';
import ManageColumns from './ManageColumns';
import styles from './ManageAndExport.module.scss';

type ManageAndExportWrapperProps = {
  direction: SelectDirection;
};
const ManageAndExportWrapper = (props: ManageAndExportWrapperProps) => {
  return (
    <ToolbarGroup>
      <ToolbarItem className={styles.eiManageExportWrapper}>
        <ManageColumns direction={props.direction} />
      </ToolbarItem>
      <ToolbarItem>
        <ExportColumns />
      </ToolbarItem>
    </ToolbarGroup>
  );
};

export default ManageAndExportWrapper;
