import React from 'react';
import { Chip, ChipGroup } from '@patternfly/react-core';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { MonthPickerError } from '../constants/validationErrors';
import { GlobalState } from '../contexts/GlobalContext/GlobalContextReducer';
import { FilterOption } from '../models/FilterOption';
import { FilterDataSelection, PickMonthYear } from '../models/filter';

export function convertToFilterOption(list: string[] | undefined): FilterOption[] {
  let result = [];
  for (let item of list) {
    if (item) {
      result.push({ label: item, value: item });
    }
  }

  return result;
}
export const getFilterLookupTypes = (data) => {
  const lookupTypes = {
    partner_parent: [],
    partner_account: [],
    distributor_parent: [],
    distributor_account: [],
    geo: [],
    subregion: [],
    country: [],
    account_segment: [],
  };

  /* Loop through the data, for all arrays defined above, and ensure that every distinct value from all data accounts is included */
  if (data) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (let [accountId, accountDetails] of Object.entries(data)) {
      for (let [lookupType, lookupTypeDetails] of Object.entries(accountDetails)) {
        if (lookupTypes.hasOwnProperty(lookupType)) {
          const arrayReference = lookupTypes[lookupType];
          lookupTypeDetails.forEach((value) => {
            if (arrayReference.includes(value) === false) {
              arrayReference.push(value);
            }
          });
        }
      }
    }
  }

  for (const lookupType in lookupTypes) {
    lookupTypes[lookupType].sort();
  }

  //TODO: remove this logic once 0 is no longer returned as a valid country option
  if (lookupTypes?.country?.includes('0')) {
    const zeroIndex = lookupTypes?.country?.indexOf('0');
    lookupTypes?.country?.splice(zeroIndex, 1);
  }

  return lookupTypes;
};

export const chipGroupComponent = (selectedOptions, onSelectCallback) => {
  return (
    <ChipGroup numChips={3}>
      {(selectedOptions || []).map((currentChip) => {
        return (
          <Chip key={currentChip} onClick={(event) => onSelectCallback(event, currentChip)}>
            {currentChip}
          </Chip>
        );
      })}
    </ChipGroup>
  );
};

export const getFilterDropdownValues = (lookup: Record<string, string[]> | undefined): string[] => {
  if (!lookup) return [];
  return (Object.keys(lookup) || []).filter((ele) => ele != null || ele !== 'null').map((option) => option);
};

export const mapStringsToInputOption = (lookup: string): FilterOption => {
  return {
    label: lookup,
    value: lookup,
  };
};
export const mapStringsToInputOptions = (lookup: string[]): FilterOption[] => {
  return lookup.map((opt) => ({
    label: `${opt}`,
    value: `${opt}`,
  }));
};

export const unpackValuesForFilterAPI = (selections: FilterOption[]): string[] => {
  return selections.map((opt) => opt.value);
};

export const getSelectedValues = (option, selection) => {
  if (option.includes(selection)) {
    return option.filter((item) => item.toLowerCase() !== selection.toLowerCase());
  } else {
    return [...option, selection];
  }
};

export const reverseFilterTypesLookup = {
  partnerParent: 'partner_parent',
  partnerAccount: 'partner_account',
  distributorParent: 'distributor_parent',
  distributorAccount: 'distributor_account',
  geoRegion: 'geo',
  subregion: 'subregion',
  country: 'country',
  incumbentDistributor: 'incumbent_distributor',
  incumbentPartner: 'incumbent_partner',
  customerSegment: 'account_segment',
  calculationAmount: 'total_or_annualized',
  currencyCode: 'currency_code',
  partnerTier: 'partner_tier',
  accountIds: 'account_ids',
  viewContext: 'finder_partner_type_name',
};

export const getAllSelectedFilterValues = (globalState: GlobalState, params) => {
  let selectedFilterValues: any = {};
  let selectedFilterData: FilterDataSelection = {};
  const currentPath = window.location.pathname;
  if (globalState.filterState.isApplyFilter || globalState.filterState.isResetFilter) {
    selectedFilterData = cloneDeep(globalState.filterState.selectedFilterData || {});
  }

  Object.assign(selectedFilterData, params, globalState.otherFilters);

  for (let filterCategory in selectedFilterData) {
    let categoryName = filterCategory;

    if (reverseFilterTypesLookup.hasOwnProperty(filterCategory)) {
      categoryName = reverseFilterTypesLookup[filterCategory];
    }

    if (selectedFilterData.renewDates || selectedFilterData.reviewDates) {
      let startEndDates = {};
      if (currentPath.includes('/review')) {
        startEndDates = {
          start_month: selectedFilterData.reviewDates?.startMonth,
          start_year: selectedFilterData.reviewDates?.startYear,
          end_month: selectedFilterData.reviewDates?.endMonth,
          end_year: selectedFilterData.reviewDates?.endYear,
        };
      } else if (currentPath.includes('/renew')) {
        startEndDates = {
          start_month: selectedFilterData.renewDates?.startMonth,
          start_year: selectedFilterData.renewDates?.startYear,
          end_month: selectedFilterData.renewDates?.endMonth,
          end_year: selectedFilterData.renewDates?.endYear,
        };
      }
      Object.assign(selectedFilterValues, startEndDates);
    }

    Object.assign(selectedFilterValues, { [categoryName]: selectedFilterData[filterCategory] });
  }
  delete selectedFilterValues.reviewDates;
  delete selectedFilterValues.renewDates;
  delete selectedFilterValues.infraredDates;
  delete selectedFilterValues.defaultDates;
  return selectedFilterValues;
};

export const getDefaultStartMonthYear = (groupId) => {
  if (groupId === 'review') {
    const date = moment().subtract(12, 'months');
    return { month: date.get('month') + 1, year: date.get('year') };
  } else if (groupId === 'renew' || groupId === 'infrared') {
    return { month: moment().get('month') + 1, year: moment().get('year') };
  } else {
    return { month: moment().get('month') + 1, year: moment().get('year') };
  }
};

export const getDefaultEndMonthYear = (groupId) => {
  if (groupId === 'review') {
    const date = moment().subtract(1, 'months');
    return { month: date.get('month') + 1, year: date.get('year') };
  } else if (groupId === 'renew' || groupId === 'infrared') {
    const date = moment().add(11, 'months');
    return { month: date.get('month') + 1, year: date.get('year') };
  } else {
    return { month: moment().get('month') + 1, year: moment().get('year') };
  }
};

export const monthPickerValidationCheck = (startMonthYear: PickMonthYear, endMonthYear: PickMonthYear) => {
  if (startMonthYear.year > endMonthYear.year || (startMonthYear.year === endMonthYear.year && startMonthYear.month > endMonthYear.month)) {
    return MonthPickerError;
  }
  return '';
};
