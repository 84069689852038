export const currentPageLimit: number = 500;

export const DealManagementTableSortColumnIndex = {
  1: 'opportunity_contract_number',
  2: 'distributor_partner_account_name',
  3: 'opportunity_number',
  4: 'close_date',
  5: 'movement_detail',
  6: 'account_name',
  7: 'reseller_partner_account_name',
  8: 'total_opp_amount',
};

export const initialDistributorColumns: Array<string> = [
  'Contract #',
  'Distributor Account',
  'Opp #',
  'Close Date',
  'Movement Detail',
  'Customer Account',
  'Partner Account',
  'Opp Amount',
];

export const initialResellerColumns: Array<string> = [
  'Contract #',
  'Distributor Account',
  'Opp #',
  'Close Date',
  'Movement Detail',
  'Customer Account',
  'Partner Account',
  'Est. Opp Amount',
];

export const distributorColumnDetails = {
  opportunity_contract_number: {
    columnName: 'Contract #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  distributor_partner_account_name: {
    columnName: 'Distributor Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  opportunity_number: {
    columnName: 'Opp #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  close_date: {
    columnName: 'Close Date',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: false,
  },
  movement_detail: {
    columnName: 'Movement Detail',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: false,
  },
  reseller_partner_account_name: {
    columnName: 'Partner Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
  },
  total_opp_amount: {
    columnName: 'Opp Amount',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: true,
  },
};

export const resellerColumnDetails = {
  opportunity_contract_number: {
    columnName: 'Contract #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  distributor_partner_account_name: {
    columnName: 'Distributor Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  opportunity_number: {
    columnName: 'Opp #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  close_date: {
    columnName: 'Close Date',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: false,
  },
  movement_detail: {
    columnName: 'Movement Detail',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: false,
  },
  reseller_partner_account_name: {
    columnName: 'Partner Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
  },
  total_opp_amount: {
    columnName: 'Est. Opp Amount',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: true,
  },
};
