import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from '@patternfly/react-core';
import pageRoutes from '../../../../../utils/pageRoutes';
import routeHelper from '../../../../../utils/routeHelper';

interface BreadcrumbsProps {
  currentPath: any;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = (props) => {
  const { currentPath } = props;
  const currentRouteObject = routeHelper.getRouteObjectFromPath(currentPath);
  const homeRouteObject = pageRoutes.baseUrl;
  const pathParts = currentPath.toString().split('/');

  const renderBreakcrumbItem = (breadcrumbText, breadcrumbPath, isRouteActive) => {
    return (
      <BreadcrumbItem isActive={isRouteActive} key={`${breadcrumbText}${breadcrumbPath}${isRouteActive}`}>
        {breadcrumbPath ? <Link to={breadcrumbPath}>{breadcrumbText}</Link> : <span>{breadcrumbText}</span>}
      </BreadcrumbItem>
    );
  };

  const renderAdditionalBreadcrumbItems = (pathParts) => {
    let additionalBreakcrumbItems = [];

    // since pathPart[0] is the home url, and the last pathPart is the current page, we need to dynamically fill in the middle parts
    for (let i = 1; i < pathParts.length - 1; i++) {
      additionalBreakcrumbItems.unshift(renderBreakcrumbItem(routeHelper.getGroupRouteObjectFromGroupName(pathParts[i]).linkText, null, false));
    }

    return additionalBreakcrumbItems;
  };

  return (
    <Breadcrumb>
      {renderBreakcrumbItem(homeRouteObject.linkText, homeRouteObject.path, false)}
      {renderAdditionalBreadcrumbItems(pathParts)}
      {currentRouteObject && renderBreakcrumbItem(currentRouteObject.linkText, null, true)}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
