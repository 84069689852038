import React from 'react';
import '../Shared/constants';
import styles from './InfraredHero.module.scss';

const InfraredHero: React.FC<{}> = () => {
  return (
    <div className={styles.eiInfraredHero}>
      <h1 data-test="hero-content-title">
        See more with <br />
        <span className={styles.eiRed}>predictive intelligence</span>
        <br />& <span className={styles.eiRed}>deeper insights</span>
      </h1>
    </div>
  );
};

export default InfraredHero;
