import React, { useEffect, useRef, useState } from 'react';
import { Chart, ChartArea, ChartAxis, ChartCursorFlyout, ChartCursorTooltip, ChartGroup, createContainer } from '@patternfly/react-charts';
import { finderPartnerTypes } from '../../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import numberHelper from '../../../../utils/numberHelper';

interface PipelineByAccountSummaryChartProps {
  cumulativeRunningTotals: number[];
  cumulativeTotal: number;
  selectedSummaryCategoryDisplayName: string;
  viewContext: string;
}

const PipelineByAccountSummaryChart: React.FC<PipelineByAccountSummaryChartProps> = ({
  cumulativeRunningTotals,
  cumulativeTotal,
  selectedSummaryCategoryDisplayName,
  viewContext,
}) => {
  const ref = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);
  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  useEffect(() => {
    const handleResize = () => {
      setChartWidth(ref.current && ref.current.offsetParent.offsetWidth > 976 ? ref.current.offsetParent.offsetWidth - 50 : 976);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  const getTickValues = (cumulativeRunningTotalsLength: number): number[] => {
    /* Draw a set interval of tick marks depending on the number of records returned(100 records is the max):
    > 50 records = 1, 10, 20, 30...
    > 20 records = 1, 5, 10, 15 ...
    > 10 records = 1, 3, 5, 7...
    1 to 10 records = 1, 2, 3, 4... */

    let tickValues = [1];

    if (!isNaN(cumulativeRunningTotalsLength) && cumulativeRunningTotalsLength > 1) {
      let tickInterval = 1;

      if (cumulativeRunningTotalsLength > 50) {
        tickInterval = 10;
      } else if (cumulativeRunningTotalsLength > 20) {
        tickInterval = 5;
      } else if (cumulativeRunningTotalsLength > 10) {
        tickInterval = 2;
      }

      let tickMarks = Math.ceil(cumulativeRunningTotalsLength / tickInterval);

      for (let i = 1; i <= tickMarks; i++) {
        if (tickInterval === 2) {
          tickValues.push(i * tickInterval - 1);
        } else {
          tickValues.push(i * tickInterval);
        }
      }
    }

    return tickValues;
  };

  const CursorVoronoiContainer = createContainer('voronoi', 'cursor');
  const HTMLLegendContent = (props) => {
    const { datum, text, x, y } = props;

    return (
      <g>
        <foreignObject height="100%" width="100%" x={x - 40} y={y - 45}>
          <table>
            <thead>
              <tr>
                <th colSpan={1} style={{ color: '#f0f0f0', fontSize: '16px', fontWeight: 700, paddingBottom: '16px' }}>
                  {`Top ${datum?.x} Accounts`}
                </th>
              </tr>
            </thead>
            <tbody style={{ borderLeft: '4px solid #FFFFFF' }}>
              <tr>
                <td style={{ color: '#f0f0f0', fontSize: '21px', paddingLeft: '12px', textAlign: 'left' }}>
                  {`${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}${text}`}
                </td>
              </tr>
              <tr>
                <td style={{ color: '#f0f0f0', fontSize: '14px', paddingLeft: '12px', textAlign: 'left' }}>{`Cumulative Opportunity`}</td>
              </tr>
              <tr>
                <td style={{ color: '#f0f0f0', fontSize: '21px', paddingLeft: '12px', paddingTop: '12px', textAlign: 'left' }}>
                  {`${Math.round((datum?.y / cumulativeTotal) * 100)}%`}
                </td>
              </tr>
              <tr>
                <td style={{ color: '#f0f0f0', fontSize: '14px', paddingLeft: '12px', textAlign: 'left' }}>{'of Cumulative Amount'}</td>
              </tr>
            </tbody>
          </table>
        </foreignObject>
      </g>
    );
  };

  const renderChart = () => {
    return (
      <Chart
        data-test="pipeline-by-account-summary-chart"
        ariaDesc={`Trendline showing the number of ${
          viewContext === finderPartnerTypes.DISTRIBUTOR ? 'partners' : 'customers'
        } up to 100 ranked by highest opportunity amount on the x axis and cumulative amount on the y axis.`}
        containerComponent={
          <CursorVoronoiContainer
            cursorDimension="x"
            labels={({ datum }) => `${numberHelper.formatAsCompactDollarAmount(datum.y, currencySelector)}`}
            labelComponent={
              <ChartCursorTooltip
                flyoutComponent={<ChartCursorFlyout />}
                flyoutHeight={200}
                flyoutPadding={{ top: 0, right: 0, bottom: 70, left: -58 }}
                flyoutWidth={244}
                labelComponent={<HTMLLegendContent />}
              />
            }
            mouseFollowTooltips
            voronoiDimension="x"
            voronoiPadding={0}
          />
        }
        height={375}
        name="cumulative"
        padding={{ bottom: 70, left: 120, right: 15, top: 20 }}
        maxDomain={{ x: cumulativeRunningTotals?.length }}
        width={chartWidth}
      >
        <ChartAxis
          label={`Number of ${
            viewContext === finderPartnerTypes.DISTRIBUTOR ? 'Partners' : 'Customers'
          } up to 100 ranked by highest ${selectedSummaryCategoryDisplayName}`}
          style={{ axisLabel: { fontWeight: 600 } }}
          tickCount={cumulativeRunningTotals?.length || 1}
          tickValues={getTickValues(cumulativeRunningTotals?.length)}
        />
        <ChartAxis
          label="Cumulative Amount"
          style={{ axisLabel: { fontWeight: 600, padding: 100 } }}
          tickFormat={(x) => `${numberHelper.formatAsCompactDollarAmount(x, currencySelector)}`}
          dependentAxis /*showGrid*/
        />
        <ChartGroup>
          <ChartArea
            data={cumulativeRunningTotals}
            style={{
              data: { fill: '#71BF6F', fillOpacity: 0.25, stroke: '#317631', strokeWidth: 2 },
            }}
          />
        </ChartGroup>
      </Chart>
    );
  };

  return (
    <div ref={ref} style={{ height: '375px', width: 'auto' }}>
      {cumulativeRunningTotals !== null && cumulativeRunningTotals.length > 0 && viewContext !== '' && renderChart()}
    </div>
  );
};

export default PipelineByAccountSummaryChart;
