import React, { useEffect, useState } from 'react';
import {
  Chart,
  ChartAxis,
  ChartBar,
  ChartDonutUtilization,
  ChartGroup,
  ChartLabel,
  ChartLegend,
  ChartStack,
  ChartVoronoiContainer,
} from '@patternfly/react-charts';
import { Card, CardBody, Skeleton, Split, SplitItem } from '@patternfly/react-core';
import EcoIntelEmptyState from '../../../components/EcoIntelEmptyState';
import { errorStateSummaryDetails } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import numberHelper from '../../../utils/numberHelper';
import styles from './BookingsPerformanceSummary.module.scss';

interface BookingsPerformanceSummaryProps {
  bookingsPerformanceSummaryData: any;
  isFetching: boolean;
  responseError: any;
  viewContext: string;
}

const BookingsPerformanceSummary: React.FC<BookingsPerformanceSummaryProps> = ({
  bookingsPerformanceSummaryData,
  isFetching,
  responseError,
  viewContext,
}) => {
  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  const summaryData = bookingsPerformanceSummaryData?.bookings_performance;

  return (
    <Card className={styles.eiBookingsPerformanceSummaryCard}>
      <CardBody>
        {responseError ? (
          <EcoIntelEmptyState emptyStateProps={errorStateSummaryDetails} />
        ) : (
          <Split className="eiBookingsPerformanceSummarySplit">
            <SplitItem isFilled>
              <div style={{ height: '310px', width: '730px' }}>
                {isFetching ? (
                  <Skeleton shape="square" width="100%" height="310px" screenreaderText="Loading small circle contents" />
                ) : (
                  <>
                    {summaryData && (
                      <Chart
                        name="bookings-performance-summary"
                        ariaDesc="Chart illustrates the bookings performance as both dollar amounts and percentages for Renewal Available, Renewal at Par, Up-Sell, Cross-Sell, and Performance."
                        data-test="bookings-performance-summary-chart"
                        containerComponent={<ChartVoronoiContainer labels={({ datum }) => `${datum.x}: ${datum.y}`} constrainToVisibleArea />}
                        domain={{ y: [0, 100] }}
                        domainPadding={{ x: [90, 0] }}
                        legendPosition="right"
                        legendComponent={
                          <ChartLegend
                            x={0}
                            y={0}
                            title="Bookings Performance"
                            orientation="horizontal"
                            gutter={20}
                            style={{ title: { fontSize: 16, fontWeight: 500, fontFamily: 'RedHatDisplay' } }}
                            data={[]}
                          />
                        }
                        height={310}
                        padding={{
                          bottom: 75,
                          left: 50,
                          right: 55,
                          top: 100,
                        }}
                        width={730}
                      >
                        <ChartAxis
                          style={{
                            tickLabels: {
                              fontWeight: 500,
                              fill: '#151515',
                              fontSize: '14px',
                            },
                            ticks: {
                              stroke: '#D2D2D2',
                              strokeWidth: '2.5px',
                              strokeOpacity: '1',
                              strokeLinecap: 'square',
                              strokeLinejoin: 'unset',
                            },
                            axis: {
                              strokeWidth: '1px',
                              stroke: '#D2D2D2',
                              fill: '#D2D2D2',
                              strokeOpacity: '1',
                              strokeLinecap: 'square',
                            },
                          }}
                          dependentAxis
                          tickValues={[50, 100]}
                          tickFormat={(x) => `${x}%`}
                        />
                        <ChartAxis
                          style={{
                            tickLabels: {
                              fontWeight: 500,
                              fill: '#151515',
                              fontSize: '14px',
                              lineHeight: 100,
                            },
                            ticks: {
                              stroke: '#D2D2D2',
                              strokeWidth: '3.5px',
                              strokeOpacity: '1',
                              strokeLinecap: 'square',
                              strokeLinejoin: 'unset',
                            },
                            axis: {
                              strokeWidth: '1px',
                              stroke: '#D2D2D2',
                              fill: '#D2D2D2',
                              strokeOpacity: '1',
                              strokeLinecap: 'square',
                            },
                          }}
                        />
                        <ChartGroup>
                          <ChartStack>
                            <ChartBar
                              name="renewals-available-chart"
                              barWidth={74}
                              colorScale={['#A2D9D9']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Renewal Available`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Renewal Available \n ${numberHelper.formatAsCompactDollarAmount(
                                    summaryData?.renewal_available,
                                    currencySelector
                                  )}`,
                                  y: 100,
                                  y0: 0,
                                },
                              ]}
                              labels={({ datum }) => datum.y + '%'}
                              labelComponent={<ChartLabel y={90} />}
                            />
                          </ChartStack>
                          <ChartStack>
                            <ChartBar
                              name="renewal-at-par-chart"
                              barWidth={74}
                              colorScale={['#003737']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Renewal @ Par`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Renewal @ Par \n ${numberHelper.formatAsCompactDollarAmount(summaryData?.renewal_at_par, currencySelector)}`,
                                  y: summaryData?.renewal_at_par_percent,
                                  y0: 0,
                                },
                              ]}
                              labels={({ datum }) => datum.y + '%'}
                              labelComponent={<ChartLabel y={90} />}
                            />
                            <ChartBar
                              name="down-sell-chart"
                              barWidth={74}
                              colorScale={['#A30000']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Renewal @ Par`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Renewal @ Par \n ${numberHelper.formatAsCompactDollarAmount(summaryData?.renewal_at_par, currencySelector)}`,
                                  y: 100 - summaryData?.renewal_at_par_percent,
                                  y0: 0,
                                },
                              ]}
                              style={{ labels: { fill: 'white' } }}
                              labels={['Down-Sell']}
                              labelComponent={<ChartLabel y={140} />}
                            />
                          </ChartStack>
                          <ChartStack>
                            <ChartBar
                              name="up-sell-chart"
                              barWidth={74}
                              colorScale={['#73C5C5']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Up-Sell`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Up-Sell \n ${numberHelper.formatAsCompactDollarAmount(summaryData?.up_sell, currencySelector)}`,
                                  y: summaryData?.up_sell_percent,
                                  y0: summaryData?.renewal_at_par_percent,
                                },
                              ]}
                              labels={({ datum }) => datum.y + '%'}
                              labelComponent={<ChartLabel y={90} />}
                            />
                          </ChartStack>
                          <ChartStack>
                            <ChartBar
                              name="cross-sell-chart"
                              barWidth={74}
                              colorScale={['#005F60']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Cross-Sell`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Cross-Sell \n ${numberHelper.formatAsCompactDollarAmount(summaryData?.cross_sell, currencySelector)}`,
                                  y: summaryData?.cross_sell_percent,
                                  y0: summaryData?.renewal_at_par_percent + summaryData?.up_sell_percent,
                                },
                              ]}
                              labels={({ datum }) => datum.y + '%'}
                              labelComponent={<ChartLabel y={90} />}
                            />
                          </ChartStack>
                          <ChartStack>
                            <ChartBar
                              name="performance-chart"
                              barWidth={74}
                              colorScale={['#009596']}
                              data={[
                                {
                                  name: `${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '}Performance`,
                                  x: `${
                                    viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '
                                  }Performance \n ${numberHelper.formatAsCompactDollarAmount(summaryData?.performance, currencySelector)}`,
                                  y: summaryData?.performance_percent,
                                  y0: 0,
                                },
                              ]}
                              labels={({ datum }) => datum.y + '%'}
                              labelComponent={<ChartLabel y={90} />}
                            />
                          </ChartStack>
                        </ChartGroup>
                      </Chart>
                    )}
                  </>
                )}
              </div>
            </SplitItem>
            <SplitItem>
              <div style={{ height: '320px', width: '320px' }}>
                {isFetching ? (
                  <Skeleton shape="square" width="100%" height="320px" screenreaderText="Loading small circle contents" />
                ) : (
                  <>
                    {summaryData && (
                      <ChartDonutUtilization
                        ariaDesc={`Donut chart illustrating ${summaryData?.on_time_percent}% of bookings are on time and ${summaryData?.late_percent}% are late}`}
                        padding={{
                          bottom: 15, // Adjusted to accommodate legend
                          left: 0,
                          right: 0,
                          top: 70,
                        }}
                        constrainToVisibleArea={true}
                        data={{ x: 'On-Time', y: summaryData?.on_time_percent }}
                        height={280}
                        innerRadius={91}
                        radius={98}
                        labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
                        subTitle={'On-Time'}
                        colorScale={['#71BF6F', '#C60011']}
                        title={summaryData?.on_time_percent + '% \n'}
                        width={280}
                        legendComponent={
                          <ChartLegend
                            x={0}
                            y={0}
                            title="&nbsp;&nbsp;Late vs. On-Time"
                            orientation="horizontal"
                            gutter={20}
                            style={{
                              title: { fontSize: 16, fontWeight: 500, fontFamily: 'RedHatDisplay' },
                              labels: { fontSize: 14, fontWeight: 'lighter', fontFamily: 'RedHatDisplay' },
                            }}
                            data={[{ name: 'Late' }, { name: 'On-Time' }]}
                            label={() => 'label'}
                            colorScale={['#C60011', '#71BF6F']}
                          />
                        }
                        titleComponent={
                          <ChartLabel
                            style={[
                              {
                                fontSize: 24,
                                fill: '#151515',
                                display: 'block',
                                margin: '10px',
                              },
                            ]}
                          />
                        }
                        subTitleComponent={<ChartLabel style={[{ fontSize: 14, fill: '#151515', padding: '10px' }]} />}
                      />
                    )}
                  </>
                )}
              </div>
            </SplitItem>
          </Split>
        )}
      </CardBody>
    </Card>
  );
};

export default BookingsPerformanceSummary;
