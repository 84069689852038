import React from 'react';
import { Link } from 'react-router-dom';
import { Button, EmptyState, EmptyStateBody, EmptyStateIcon, Flex, FlexItem, Title } from '@patternfly/react-core';
import { EcoIntelEmptyStateProps } from '../../models/emptyState';
import CardBodyWithImage from '../CardBodyWithImage';
import styles from './EcoIntelEmptyState.module.scss';

const EcoIntelEmptyState: React.FC<EcoIntelEmptyStateProps> = ({ emptyStateProps, isCardVersion }) => {
  const getEmptyStateActionIcon = () => {
    return <>{emptyStateProps?.emptyStateActionIcon}</>;
  };

  const getEmptyStateAction = (path: string, label: string) => {
    return (
      <Button className={styles.eiEmptyStatePrimaryButton} variant="link" component={(props: any) => <Link {...props} to={path} />}>
        {label} <span>{getEmptyStateActionIcon()}</span>
      </Button>
    );
  };

  const getEmptyStateBodyActionSupportMail = () => {
    return (
      <Button isInline className={styles.eiEmptyStateMailButton} component="a" href={emptyStateProps?.emptyStateBodyActionMailId} variant="link">
        {emptyStateProps?.emptyStateBodyActionMailLabel}
      </Button>
    );
  };

  const emptyStateText = (
    <>
      {emptyStateProps?.emptyStateTitle && (
        <Title className={styles.eiEmptyStateTitle} headingLevel={emptyStateProps?.emptyStateHeadingLevel} size="lg">
          {emptyStateProps?.emptyStateTitle}
        </Title>
      )}
      {emptyStateProps?.emptyStateBody && (
        <EmptyStateBody className={styles.eiEmptyStateBody}>
          {emptyStateProps?.emptyStateBody}
          {emptyStateProps?.emptyStateBodyActionMail && getEmptyStateBodyActionSupportMail()}
          {emptyStateProps?.emptyStateBodyConclusion && emptyStateProps?.emptyStateBodyConclusion}
        </EmptyStateBody>
      )}
      {emptyStateProps?.emptyStateActions && (
        <section className={styles.eiEmptyStatePrimaryActions}>
          {getEmptyStateAction(emptyStateProps?.emptyStateActionPath, emptyStateProps?.emptyStateActionLabel)}
        </section>
      )}
    </>
  );

  return isCardVersion ? (
    <CardBodyWithImage
      data-test="card-empty-state"
      imageComponent={<EmptyStateIcon className={styles.eiEmptyStateIcon} icon={emptyStateProps?.emptyStateIcon} />}
      textComponent={emptyStateText}
    />
  ) : (
    <section className={styles.eiEmptyStateWrapper} data-test="error-state">
      <EmptyState
        data-test="empty-state"
        className={`${styles.eiEmptyState} 
      ${emptyStateProps?.emptyStateTheme === 'dark' && styles.eiEmptyStateDarkTheme}`}
      >
        <Flex
          direction={{ default: 'row' }}
          flexWrap={{ default: 'nowrap' }}
          justifyContent={{ default: 'justifyContentFlexStart' }}
          alignItems={{ default: 'alignItemsCenter' }}
        >
          <FlexItem spacer={{ default: 'spacer3xl' }}>
            <EmptyStateIcon className={styles.eiEmptyStateIcon} icon={emptyStateProps?.emptyStateIcon} />
          </FlexItem>
          <FlexItem>{emptyStateText}</FlexItem>
        </Flex>
      </EmptyState>
    </section>
  );
};

export default EcoIntelEmptyState;
