import React from 'react';
import { Alert, AlertActionCloseButton, AlertGroup, AlertVariant } from '@patternfly/react-core';
import { useToastContext } from '../../contexts/Toast/ToastContext';

interface ToastProps {}

const Toast: React.FC<ToastProps> = () => {
  const { maxToastsDisplayed, onOverflowMessageClick, overflowMessage, removeToast, toasts } = useToastContext();

  const onDismiss = (id) => {
    removeToast(id);
  };

  return (
    <AlertGroup aria-live="assertive" isToast isLiveRegion onOverflowClick={() => onOverflowMessageClick()} overflowMessage={overflowMessage}>
      {toasts.slice(0, maxToastsDisplayed).map(({ id, title, variant }) => (
        <Alert
          actionClose={<AlertActionCloseButton title={title} variantLabel={`${variant} alert`} onClose={() => onDismiss(id)} />}
          aria-label={`${variant} alert: ${title}`}
          key={id}
          title={title}
          variant={AlertVariant[variant]}
          data-test="toast"
        />
      ))}
    </AlertGroup>
  );
};

export default Toast;
