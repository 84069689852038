import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Stack, StackItem, Text, TextVariants } from '@patternfly/react-core';
import { useGlobalContext } from '../../../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../../../contexts/GlobalContext/GlobalContextReducer';
import { FilterOption } from '../../../../../models/FilterOption';
import { VirtualizedSelect } from '../../../../VirtualizedSelect/VirtualizedSelect';
import styles from './LocationFilter.module.scss';

const LocationFilter: React.FC = () => {
  const { globalState, dispatch } = useGlobalContext();
  const [selectedGeos, setSelectedGeos] = useState<FilterOption[]>([]);
  const [selectedSubregions, setSelectedSubregions] = useState<FilterOption[]>([]);
  const [selectedCountries, setSelectedCountries] = useState<FilterOption[]>([]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.lookupState) {
        const lookups = globalState.lookupState;
        const selectedFilters = globalState?.filterState?.selectedFilterData;

        setSelectedGeos(
          selectedFilters?.geoRegion?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.geoLookup
        );
        setSelectedSubregions(
          selectedFilters?.subregion?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.subregionLookup
        );
        setSelectedCountries(
          selectedFilters?.country?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.countryLookup
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lookups = globalState.lookupState;
    unstable_batchedUpdates(() => {
      if (globalState.filterState.isResetFilter) {
        setSelectedGeos(lookups.geoLookup);
        setSelectedSubregions(lookups.subregionLookup);
        setSelectedCountries(lookups.countryLookup);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.filterState.isResetFilter]);

  const onFilterSelect = (
    selection: FilterOption[],
    setStateCallback: (optionArray: FilterOption[]) => void,
    eventType: StateActions,
    payloadKey: string
  ) => {
    setStateCallback(selection);

    dispatch({
      type: eventType,
      payload: {
        [payloadKey]: selection.map((it) => it.value),
      },
    });
  };

  return (
    <Stack hasGutter className={styles.eiFilterMenu}>
      <StackItem>
        <Text component={TextVariants.small} id="geo-region-select">
          Geographic Region
        </Text>
        <VirtualizedSelect
          placeHolder={'Select Geographic Region'}
          isMulti
          isSearchable
          remainOpenOnSelect={true}
          options={globalState.lookupState.geoLookup}
          selections={selectedGeos}
          onChange={(s: FilterOption[]) => onFilterSelect(s, setSelectedGeos, StateActions.SetGeoRegion, 'geoRegion')}
          dataTest="location-filter"
        />
      </StackItem>
      <StackItem>
        <Text component={TextVariants.small} id="subregion-select">
          Subregion
        </Text>
        <VirtualizedSelect
          placeHolder={'Select Subregion'}
          isMulti
          isSearchable
          remainOpenOnSelect={true}
          options={globalState.lookupState.subregionLookup}
          selections={selectedSubregions}
          onChange={(s: FilterOption[]) => onFilterSelect(s, setSelectedSubregions, StateActions.SetSubregion, 'subregion')}
        />
      </StackItem>
      <StackItem>
        <Text component={TextVariants.small} id="country-select">
          Country
        </Text>
        <VirtualizedSelect
          placeHolder={'Select Country'}
          isMulti
          isSearchable
          remainOpenOnSelect={true}
          options={globalState.lookupState.countryLookup}
          selections={selectedCountries}
          onChange={(s: FilterOption[]) => onFilterSelect(s, setSelectedCountries, StateActions.SetCountry, 'country')}
        />
      </StackItem>
    </Stack>
  );
};

export default LocationFilter;
