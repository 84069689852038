import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import { Button, Popover, PopoverPosition, Text, TextContent } from '@patternfly/react-core';
import InfoIcon from '@patternfly/react-icons/dist/esm/icons/info-icon';
import moment from 'moment';
import { useTableContext } from '../../../../../contexts/Table/TableContext';
import pageRoutes from '../../../../../utils/pageRoutes';
import { getColumnHeadersToExport } from '../../../../../utils/tableHelper';
import styles from './ExportColumns.module.scss';

const ExportColumns: React.FC = () => {
  const { selectedColumns, currentPageData, tableName, tableResponseError } = useTableContext();
  const [exportData, setExportData] = useState({ data: [], headers: [] });

  useEffect(() => {
    if (currentPageData) {
      setExportData({
        data: currentPageData,
        headers: getColumnHeadersToExport(selectedColumns, tableName),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageData, selectedColumns]);

  return (
    <section className={styles.eiButtonWrapper}>
      <CSVLink
        className={`${styles.eiExportCurrentViewButton} ${tableResponseError || !selectedColumns?.length ? styles.eiDisablePointerEvents : ''}`}
        data={exportData.data}
        aria-label="Export current view"
        headers={exportData.headers}
        aria-disabled={tableResponseError || !selectedColumns?.length ? true : false}
        filename={`${tableName}-${moment.utc().format('YYYY-MM-DD-h-mm-ss')}-utc.csv`}
      >
        Export Current View
      </CSVLink>

      <Popover
        aria-label="Export popover"
        className="eiDarkPopover"
        position={PopoverPosition.top}
        showClose={false}
        headerContent={
          <TextContent>
            <Text>Quick Export</Text>
          </TextContent>
        }
        bodyContent={
          <TextContent>
            <Text component="p">Download displayed rows and columns to a .csv file.</Text>
            <Text>
              Or use the{' '}
              <Button
                aria-label="Go to Export"
                variant="link"
                isInline
                component={(props: any) => <Link {...props} to={pageRoutes.exportUrl.path} />}
              >
                {pageRoutes.exportUrl.linkText}
              </Button>{' '}
              tool to download complete datasets.
            </Text>
          </TextContent>
        }
      >
        <Button variant="plain" aria-label="Export info icon" icon={<InfoIcon />} className="eiIconButton"></Button>
      </Popover>
    </section>
  );
};

export default ExportColumns;
