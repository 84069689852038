import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Alert, Flex, FlexItem, Radio, Stack, StackItem, Text, TextVariants } from '@patternfly/react-core';
import { ExclamationCircleIcon } from '@patternfly/react-icons';
import { map } from 'lodash';
import { defaultMonths } from '../../../../../constants/filter';
import { useGlobalContext } from '../../../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../../../contexts/GlobalContext/GlobalContextReducer';
import { FilterDataSelection, MonthYearRange, PickMonthYear } from '../../../../../models/filter';
import { getDefaultEndMonthYear, getDefaultStartMonthYear, monthPickerValidationCheck } from '../../../../../utils/filterHelper';
import routeHelper from '../../../../../utils/routeHelper';
import MonthPicker from '../../../../MonthPicker';
import styles from './OtherFilter.module.scss';

const OtherFilter: React.FC = () => {
  const { globalState, dispatch } = useGlobalContext();
  const routeObject = routeHelper.getRouteObjectFromPath(useLocation()?.pathname);
  const currentPath = useLocation()?.pathname;
  const [calculationCheck, setCalculationCheck] = useState({ TB: false, SYB: true });
  const [startMonthYear, setStartMonthYear] = useState<MonthYearRange>({ months: [], years: [] });
  const [selectedStartMonthYear, setSelectedStartMonthYear] = useState<PickMonthYear>(getDefaultStartMonthYear(routeObject?.groupId));
  const [monthPickerValidationError, setMonthPickerValidationError] = useState('');
  const [endMonthYear, setEndMonthYear] = useState<MonthYearRange>({ months: [], years: [] });
  const [selectedEndMonthYear, setSelectedEndMonthYear] = useState<PickMonthYear>(getDefaultEndMonthYear(routeObject?.groupId));

  const setSelectedStartEndDatesByRoute = () => {
    const selectedFilterOptions = globalState.filterState.selectedFilterData || {};
    const setDates = (dateRoute: string) => {
      setSelectedStartMonthYear({ month: selectedFilterOptions[dateRoute]?.startMonth, year: selectedFilterOptions[dateRoute]?.startYear });
      setSelectedEndMonthYear({ month: selectedFilterOptions[dateRoute]?.endMonth, year: selectedFilterOptions[dateRoute]?.endYear });
    };

    switch (routeObject?.groupId) {
      case 'review': {
        if (selectedFilterOptions.reviewDates) setDates('reviewDates');
        break;
      }
      case 'renew': {
        if (selectedFilterOptions.renewDates) setDates('renewDates');
        break;
      }
      case 'infrared': {
        if (selectedFilterOptions.infraredDates) setDates('infraredDates');
        break;
      }
      default: {
        if (selectedFilterOptions.defaultDates) setDates('defaultDates');
        break;
      }
    }
  };

  const dispatchStartEndDatesByRoute = (selectedDate) => {
    let selectedStartEndDate: FilterDataSelection = {};

    switch (routeObject?.groupId) {
      case 'review': {
        selectedStartEndDate = { reviewDates: { ...(globalState.filterState.selectedFilterData?.reviewDates || {}), ...selectedDate } };
        break;
      }
      case 'renew': {
        selectedStartEndDate = { renewDates: { ...(globalState.filterState.selectedFilterData?.renewDates || {}), ...selectedDate } };
        break;
      }
      case 'infrared': {
        selectedStartEndDate = { infraredDates: { ...(globalState.filterState.selectedFilterData?.infraredDates || {}), ...selectedDate } };
        break;
      }
      default: {
        selectedStartEndDate = { defaultDates: { ...(globalState.filterState.selectedFilterData?.defaultDates || {}), ...selectedDate } };
        break;
      }
    }

    dispatch({
      type: StateActions.filterData,
      payload: {
        filterState: {
          ...globalState.filterState,
          selectedFilterData: { ...globalState.filterState.selectedFilterData, ...selectedStartEndDate },
        },
      },
    });
  };

  const dispatchValidationErrorOnly = () => {
    const validationError = Object.assign({}, globalState.filterState.validationError);
    delete validationError?.monthPickerValidation;
    globalState.filterState.validationError = validationError;
    dispatch({
      type: StateActions.filterData,
      payload: {
        filterState: {
          ...globalState.filterState,
          validationError: { ...validationError },
        },
      },
    });
  };

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.lookupState) {
        const filterData = globalState.filterState.originalFilterData;
        setStartMonthYear({ months: map(filterData['start_month'], 'text'), years: filterData['start_year'] });
        setEndMonthYear({ months: map(filterData['end_month'], 'text'), years: filterData['end_year'] });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedStartEndDatesByRoute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.filterState.isApplyFilter) {
        const selectedFilterOptions = globalState.filterState.selectedFilterData || {};
        if (selectedFilterOptions.calculationAmount) {
          const sybOrTotal = {
            TB: false,
            SYB: false,
          };

          sybOrTotal[selectedFilterOptions.calculationAmount] = true;
          setCalculationCheck(sybOrTotal);
        }

        setSelectedStartEndDatesByRoute();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(globalState.filterState.isApplyFilter || {}).valueOf()]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.filterState.isResetFilter) {
        setCalculationCheck({ TB: false, SYB: true });
        setSelectedStartMonthYear(getDefaultStartMonthYear(routeObject.groupId));
        setSelectedEndMonthYear(getDefaultEndMonthYear(routeObject.groupId));
        setMonthPickerValidationError('');
        dispatchValidationErrorOnly();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.filterState.isResetFilter]);

  const onStartMonthChange = (year, month) => {
    const selected = { month: month, year: year };
    const validationError = monthPickerValidationCheck(selected, selectedEndMonthYear);

    if (validationError) {
      setMonthPickerValidationError(validationError);
      setSelectedStartMonthYear(selected);
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: {
            ...globalState.filterState,
            validationError: { ...globalState.filterState.validationError, monthPickerValidation: validationError },
          },
        },
      });
    } else {
      setMonthPickerValidationError('');
      setSelectedStartMonthYear(selected);
      dispatchValidationErrorOnly();
      dispatchStartEndDatesByRoute({
        startMonth: selected.month,
        startYear: selected.year,
        endMonth: selectedEndMonthYear.month,
        endYear: selectedEndMonthYear.year,
      });
    }
  };

  const onEndMonthChange = (year, month) => {
    const selected = { month: month, year: year };
    const validationError = monthPickerValidationCheck(selectedStartMonthYear, selected);

    if (validationError) {
      setMonthPickerValidationError(validationError);
      setSelectedEndMonthYear(selected);
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: {
            ...globalState.filterState,
            validationError: { ...globalState.filterState.validationError, monthPickerValidation: validationError },
          },
        },
      });
    } else {
      setMonthPickerValidationError('');
      setSelectedEndMonthYear(selected);
      dispatchValidationErrorOnly();
      dispatchStartEndDatesByRoute({
        endMonth: selected.month,
        endYear: selected.year,
        startMonth: selectedStartMonthYear.month,
        startYear: selectedStartMonthYear.year,
      });
    }
  };

  const onCalculationChange = (checked, event) => {
    const { value } = event.currentTarget;
    setCalculationCheck(value === 'SYB' ? { TB: !checked, SYB: checked } : { TB: checked, SYB: !checked });
    dispatch({
      type: StateActions.filterData,
      payload: {
        filterState: { ...globalState.filterState, selectedFilterData: { ...globalState.filterState.selectedFilterData, calculationAmount: value } },
      },
    });
  };

  return (
    <Stack hasGutter className={styles.eiFilterMenu}>
      <StackItem className={styles.eiAmountsStackItem}>
        <Text component={TextVariants.small} id="calculation-radio" className={styles.eiAmountsText}>
          Amounts Shown
        </Text>
        <div className={styles.eiCalculation}>
          <Radio
            isChecked={calculationCheck.SYB}
            name="calculation-radio"
            onChange={onCalculationChange}
            label="Annualized"
            id="annualized-radio"
            value="SYB"
            className={styles.eiRadio}
          />
          <Radio
            isChecked={calculationCheck.TB}
            name="calculation-radio"
            onChange={onCalculationChange}
            label="Total"
            id="total-radio"
            value="TB"
            className={styles.eiRadio}
          />
        </div>
      </StackItem>
      <StackItem>
        <Flex className={styles.eiMonthPickers}>
          <FlexItem className={styles.eiCalendarAndLabel}>
            <Text component={TextVariants.small}>
              {monthPickerValidationError ? <ExclamationCircleIcon className={styles.eiValidationErrorIcon} /> : null} Start Date
            </Text>
            {startMonthYear.years.length > 0 ? (
              <MonthPicker
                dataTest="start-date-calendar-button"
                years={startMonthYear.years}
                months={startMonthYear.months ? startMonthYear.months : defaultMonths}
                value={selectedStartMonthYear}
                onChange={onStartMonthChange}
              />
            ) : null}
          </FlexItem>
          <FlexItem className={styles.eiCalendarAndLabel}>
            <Text component={TextVariants.small}>
              {monthPickerValidationError ? <ExclamationCircleIcon className={styles.eiValidationErrorIcon} /> : null} End Date
            </Text>
            {endMonthYear.years.length > 0 ? (
              <MonthPicker
                dataTest="end-date-calendar-button"
                years={endMonthYear.years}
                months={endMonthYear.months ? endMonthYear.months : defaultMonths}
                value={selectedEndMonthYear}
                onChange={onEndMonthChange}
              />
            ) : null}
          </FlexItem>
        </Flex>
      </StackItem>
      <StackItem className={styles.eiValidationError}>
        {monthPickerValidationError ? (
          <Alert data-test="filter-validation-error" variant="danger" isInline title={monthPickerValidationError} />
        ) : null}
      </StackItem>
    </Stack>
  );
};

export default OtherFilter;
