import React from 'react';
import { Card, CardBody, CardTitle, Split, SplitItem } from '@patternfly/react-core';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import pageRoutes from '../../../utils/pageRoutes';
import DealManagementChart from './DealManagementChart';
import DealManagementTable from './DealManagementTable';
import styles from './DealManagementOverview.module.scss';

interface DealManagementOverviewProps {
  dealManagement: any;
  getCardTitleContent: Function;
  isFetching: boolean;
  viewContext: any;
}

const DealManagementOverview: React.FC<DealManagementOverviewProps> = ({ dealManagement, getCardTitleContent, isFetching, viewContext }) => {
  const dealManagementCategoryMappings = {
    originalRenewals: {
      name: 'Original Renewals',
      dealManagementAmountColumnData: 'original_renewals_amount',
      dealManagementCountColumnData: 'original_renewals_count',
    },
    transferredIn: {
      name: 'Transferred In',
      dealManagementAmountColumnData: 'transfer_in_amount',
      dealManagementCountColumnData: 'transfer_in_count',
    },
    transferredOut: {
      name: 'Transferred Out',
      dealManagementAmountColumnData: 'transfer_out_amount',
      dealManagementCountColumnData: 'transfer_out_count',
    },
    closedLost: { name: 'Closed Lost', dealManagementAmountColumnData: 'closed_lost_amount', dealManagementCountColumnData: 'closed_lost_count' },
    renewedOpportunities: {
      name: 'Renewed Opportunities',
      dealManagementAmountColumnData: 'renewed_opportunities_amount',
      dealManagementCountColumnData: 'renewed_opportunities_count',
    },
    transferredInternally: {
      name: 'Transferred Internally',
      dealManagementAmountColumnData:
        viewContext === finderPartnerTypes.DISTRIBUTOR ? 'transferred_between_distis_amount' : 'transferred_between_partners_amount',
      dealManagementCountColumnData:
        viewContext === finderPartnerTypes.DISTRIBUTOR ? 'transferred_between_distis_count' : 'transferred_between_partners_count',
    },
  };

  return (
    <Card className={styles.eiDealManagementOverviewContainer}>
      <CardTitle>{getCardTitleContent(pageRoutes.dealManagementUrl.path, pageRoutes.dealManagementUrl.linkText)}</CardTitle>
      <CardBody>
        <Split className={styles.eiWrappableSplit}>
          <SplitItem className={styles.eiDealManagementTable} isFilled>
            <DealManagementTable
              dealManagement={dealManagement || []}
              dealManagementCategoryMappings={dealManagementCategoryMappings}
              isFetching={isFetching}
              viewContext={viewContext}
            />
          </SplitItem>
          <SplitItem className={styles.eiDealManagementChart} isFilled>
            <DealManagementChart
              dealManagement={dealManagement || []}
              dealManagementCategoryMappings={dealManagementCategoryMappings}
              isFetching={isFetching}
              viewContext={viewContext}
            />
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  );
};

export default DealManagementOverview;
