import React, { useEffect, useState } from 'react';
import { ChartDonut } from '@patternfly/react-charts';
import { Card, CardBody, Split, SplitItem, TextVariants, Text, Skeleton } from '@patternfly/react-core';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import EcoIntelEmptyState from '../../../components/EcoIntelEmptyState';
import { errorStateSummaryDetails } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import numberHelper from '../../../utils/numberHelper';
import styles from './OpportunitiesSummary.module.scss';

interface OpportunitiesSummaryProps {
  upcomingOpportunityData: any;
  isFetching: boolean;
  responseError: any;
  viewContext: string;
}

const OpportunitiesSummary: React.FC<OpportunitiesSummaryProps> = ({
  viewContext,
  isFetching,
  responseError,
  upcomingOpportunityData,
}: OpportunitiesSummaryProps) => {
  const upcomingOpportunityColumnNames = {
    category: 'Category',
    risk: 'Risk %',
    oppID: '# of Opps',
    opportunityAmount: 'Amount at Risk',
  };
  const upcomingOpportunityCategoryMappings = {
    high: { name: 'High', riskDataColumn: 'risk_percent', oppIDDataColumn: 'high', oppAmountDataColumn: 'high' },
    medium: { name: 'Medium', riskDataColumn: 'medium_percent', oppIDDataColumn: 'medium', oppAmountDataColumn: 'medium' },
    low: { name: 'Low', riskDataColumn: 'low_percent', oppIDDataColumn: 'low', oppAmountDataColumn: 'low' },
    notApplicable: { name: 'N/A', riskDataColumn: 'na_percent', oppIDDataColumn: 'na', oppAmountDataColumn: 'na' },
  };

  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  const renderSquare = (style) => {
    return (
      <div className={styles.categoryGraphicContainer}>
        <div className={`${styles.categoryGraphicSquare} ${style}`} />
      </div>
    );
  };

  const chartData = (category) => {
    if (upcomingOpportunityData?.risk_profile_details) {
      for (let data of upcomingOpportunityData?.risk_profile_details) {
        if (data?.risk_category === category) {
          return data.risk_percent;
        }
      }
    } else {
      return 0;
    }
  };

  const renderCategoryGraphic = (categoryName) => {
    let categoryGraphic = null;

    switch (categoryName) {
      case upcomingOpportunityCategoryMappings.high.name:
        categoryGraphic = renderSquare(styles.eiCategoryHigh);
        break;
      case upcomingOpportunityCategoryMappings.medium.name:
        categoryGraphic = renderSquare(styles.eiCategoryMedium);
        break;
      case upcomingOpportunityCategoryMappings.notApplicable.name:
        categoryGraphic = renderSquare(styles.eiCategoryNA);
        break;
      case upcomingOpportunityCategoryMappings.low.name:
        categoryGraphic = renderSquare(styles.eiCategoryLow);
        break;
      default:
        break;
    }

    return categoryGraphic;
  };

  const renderUpcomingOpportunityTableRows = () => {
    const tableRows = [];
    for (let category in upcomingOpportunityCategoryMappings) {
      upcomingOpportunityData?.risk_profile_details.forEach((data) => {
        if (data.risk_category === upcomingOpportunityCategoryMappings[category].name) {
          tableRows.push(
            <Tr key={category}>
              <Td noPadding={true} dataLabel={upcomingOpportunityColumnNames.category}>
                {renderCategoryGraphic(upcomingOpportunityCategoryMappings[category].name)}
                {upcomingOpportunityCategoryMappings[category].name}
              </Td>
              <Td noPadding={true} dataLabel={upcomingOpportunityColumnNames.risk}>
                {isFetching ? <Skeleton screenreaderText={'Loading contents...'} /> : data.risk_percent + `%`}
              </Td>
              <Td noPadding={true} dataLabel={upcomingOpportunityColumnNames.oppID}>
                {isFetching ? <Skeleton screenreaderText={'Loading contents...'} /> : data.opp_count}
              </Td>
              <Td noPadding={true} dataLabel={upcomingOpportunityColumnNames.opportunityAmount}>
                {isFetching ? (
                  <Skeleton screenreaderText={'Loading contents...'} />
                ) : (
                  numberHelper.formatAsCompactDollarAmount(data.total_opp_amount, currencySelector)
                )}
              </Td>
            </Tr>
          );
        }
      });
    }
    return tableRows;
  };

  return (
    <>
      {!responseError && (
        <Card className={styles.eiUpcomingOpportunitySummary}>
          <CardBody>
            <Split>
              <SplitItem>
                <div style={{ height: '225px', width: '225px' }}>
                  <ChartDonut
                    data-test="opportunities-summary-chart"
                    ariaDesc={`Donut chart showing ${chartData('Low')}% of opportunities are at low risk, ${chartData(
                      'Medium'
                    )}% are at medium risk, and ${chartData('High')}% are at high risk. ${chartData('N/A')}% of opportunities are not at risk.`}
                    constrainToVisibleArea
                    standalone={true}
                    style={{
                      data: {
                        stroke: 'none',
                        border: 'none',
                      },
                    }}
                    data={[
                      { x: 'Low', y: chartData('Low') },
                      { x: 'High', y: chartData('High') },
                      { x: 'Medium', y: chartData('Medium') },
                      { x: 'N/A', y: chartData('N/A') },
                    ]}
                    labels={({ datum }) => `${datum.x}: ${datum.y}%`}
                    radius={105}
                    innerRadius={55}
                    padding={{
                      bottom: 0,
                      left: 20,
                      right: 0, // Adjusted to accommodate legend
                      top: 15,
                    }}
                    colorScale={['#71BF6F', '#C60011', '#EE6B1E', '#8B8D8F']}
                    width={225}
                  />
                </div>
              </SplitItem>
              <SplitItem>
                <section className={styles.eiUpcomingOpportunityTable}>
                  <Text className={styles.eiOpportunitiesSummaryTitle} component={TextVariants.p}>
                    Pipeline Risk Profile
                  </Text>
                  <TableComposable aria-label="Pipeline Risk Profile" borders={false} variant={'compact'}>
                    <Thead>
                      <Tr>
                        <Th modifier="nowrap">{upcomingOpportunityColumnNames.category}</Th>
                        <Th modifier="nowrap">{upcomingOpportunityColumnNames.risk}</Th>
                        <Th modifier="nowrap">{upcomingOpportunityColumnNames.oppID}</Th>
                        <Th modifier="nowrap">{upcomingOpportunityColumnNames.opportunityAmount}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>{renderUpcomingOpportunityTableRows()}</Tbody>
                  </TableComposable>
                </section>
              </SplitItem>
            </Split>
          </CardBody>
        </Card>
      )}
      {responseError && (
        <Card className={styles.eiUpcomingOpportunitySummary}>
          <CardBody>
            <EcoIntelEmptyState emptyStateProps={errorStateSummaryDetails} />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default OpportunitiesSummary;
