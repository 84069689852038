import React, { useState } from 'react';
import { Card, CardBody } from '@patternfly/react-core';
import ExportContent from './ExportContent';
import { exportFileTypeOptions, exportReportOptions } from './Shared/exportOptions';
import styles from './Export.module.scss';

interface ExportProps {
  viewContext: string;
}

const Export: React.FC<ExportProps> = ({ viewContext }) => {
  const dt = new Date();
  const [startMonth, setStartMonth] = useState<number>(dt.getMonth() + 1);
  const [startYear, setStartYear] = useState<number>(dt.getFullYear());
  const [endMonth, setEndMonth] = useState<number>(dt.getMonth() + 1);
  const [endYear, setEndYear] = useState<number>(dt.getFullYear());
  const [selectedReportOption, setSelectedReportOption] = useState<string | null>(() => {
    // since the options are an object, we must check the length of the object's keys and access the title with the key's index
    const selectedReportOptionKeys: string[] = Object.keys(exportReportOptions);
    if (selectedReportOptionKeys.length === 1) {
      return exportReportOptions[selectedReportOptionKeys[0]].title;
    }
    return null;
  });
  const [fileTypeOption, setFileTypeOption] = useState<string | null>(() => {
    // since the options are an object, we must check the length of the object's keys and access the title with the key's index
    const exportFileTypeOptionsKeys: string[] = Object.keys(exportFileTypeOptions);
    if (exportFileTypeOptionsKeys.length === 1) {
      return exportFileTypeOptions[exportFileTypeOptionsKeys[0]].title;
    }
    return null;
  });

  return (
    <Card className={styles.eiExportCard}>
      <CardBody className={styles.eiExportCardBody}>
        <ExportContent
          selectedReportOption={selectedReportOption}
          setSelectedReportOption={setSelectedReportOption}
          startMonth={startMonth}
          setStartMonth={setStartMonth}
          startYear={startYear}
          setStartYear={setStartYear}
          endMonth={endMonth}
          setEndMonth={setEndMonth}
          endYear={endYear}
          setEndYear={setEndYear}
          fileTypeOption={fileTypeOption}
          setFileTypeOption={setFileTypeOption}
          viewContext={viewContext}
        />
      </CardBody>
    </Card>
  );
};

export default Export;
