import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  Alert,
  AlertVariant,
  Button,
  ButtonVariant,
  Flex,
  FlexItem,
  Select,
  SelectOption,
  SelectVariant,
  Split,
  SplitItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core';
import { DownloadIcon } from '@patternfly/react-icons';
import { map } from 'lodash';
import { getExportData } from '../../../api/export';
import MonthPicker from '../../../components/MonthPicker';
import { MonthPickerError } from '../../../constants/validationErrors';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../contexts/GlobalContext/GlobalContextReducer';
import { useToastContext } from '../../../contexts/Toast/ToastContext';
import { useLegacyFetch } from '../../../hooks/useFetch';
import { SelectedReportInformation } from '../../../models/exportReportInformation';
import { FilterDataSelection } from '../../../models/filter';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import { exportFileTypeOptions, exportReportOptions } from '../Shared/exportOptions';
import styles from './ExportContent.module.scss';

interface ExportContentProps {
  selectedReportOption: string;
  setSelectedReportOption: (option: string) => void;
  startMonth: number;
  setStartMonth: (month: number) => void;
  startYear: number;
  setStartYear: (year: number) => void;
  endMonth: number;
  setEndMonth: (month: number) => void;
  endYear: number;
  setEndYear: (year: number) => void;
  fileTypeOption: string;
  setFileTypeOption: (option: string) => void;
  viewContext: string;
}

const ExportContent: React.FC<ExportContentProps> = ({
  startMonth,
  setStartMonth,
  startYear,
  setStartYear,
  endMonth,
  setEndMonth,
  endYear,
  setEndYear,
  fileTypeOption,
  setFileTypeOption,
  selectedReportOption,
  setSelectedReportOption,
  viewContext,
}) => {
  const { globalState, dispatch } = useGlobalContext();
  const [availableStartDates, setAvailableStartDates] = useState<{ months: number[]; years: number[] }>({ months: [], years: [] });
  const [availableEndDates, setAvailableEndDates] = useState<{ months: number[]; years: number[] }>({ months: [], years: [] });
  const [hasValidationError, setHasValidationError] = useState<boolean>(false);
  const [selectedReportInformation, setSelectedReportInformation] = useState<SelectedReportInformation>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { request } = useLegacyFetch(getExportData, { propagateErrors: true });
  const { addToast } = useToastContext();
  let filterData: FilterDataSelection = null;

  //#region USE EFFECTS
  /* sets up the initially available start and end months and years which can be selected in the datepickers */
  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.lookupState) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        filterData = globalState?.filterState?.originalFilterData;
        setAvailableStartDates({ months: map(filterData['start_month'], 'text'), years: filterData['start_year'] });
        setAvailableEndDates({ months: map(filterData['end_month'], 'text'), years: filterData['end_year'] });
      }
    });
  }, []);

  /* controls triggering the validation error when the start date > end date */
  useEffect(() => {
    unstable_batchedUpdates(() => {
      monthPickerValidationCheck();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startYear, startMonth, endYear, endMonth]);

  /* controls the content which appears in the report information pane (right), the exportReportOption used for export, and the default dates */
  useEffect(() => {
    let selectedReportInformationItem: SelectedReportInformation;
    const dt = new Date();

    switch (selectedReportOption) {
      case exportReportOptions.EXPORT_BOOKINGS_PERFORMANCE.title:
        selectedReportInformationItem = exportReportOptions.EXPORT_BOOKINGS_PERFORMANCE;
        setEndYear(dt.getFullYear());
        setEndMonth(dt.getMonth());
        dt.setMonth(dt.getMonth() - 11);
        setStartYear(dt.getFullYear());
        setStartMonth(dt.getMonth());
        break;
      case exportReportOptions.EXPORT_CUSTOMER_RETENTION.title:
        selectedReportInformationItem = exportReportOptions.EXPORT_CUSTOMER_RETENTION;
        setEndYear(dt.getFullYear());
        setEndMonth(dt.getMonth());
        dt.setMonth(dt.getMonth() - 11);
        setStartYear(dt.getFullYear());
        setStartMonth(dt.getMonth());
        break;
      case exportReportOptions.EXPORT_OPPORTUNITY_DETAILS.title:
        selectedReportInformationItem = exportReportOptions.EXPORT_OPPORTUNITY_DETAILS;
        dt.setMonth(dt.getMonth() + 1);
        setStartYear(dt.getFullYear());
        setStartMonth(dt.getMonth());
        dt.setMonth(dt.getMonth() + 11);
        setEndYear(dt.getFullYear());
        setEndMonth(dt.getMonth());
        break;
      default:
        break;
    }

    setSelectedReportInformation(selectedReportInformationItem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReportOption]);

  /* necessary to remove the global filters scrim once loading is finished since there are no default api calls on the page */
  useEffect(() => {
    if (globalState.filterState.isFiltersInProgress) {
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: { ...globalState.filterState, isFiltersInProgress: false },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);
  //#endregion

  //#region HELPERS
  //#region SELECT REPORT
  const [isSelectExportReportOpen, setIsSelectExportReportOpen] = useState(false);
  const onSelectExportReportOption = (event, exportReportOption) => {
    setIsSelectExportReportOpen(false);
    if (selectedReportOption !== exportReportOption) {
      setSelectedReportOption(exportReportOption);
    }
  };

  const exportReportOptionItems: string[] = [];
  for (const option in exportReportOptions) {
    exportReportOptionItems.push(exportReportOptions[option].title);
  }
  //#endregion

  //#region SELECT FILE TYPE
  const [isSelectExportFileTypeOpen, setIsSelectExportFileTypeOpen] = useState(false);

  const onSelectExportFileTypeOption = (event, exportFileTypeOption) => {
    setIsSelectExportFileTypeOpen(false);

    if (fileTypeOption !== exportFileTypeOption) {
      setFileTypeOption(exportFileTypeOption);
    }
  };

  const exportFileTypeOptionItems: string[] = [];
  for (const option in exportFileTypeOptions) {
    exportFileTypeOptionItems.push(exportFileTypeOptions[option].title);
  }

  //#endregion

  //#region START DATE PICKER
  const onStartDateChange = (year, month) => {
    setStartYear(year);
    setStartMonth(month);
  };

  const startYears = availableStartDates?.years?.map((year) => {
    return year;
  });

  const startMonths = availableStartDates?.months?.map((month) => {
    return month.toString();
  });
  //#endregion

  //#region END DATE PICKER
  const onEndDateChange = (year, month) => {
    setEndYear(year);
    setEndMonth(month);
  };

  const endYears = availableEndDates?.years?.map((year) => {
    return year;
  });

  const endMonths = availableEndDates?.months?.map((month) => {
    return month.toString();
  });
  //#endregion

  //#region DATE PICKER
  const monthPickerValidationCheck = () => {
    if (startYear > endYear || (startYear === endYear && startMonth > endMonth)) {
      setHasValidationError(true);
    } else {
      setHasValidationError(false);
    }
  };
  //#endregion

  //#region FILE DOWNLOAD
  const fileDownloadComplete = () => {
    addToast('Export File Download Complete.', AlertVariant.success);
    setIsFetching(false);
  };

  const handleResponseError = (error) => {
    addToast(
      'Export File Download has encountered an error. Please check the report options and try again. If the problem persists, contact support.',
      AlertVariant.danger
    );
    setIsFetching(false);
  };

  const fileDownload = async () => {
    setIsFetching(true);
    addToast('Export File Download Started - the file will download automatically once processing has finished.', AlertVariant.success);
    try {
      const requestParams = {
        viewContext: viewContext,
        start_month: startMonth,
        start_year: startYear,
        end_month: endMonth,
        end_year: endYear,
      };

      let selectedFilterValues = {};
      Object.assign(selectedFilterValues, requestParams);
      selectedFilterValues = getAllSelectedFilterValues(globalState, selectedFilterValues);

      /* The request below will automatically create a webworker to download the file. Nothing is returned from the function
        since the response could be very large and the file is written at the time of completion */
      await request(selectedReportInformation, fileDownloadComplete, handleResponseError, selectedFilterValues);
    } catch (error) {
      handleResponseError(error);
    }
  };
  //#endregion
  //#endregion

  //#region RENDERS
  //#region Generic
  const renderTextContent = (text, variant, style) => {
    return (
      <TextContent>
        <Text className={style} component={variant}>
          {text}
        </Text>
      </TextContent>
    );
  };
  //#endregion

  //#region Center Elements
  const renderExportReportSelect = () => {
    return (
      <>
        {renderTextContent('Report Dataset', TextVariants.p, styles.eiExportCenterSelectHeader)}
        <div data-test="export-select-report">
          <Select
            aria-label="Select Export Report Dataset"
            className={styles.eiToolbarSelectWidth}
            id="select-export-report"
            isDisabled={exportReportOptionItems?.length <= 1 || isFetching}
            isOpen={isSelectExportReportOpen}
            onSelect={onSelectExportReportOption}
            onToggle={() => setIsSelectExportReportOpen(!isSelectExportReportOpen)}
            placeholderText={<>Select Report</>}
            selections={selectedReportOption}
            variant={SelectVariant.single}
          >
            {exportReportOptionItems?.map((option, index) => {
              return <SelectOption key={index} value={option} data-test={option.toLowerCase()} />;
            })}
          </Select>
        </div>
      </>
    );
  };

  const renderStartDatePicker = () => {
    return (
      <>
        {renderTextContent('Start Date', TextVariants.p, styles.eiExportCenterSelectHeader)}
        <div data-test="start-date-calendar-button">
          {startYears?.length > 0 && startMonths?.length > 0 && startMonth !== null && startYear !== null ? (
            <MonthPicker
              years={startYears}
              months={startMonths}
              isDisabled={!selectedReportOption || isFetching}
              value={{ month: startMonth, year: startYear }}
              onChange={onStartDateChange}
            />
          ) : null}
        </div>
      </>
    );
  };

  const renderEndDatePicker = () => {
    return (
      <>
        {renderTextContent('End Date', TextVariants.p, styles.eiExportCenterSelectHeader)}
        <div data-test="end-date-calendar-button">
          {endYears?.length > 0 && endMonths.length > 0 && endMonth !== null && endYear !== null ? (
            <MonthPicker
              years={endYears}
              months={endMonths}
              isDisabled={!selectedReportOption || isFetching}
              value={{ month: endMonth, year: endYear }}
              onChange={onEndDateChange}
            />
          ) : null}
        </div>
      </>
    );
  };

  const renderValidationError = () => {
    return (
      <>
        {hasValidationError ? (
          <div className={styles.eiExportValidationError}>
            <Alert variant="danger" isInline title={MonthPickerError} />
          </div>
        ) : null}
      </>
    );
  };

  const renderExportFileTypeSelect = () => {
    return (
      <>
        {renderTextContent('FileType', TextVariants.p, styles.eiExportCenterSelectHeader)}
        <div data-test="export-select-file-type">
          <Select
            aria-label="Select Export File Type"
            className={styles.eiToolbarSelectWidth}
            id="select-export-file-type"
            isDisabled={exportFileTypeOptionItems?.length <= 1 || isFetching}
            isOpen={isSelectExportFileTypeOpen}
            onSelect={onSelectExportFileTypeOption}
            onToggle={() => setIsSelectExportFileTypeOpen(!isSelectExportReportOpen)}
            placeholderText={<>Select File Type</>}
            selections={fileTypeOption}
            variant={SelectVariant.single}
          >
            {exportFileTypeOptionItems?.map((option, index) => {
              return <SelectOption key={index} value={option} data-test={option.toLowerCase()} />;
            })}
          </Select>
        </div>
      </>
    );
  };

  const renderExportDownloadButton = () => {
    return (
      <div className={styles.eiExportDownloadButtonContainer}>
        <Button
          aria-label={'Export File Download'}
          data-test="export-file-download-button"
          icon={<DownloadIcon />}
          iconPosition={'right'}
          isDisabled={hasValidationError || !selectedReportOption || !fileTypeOption || globalState?.filterState?.isFiltersInProgress || isFetching}
          onClick={fileDownload}
          variant={ButtonVariant.primary}
          //data-test={dataTestId}
        >
          {'Download'}
        </Button>
      </div>
    );
  };

  const renderReportSelectSplitItem = () => {
    return (
      <SplitItem className={styles.eiExportCenterContent} data-test="export-center-content">
        {renderTextContent('Select Report', TextVariants.p, styles.eiExportCenterContentHeader)}
        {renderExportReportSelect()}
        <Flex className={styles.eiStartDateEndDateContainer}>
          <FlexItem>{renderStartDatePicker()}</FlexItem>
          <FlexItem>{renderEndDatePicker()}</FlexItem>
        </Flex>
        {renderValidationError()}
        {renderExportFileTypeSelect()}
        {renderExportDownloadButton()}
      </SplitItem>
    );
  };
  //#endregion

  //#region Left Elements
  const renderLeftInfoSplitItem = () => {
    return (
      <SplitItem className={styles.eiExportLeftContent} data-test="export-left-content">
        {renderTextContent('All selected filters will be applied to the data prior to export.', TextVariants.p, styles.eiExportLeftText)}
        {renderTextContent('Use the main filter menu to optimize the size of the export.', TextVariants.p, styles.eiExportLeftText)}
        {renderTextContent('Date range selections on this page will override the main filter.', TextVariants.p, styles.eiExportLeftText)}
      </SplitItem>
    );
  };
  //#endregion

  //#region Right Elements
  const renderRightInfoSplitItem = () => {
    return (
      <SplitItem className={styles.eiExportRightContent} data-test="export-right-content">
        {renderTextContent(selectedReportInformation?.title, TextVariants.p, styles.eiExportRightTextHeader)}
        {renderTextContent(selectedReportInformation?.description, TextVariants.p, styles.eiExportRightText)}
        {renderTextContent(
          selectedReportOption ? 'Date Range' : '',
          TextVariants.p,
          `${styles.eiExportRightTextHeader} ${styles.eiExportCenterDateSpacing}`
        )}
        {renderTextContent(selectedReportInformation?.dateRange, TextVariants.p, styles.eiExportRightText)}
      </SplitItem>
    );
  };
  //#endregion
  //#endregion

  return (
    <Split className={styles.eiExportContentContainer} hasGutter>
      {renderLeftInfoSplitItem()}
      {renderReportSelectSplitItem()}
      {renderRightInfoSplitItem()}
    </Split>
  );
};

export default ExportContent;
