import * as React from 'react';
import GlobalContextReducer, { initialGlobalState, GlobalState } from './GlobalContextReducer';

export interface GlobalContext {
  globalState: GlobalState;
  dispatch?: React.Dispatch<any>;
}

const initialGlobalContextDetails: GlobalContext = {
  globalState: initialGlobalState,
};

export const GlobalContextDetails = React.createContext<GlobalContext>(initialGlobalContextDetails);

export const useGlobalContext = () => React.useContext(GlobalContextDetails);

const GlobalContextProvider = (props) => {
  const [globalState, dispatch] = React.useReducer(GlobalContextReducer, props.value);
  return <GlobalContextDetails.Provider value={{ globalState, dispatch }}>{props.children}</GlobalContextDetails.Provider>;
};

export default GlobalContextProvider;
