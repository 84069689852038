const numberHelper = {
  formatAsNumberWithComma(value: number | string): string {
    let returnVal = '';
    // this returns '' for 0, hence the edge case handling below
    // we will pass back an '' for NaN, null, etc.
    let formatString: string = (value || '').toString();
    if (formatString) {
      returnVal = formatString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (value === 0) {
      returnVal = '0';
    }

    return returnVal;
  },

  /**
   * Used to format currencies for heroes, charts, and tables
   * @param {number} value - Currency amount
   * @param {string} currencyCode - The current currency code in the currency selector (optional - defaults to USD)
   * @returns {string} The value formatted as currency for the locale en-us
   * @example
   * // returns $0
   * formatAsCompactDollarAmount(0, USD);
   * @example
   * // returns $1.0K
   * formatAsCompactDollarAmount(1000, USD);
   */
  formatAsCompactDollarAmount(value: number, currencyCode?: string): string {
    return new Intl.NumberFormat('en-us', {
      notation: 'compact',
      style: 'currency',
      currency: currencyCode || 'USD',
      maximumFractionDigits: value === 0 ? 0 : 1,
      currencyDisplay: 'symbol',
    }).format(value);
  },

  getNearestRoundNumber(value: number, divideBy: number) {
    return Math.round(value / divideBy) * divideBy;
  },

  formatAsPercentage(value: number): string {
    return value + `%`;
  },
};

export default numberHelper;
