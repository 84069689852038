// App.scss needs to come first because it contains the PatternFly defaults - putting this
// further down causes unwanted overrides
import './App.scss';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import Cookies from 'js-cookie';
import EcoIntelWrapper from './components/EcoIntelWrapper/EcoIntelWrapper';
import Layout from './components/Layout';
import GlobalContextProvider from './contexts/GlobalContext/GlobalContext';
import { initialGlobalState } from './contexts/GlobalContext/GlobalContextReducer';
import keyCloak from './keycloak';

const AuthWrapper = () => {
  const [isLoading, setIsLoading] = useState(true); // Loading status
  const { keycloak } = useKeycloak();

  const renderApp = React.useCallback(async () => {
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (keycloak.authenticated) {
      renderApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keycloak.authenticated, renderApp]);

  return (
    <div className="eiApp">
      {!isLoading && (
        <GlobalContextProvider value={initialGlobalState}>
          <EcoIntelWrapper>
            <Router>
              <Layout />
            </Router>
          </EcoIntelWrapper>
        </GlobalContextProvider>
      )}
    </div>
  );
};

const App = () => {
  // SSO starts
  const onKeycloakEvent = (event, error) => {
    if ((event === 'onReady' || event === 'onAuthRefreshError' || event === 'onTokenExpired') && !keyCloak.authenticated) {
      keyCloak.login();
    }

    if (event === 'onInitError' || event === 'onAuthError') {
      keyCloak.login();
    }

    if (event === 'onAuthLogout') {
      keyCloak.logout();
    }
  };

  const onKeycloakTokens = (tokens) => {
    Cookies.set('ecointel_sso', tokens?.idToken, { sameSite: 'strict' });
  };
  // SSO ends

  return (
    <ReactKeycloakProvider authClient={keyCloak} onEvent={onKeycloakEvent} onTokens={onKeycloakTokens}>
      <AuthWrapper />
    </ReactKeycloakProvider>
  );
};

export default App;
