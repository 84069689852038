import React from 'react';
import { Card, CardBody, CardTitle, Split, SplitItem } from '@patternfly/react-core';
import pageRoutes from '../../../utils/pageRoutes';
import PerformanceOverTime from './PerformanceOverTime';
import PerformanceVsPeers from './PerformanceVsPeers';
import styles from './BookingsPerformanceOverview.module.scss';

interface BookingsPerformanceOverviewProps {
  bookingsPerformanceTime: any;
  bookingsPerformance: any;
  getCardTitleContent: Function;
  isFetching: boolean;
  peerBookingsPerformance: any;
  viewContext: string;
}

const BookingsPerformanceOverview: React.FC<BookingsPerformanceOverviewProps> = ({
  bookingsPerformanceTime,
  bookingsPerformance,
  getCardTitleContent,
  isFetching,
  peerBookingsPerformance,
  viewContext,
}) => {
  return (
    <Card className={styles.eiBookingsPerformanceOverviewContainer}>
      <CardTitle className={styles.eiTitle}>
        {getCardTitleContent(pageRoutes.bookingsPerformanceUrl.path, pageRoutes.bookingsPerformanceUrl.linkText)}
      </CardTitle>
      <CardBody>
        <Split className={styles.eiResizableSplit}>
          <SplitItem className={styles.eiHalfWidthSplitItem} isFilled>
            <PerformanceOverTime bookingsPerformanceTime={bookingsPerformanceTime || []} isFetching={isFetching} />
          </SplitItem>
          <SplitItem className={styles.eiHalfWidthSplitItem} isFilled>
            <PerformanceVsPeers
              bookingsPerformance={bookingsPerformance}
              peerBookingsPerformance={peerBookingsPerformance || []}
              isFetching={isFetching}
              viewContext={viewContext}
            />
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  );
};

export default BookingsPerformanceOverview;
