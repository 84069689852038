export default class WorkerBuilder extends Worker {
  constructor() {
    const workerCode = `
      let blob = new Blob([], { type: "text/csv;charset=utf-8" });
      onmessage = (message) => {
        const stream = message.data;
        if (message.data === 'done-worker') {
          postMessage(blob);
        }
        else {
          blob = new Blob([blob, stream], { type: "text/csv;charset=utf-8" });
        }
      };
    `;

    const blob = new Blob([workerCode], { type: 'application/javascript' });
    super(URL.createObjectURL(blob));
  }
}
