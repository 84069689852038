import React, { useState } from 'react';
import { EcoIntelEmptyStatePropsTypes } from '../../models/emptyState';

export interface TableState {
  tableName: string;
  tableData: any[]; // Table data fetched from API
  currentPage: number; // The page currently dispayed
  currentPageData: any[]; // Current page data displayed in table
  selectedPerPage: number; // Number of rows displayed per page
  currentFetchId: number; // Fetch Id for API fetch
  startIndex: number; // Internal start index to handle previous / next click
  endIndex: number; // Internal end index to handle previous / next click
  fetchStartIndex: number; // Start Index set when data is fetched
  fetchEndIndex: number; // End Index set when data is fetched
  activeSortIndex: number; // Index of the column sorted
  activeSortDirection: 'asc' | 'desc' | null; // Sort direction of the column sorted
  totalRecordsCount: number; // Total records count
  isTableDataFetching: boolean; // Flag set when API is fetching data and used to disable pagination
  tableResponseError: any; // Error from API response
  columns: any; // Total number of columns available. Max column count is 10 as per AC
  initialColumns: string[]; // Initial set of columns as in UX design and are selected by default
  defaultColumns: string[]; // Columns that are already selected and cannot be deselected.
  // defaultColumns will be empty array when all the columns can be selected/deselected.
  selectedColumns: any; // Columns user selected from the columns dropdown i.e the columns which are displayed
  filterBy: any; // Filter by dropdown values
  pageLimit: number; // Total number of rows fetched at a time on API call
  paginationType: boolean; // Type of Pagination(Normal or Compact)
  tableEmptyState: EcoIntelEmptyStatePropsTypes;
  actionRowData: any;
  globalFilterAppliedOrReset: any;
  // The setter functions for all the context variables  //
  setTableName: React.Dispatch<React.SetStateAction<string>>;
  setTableData: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentPageData: React.Dispatch<React.SetStateAction<any>>;
  setSelectedPerPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentFetchId: React.Dispatch<React.SetStateAction<number>>;
  setStartIndex: React.Dispatch<React.SetStateAction<number>>;
  setEndIndex: React.Dispatch<React.SetStateAction<number>>;
  setFetchStartIndex: React.Dispatch<React.SetStateAction<number>>;
  setFetchEndIndex: React.Dispatch<React.SetStateAction<number>>;
  setActiveSortIndex: React.Dispatch<React.SetStateAction<number>>;
  setActiveSortDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc' | null>>;
  setTotalRecordsCount: React.Dispatch<React.SetStateAction<number>>;
  setColumns: React.Dispatch<React.SetStateAction<any>>;
  setIsTableDataFetching: React.Dispatch<React.SetStateAction<boolean>>;
  setFilterBy: React.Dispatch<React.SetStateAction<any>>;
  setPageLimit: React.Dispatch<React.SetStateAction<number>>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<any>>;
  setPaginationType: React.Dispatch<React.SetStateAction<boolean>>;
  setDefaultColumns: React.Dispatch<React.SetStateAction<string[]>>;
  setInitialColumns: React.Dispatch<React.SetStateAction<string[]>>;
  setTableEmptyState: React.Dispatch<React.SetStateAction<EcoIntelEmptyStatePropsTypes>>;
  setTableResponseError: React.Dispatch<React.SetStateAction<any>>;
  setActionRowData: React.Dispatch<React.SetStateAction<any>>;
  setGlobalFilterAppliedOrReset: React.Dispatch<React.SetStateAction<number>>;
}

const TableContext = React.createContext<TableState>({} as TableState);
export const useTableContext = () => React.useContext(TableContext);

const TableContextProvider = (props) => {
  const [tableName, setTableName] = useState();
  const [tableData, setTableData] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageData, setCurrentPageData] = useState();
  const [totalRecordsCount, setTotalRecordsCount] = useState<number>(0);
  const [selectedPerPage, setSelectedPerPage] = useState<number>(10);
  const [currentFetchId, setCurrentFetchId] = useState<number>(0);
  const [startIndex, setStartIndex] = useState<number>(0);
  const [endIndex, setEndIndex] = useState<number>(10);
  const [fetchStartIndex, setFetchStartIndex] = useState<number>(0);
  const [fetchEndIndex, setFetchEndIndex] = useState<number>(10);
  const [activeSortIndex, setActiveSortIndex] = useState<number | null>();
  const [activeSortDirection, setActiveSortDirection] = useState<'asc' | 'desc' | null>();
  const [columns, setColumns] = useState();
  const [isTableDataFetching, setIsTableDataFetching] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState();
  const [pageLimit, setPageLimit] = useState<number>(500);
  const [selectedColumns, setSelectedColumns] = useState();
  const [paginationType, setPaginationType] = useState<boolean>(false);
  const [defaultColumns, setDefaultColumns] = useState<string[]>();
  const [initialColumns, setInitialColumns] = useState<string[]>();
  const [tableEmptyState, setTableEmptyState] = useState<EcoIntelEmptyStatePropsTypes>();
  const [tableResponseError, setTableResponseError] = useState();
  const [actionRowData, setActionRowData] = useState();
  const [globalFilterAppliedOrReset, setGlobalFilterAppliedOrReset] = useState<number>();

  return (
    <TableContext.Provider
      value={{
        tableName,
        tableData,
        currentPage,
        currentPageData,
        selectedPerPage,
        currentFetchId,
        startIndex,
        endIndex,
        fetchStartIndex,
        fetchEndIndex,
        activeSortIndex,
        activeSortDirection,
        totalRecordsCount,
        isTableDataFetching,
        tableResponseError,
        columns,
        selectedColumns,
        filterBy,
        pageLimit,
        paginationType,
        defaultColumns,
        initialColumns,
        tableEmptyState,
        actionRowData,
        globalFilterAppliedOrReset,
        setTableName,
        setTableData,
        setCurrentPage,
        setCurrentPageData,
        setSelectedPerPage,
        setCurrentFetchId,
        setStartIndex,
        setEndIndex,
        setFetchStartIndex,
        setFetchEndIndex,
        setActiveSortIndex,
        setActiveSortDirection,
        setTotalRecordsCount,
        setColumns,
        setIsTableDataFetching,
        setFilterBy,
        setPageLimit,
        setSelectedColumns,
        setPaginationType,
        setDefaultColumns,
        setInitialColumns,
        setTableEmptyState,
        setTableResponseError,
        setActionRowData,
        setGlobalFilterAppliedOrReset,
      }}
    >
      {props.children}
    </TableContext.Provider>
  );
};

export default TableContextProvider;
