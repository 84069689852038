import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChartDonutUtilization, ChartLabel } from '@patternfly/react-charts';
import { Button, Card, CardBody, CardTitle, Grid, GridItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import { kebabCase } from 'lodash';
import { ReactComponent as AnalyticsLogo } from '../../../assets/Analytics-logo-black-type.svg';
import { ReactComponent as ExportLogo } from '../../../assets/export-icon.svg';
import { ReactComponent as InfraredLogo } from '../../../assets/infrared.svg';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { HomePageData } from '../../../models/homePage';
import numberHelper from '../../../utils/numberHelper';
import pageRoutes from '../../../utils/pageRoutes';
import styles from './Content.module.scss';

type ContentProps = {
  contentData: HomePageData;
};

const Content: React.FC<ContentProps> = (props) => {
  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  const getCardTitleContent = (path: string, cardTitle: string): React.ReactNode => {
    return (
      <section className={styles.eiCardTitle}>
        <Button
          data-test={`home-${kebabCase(cardTitle)}`}
          variant="link"
          component={(props) => <Link className={styles.eiCardTitleLinkText} {...props} to={path} />}
        >
          {cardTitle}
        </Button>
      </section>
    );
  };

  return (
    <Grid hasGutter className={styles.eiContent} data-test="home-content">
      <GridItem sm={12} xl={6}>
        <Card className={styles.eiContentCard}>
          <CardTitle>
            <TextContent>
              <Text component={TextVariants.h2}>{getCardTitleContent(pageRoutes.reviewOverviewUrl.path, pageRoutes.reviewUrl.linkText)}</Text>
            </TextContent>
          </CardTitle>
          <CardBody>
            <Grid>
              <GridItem sm={5} lg={3} xl={5}>
                <div style={{ height: '144px', width: '144px' }}>
                  <ChartDonutUtilization
                    ariaDesc={`Donut chart illustrating ${props.contentData?.bookings_performance}% renewal rate at par`}
                    padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                    constrainToVisibleArea={false}
                    data={{ x: 'Renewal Rate', y: props.contentData?.bookings_performance }}
                    height={144}
                    innerRadius={67}
                    labels={({ datum }) => (datum.x ? `${datum.x}: ${props.contentData?.bookings_performance}%` : null)}
                    subTitle={'Renewal Rate \n (at Par)'}
                    colorScale={['#003737', '#F0F0F0']}
                    title={props.contentData?.bookings_performance + '% \n'}
                    width={144}
                    titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                    subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                  />
                </div>
              </GridItem>
              <GridItem sm={7} lg={9} xl={7} className={styles.eiGridText}>
                <AnalyticsLogo className={styles.eiAnalyticsLogo} />
                <TextContent>
                  <Text component={TextVariants.p}>
                    Access insights on how you’ve performed relative to your peers and uncover areas for improvement.
                  </Text>
                </TextContent>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={12} xl={6}>
        <Card className={styles.eiContentCard}>
          <CardTitle>
            <TextContent>
              <Text component={TextVariants.h2}>{getCardTitleContent(pageRoutes.renewOverviewUrl.path, pageRoutes.renewUrl.linkText)}</Text>
            </TextContent>
          </CardTitle>
          <CardBody>
            <Grid>
              <GridItem sm={5} lg={3} xl={5}>
                <div style={{ height: '144px', width: '144px' }}>
                  <ChartDonutUtilization
                    ariaDesc={`Donut chart illustrating ${
                      props.contentData?.renewals?.number_of_opps
                    } upcoming renewals opportunities totaling ${numberHelper.formatAsCompactDollarAmount(
                      props.contentData?.renewals?.opp_amount_total,
                      currencySelector
                    )}`}
                    padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                    constrainToVisibleArea={false}
                    data={{ x: 'Upcoming Renewals', y: 100 }}
                    height={144}
                    innerRadius={67}
                    labels={({ datum }) =>
                      `${datum.x}: ${numberHelper.formatAsCompactDollarAmount(props.contentData?.renewals?.opp_amount_total, currencySelector)}`
                    }
                    subTitle={props.contentData?.renewals?.number_of_opps + ' Upcoming \n Renewals'}
                    title={numberHelper.formatAsCompactDollarAmount(props.contentData?.renewals?.opp_amount_total, currencySelector) + '\n'}
                    width={144}
                    titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                    subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                    colorScale={['#71BF6F']}
                  />
                </div>
              </GridItem>
              <GridItem sm={7} lg={9} xl={7} className={styles.eiGridText}>
                <AnalyticsLogo className={styles.eiAnalyticsLogo} />
                <TextContent>
                  <Text component={TextVariants.p}>
                    View, analyze, and act on your opportunity-level renewals pipeline, with insights to drive retention and growth.
                  </Text>
                </TextContent>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={12} xl={6}>
        <Card className={styles.eiContentCard}>
          <CardTitle>
            <TextContent>
              <Text component={TextVariants.h2}>
                {getCardTitleContent(pageRoutes.crossSellProbabilities.path, pageRoutes.crossSellProbabilities.linkText)}
              </Text>
            </TextContent>
          </CardTitle>
          <CardBody>
            <Grid>
              <GridItem sm={5} lg={3} xl={5}>
                <ExclamationTriangleIcon className={styles.exclamationIcon} />
                {/* <div style={{ height: '144px', width: '144px' }}>
                  <ChartDonutUtilization
                    ariaDesc="Cross-Sell Predictions"
                    constrainToVisibleArea={false}
                    padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                    data={{ x: 'Cross-Sell Probabilities', y: 100 }}
                    height={144}
                    innerRadius={67}
                    labels={({ datum }) => `${datum.x}: ${props.contentData?.cross_sell_probabilities?.number_of_opps}`}
                    subTitle={'Cross-Sell \n Predictions'}
                    title={props.contentData?.cross_sell_probabilities?.number_of_opps + '\n'}
                    width={144}
                    titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                    subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                    colorScale={['#005F60']}
                  /> 
                </div>*/}
              </GridItem>
              <GridItem sm={7} lg={9} xl={7} className={styles.eiGridText}>
                <InfraredLogo className={styles.eiInfraredLogo} />
                <TextContent>
                  <Text component={TextVariants.p}>
                    This page is currently in development. Check back soon!
                    {/* Use Infrared's predictive AI to identify the best customers for cross-sell campaigns
                    and the products customers are most likely to buy next. */}
                  </Text>
                </TextContent>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={12} xl={6}>
        <Card className={styles.eiContentCard}>
          <CardTitle>
            <TextContent>
              <Text component={TextVariants.h2}>{getCardTitleContent(pageRoutes.customerInsights.path, pageRoutes.customerInsights.linkText)}</Text>
            </TextContent>
          </CardTitle>
          <CardBody>
            <Grid>
              <GridItem sm={5} lg={3} xl={5}>
                <ExclamationTriangleIcon className={styles.exclamationIcon} />
                {/*  <div style={{ height: '144px', width: '144px' }}>
                  <ChartDonutUtilization
                    ariaDesc="Customers with Insights"
                    constrainToVisibleArea={false}
                    padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                    data={{ x: 'Customers with Insights', y: 100 }}
                    height={144}
                    innerRadius={67}
                    labels={({ datum }) => `${datum.x}: ${props.contentData['customer-insights']?.num_of_customers}`}
                    subTitle={'Customers \n with Insights'}
                    title={props.contentData['customer-insights']?.num_of_customers + '\n'}
                    width={144}
                    titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                    subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                    colorScale={['#317631']}
                  /> 
                </div>*/}
              </GridItem>
              <GridItem sm={7} lg={9} xl={7} className={styles.eiGridText}>
                <InfraredLogo className={styles.eiInfraredLogo} />
                <TextContent>
                  <Text component={TextVariants.p}>
                    This page is currently in development. Check back soon!
                    {/* Access deeper, customer-level retention and growth insights related to revenue
                    trends, install base, key contacts, cross-sell probabilities and engagement. */}
                  </Text>
                </TextContent>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem>
        <Card className={styles.eiContentCard}>
          <CardBody>
            <Grid>
              <GridItem sm={5} lg={3} xl={2} xl2={2}>
                <ExportLogo style={{ height: '144px', width: '144px' }} />
              </GridItem>
              <GridItem sm={7} lg={9} xl={10} xl2={10} className={styles.eiGridText}>
                <TextContent className={styles.eiExport}>
                  <Text component={TextVariants.h2}>{getCardTitleContent(pageRoutes.exportUrl.path, pageRoutes.exportUrl.linkText)}</Text>
                  <Text component={TextVariants.p}>
                    Customize the export of any of the data underlying Renewals Intelligence. Datasets include historical bookings or retention
                    performance as well as forward-looking Renew and Infrared features.
                  </Text>
                </TextContent>
              </GridItem>
            </Grid>
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Content;
