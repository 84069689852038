export const WHATS_NEW_TEXT = [
  {
    release: '0.5.0',
    date: 'February 28, 2023',
    text: `Renewals Intelligence is now a cloud native web app built on Red Hat’s very own
      Openshift. Enjoy faster load times and an intuitive customizable export feature while
      we set the stage for a best-in-class experience with faster responsiveness to user 
      feedback and evolving user needs.`,
  },
  {
    release: '3.6.0',
    date: 'April 14, 2023',
    text: `The initial release of the Export page and API services is here! Export allows 
      authenticated users to pull their data from Ecosystem Intelligence. For Release 3.6.0, 
      three exports have been published: Bookings Performance Insights, Retention Performance 
      Insights, and Opportunity Details Insights. Additionally, several Filters components 
      have been reworked to improve speed and usability!`,
  },

  /* add new notes to the bottom of the array above */
];
