import React from 'react';
import { Grid, GridItem, Skeleton, Split, SplitItem, Text, TextContent, TextVariants, ToggleGroup, ToggleGroupItem } from '@patternfly/react-core';
import { finderPartnerTypes } from '../../../../../constants/finderPartnerTypes';
import EcoIntelEmptyState from '../../../../EcoIntelEmptyState';
import { errorStateDark } from '../../../../EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import HeroCard from './HeroCard';
import styles from './HeroContent.module.scss';

interface HeroContentProps {
  carouselData: { bodyText: string; titleText: string }[];
  carouselTitle: string;
  heroBodyText: string;
  heroTitleText: string;
  heroFooterContent?: React.ReactNode;
  isFetching: boolean;
  isViewContextToggleVisible: boolean;
  onClickToggleViewContext: (isSelected: boolean, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  viewContext: string;
  noOfLoaders: number;
  responseError: string | null;
}

const HeroContent: React.FC<HeroContentProps> = ({
  carouselData,
  carouselTitle,
  heroBodyText,
  heroTitleText,
  heroFooterContent,
  isFetching,
  isViewContextToggleVisible,
  onClickToggleViewContext,
  viewContext,
  noOfLoaders,
  responseError,
}) => {
  const renderToggleGroup = () => {
    return (
      <ToggleGroup className={styles.eiViewContextToggle}>
        <ToggleGroupItem
          data-test="distributor"
          text="Distributor"
          buttonId={finderPartnerTypes.DISTRIBUTOR}
          isSelected={viewContext === finderPartnerTypes.DISTRIBUTOR}
          onChange={onClickToggleViewContext}
        />
        <ToggleGroupItem
          data-test="partner"
          text="Partner"
          buttonId={finderPartnerTypes.RESELLER}
          isSelected={viewContext === finderPartnerTypes.RESELLER}
          onChange={onClickToggleViewContext}
        />
      </ToggleGroup>
    );
  };

  const renderTextContent = (text: string, variant: TextVariants, textStyle?: string, textContainerStyle?: string, dataTestId?: string) => {
    return (
      <TextContent className={textContainerStyle}>
        <Text data-test={dataTestId} component={variant} className={textStyle}>
          {text}
        </Text>
      </TextContent>
    );
  };

  return (
    <Grid className={styles.eiHeroTemplate}>
      <GridItem className={styles.eiHeroTemplateToggle}>
        {isViewContextToggleVisible ? renderToggleGroup() : <div className={styles.eiViewContextToggle} />}
      </GridItem>
      <GridItem>
        {!responseError && (
          <Split className={styles.eiHeroTemplateLayout}>
            <SplitItem className={styles.eiHeroTemplateLeft}>
              {renderTextContent(heroTitleText, TextVariants.h1, styles.eiHeroTemplateHeader, null, 'hero-content-title')}
              {renderTextContent(heroBodyText, TextVariants.p, styles.eiHeroTemplateBody, styles.eiHeroTemplateBodyContainer)}
              {heroFooterContent}
            </SplitItem>
            <SplitItem className={styles.eiHeroTemplateRight}>
              {renderTextContent(carouselTitle, TextVariants.h2, null, styles.eiCarouselTitle)}
              <div className={styles.eiHeroTemplateCarousel} tabIndex={0}>
                <Split hasGutter>
                  {!isFetching &&
                    carouselData.map((item, index) => (
                      <SplitItem key={index}>
                        <HeroCard bodyText={item.bodyText} titleText={item.titleText} />
                      </SplitItem>
                    ))}
                  {isFetching && (
                    <SplitItem width={'100%'}>
                      <Split hasGutter>
                        {[...Array(noOfLoaders)].map((ele, index) => (
                          <SplitItem key={`loading_${index}`}>
                            <Skeleton height={'110px'} screenreaderText={'Loading contents...'} width={'190px'} />
                          </SplitItem>
                        ))}
                      </Split>
                    </SplitItem>
                  )}
                </Split>
              </div>
            </SplitItem>
          </Split>
        )}
        {responseError && (
          <section className={styles.eiHeroErrorState}>
            <EcoIntelEmptyState emptyStateProps={errorStateDark} />
          </section>
        )}
      </GridItem>
    </Grid>
  );
};

export default HeroContent;
