import React, { useEffect, useState } from 'react';
import { Button, Popover, PopoverPosition, Skeleton, Text, TextContent, TextVariants } from '@patternfly/react-core';
import InfoIcon from '@patternfly/react-icons/dist/esm/icons/info-icon';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { finderPartnerTypes } from '../../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import styles from './PerformanceVsPeers.module.scss';

interface PerformanceVsPeersProps {
  bookingsPerformance: any;
  isFetching: boolean;
  peerBookingsPerformance: any;
  viewContext: string;
}

const PerformanceVsPeers: React.FC<PerformanceVsPeersProps> = ({ bookingsPerformance, isFetching, peerBookingsPerformance, viewContext }) => {
  const [arePeerDataConditonsMet, setArePeerDataConditonsMet] = useState(false);
  const { globalState } = useGlobalContext();

  useEffect(() => {
    let numberOfParents = 0;
    /*
        The following conditions must be true for peer data to appear in the peer column. Otherwise
        display the placeholder text of 'N/A'. Info icon appears next to column text when the conditons
        are not met

        for distrubutor:
        must have exactly one distributor parent selected in the filter or display 'N/A'
        must have only one distributor parent in the base data or display 'N/A
        for partner:
        must have exactly one partner parent selected in the filter or display 'N/A'
        must have only one partner parent in the base data or display 'N/A
      */

    if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
      numberOfParents =
        globalState?.filterState?.selectedFilterData?.distributorParent?.length || globalState?.lookupState?.distributorParentLookup?.length;
      //globalState?.filterState?.originalFilterData?.data[Object.keys(globalState?.filterState?.originalFilterData?.data)[0]]?.distributor_parent?.length);
    } else if (viewContext === finderPartnerTypes.RESELLER) {
      numberOfParents = globalState?.filterState?.selectedFilterData?.partnerParent?.length || globalState?.lookupState?.partnerParentLookup?.length;
      //globalState?.filterState?.originalFilterData?.data[Object.keys(globalState?.filterState?.originalFilterData?.data)[0]]?.partner_parent?.length);
    }

    setArePeerDataConditonsMet(numberOfParents === 1 ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.filterState?.isApplyFilter, globalState?.filterState?.isResetFilter, viewContext]);

  const bookingsPerformanceColumnNames = {
    metric: 'Metric',
    partner: 'Partner',
    distributor: 'Distributor',
    peer: 'Peer',
  };

  const bookingsPerformanceCategoryMappings = {
    performance: { name: 'Performance', bookingsDataColumn: 'performance_percent', peerDataColumn: 'performance' },
    renewalAtPar: { name: 'Renewal @ Par', bookingsDataColumn: 'renewal_at_par_percent', peerDataColumn: 'renewal_at_par' },
    upSell: { name: 'Up-Sell', bookingsDataColumn: 'up_sell_percent', peerDataColumn: 'up_sell' },
    crossSell: { name: 'Cross-Sell', bookingsDataColumn: 'cross_sell_percent', peerDataColumn: 'cross_sell' },
  };

  const renderSquare = (style) => {
    return (
      <div className={styles.categoryGraphicContainer}>
        <div className={`${styles.categoryGraphicSquare} ${style}`} />
      </div>
    );
  };

  const renderSquares = (style) => {
    return (
      <div className={styles.categoryGraphicContainer}>
        <div className={`${styles.categoryGraphicSquares} ${style}`} />
        <div className={`${styles.categoryGraphicSquares} ${style}`} />
        <div className={`${styles.categoryGraphicSquares} ${style}`} />
      </div>
    );
  };

  const renderCategoryGraphic = (categoryName) => {
    let categoryGraphic = null;

    switch (categoryName) {
      case bookingsPerformanceCategoryMappings.performance.name:
        categoryGraphic = renderSquare(styles.viridianGreen);
        break;
      case bookingsPerformanceCategoryMappings.renewalAtPar.name:
        categoryGraphic = renderSquare(styles.darkGreen);
        break;
      case bookingsPerformanceCategoryMappings.upSell.name:
        categoryGraphic = renderSquares(styles.viridianGreen);
        break;
      case bookingsPerformanceCategoryMappings.crossSell.name:
        categoryGraphic = renderSquares(styles.darkGreen);
        break;
      default:
        break;
    }

    return categoryGraphic;
  };

  const renderBookingsPerformanceTableRows = () => {
    const tableRows = [];

    for (let category in bookingsPerformanceCategoryMappings) {
      tableRows.push(
        <Tr key={category}>
          <Td noPadding={true} dataLabel={bookingsPerformanceColumnNames.metric}>
            {renderCategoryGraphic(bookingsPerformanceCategoryMappings[category].name)}
            {bookingsPerformanceCategoryMappings[category].name} %
          </Td>
          <Td
            noPadding={true}
            dataLabel={
              viewContext === finderPartnerTypes.DISTRIBUTOR ? bookingsPerformanceColumnNames.distributor : bookingsPerformanceColumnNames.partner
            }
          >
            {isFetching ? (
              <Skeleton screenreaderText={'Loading contents...'} />
            ) : (
              `${bookingsPerformance[bookingsPerformanceCategoryMappings[category].bookingsDataColumn]}%`
            )}
          </Td>
          <Td noPadding={true} dataLabel={bookingsPerformanceColumnNames.peer}>
            {arePeerDataConditonsMet ? (
              isFetching ? (
                <Skeleton screenreaderText={'Loading contents...'} />
              ) : (
                `${peerBookingsPerformance[bookingsPerformanceCategoryMappings[category].peerDataColumn]}%`
              )
            ) : (
              `N/A`
            )}
          </Td>
        </Tr>
      );
    }

    return tableRows;
  };

  return (
    <>
      <TextContent>
        <Text className={styles.eiPerformanceVsPeersTableTitle} component={TextVariants.p}>
          {'Performance Vs. Peers'}
        </Text>
      </TextContent>
      <section className={styles.eiPerformanceVsPeersTable}>
        <TableComposable aria-label="Performance Vs. Peers" borders={false} variant={'compact'}>
          <Thead>
            <Tr>
              <Th>{bookingsPerformanceColumnNames.metric}</Th>
              <Th>
                {viewContext === finderPartnerTypes.DISTRIBUTOR ? bookingsPerformanceColumnNames.distributor : bookingsPerformanceColumnNames.partner}
              </Th>
              <Th className={styles.eiPerformanceVsPeersButtonWrapper}>
                {bookingsPerformanceColumnNames.peer}{' '}
                {arePeerDataConditonsMet ? null : (
                  <Popover
                    aria-label="Peer popover"
                    className="eiDarkPopover"
                    position={PopoverPosition.top}
                    hasAutoWidth
                    showClose={false}
                    bodyContent={
                      <div>
                        {`Filter to only one ${viewContext === finderPartnerTypes.DISTRIBUTOR ? 'Distributor' : 'Partner'} Parent for comparison.`}
                      </div>
                    }
                  >
                    <Button aria-label="Peer Info" icon={<InfoIcon />} className={styles.eiIconButton} key="definitions" variant="plain"></Button>
                  </Popover>
                )}
              </Th>
            </Tr>
          </Thead>
          <Tbody>{renderBookingsPerformanceTableRows()}</Tbody>
        </TableComposable>
      </section>
    </>
  );
};

export default PerformanceVsPeers;
