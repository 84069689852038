import React, { useEffect } from 'react';
import { Pagination } from '@patternfly/react-core';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../../contexts/Table/TableContext';

const TablePagination = (props) => {
  const { globalState } = useGlobalContext();
  const tableContext = useTableContext();
  const {
    currentPage,
    selectedPerPage,
    pageLimit,
    isTableDataFetching,
    startIndex,
    endIndex,
    totalRecordsCount,
    paginationType,
    tableResponseError,
    setCurrentPage,
    setSelectedPerPage,
    setCurrentFetchId,
    setStartIndex,
    setEndIndex,
    setFetchStartIndex,
    setFetchEndIndex,
    setGlobalFilterAppliedOrReset,
  } = tableContext;
  let resetStartIndex: number = 0;
  let resetEndIndex: number = selectedPerPage;

  useEffect(() => {
    setCurrentFetchId(0);
    setStartIndex(0);
    setEndIndex(selectedPerPage);
    setFetchStartIndex(0);
    setFetchEndIndex(selectedPerPage);
    setCurrentPage(1);
    const timestamp = new Date().getTime() / 1000;
    setGlobalFilterAppliedOrReset(timestamp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  //Function called when user inputs page number.
  const handlePageInput = (event, page) => {
    // If the selectedPerPage value is 100 and the pageLimit is 500 there will be 5 pages of data available at a time.
    // A new set of data will be fetched after/before every 5 pages.
    // For Eg: Next Click will be at 0, 6, 11, 16... and Prev Click will be at ...20, 15, 10, 5.
    // When user inputs a page number, the data set is fetched for the page limit in
    // which the input page number fits in.
    // For Example: When user input page number `8` the data set will be loaded from page `6` to `10`
    // and not from `8` to `12`. The data is loaded from `6` to `10` inorder to have the correct previous
    // and next data values in such a way that the previous click and next click work efficiently.

    const totalPages: number = tableContext.pageLimit / selectedPerPage;
    // If the perPage is 100 and pageLimit is 500, the totalPages is 500/100 = 5.

    let startPage: number;
    // The startPage value is calculated to find the fetch id.

    let pageStartIndex: number;
    let pageEndIndex: number;
    const nearestFetchPage: number = Math.round(page / totalPages) * totalPages;
    // The nearestFetchPage is calculated to find the pages limit in which the input page is in.
    // Eg: If user input 7, the nearestFetchPage is 5 and if the user input is 8, the nearest
    // fetch page 10. Either way the data will be loaded from 6 to 10, i.e from fetch ID 500 - 999.

    if (nearestFetchPage > page || (nearestFetchPage === page && (nearestFetchPage * selectedPerPage) % pageLimit === 0)) {
      startPage = nearestFetchPage - totalPages;
      // If the user input 8, nearestFetchPage is 10. And the data will be loaded from 6 to 10.
      // Here the startPage value is 5
    } else {
      startPage = nearestFetchPage;
      // If the user input 7, nearestFetchPage is 5. And the data will be loaded from 6 to 10.
      // Here the startPage value is 5
    }

    const fetchId: number = startPage * selectedPerPage;
    // The fetchId is 500, as the start startPage is 5 and selectedPerPage is 100

    const currentPageIndex: number = page - startPage;
    // The currentPageIndex is calculated to find the pageStartIndex and pageEndIndex values of the page user input.
    // For page 8, pageStartIndex is 200 and pageEndIndex is 300 as page 8 is 3rd in 5 pages.
    // The index list will be (0, 100), (100, 200), (200, 300), (300, 400), (400, 500) for every 5 pages.

    if (currentPageIndex === 0) {
      pageStartIndex = 0;
      pageEndIndex = selectedPerPage;
    } else {
      pageStartIndex = selectedPerPage * (currentPageIndex - 1);
      pageEndIndex = selectedPerPage * currentPageIndex;
    }

    // Here fetchId, page, pageStartIndex, pageEndIndex values are set to table context.
    setCurrentFetchId(fetchId);
    setStartIndex(pageStartIndex);
    setEndIndex(pageEndIndex);
    setFetchStartIndex(pageStartIndex);
    setFetchEndIndex(pageEndIndex);
    setCurrentPage(page);
  };

  //Function called when user clicks on navigate to next page.
  const handleNextClick = (event: React.SyntheticEvent<HTMLButtonElement>, page: number) => {
    // If the selectedPerPage value is 100, the pageLimit is 500 there will be 5 pages of data available at a time.
    // And a new set of data will be fetched after every 5 pages.
    // For Eg: Next Click will be at 0, 6, 11, 16...

    if (((page - 1) * selectedPerPage) % pageLimit === 0) {
      // If user is in page 10 and clicks next, the page will be 11. (((11 - 1)*100)/500) === 0.
      // Here the next set of data will be fetched.
      // The currentPage is set to 11, fetchId is set to 1000, startIndex is reset to 0 and endIndex is reset to 100.
      setCurrentFetchId((page - 1) * selectedPerPage);
      setCurrentPage(page);
      setStartIndex(0);
      setEndIndex(selectedPerPage);
      setFetchStartIndex(0);
      setFetchEndIndex(selectedPerPage);
      resetStartIndex = 0;
      resetEndIndex = selectedPerPage;
    } else {
      // If user is in page 11 and clicks next, the page will be 12.
      // Here the data set is available from 11 - 15 hence not fetching a new set
      // The currentPage is set to 12, startIndex is set to 100 and endIndex is set to 200.
      setStartIndex(startIndex + selectedPerPage);
      setEndIndex(endIndex + selectedPerPage);
      resetStartIndex = startIndex + selectedPerPage;
      resetEndIndex = endIndex + selectedPerPage;
      setCurrentPage(page);
      setSelectedPerPage(selectedPerPage);
      setFetchStartIndex(resetStartIndex);
      setFetchEndIndex(resetEndIndex);
    }
  };

  //Function called when user clicks on navigate to previous page.
  const handlePreviousClick = (event, page) => {
    // If the selectedPerPage value is 100, the pageLimit is 500 there will be 5 pages of data available at a time.
    // And a new set of data will be fetched before every 5 pages.
    // For Eg: Prev Click will be at ...20, 15, 10, 5.

    if ((page * selectedPerPage) % pageLimit === 0) {
      // If user is in page 11 and clicks previous, the page will be 10. ((10*100)/500) === 0.
      // Here the previous set of data will be fetched.
      // The currentPage is set to 10, fetchId is set to 500, startIndex is reset to 400 and endIndex is reset to 500.
      setCurrentFetchId(page * selectedPerPage - pageLimit);
      setCurrentPage(page);
      setStartIndex(pageLimit - selectedPerPage);
      setEndIndex(pageLimit);
      setFetchStartIndex(pageLimit - selectedPerPage);
      setFetchEndIndex(pageLimit);
      resetStartIndex = 0;
      resetEndIndex = selectedPerPage;
    } else {
      // If user is in page 10 and clicks previous, the page will be 9.
      // Here the data set is available from 6 - 10 hence not fetching the new set
      // The currentPage is set to 9, startIndex is set to 300 and endIndex is set to 400.
      if (startIndex > 0 && endIndex > 0) {
        setStartIndex(startIndex - selectedPerPage);
        setEndIndex(endIndex - selectedPerPage);
        resetStartIndex = startIndex - selectedPerPage;
        resetEndIndex = endIndex - selectedPerPage;
      } else {
        setStartIndex(pageLimit - selectedPerPage);
        setEndIndex(pageLimit);
        resetStartIndex = pageLimit - selectedPerPage;
        resetEndIndex = pageLimit;
      }
      setCurrentPage(page);
      setSelectedPerPage(selectedPerPage);
      setFetchStartIndex(resetStartIndex);
      setFetchEndIndex(resetEndIndex);
    }
  };

  // Function called when user clicks on navigate to last page.
  const handleLastClick = (event, page) => {
    // Last set of data is fetched.

    if (totalRecordsCount > pageLimit) {
      // If the total records count is greater than page limit, will fetch the next set of data
      setCurrentFetchId(totalRecordsCount - pageLimit);
      setStartIndex(pageLimit - selectedPerPage);
      setEndIndex(pageLimit);
      setFetchStartIndex(pageLimit - selectedPerPage);
      setFetchEndIndex(pageLimit);
    } else {
      // If the total records count is less than page limit, start index and end index will be set to last set of data
      setStartIndex((page - 1) * selectedPerPage);
      setEndIndex(page * selectedPerPage - 1);
      setFetchStartIndex((page - 1) * selectedPerPage);
      setFetchEndIndex(page * selectedPerPage - 1);
    }
    setCurrentPage(page);
  };

  // Function called when user clicks on navigate to first page.
  const handleFirstClick = (event, page) => {
    // First set of data is fetched.
    setCurrentFetchId(0);
    setStartIndex(0);
    setEndIndex(selectedPerPage);
    setFetchStartIndex(0);
    setFetchEndIndex(selectedPerPage);
    setCurrentPage(page);
  };

  //Function called when user selects number of items per page.
  const handlePerPageSelect = (event, newPerPage, newPage) => {
    // If the perPage value is 100 and the pageLimit is 500 there will be 5 pages of data available at a time.
    // A new set of data will be fetched after/before every 5 pages.
    // For Eg: Next Click will be at 0, 6, 11, 16... and Prev Click will be at ...20, 15, 10, 5.
    // When an user select a perPage value, the data set is fetched for the page limit in
    // which the current page number fits in.

    const totalPages: number = pageLimit / newPerPage;
    // If the per page is 100 and page limit is 500, the total pages is 500/100 = 5.

    let pageStartIndex: number;
    let pageEndIndex: number;
    let startPage: number;
    const nearestFetchPage: number = Math.round(newPage / totalPages) * totalPages;
    // The nearestFetchPage is calculated to find the page limit in which the current page is in.

    if (nearestFetchPage > newPage || (nearestFetchPage === newPage && (nearestFetchPage * newPerPage) % pageLimit === 0)) {
      startPage = nearestFetchPage - totalPages;
    } else {
      startPage = nearestFetchPage;
      // If the current page 7, and user select 100 per page, the nearestFetchPage / start page is 5.
    }

    const fetchId: number = startPage * newPerPage;
    // The fetch id is 500, since the start page value is 5 and per page is 100

    const currentPageIndex: number = newPage - startPage;
    // The currentPageIndex is calculated to find the startIndex and EndIndex values of the user current Page.
    // For the current page 7, the startIndex is 100 and end index is 200 since page 7 is 2nd in 5 pages.
    // The index list will be (0, 100), (100, 200), (200, 300), (300, 400), (400, 500) for every 5 pages.

    if (currentPageIndex === 0) {
      pageStartIndex = 0;
      pageEndIndex = newPerPage;
    } else {
      pageStartIndex = newPerPage * (currentPageIndex - 1);
      pageEndIndex = newPerPage * currentPageIndex;
    }

    // Here fetch id, page, perPage, start index, end index values are set to table context
    setCurrentFetchId(fetchId);
    setSelectedPerPage(newPerPage);
    setStartIndex(pageStartIndex);
    setEndIndex(pageEndIndex);
    setFetchStartIndex(pageStartIndex);
    setFetchEndIndex(pageEndIndex);
    setCurrentPage(newPage);
  };

  // Function that renders pagination
  const renderPagination = (variant, isCompact: boolean) => {
    return (
      <Pagination
        data-test="table-pagination"
        isCompact={paginationType}
        itemCount={totalRecordsCount}
        page={currentPage}
        perPage={selectedPerPage}
        onFirstClick={handleFirstClick}
        onLastClick={handleLastClick}
        onPerPageSelect={handlePerPageSelect}
        onPageInput={handlePageInput}
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        perPageOptions={[
          { title: '10', value: 10 },
          { title: '20', value: 20 },
          { title: '50', value: 50 },
          { title: '100', value: 100 },
        ]}
        titles={{
          paginationTitle: `${variant} pagination`,
        }}
        isDisabled={isTableDataFetching || tableResponseError}
        widgetId={`${variant}-pagination`}
      />
    );
  };
  return <>{renderPagination(props.position, false)}</>;
};

export default TablePagination;
