import ENV from '../utils/env';
import { postRequest } from '../utils/fetch';

/* get the Review Hero data */
export const getReviewHeroData = (bodyParams, abortSignal?: AbortSignal): Promise<any> => {
  const additionalParams: RequestInit = {};

  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/review-service/review-hero`);

  if (abortSignal) {
    Object.assign(additionalParams, { signal: abortSignal });
  }

  return postRequest(uri, bodyParams, additionalParams);
};

/* get the Renew Hero data */
export const getRenewHeroData = (bodyParams, abortSignal?: AbortSignal): Promise<any> => {
  const additionalParams: RequestInit = {};

  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/renew-service/data/renew-hero`);

  if (abortSignal) {
    Object.assign(additionalParams, { signal: abortSignal });
  }

  return postRequest(uri, bodyParams, additionalParams);
};

/* get the Infrared Hero data */
export const getInfraredCustomerInsightsHeroData = (bodyParams, abortSignal?: AbortSignal): Promise<any> => {
  const additionalParams: RequestInit = {};

  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/review-service/review-hero`);

  if (abortSignal) {
    Object.assign(additionalParams, { signal: abortSignal });
  }

  return postRequest(uri, bodyParams, additionalParams);
};

/* get the Infrared Cross-Sell Hero data */
export const getInfraredCrossSellProbabilitiesHeroData = (bodyParams, abortSignal?: AbortSignal): Promise<any> => {
  const additionalParams: RequestInit = {};

  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/infrared-data-service/cross-sell/hero`);

  if (abortSignal) {
    Object.assign(additionalParams, { signal: abortSignal });
  }

  return postRequest(uri, bodyParams, additionalParams);
};
