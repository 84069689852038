import React from 'react';
import { Button, Popover, Split, SplitItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import { ReactComponent as RenewalsIllustration } from '../../../../../assets/renewals_Intelligence.svg';
import '../Shared/constants';
import { WHATS_NEW_TEXT } from '../Shared/constants';
import styles from './HeroStatic.module.scss';

type HeroStaticProps = {
  title: string;
  text: string;
  hasPopover?: boolean;
};

const HeroStatic: React.FC<HeroStaticProps> = ({ title, text, hasPopover = true }) => {
  const recentWhatsNewText = WHATS_NEW_TEXT[WHATS_NEW_TEXT?.length - 1]?.text;

  return (
    <Split className={styles.eiHeroContent} data-test="hero-home">
      <SplitItem isFilled className={styles.eiHeroTextContainer}>
        <TextContent>
          <Text component={TextVariants.h1}>{title}</Text>
        </TextContent>
        <TextContent className={styles.eiHeroTextSpacing}>
          <Text component={TextVariants.p}>{text}</Text>
        </TextContent>
        {hasPopover && (
          <div id="tooltip">
            <Popover
              className={styles.eiWhatsNewStyles}
              bodyContent={<div data-test="hero-home-popover">{recentWhatsNewText}</div>}
              position={'bottom'}
            >
              <Button data-test="hero-home-button" variant="link" icon={<InfoCircleIcon />}>
                What’s New?
              </Button>
            </Popover>
          </div>
        )}
      </SplitItem>
      <SplitItem className={styles.eiRenewalsIntelligenceSVGContainer}>
        <RenewalsIllustration className={styles.eiRenewalsIntelligenceSVG} />
      </SplitItem>
    </Split>
  );
};

export default HeroStatic;
