import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Split, SplitItem, Stack, StackItem } from '@patternfly/react-core';
import { kebabCase } from 'lodash';
import { fetchDataPostRequest } from '../../api/apiCalls';
import EcoIntelEmptyState from '../../components/EcoIntelEmptyState';
import { errorState } from '../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../utils/filterHelper';
import BookingsPerformanceOverview from './BookingsPerformanceOverview';
import DealManagementOverview from './DealManagementOverview';
import RetentionPerformanceOverview from './RetentionPerformanceOverview';
import TopAndBottomPerformersOverview from './TopAndBottomPerformersOverview';
import styles from './ReviewOverview.module.scss';

interface OverviewProps {
  viewContext: string;
}

const ReviewOverview: React.FC<OverviewProps> = ({ viewContext }) => {
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.REVIEW_OVERVIEW);
  const [reviewOverviewData, setOverviewData] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const { globalState } = useGlobalContext();

  useEffect(() => {
    getOverviewData(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  const getOverviewData = async (requestData) => {
    setResponseError(null);
    try {
      const selectedFilterValues = getAllSelectedFilterValues(globalState, { viewContext: viewContext });

      const response = await requestData(selectedFilterValues);

      setResponseError(null);
      setOverviewData(response);
    } catch (error) {
      setResponseError(error.message);
    }
  };
  //#endregion

  const getCardTitleContent = (path: string, cardTitle: string) => {
    return (
      <section className={styles.eiCardTitle}>
        <Button
          data-test={`review-overview-${kebabCase(cardTitle)}`}
          variant="link"
          component={(props: any) => <Link className={styles.eiCardTitleLinkText} {...props} to={path} />}
        >
          {cardTitle}
        </Button>
      </section>
    );
  };

  return (
    <>
      {!responseError && (
        <Stack data-test="review-overview-content">
          <StackItem className={styles.eiBookingsPerformanceOverviewContainer}>
            <BookingsPerformanceOverview
              bookingsPerformanceTime={reviewOverviewData?.bookings_performance_time || []}
              bookingsPerformance={reviewOverviewData?.bookings_performance || []}
              getCardTitleContent={getCardTitleContent}
              isFetching={isFetching}
              peerBookingsPerformance={reviewOverviewData?.peer_bookings_performance || []}
              viewContext={viewContext}
            />
          </StackItem>
          <StackItem className={styles.eiRetentionTopAndBottomOverviewStack}>
            <Split hasGutter className={styles.eiWrappableSplit}>
              <SplitItem className={styles.eiRetentionPerformance}>
                <RetentionPerformanceOverview
                  customerRetention={reviewOverviewData?.customer_retention || {}}
                  getCardTitleContent={getCardTitleContent}
                  isFetching={isFetching}
                />
              </SplitItem>
              <SplitItem className={styles.eiTopAndBottomPerformers}>
                <TopAndBottomPerformersOverview
                  bookingsPerformance={reviewOverviewData?.bookings_performance || []}
                  getCardTitleContent={getCardTitleContent}
                  isFetching={isFetching}
                />
              </SplitItem>
            </Split>
          </StackItem>
          <StackItem>
            <DealManagementOverview
              dealManagement={reviewOverviewData?.deal_management_opps || []}
              getCardTitleContent={getCardTitleContent}
              isFetching={isFetching}
              viewContext={viewContext}
            />
          </StackItem>
        </Stack>
      )}
      {responseError && (
        <Card>
          <CardBody>
            <EcoIntelEmptyState emptyStateProps={errorState} />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default ReviewOverview;
