import { FilterOption } from '../../models/FilterOption';
import { FilterDataSelection } from '../../models/filter';

export enum StateActions {
  filterData,
  filterLookups,
  otherFilters,
  SetCustomerSegment,
  SetGeoRegion,
  SetSubregion,
  SetCountry,
  SetDistributorParent,
  SetDistributorAccount,
  SetIncumbentDistributor,
  SetIncumbentPartner,
  SetPartnerParent,
  SetPartnerAccount,
  ApplyFilter,
  ResetFilter,
}

export interface FilterState {
  originalFilterData?: any;
  selectedFilterData?: FilterDataSelection;
  isResetFilter?: boolean;
  isApplyFilter: Boolean;
  validationError?: any;
  isFiltersInProgress?: boolean;
}

export interface Lookups {
  partnerParentLookup?: FilterOption[];
  partnerAccountLookup?: FilterOption[];
  distributorAccountLookup?: FilterOption[];
  distributorParentLookup?: FilterOption[];
  geoLookup?: FilterOption[];
  subregionLookup?: FilterOption[];
  countryLookup?: FilterOption[];
  customerSegmentLookup?: FilterOption[];
}

export interface OtherFilters {
  currencyCode?: string;
}

export interface GlobalState {
  filterState?: FilterState;
  lookupState?: Lookups;
  otherFilters?: OtherFilters;
  error?: any;
}

const initialFilterDataSelection: FilterDataSelection = {
  calculationAmount: 'SYB',
};

const initialFilterState: FilterState = {
  originalFilterData: {} as any,
  selectedFilterData: initialFilterDataSelection,
  isResetFilter: false,
  // eslint-disable-next-line no-new-wrappers
  isApplyFilter: false,
  validationError: {},
  isFiltersInProgress: false,
};

export const initialGlobalState: GlobalState = {
  filterState: initialFilterState,
  lookupState: {} as any,
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: GlobalState, action: any): GlobalState => {
  switch (action.type) {
    case StateActions.filterData:
      return {
        ...state,
        filterState: action.payload.filterState,
        error: action.payload.error,
      };
    case StateActions.filterLookups:
      return {
        ...state,
        lookupState: action.payload.lookupState,
        error: action.payload.error,
      };
    case StateActions.otherFilters:
      return {
        ...state,
        otherFilters: action.payload.otherFilters,
        error: action.payload.error,
      };

    case StateActions.SetCustomerSegment: {
      state.filterState.selectedFilterData.customerSegment = action.payload.customerSegment;
      break;
    }
    case StateActions.SetGeoRegion: {
      state.filterState.selectedFilterData.geoRegion = action.payload.geoRegion;
      break;
    }
    case StateActions.SetSubregion: {
      state.filterState.selectedFilterData.subregion = action.payload.subregion;
      break;
    }
    case StateActions.SetCountry: {
      state.filterState.selectedFilterData.country = action.payload.country;
      break;
    }
    case StateActions.SetDistributorParent: {
      state.filterState.selectedFilterData.distributorParent = action.payload.distributorParent;
      break;
    }
    case StateActions.SetDistributorAccount: {
      state.filterState.selectedFilterData.distributorAccount = action.payload.distributorAccount;
      break;
    }
    case StateActions.SetPartnerParent: {
      state.filterState.selectedFilterData.partnerParent = action.payload.partnerParent;
      break;
    }
    case StateActions.SetPartnerAccount: {
      state.filterState.selectedFilterData.partnerAccount = action.payload.partnerAccount;
      break;
    }
    case StateActions.SetIncumbentPartner: {
      state.filterState.selectedFilterData.incumbentPartner = action.payload.incumbentPartner;
      break;
    }
    case StateActions.SetIncumbentDistributor: {
      state.filterState.selectedFilterData.incumbentDistributor = action.payload.incumbentDistributor;
      break;
    }
    case StateActions.ApplyFilter: {
      state.filterState.isApplyFilter = action.payload.isApplyFilter;
      break;
    }
    case StateActions.ResetFilter: {
      state.filterState.isResetFilter = action.payload.isResetFilter;
      break;
    }
    default:
      break;
  }

  return state;
};
