import { FilterMenuConfig } from '../models/filter';

export const initialFilterMenuConfig: FilterMenuConfig = {
  options: [],
  isOpen: false,
  selected: [],
  isDisabled: false,
};

export const defaultMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
