import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Stack, StackItem, Text, TextVariants } from '@patternfly/react-core';
import { useGlobalContext } from '../../../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../../../contexts/GlobalContext/GlobalContextReducer';
import { FilterOption } from '../../../../../models/FilterOption';
import { VirtualizedSelect } from '../../../../VirtualizedSelect/VirtualizedSelect';
import styles from './CustomerFilter.module.scss';

const CustomerFilter: React.FC = () => {
  const { globalState, dispatch } = useGlobalContext();
  const [selectedCustomerSegmentOptions, setSelectedCustomerSegmentOptions] = useState<FilterOption[]>([]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.lookupState) {
        const lookups = globalState.lookupState;
        const selectedFilters = globalState?.filterState?.selectedFilterData;

        setSelectedCustomerSegmentOptions(
          selectedFilters?.customerSegment?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.customerSegmentLookup
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lookups = globalState.lookupState;
    unstable_batchedUpdates(() => {
      if (globalState.filterState.isResetFilter) {
        setSelectedCustomerSegmentOptions(lookups.customerSegmentLookup);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.filterState.isResetFilter]);

  const onCustomerSegmentSelect = (selection: FilterOption[]) => {
    setSelectedCustomerSegmentOptions(selection);
    dispatch({
      type: StateActions.SetCustomerSegment,
      payload: {
        customerSegment: selection.map((it) => it.value),
      },
    });
  };

  return (
    <Stack hasGutter className={styles.eiFilterMenu}>
      {globalState && (
        <>
          <StackItem>
            <Text component={TextVariants.small} id="customer-segment-select">
              Customer Segment
            </Text>
            <VirtualizedSelect
              placeHolder={'Select Customer Segment'}
              isMulti
              isSearchable
              remainOpenOnSelect={true}
              options={globalState.lookupState.customerSegmentLookup}
              selections={selectedCustomerSegmentOptions}
              onChange={onCustomerSegmentSelect}
              dataTest="customer-filter"
            />
          </StackItem>
        </>
      )}
    </Stack>
  );
};

export default CustomerFilter;
