import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, Page, PageSection, PageSidebar, SkipToContent } from '@patternfly/react-core';
import { isEmpty } from 'lodash';
import { finderPartnerTypes } from '../../constants/finderPartnerTypes';
import { userTypes } from '../../constants/userTypes';
import DefinitionsContext from '../../contexts/Definitions/DefinitionsContext';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import ToastContext from '../../contexts/Toast/ToastContext';
import { useUserMetadata } from '../../hooks/useUserMetadata';
import routeHelper from '../../utils/routeHelper';
import EcoIntelEmptyState from '../EcoIntelEmptyState';
import { errorState } from '../EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import EmptyStateSpinner from '../EmptyStateSpinner';
import Toast from '../Toast';
import Footer from './Footer';
import Header from './Header';
import PageContent from './PageContent';
import Filter from './PageContent/Filter';
import Hero from './PageContent/Hero';
import Navbar from './PageContent/Navbar';
import styles from './Layout.module.scss';

const Layout: React.FC = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [definitions, setDefinitions] = useState([]);
  const [infraredCustomerInsightsSelectedCustomer, setInfraredCustomerInsightsSelectedCustomer] = useState();
  const currentPath = useLocation()?.pathname;
  const currentPathRouteObject = routeHelper.getRouteObjectFromPath(currentPath);
  const { globalState } = useGlobalContext();

  const toggleNavbar = () => {
    if (showFilter) {
      setShowFilter(false);
    }

    setShowNavbar(!showNavbar);
  };

  const toggleFilter = () => {
    if (showNavbar) {
      setShowNavbar(false);
    }

    setShowFilter(!showFilter);
  };

  const [viewContext, setViewContext] = useState('');
  const userMetadata = useUserMetadata();
  const isViewContextToggleVisible = () => {
    return (
      viewContext &&
      userMetadata &&
      (userMetadata.finder_partner_type_name === finderPartnerTypes.DISTRIBUTOR || userMetadata.user_type === userTypes.INTERNAL)
    );
  };

  useEffect(() => {
    setViewContext(userMetadata ? userMetadata.finder_partner_type_name : '');
  }, [userMetadata]);

  useEffect(() => {
    if (currentPathRouteObject) {
      document.title = currentPathRouteObject.linkText ? `${currentPathRouteObject.linkText} |` : '';
      document.title += document.title ? ' Renewals Intelligence' : 'Renewals Intelligence';
    }
  }, [currentPathRouteObject]);

  const SidebarComponent =
    showNavbar || showFilter ? (
      <PageSidebar
        className={styles.eiPageSidebar}
        nav={
          <>
            {showNavbar && <Navbar currentPath={currentPath} setDefinitions={setDefinitions} />}
            {showFilter && <Filter />}
          </>
        }
        isNavOpen={showNavbar || showFilter}
      />
    ) : (
      <></>
    );

  //isSelected is needed, even though it isn't used, for the tsx type to function correctly in the Hero.tsx file.
  const handleToggleViewContextClick = (isSelected, event) => {
    const id = event.currentTarget.id;
    setViewContext(id);
  };

  const pageSkipToContent = (
    <SkipToContent className={styles.eiSkip} href="#main">
      Skip to content
    </SkipToContent>
  );

  return (
    <>
      <ToastContext>
        <DefinitionsContext>
          <Page
            header={<Header toggleNavbar={toggleNavbar} toggleFilter={toggleFilter} currentPath={currentPath} viewContext={viewContext} />}
            sidebar={SidebarComponent}
            skipToContent={pageSkipToContent}
          >
            {!isEmpty(globalState.error) ? (
              <div className={styles.eiEmptyState}>
                <Card>
                  <CardBody>
                    <EcoIntelEmptyState emptyStateProps={errorState} />
                  </CardBody>
                </Card>
              </div>
            ) : viewContext === '' ? (
              <>
                <div className={styles.eiHeroAndPageContentPlaceholder}>
                  <EmptyStateSpinner />
                </div>
              </>
            ) : (
              <>
                <PageSection padding={{ default: 'noPadding' }}>
                  <Hero
                    currentPath={currentPath}
                    handleToggleViewContextClick={handleToggleViewContextClick}
                    infraredCustomerInsightsSelectedCustomer={infraredCustomerInsightsSelectedCustomer}
                    isViewContextToggleVisible={isViewContextToggleVisible()}
                    viewContext={viewContext}
                  />
                </PageSection>
                <PageSection padding={{ default: 'noPadding' }}>
                  <PageContent
                    currentPath={currentPath}
                    infraredCustomerInsightsSelectedCustomer={infraredCustomerInsightsSelectedCustomer}
                    setInfraredCustomerInsightsSelectedCustomer={setInfraredCustomerInsightsSelectedCustomer}
                    setShowFilter={setShowFilter}
                    setShowNavbar={setShowNavbar}
                    setViewContext={setViewContext}
                    viewContext={viewContext}
                    definitions={definitions}
                  />
                </PageSection>
              </>
            )}
          </Page>
          <Footer viewContext={viewContext} />
        </DefinitionsContext>
        <Toast />
      </ToastContext>
    </>
  );
};

export default Layout;
