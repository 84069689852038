import React, { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle } from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../api/apiCalls';
import TableWrapper from '../../components/TableWrapper';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../utils/filterHelper';
import OpportunitiesDetail from './OpportunitiesDetail';
import OpportunitiesSummary from './OpportunitiesSummary';
import styles from './Opportunities.module.scss';

interface OpportunitiesProps {
  viewContext: string;
}

const Opportunities: React.FC<OpportunitiesProps> = ({ viewContext }) => {
  const [expanded, setExpanded] = useState(['upcoming-opportunity-summary', 'upcoming-opportunity-detail']);
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.PIPELINE_RISK_PROFILE);
  const [upcomingOpportunityData, setUpcomingOpportunityData] = useState();
  const [responseError, setResponseError] = useState(null);
  const { globalState } = useGlobalContext();

  const toggle = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  useEffect(() => {
    const getOpportunitiesPageData = async () => {
      setResponseError(null);
      try {
        const selectedFilterValues = getAllSelectedFilterValues(globalState, { viewContext: viewContext });
        const response = await request(selectedFilterValues);
        setUpcomingOpportunityData(response);
      } catch (error) {
        setResponseError(error.message);
      }
    };
    getOpportunitiesPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  return (
    <div className={styles.eiOpportunities}>
      <Accordion asDefinitionList={false} headingLevel="h2">
        <AccordionItem>
          <AccordionToggle
            data-test="opportunities-summary-toggle"
            onClick={() => toggle('upcoming-opportunity-summary')}
            isExpanded={expanded.includes('upcoming-opportunity-summary')}
            id="upcoming-opportunity-summary"
          >
            Upcoming Opportunity Summary
          </AccordionToggle>
          <AccordionContent
            id="upcoming-opportunity-summary-expand"
            isHidden={!expanded.includes('upcoming-opportunity-summary')}
            isFixed
            tabIndex={0}
          >
            <OpportunitiesSummary
              viewContext={viewContext}
              responseError={responseError}
              isFetching={isFetching}
              upcomingOpportunityData={upcomingOpportunityData}
            />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordionToggle
            data-test="opportunities-detail-toggle"
            onClick={() => toggle('upcoming-opportunity-detail')}
            isExpanded={expanded.includes('upcoming-opportunity-detail')}
            id="upcoming-opportunity-detail"
          >
            Upcoming Opportunity Detail
          </AccordionToggle>
          <AccordionContent id="upcoming-opportunity-detail-expand" isHidden={!expanded.includes('upcoming-opportunity-detail')} isFixed tabIndex={0}>
            {/* // Render table as a child of table context provider */}
            <TableWrapper>
              <OpportunitiesDetail viewContext={viewContext} />
            </TableWrapper>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default Opportunities;
