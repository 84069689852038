import React from 'react';
import { EmptyState, EmptyStateIcon, Spinner, Title } from '@patternfly/react-core';

interface EmptyStateSpinnerProps {
  hideText?: boolean;
}

const EmptyStateSpinner: React.FC<EmptyStateSpinnerProps> = (props) => {
  const { hideText } = props;

  return (
    <EmptyState data-test="empty-state">
      <EmptyStateIcon variant="container" component={Spinner} />
      {!hideText && (
        <Title size="lg" headingLevel="h2">
          Loading
        </Title>
      )}
    </EmptyState>
  );
};

export default EmptyStateSpinner;
