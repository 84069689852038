import React, { useEffect } from 'react';
import PlaceholderPageContent from '../../components/PlaceholderPageContent';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../contexts/GlobalContext/GlobalContextReducer';
import pageRoutes from '../../utils/pageRoutes';

interface CustomerInsightsProps {
  infraredCustomerInsightsSelectedCustomer: any;
  setInfraredCustomerInsightsSelectedCustomer: Function;
  viewContext: string;
}

const CustomerInsights: React.FC<CustomerInsightsProps> = ({
  infraredCustomerInsightsSelectedCustomer,
  setInfraredCustomerInsightsSelectedCustomer,
  viewContext,
}) => {
  const { globalState, dispatch } = useGlobalContext();

  /*
  The below useEffect should be removed when you make an API call to display the data to the page.
  Currently it is not developed hence we need to make isFiltersInProgress flag to false manually.
  Note: If your page is not having any API call and yet you are going to show the filters, you need this useEffect.
  */
  useEffect(() => {
    if (globalState.filterState.isFiltersInProgress) {
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: { ...globalState.filterState, isFiltersInProgress: false },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  // <>
  //   <Button variant={'primary'} onClick={() => setInfraredCustomerInsightsSelectedCustomer(Math.random())}>
  //     Set Customer
  //   </Button>
  //   <Button variant={'secondary'} onClick={() => setInfraredCustomerInsightsSelectedCustomer(null)}>
  //     Reset
  //   </Button>
  //   <PlaceholderPageContent pageTitle={pageRoutes.customerInsights.linkText} />
  // </>

  return <PlaceholderPageContent pageTitle={pageRoutes.customerInsights.linkText} />;
};

export default CustomerInsights;
