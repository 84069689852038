import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle } from '@patternfly/react-core';
import TableWrapper from '../../components/TableWrapper';
import TopAndBottomPerformersDetail from './TopAndBottomPerformersDetail';
import TopAndBottomPerformersSummary from './TopAndBottomPerformersSummary';
import styles from './TopAndBottomPerformers.module.scss';

interface TopAndBottomPerformersProps {
  viewContext: string;
}

const TopAndBottomPerformers: React.FC<TopAndBottomPerformersProps> = ({ viewContext }) => {
  const [expanded, setExpanded] = useState(['top-and-bottom-performers-summary', 'top-and-bottom-performers-detail']);

  const toggle = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  return (
    <div className={styles.eiTopAndBottomPerformers}>
      <Accordion asDefinitionList={false} headingLevel="h2">
        <AccordionItem>
          <AccordionToggle
            data-test="top-and-bottom-performers-summary-toggle"
            isExpanded={expanded.includes('top-and-bottom-performers-summary')}
            id="top-and-bottom-performers-summary"
            onClick={() => toggle('top-and-bottom-performers-summary')}
          >
            Top And Bottom Performers Summary
          </AccordionToggle>
          <AccordionContent
            id="top-and-bottom-performers-summary-accordion"
            isHidden={!expanded.includes('top-and-bottom-performers-summary')}
            isFixed
          >
            <TopAndBottomPerformersSummary viewContext={viewContext} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordionToggle
            data-test="top-and-bottom-performers-detail-toggle"
            isExpanded={expanded.includes('top-and-bottom-performers-detail')}
            id="top-and-bottom-performers-detail"
            onClick={() => toggle('top-and-bottom-performers-detail')}
          >
            Top And Bottom Performers Detail
          </AccordionToggle>
          <AccordionContent id="top-and-bottom-performers-detail-accordion" isHidden={!expanded.includes('top-and-bottom-performers-detail')} isFixed>
            <TableWrapper>
              <TopAndBottomPerformersDetail viewContext={viewContext} />
            </TableWrapper>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default TopAndBottomPerformers;
