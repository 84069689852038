import React, { useState } from 'react';
import {
  Button,
  ButtonVariant,
  DataList,
  DataListCell,
  DataListContent,
  DataListItem,
  DataListItemCells,
  DataListItemRow,
  DataListToggle,
  Flex,
  FlexItem,
  Stack,
  StackItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core';
import { isEmpty } from 'lodash';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../../contexts/GlobalContext/GlobalContextReducer';
import EcoIntelBackdrop from '../../../EcoIntelBackdrop';
import CustomerFilter from './CustomerFilter';
import LocationFilter from './LocationFilter';
import OtherFilter from './OtherFilter';
import PartnerFilter from './PartnerFilter';
import styles from './Filter.module.scss';

const Filter: React.FC = () => {
  const { globalState, dispatch } = useGlobalContext();
  const [expandFilter, setExpandFilter] = useState([]);

  const onToggle = (index) => {
    const id = expandFilter.indexOf(index);
    const newExpanded = id >= 0 ? [...expandFilter.slice(0, id), ...expandFilter.slice(id + 1, expandFilter.length)] : [...expandFilter, index];
    setExpandFilter(newExpanded);
  };

  const onApplyFilter = () => {
    dispatch({
      type: StateActions.filterData,
      payload: {
        // eslint-disable-next-line
        filterState: { ...globalState.filterState, isApplyFilter: new Boolean(true), isResetFilter: false, isFiltersInProgress: true },
      },
    });
  };

  const onResetFilter = () => {
    dispatch({
      type: StateActions.filterData,
      payload: {
        // eslint-disable-next-line
        filterState: { ...globalState.filterState, selectedFilterData: {}, isApplyFilter: new Boolean(false), isResetFilter: true },
      },
    });
  };

  return (
    <>
      {globalState?.filterState?.isFiltersInProgress && <EcoIntelBackdrop />}
      <Stack role="form" className={styles.eiFilter}>
        <StackItem className={styles.eiFilterStackItem}>
          <Flex className={styles.eiFilterAction}>
            <FlexItem>
              <TextContent>
                <Text component={TextVariants.h3} className={styles.eiFilterText} data-test="filter-title">
                  FILTERS
                </Text>
              </TextContent>
            </FlexItem>
            <FlexItem align={{ default: 'alignRight' }}>
              <Button
                variant={ButtonVariant.primary}
                onClick={onApplyFilter}
                isDisabled={!isEmpty(globalState.filterState.validationError)}
                data-test="apply-filter"
              >
                Apply
              </Button>
            </FlexItem>
            <FlexItem>
              <Button variant={ButtonVariant.tertiary} className={styles.eiClearFilter} onClick={onResetFilter} data-test="reset-filter">
                Reset
              </Button>
            </FlexItem>
          </Flex>
        </StackItem>

        <StackItem>
          <DataList aria-label="Filter list" className={styles.eiFilterMenu} isCompact>
            <DataListItem aria-labelledby="partner-filter-item" isExpanded={expandFilter.includes('partner-filter')} className={styles.eiFilterItem}>
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell key="partner filters" className={styles.eiFilterTitle}>
                      <TextContent>
                        <Text>Partner Filters</Text>
                      </TextContent>
                    </DataListCell>,
                  ]}
                />
                <DataListToggle
                  onClick={() => onToggle('partner-filter')}
                  isExpanded={expandFilter.includes('partner-filter')}
                  id="partner-filter-toggle"
                  aria-controls="partner-filter-options"
                  className={styles.eiFilterToggle}
                />
              </DataListItemRow>
              <DataListContent
                aria-label="Partner filter Details"
                id="partner-filter-options"
                isHidden={!expandFilter.includes('partner-filter')}
                className={styles.eiFilterOptions}
              >
                <PartnerFilter />
              </DataListContent>
            </DataListItem>

            <DataListItem
              aria-labelledby="customer-filter-item"
              isExpanded={expandFilter.includes('customer-filter')}
              className={styles.eiFilterItem}
            >
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell key="customer filters" className={styles.eiFilterTitle}>
                      <TextContent>
                        <Text>Customer filters</Text>
                      </TextContent>
                    </DataListCell>,
                  ]}
                />
                <DataListToggle
                  onClick={() => onToggle('customer-filter')}
                  isExpanded={expandFilter.includes('customer-filter')}
                  id="customer-filter-toggle"
                  aria-controls="customer-filter-options"
                  className={styles.eiFilterToggle}
                />
              </DataListItemRow>
              <DataListContent
                aria-label="customer filter Details"
                id="customer-filter-options"
                isHidden={!expandFilter.includes('customer-filter')}
                className={styles.eiFilterOptions}
              >
                <CustomerFilter />
              </DataListContent>
            </DataListItem>

            <DataListItem
              aria-labelledby="location-filter-item"
              isExpanded={expandFilter.includes('location-filter')}
              className={styles.eiFilterItem}
            >
              <DataListItemRow>
                <DataListItemCells
                  dataListCells={[
                    <DataListCell key="location filters" className={styles.eiFilterTitle}>
                      <TextContent>
                        <Text>Location filters</Text>
                      </TextContent>
                    </DataListCell>,
                  ]}
                />
                <DataListToggle
                  onClick={() => onToggle('location-filter')}
                  isExpanded={expandFilter.includes('location-filter')}
                  id="location-filter-toggle"
                  aria-controls="location-filter-options"
                  className={styles.eiFilterToggle}
                />
              </DataListItemRow>
              <DataListContent
                aria-label="Location filter Details"
                id="location-filter-options"
                isHidden={!expandFilter.includes('location-filter')}
                className={styles.eiFilterOptions}
              >
                <LocationFilter />
              </DataListContent>
            </DataListItem>
          </DataList>
        </StackItem>
        <StackItem>
          <OtherFilter />
        </StackItem>
      </Stack>
    </>
  );
};

export default Filter;
