import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import PathMissingIcon from '@patternfly/react-icons/dist/esm/icons/pathMissing-icon';
import { EcoIntelEmptyStatePropsTypes } from '../../models/emptyState';

export const errorState: EcoIntelEmptyStatePropsTypes = {
  emptyStateTitle: 'Error',
  emptyStateBody: `An error has occured. Please try reloading the page. If the problem persists, `,
  emptyStateBodyConclusion: '.',
  emptyStateBodyActionMail: true,
  emptyStateBodyActionMailLabel: 'contact us',
  emptyStateBodyActionMailId: 'mailto:intelligence@redhat.com',
  emptyStateIcon: ExclamationTriangleIcon,
  emptyStateActions: false,
  emptyStateTheme: 'light',
  emptyStateHeadingLevel: 'h1',
};

export const errorStateSummaryDetails: EcoIntelEmptyStatePropsTypes = {
  emptyStateTitle: 'Error',
  emptyStateBody: `An error has occured. Please try reloading the page. If the problem persists, `,
  emptyStateBodyConclusion: '.',
  emptyStateBodyActionMail: true,
  emptyStateBodyActionMailLabel: 'contact us',
  emptyStateBodyActionMailId: 'mailto:intelligence@redhat.com',
  emptyStateIcon: ExclamationTriangleIcon,
  emptyStateActions: false,
  emptyStateTheme: 'light',
  emptyStateHeadingLevel: 'h3',
};

export const noDataState: EcoIntelEmptyStatePropsTypes = {
  emptyStateTitle: 'No Data',
  emptyStateBody: `There is no data that can be displayed here.`,
  emptyStateIcon: ExclamationTriangleIcon,
  emptyStateActions: false,
  emptyStateTheme: 'light',
  emptyStateHeadingLevel: 'h3',
};

export const errorStateDark: EcoIntelEmptyStatePropsTypes = {
  emptyStateTitle: 'Error',
  emptyStateBody: `An error has occured. Please try reloading the page. If the problem persists, `,
  emptyStateBodyConclusion: '.',
  emptyStateBodyActionMail: true,
  emptyStateBodyActionMailLabel: 'contact us',
  emptyStateBodyActionMailId: 'mailto:intelligence@redhat.com',
  emptyStateIcon: ExclamationTriangleIcon,
  emptyStateActions: false,
  emptyStateTheme: 'dark',
  emptyStateHeadingLevel: 'h1',
};

export const pageNotFoundState: EcoIntelEmptyStatePropsTypes = {
  emptyStateTitle: '404 - Page Not Found',
  emptyStateBody: `The page you're trying to find was either removed, moved, or maybe the URL isn't quite right.`,
  emptyStateIcon: PathMissingIcon,
  emptyStateActions: true,
  emptyStateActionLabel: 'Back to home',
  emptyStateActionIcon: '',
  emptyStateActionPath: '',
  emptyStateTheme: 'light',
  emptyStateHeadingLevel: 'h2',
};
