import React from 'react';
import { Button } from '@patternfly/react-core';
import styles from './IconButton.module.scss';

type IconButtonProps = {
  dataTestId?: string;
  icon: React.ReactNode;
  iconContainerStyles: string;
  onClickIcon?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled: boolean;
  iconName: string;
};

const IconButton: React.FC<IconButtonProps> = (props) => {
  const { dataTestId, icon, iconContainerStyles, onClickIcon, isDisabled, iconName } = props;

  return (
    <span className={`${styles.eiIconContainer} ${iconContainerStyles}`}>
      <Button
        aria-label={iconName}
        className={`${styles.eiIconContainer} ${styles.eiIconButton}`}
        icon={icon}
        isDisabled={isDisabled}
        onClick={onClickIcon}
        variant="link"
        data-test={dataTestId}
      />
    </span>
  );
};

export default IconButton;
