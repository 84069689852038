import React from 'react';
import { ChartDonutUtilization, ChartLabel } from '@patternfly/react-charts';
import { Card, CardBody, CardTitle, Skeleton, Split, SplitItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import pageRoutes from '../../../utils/pageRoutes';
import styles from './TopAndBottomPerformersOverview.module.scss';

interface TopAndBottomPerformersOverviewProps {
  bookingsPerformance: any;
  getCardTitleContent: Function;
  isFetching: boolean;
}

const TopAndBottomPerformersOverview: React.FC<TopAndBottomPerformersOverviewProps> = ({ bookingsPerformance, getCardTitleContent, isFetching }) => {
  const renderDonutChart = () => {
    return (
      <section style={{ height: '150px', marginRight: '45px', width: '150px' }}>
        <ChartDonutUtilization
          ariaDesc={`Donut chart showing ${
            bookingsPerformance?.below_85_par !== undefined && bookingsPerformance?.below_85_par !== null ? bookingsPerformance?.below_85_par : 0
          }% partners are below benchmark 85% Renewal @ Par.`}
          colorScale={['#C60011', '#F0F0F0']}
          constrainToVisibleArea={false}
          data={{ x: 'Partners Below Benchmark', y: bookingsPerformance?.below_85_par }}
          height={150}
          innerRadius={70}
          labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
          padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          subTitle={'\nPartners Below\nBenchmark'}
          subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
          title={`${
            bookingsPerformance?.below_85_par !== undefined && bookingsPerformance?.below_85_par !== null ? bookingsPerformance?.below_85_par : ''
          }%`}
          titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
          width={150}
        />
      </section>
    );
  };

  return (
    <Card className={styles.eiTopAndBottomPerformersOverviewContainer}>
      <CardTitle className={styles.eiTitle}>
        {getCardTitleContent(pageRoutes.topAndBottomPerformersUrl.path, pageRoutes.topAndBottomPerformersUrl.linkText)}
      </CardTitle>
      <CardBody>
        <Split className={styles.eiChartAndTextContainer}>
          <SplitItem className={styles.eiChartAndTextContainerSplitItem}>
            {isFetching ? (
              <Skeleton height={'150px'} screenreaderText={'Loading contents...'} shape={'circle'} style={{ marginRight: '45px' }} width={'150px'} />
            ) : (
              renderDonutChart()
            )}
          </SplitItem>
          <SplitItem className={styles.eiChartAndTextContainerSplitItem}>
            <TextContent>
              <Text className={styles.eiCardContent} component={TextVariants.p}>
                Which accounts were below 85% Renewal @ Par and which should you focus on to improve bookings performance? View top and bottom
                performers.
              </Text>
            </TextContent>
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  );
};

export default TopAndBottomPerformersOverview;
