export const retentionTableSortColumnIndex = {
  1: 'account_name',
  2: 'retention',
  3: 'beg_arr',
  4: 'end_arr',
  5: 'churn',
  6: 'expansion',
  7: 'gross_rev_retention_percent',
  8: 'net_rev_retention_percent',
};

export const currentPageLimit: number = 500;

export const initialDistributorColumns: Array<string> = [
  'Customer Account',
  'Retention',
  'Beg ARR',
  'End ARR',
  'Churn',
  'Expansion',
  'Gross Retention',
  'Net Retention',
];

export const initialResellerColumns: Array<string> = [
  'Customer Account',
  'Retention',
  'Est. Beg ARR',
  'Est. End ARR',
  'Est. Churn',
  'Est. Expansion',
  'Gross Retention',
  'Net Retention',
];

export const distributorColumnDetails = {
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  retention: {
    columnName: 'Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  beg_arr: {
    columnName: 'Beg ARR',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: true,
  },
  end_arr: {
    columnName: 'End ARR',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  churn: {
    columnName: 'Churn',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  expansion: {
    columnName: 'Expansion',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  gross_rev_retention_percent: {
    columnName: 'Gross Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
    valueInPercentage: true,
  },
  net_rev_retention_percent: {
    columnName: 'Net Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
    valueInPercentage: true,
  },
};

export const resellerColumnDetails = {
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  retention: {
    columnName: 'Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  beg_arr: {
    columnName: 'Est. Beg ARR',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: true,
  },
  end_arr: {
    columnName: 'Est. End ARR',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  churn: {
    columnName: 'Est. Churn',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  expansion: {
    columnName: 'Est. Expansion',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  gross_rev_retention_percent: {
    columnName: 'Gross Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
    valueInPercentage: true,
  },
  net_rev_retention_percent: {
    columnName: 'Net Retention',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
    valueInPercentage: true,
  },
};
