import React from 'react';
import { Alert, AlertGroup, Button, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { ReactComponent as Lightbulb } from '../../../../../assets/lightbulb.svg';
import { pageDefinitionTypes } from '../../../../../constants/pageDefinitionsTypes';
import pageDefinitions from '../../../../../utils/pageDefinitions';
import styles from './Definitions.module.scss';

interface DefinitionsProps {
  definitions: any;
  setAlternateDefinitions: Function;
  setAlternateDefinitionsTitle: Function;
}

const Definitions: React.FC<DefinitionsProps> = ({ definitions, setAlternateDefinitions, setAlternateDefinitionsTitle }) => {
  const handleAlternativeDefinitionsButtonClick = (alternateDefinitions, alternateDefinitionsTitle) => {
    setAlternateDefinitions(alternateDefinitions);
    setAlternateDefinitionsTitle(alternateDefinitionsTitle);
  };

  const renderLink = (item, index) => {
    let renderContent = null;
    let linkElements = [];

    if (item?.links && item?.links.length > 0) {
      linkElements = item?.links.map((link, linkIndex) => {
        let linkButton = null;

        if (link.linkDefinitions && link.linkPageTitle && link.linkText) {
          linkButton = (
            <Button
              data-test="definitions-link"
              key={linkIndex}
              onClick={() => handleAlternativeDefinitionsButtonClick(pageDefinitions[link.linkDefinitions], link.linkPageTitle)}
              variant="link"
            >
              {link.linkText}
            </Button>
          );
        }

        return linkButton;
      });

      renderContent = (
        <TextContent className={styles.eiDefinitionContent} key={index}>
          {item?.preText && (
            <div>
              {' '}
              {/* this div is here because otherwise, the .pf-c-content p:not(:last-child) style is applied can not be overridden */}
              <Text component={TextVariants.p}>{item?.preText}</Text>
            </div>
          )}
          <span className={styles.eiLinkElements}>{linkElements}</span>
          {item?.postText && (
            <div>
              {' '}
              {/* this div is here because otherwise, the .pf-c-content p:not(:last-child) style is applied can not be overridden */}
              <Text component={TextVariants.p}>{item?.postText}</Text>
            </div>
          )}
        </TextContent>
      );
    }

    return renderContent;
  };

  const renderSVG = (item, index) => {
    const renderContent = item?.svg ? <item.svg data-test="definitions-svg" className={styles.eiDefinitionContent} key={index} /> : null;

    return renderContent;
  };

  const renderText = (item, index) => {
    const renderContent =
      item?.term && item?.definition ? (
        <TextContent data-test="definitions-text" className={styles.eiDefinitionContent} key={index}>
          <Text component={TextVariants.h2} className={styles.eiHeading}>
            {item.term}
          </Text>
          {typeof item?.definition === 'string' ? (
            <Text component={TextVariants.p}> {item.definition}</Text>
          ) : (
            renderDefinitionsList(item?.definition)
          )}
        </TextContent>
      ) : null;

    return renderContent;
  };

  const renderDefinitionsList = (item: string[]) => {
    const definitionList: any[] = [];
    item?.forEach((element, index) => {
      const definition = (
        <Text key={`definitions-list-${index}`} className="eiDefinitionsList" component={TextVariants.p}>
          {' '}
          {element}{' '}
        </Text>
      );
      definitionList.push(definition);
    });
    return definitionList;
  };

  const renderTip = (item, index) => {
    const renderContent =
      item?.title && item?.content ? (
        <AlertGroup className={`${styles.eiDefinitionContent} ${styles.eiDefinitionTipContent}`} key={index}>
          <Alert
            titleHeadingLevel="h2"
            customIcon={
              <span data-test="definitions-tip-icon" className={styles.eiTipIconContainer}>
                <Lightbulb className={styles.eiTipIcon} />
              </span>
            }
            title={item.title}
            variant="info"
            isInline
          >
            {item.content}
          </Alert>
        </AlertGroup>
      ) : null;

    return renderContent;
  };

  const renderDefinitionsConent = (definitionsContent) => {
    return definitionsContent.map((item, index) => {
      let content = null;

      switch (item?.type) {
        case pageDefinitionTypes.LINK:
          content = renderLink(item, index);
          break;
        case pageDefinitionTypes.TEXT:
          content = renderText(item, index);
          break;
        case pageDefinitionTypes.TIP:
          content = renderTip(item, index);
          break;
        case pageDefinitionTypes.SVG:
          content = renderSVG(item, index);
          break;
        default:
          break;
      }

      return content;
    });
  };

  return <div className={styles.eiDefinitionsContainer}>{renderDefinitionsConent(definitions)}</div>;
};

export default Definitions;
