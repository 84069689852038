import React, { useEffect, useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle } from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../api/apiCalls';
import TableWrapper from '../../components/TableWrapper';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../utils/filterHelper';
import BookingsPerformanceDetail from './BookingsPerformanceDetail';
import BookingsPerformanceSummary from './BookingsPerformanceSummary';
import styles from './BookingsPerformance.module.scss';

interface BookingsPerformanceProps {
  viewContext: string;
}

const BookingsPerformance: React.FC<BookingsPerformanceProps> = ({ viewContext }) => {
  const [expanded, setExpanded] = useState(['bookings-performance-summary', 'bookings-performance-detail']);
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.BOOKING_PERFORMANCE);
  const [BookingsPerformanceData, setBookingsPerformanceData] = useState();
  const [responseError, setResponseError] = useState(null);
  const { globalState } = useGlobalContext();

  const toggle = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  useEffect(() => {
    const getBookingsPerformancePageData = async () => {
      setResponseError(null);
      try {
        const selectedFilterValues = getAllSelectedFilterValues(globalState, { viewContext: viewContext });

        const response = await request(selectedFilterValues);

        setBookingsPerformanceData(response);
      } catch (error) {
        setResponseError(error.message);
      }
    };
    getBookingsPerformancePageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  return (
    <div className={styles.eiBookingsPerformance}>
      <Accordion asDefinitionList={false} headingLevel="h2">
        <AccordionItem>
          <AccordionToggle
            onClick={() => toggle('bookings-performance-summary')}
            isExpanded={expanded.includes('bookings-performance-summary')}
            id="bookings-performance-summary"
            data-test="bookings-performance-summary-toggle"
          >
            Bookings Performance Summary
          </AccordionToggle>
          <AccordionContent
            id="bookings-performance-summary-expand"
            isHidden={!expanded.includes('bookings-performance-summary')}
            isFixed
            tabIndex={0}
          >
            <BookingsPerformanceSummary
              responseError={responseError}
              isFetching={isFetching}
              bookingsPerformanceSummaryData={BookingsPerformanceData}
              viewContext={viewContext}
            />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordionToggle
            onClick={() => toggle('bookings-performance-detail')}
            isExpanded={expanded.includes('bookings-performance-detail')}
            id="bookings-performance-detail"
            data-test="bookings-performance-detail-toggle"
          >
            Bookings Performance Detail
          </AccordionToggle>
          <AccordionContent id="bookings-performance-detail-expand" isHidden={!expanded.includes('bookings-performance-detail')} isFixed tabIndex={0}>
            {/* // Render table as a child of table context provider */}
            <TableWrapper>
              <BookingsPerformanceDetail viewContext={viewContext} />
            </TableWrapper>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default BookingsPerformance;
