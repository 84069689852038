import { ReactComponent as BookingsPerformanceExampleIllustration } from '../assets/bookings-defnitions-example.svg';
import { ReactComponent as BookingsPerformanceIllustration } from '../assets/bookings-defnitions.svg';
import { ReactComponent as RetentionPerformance1 } from '../assets/retention-defnitions-01.svg';
import { ReactComponent as RetentionPerformance2 } from '../assets/retention-defnitions-02.svg';
import { pageDefinitionTypes } from '../constants/pageDefinitionsTypes';

/* 
  valid types are:
  pageDefinitionTypes.SVG - requires a 'svg' prop. That prop must be the name of the svg import that has been imported in this file exactly as shown below:
    import { ReactComponent as RenewalsIllustration } from '../assets/renewals_Intelligence.svg';

  pageDefinitionTypes.LINK  - props are links (required), preText (optional), and postText (optional). preText will appear above the links and postText will appar below.
    The 'links' prop is an array of objects that require the following members
    'linkDefinitions' - the definitions you are linking to 
    'linkPageTitle' - the title of the page you are linking to (should probably be the same as linkText)
    'linkText' - the text used for the link 

  pageDefinitionTypes.TEXT  - requires 'term' and 'definition' props

  pageDefinitionTypes.TIP - requires 'title' and 'content' props

  //EXAMPLES
  // overviewDefinitions: [
  //   {type: pageDefinitionTypes.TIP, title: 'Thing 1', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"},
  //   {type: pageDefinitionTypes.TEXT, term: 'Thing 1', definition: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodoconsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nullapariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim idest laborum."},
  //   {type: pageDefinitionTypes.LINK, preText: 'Learn how performance is calculated:', links: [{linkDefinitions: 'testDefinitions', linkPageTitle: 'Test Definitions', linkText: 'Test Definitions'}, {linkDefinitions: 'testDefinitions2', linkPageTitle: 'Test Definitions 2', linkText: "Test Definitions 2"}]},
  //   {type: pageDefinitionTypes.SVG, svg: RenewalsIllustration},
  // ],
  // testDefinitions: [
  //   {type: pageDefinitionTypes.SVG, svg: RenewalsIllustration},
  //   {type: pageDefinitionTypes.SVG, svg: RenewalsIllustration},
  //   {type: pageDefinitionTypes.LINK, preText: 'Learn how performance is calculated:', links: [{linkDefinitions: 'testDefinitions2', linkPageTitle: 'Test Definitions 2', linkText: 'Test Definitions 2'}]},
  // ],
  // testDefinitions2: [
  //   {type: pageDefinitionTypes.TIP, title: 'Thing 1', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"},
  //   {type: pageDefinitionTypes.TIP, title: 'Thing 1', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"},
  //   {type: pageDefinitionTypes.TIP, title: 'Thing 1', content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit"},
  // ],
 */

const pageDefinitions = {
  reviewOverviewDefinitions: [
    { type: pageDefinitionTypes.TIP, title: 'Section Tip', content: 'The default date range for all Review pages is the last fiscal year.' },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Overall Opportunity Amount',
      definition: `The value of an opportunity as shown in Red Hat's Salesforce. Usually expressed as "Total" or "Annualized" based on what the user has selected to see in a chosen report or dashboard.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Overall Estimated Opportunity Amount',
      definition: `An estimate of the end-customer purchase price based on Red Hat's channel partner pricing, market trends, and customer volume discounts. It does not reflect the Red Hat MSRP, as resellers retain full responsibility for end-customer pricing.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Annualized Opportunity Amount',
      definition: `The value of opportunities created in the observed fiscal period represented in terms of Single Year Bookings (SYB).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Total Opportunity Amount',
      definition: `Value of opportunities over the entire term of the contract represented in terms of Total Bookings (TB).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Peers',
      definition: `Peer data reflects the performance of other reselling partners of a similar global size that also have business in the atively selected Geo(s) and customer segment(s). Peer values default to a peer set based on global size alone until the geo or customer filters are actively filtered, even if they are passively filtered based on other filter activity applied to your organizations scope of business with Red Hat. For example, even if only EMEA is passively selected in the geo filter, ether because your organization only does business with Red Hat in EMEA or because you've actively applied other filters that narrow the scope to EMEA.  (e.g. , you selected "Germany" from the Country filter), you will need to actively de-select and re-select EMEA from the geo filter in order to see peer values based on peers in EMEA.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transferred Between Distributors',
      definition: `Renewal opportunities that transferred between Distributor's accounts within the scope of the selected Distributor(s). These opportunities will show up as Original Renewals (from the incumbent account's perspective), Transferred In (from the new account's perspective), Transferred Out (from the incumbent account's perspective), and either Closed Lost or Renewed Opps (from the new account's perspective).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transferred Between Partners',
      definition: `For Distributors, Transfer between Partners indicates Renewal opportunities that transferred between Partner accounts within the scope of the selected Distributor(s). These opportunities are independent of the other Deal Management categories shown for Distributors. For Partners, it indicates Renewal opportunities that transferred between Partner accounts within the scope of the selected Partner(s). These opportunities will show up as Original Renewals (from the incumbent account's perspective), Transferred In (from the new account's perspective), Transferred Out (from the incumbent account's perspective), and either Closed Lost or Renewed Opps (from the new account's perspective).`,
    },
    {
      type: pageDefinitionTypes.LINK,
      preText: 'Read more about:',
      links: [
        { linkDefinitions: 'bookingsPerformanceDefinitions', linkPageTitle: 'Bookings Performance', linkText: 'Bookings Performance' },
        { linkDefinitions: 'retentionPerformanceDefinitions', linkPageTitle: 'Retention Performance', linkText: 'Retention Performance' },
      ],
    },
  ],
  bookingsPerformanceDefinitions: [
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Renewal Opportunity',
      definition: `A potential extension of a subscription (or a series of subscriptions),
      managed together as an individual unit in a CRM system.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Renewal Available',
      definition: `The original value of a subscription(s) that is(are) approaching expiration.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Renewal Performance',
      definition: [
        `The final booked value of subscription(s) on the Renewal Opportunity.`,
        `[Renewal Performance = Renewal at Par + Up-sell + Cross-sell]`,
        `[Renewal Performance Rate = (Renewal at Par + Up-sell + Cross-sell) / Renewal Available]`,
      ],
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Renewal at Par',
      definition: ['The portion of an expiring subscription(s) that is(are) renewed.', '[Par Rate = Renewal at Par / Renewal available]'],
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Up-Sell',
      definition: [
        `The value above and beyond an existing product subscription(s) for a 
      renewal opportunity (inclusive of sales from term extensions).`,
        '[Up-sell rate = Up-sell / Renewal Available]',
      ],
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Cross-Sell',
      definition: [
        'The value of subscription(s) for a new product(s) attached to a renewal opportunity.',
        '[Cross-sell rate = Cross-sell / Renewal Available]',
      ],
    },
    { type: pageDefinitionTypes.SVG, svg: BookingsPerformanceIllustration },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Example',
      definition: `Expiring RHEL subscriptions (Renewal Available) are worth $60K. 
      Actual RHEL bookings (Renewal @ Par) are $60K. Up-Sell within the RHEL product category adds $20K. 
      Cross-Sell for OCP adds $40K for a Renewal Performance total of $120K.`,
    },
    { type: pageDefinitionTypes.SVG, svg: BookingsPerformanceExampleIllustration },
  ],
  retentionPerformanceDefinitions: [
    { type: pageDefinitionTypes.TEXT, term: 'ARR - Annual Recurring Revenue', definition: 'The annualized value of a subscription.' },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'CR- Customer Retention (Rate)',
      definition: 'The percentage of customers maintained and not lost, excluding new customers.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'RC- Revenue Churn (Rate)',
      definition: 'Revenue percentage lost from existing customers, excluding expansion.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'GRR- Gross Revenue Retention',
      definition: 'Revenue, expressed as a percentage, retained from existing customers, excluding expansion.',
    },
    { type: pageDefinitionTypes.SVG, svg: RetentionPerformance1 },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'RE-  Revenue Expansion (Rate)',
      definition: 'Revenue percentage gained from existing customers [e.g. through cross-sell and up-sell excluding churn]',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'NRR- Net Revenue Retention (Rate)',
      definition: 'Revenue, expressed as a percentage, retained from existing customers, including churn and expansion.',
    },
    { type: pageDefinitionTypes.SVG, svg: RetentionPerformance2 },
  ],
  topAndBottomPerformersDefinitions: [
    {
      type: pageDefinitionTypes.TIP,
      title: 'Page tip',
      content: `The x-axis of the area chart shows up to 100 accounts ranked from the highest to the 
      lowest value. The y-axis shows the cumulative amount across all accounts. Hovering over the chart 
      displays the sum of all of the accounts to the left of the line and the percentage of the total that 
      group accounts for.`,
    },
    {
      type: pageDefinitionTypes.LINK,
      preText: 'For more information on performance metrics:',
      links: [{ linkDefinitions: 'bookingsPerformanceDefinitions', linkPageTitle: 'Bookings Performance', linkText: 'Bookings Performance' }],
    },
  ],
  dealManagementDefinitions: [
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Original Renewals',
      definition: 'Renewal opportunities where either the selected Distributor or Partner was(were) incumbent.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transferred In',
      definition:
        'Renewal opportunities that the selected Distributor(s) or Partner(s) closed where the Distributor or Partner account closing the deal was not the incumbent (may include opportunities that transferred between Distributor accounts or Partner accounts within the scope of selected Distributor(s) or Partner(s)).',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transferred Out',
      definition:
        'Renewal opportunities where the selected Distributor(s) or Partner(s) was(were) the incumbent, but Red Hat or a different Distributor or Partner account closed it (may include opportunities that transferred between Distributor accounts or Partner accounts within the scope of selected Distributor(s) or Partner(s)).',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Closed Lost',
      definition:
        'Stage in the opportunity process that represents when an opportunity has been closed and the account is not pursuing the opportunity, excluding operational and administrative reasons.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Renewed Opportunities',
      definition: 'Renewal Opportunity closed won by the selected Distributor or Partner.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transfer between Distributors',
      definition:
        "Renewal opportunities that transferred between Distributors accounts within the scope of the selected Distributor(s). These opportunities will show up as Original Renewals (from the incumbent account's perspective), Transferred In (from the new account's perspective), Transferred Out (from the incumbent account's perspective), and either Closed Lost or Renewed Opps (from the new account's perspective).",
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Transfer between Partners',
      definition:
        "For Distributors, Transfer between Partners indicates Renewal opportunities that transferred between Partner accounts within the scope of the selected Distributor(s). These opportunities are independent of the other Deal Management categories shown for Distributors.For Partners, it indicates Renewal opportunities that transferred between Partner accounts within the scope of the selected Partner(s). These opportunities will show up as Original Renewals (from the incumbent account's perspective), Transferred In (from the new account's perspective), Transferred Out (from the incumbent account's perspective), and either Closed Lost or Renewed Opps (from the new account's perspective).",
    },
  ],
  renewOverviewDefinitions: [
    {
      type: pageDefinitionTypes.TIP,
      title: 'Section tip',
      content: `The default date range for all Renew pages is this month through one year from this month.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Overall Opportunity Amount',
      definition: `The value of an opportunity as shown in Red Hat's Salesforce. 
      Usually expressed as "Total" or "Annualized" based on what the user has selected to see in a chosen report or dashboard.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Overall Estimated Opportunity Amount',
      definition: `An estimate of the end-customer purchase price based on Red Hat's channel partner pricing, market trends, and customer volume discounts. 
      It does not reflect the Red Hat MSRP, as resellers retain full responsibility for end-customer pricing.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Annualized opportunity Amount',
      definition: `Value of opportunities created in the observed fiscal period represented in terms of Single Year Bookings (SYB).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Total Opportunity Amount',
      definition: `Value of opportunities over the observed fiscal period represented in terms of Total Bookings (TB).`,
    },
  ],
  pipelineByAccountDefinitions: [
    {
      type: pageDefinitionTypes.TIP,
      title: 'Page tip',
      content: `The x-axis of the area chart shows up to 100 accounts ranked from the highest to the lowest value. 
      The y-axis shows the cumulative amount across all accounts. Hovering over the chart displays the sum of 
      all of the accounts to the left of the line and the percentage of the total that group accounts for.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Product Category',
      definition: `An offering attribute that is used to indicate the following: subscription, 
      training service, or consulting service.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Deal Size',
      definition: `Groupings for "large, medium, small" opportunities.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Opp Amount at High Risk',
      definition: `Opportunities that have been manually flagged by the account management 
      team to be at risk for a lost renewal.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Opp Amount With High Cross Sell Probabilities',
      definition: `The total amount of Opportunities with a High Cross Sell Probability that a customer is likely to purchase additional Red Hat products.`,
    },
    {
      type: pageDefinitionTypes.LINK,
      preText: 'For more information on Renewal @ Par and other performance metrics:',
      links: [{ linkDefinitions: 'bookingsPerformanceDefinitions', linkPageTitle: 'Bookings Performance', linkText: 'Bookings Performance' }],
    },
    {
      type: pageDefinitionTypes.LINK,
      preText: 'For more information on Infrared Cross-Sell Probabilities:',
      links: [
        { linkDefinitions: 'crossSellProbabilitiesDefinitions', linkPageTitle: 'Cross-Sell Probabilities', linkText: 'Cross-Sell Probabilities' },
      ],
    },
  ],
  infraredOverviewDefinitions: [
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Infrared',
      definition: `The Infrared Intelligence feature is a special set of capabilities within Renewals Intelligence. It allows users to see beyond standard reporting to identify key actions that can be initiated using predictive intelligence.`,
    },
  ],
  opportunitiesDefinitions: [
    // Commented the Page tip as Risk% is not a filter criteria for the table
    // {
    //   type: pageDefinitionTypes.TIP,
    //   title: 'Page tip',
    //   content: `Click on one of the bars in the chart will act as a filter for the table below.`
    // },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Par% Goal',
      definition: `Red Hat's goal is to renew 85% of expiring subscriptions at the expected subscription amount.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Risk',
      definition: `The rating in which the account managment team can assign to an opportunity that indicates 
      if it is at risk for a lost renewal (measured in None, Low, Medium, High).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Forecast Category',
      definition: `The Category within the sales cycle in which an opportunity is assigned based on its opportunity stage.`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'High Cross-Sell Probability',
      definition: `The probability that a customer is likely to purchase additional Red Hat products 
      (measured in None, Low, Medium, High).`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Next Best Offer',
      definition: `The product with the highest Cross-Sell Probability.`,
    },
    {
      type: pageDefinitionTypes.LINK,
      preText: 'For more information on Down-sell and other performance metrics:',
      links: [{ linkDefinitions: 'bookingsPerformanceDefinitions', linkPageTitle: 'Bookings Performance', linkText: 'Bookings Performance' }],
    },
  ],
  crossSellProbabilitiesDefinitions: [
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Cross-Sell Probability',
      definition:
        "The predicted likelihood (High, Med, Low) that a customer buys within a new product category in the next 4 quarters. Based on machine learning models that consider over 300 data elements (e.g. sales, marketing, customer experience data) to evaluate how similar a customer's characteristics and behaviors are to customers who bought that product category in the past. A customer will get a product category recommendation if, in the past year, they did not have revenue for any product in that category. High probability customers convert at a rate of 24x compared to low probability customers, which outperforms leading industry standards (5x).",
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Next Best Offer',
      definition:
        "A list of products generated by recommendation engines (a subclass of machine learning) for each customer based on their interaction with Red Hat's products. Recommendation engines look at the historical purchases made by the customers and try to find the most relevant products for each customer based on the similarity between customers and/or products typically using matrix factorization (a mathematical technique to process matrices). A simple example would be, say, Customer1's purchase history is Products (A, B, C) and Customer2's purchase history is (A, B) then our recommendation engine might have Product C as top recommendation for Customer2 - because both customers' buying behavior looks very similar except for one product.",
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Product Category',
      definition: 'An offering attribute that is used to indicate the following: subscription, training service, or consulting service.',
    },
  ],
  exportDefinitions: [
    {
      type: pageDefinitionTypes.TIP,
      title: 'Page tip',
      content: 'Use the main filter menu to optimize the size of the custom dataset you download.',
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Bookings Performance Insights',
      definition: `The data underlying historical bookings performance metrics, down to the opportunity-level`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Retention Performance Insights',
      definition: `The data underlying the historical retention performance metrics, down to the customer-level`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Infrared and Renew Insights',
      definition: `The data underlying the forward-looking Renew and Infrared features, down to the opportunity-level`,
    },
    {
      type: pageDefinitionTypes.TEXT,
      term: 'Opportunity Details',
      definition: `The most detailed data, down to the SKU-level, for past and future opportunities`,
    },
  ],
  testDefinitions2: [
    { type: pageDefinitionTypes.TIP, title: 'Thing 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { type: pageDefinitionTypes.TIP, title: 'Thing 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { type: pageDefinitionTypes.TIP, title: 'Thing 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
  ],
};

export default pageDefinitions;
