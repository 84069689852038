export const exportColumns = {
  'bookings-performance': [
    { label: 'Opp #', key: 'opportunity_number' },
    { label: 'Customer Account', key: 'account_name' },
    { label: 'Down-Sell', key: 'down_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Down-Sell', key: 'down_sell' }, //only used when viewContext is Partner
    { label: 'Renewal @ Par', key: 'renewal_at_par' }, //only used when viewContext is Distributor
    { label: 'Est. Renewal @ Par', key: 'renewal_at_par' }, //only used when viewContext is Partner
    { label: 'Up-Sell', key: 'up_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Up-Sell', key: 'up_sell' }, //only used when viewContext is Partner
    { label: 'Cross-Sell', key: 'cross_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Cross-Sell', key: 'cross_sell' }, //only used when viewContext is Partner
    { label: 'Performance', key: 'performance' }, //only used when viewContext is Distributor
    { label: 'Est. Performance', key: 'performance' }, //only used when viewContext is Partner
    { label: 'Renewal Available', key: 'renewal_available' }, //only used when viewContext is Distributor
    { label: 'Est. Renewal Available', key: 'renewal_available' }, //only used when viewContext is Partner
    { label: 'Total Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Distributor
    { label: 'Est. Total Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Partner
    { label: 'On-Time', key: 'on_time' }, //only used when viewContext is Distributor
    { label: 'Est. On-Time', key: 'on_time' }, //only used when viewContext is Partner
    // { label: "Partner Account", key: "" }
  ],
  'retention-performance': [
    { label: 'Customer Account', key: 'account_name' },
    { label: 'Retention', key: 'retention' },
    { label: 'Beg ARR', key: 'beg_arr' }, //only used when viewContext is Distributor
    { label: 'Est. Beg ARR', key: 'beg_arr' }, //only used when viewContext is Partner
    { label: 'End ARR', key: 'end_arr' }, //only used when viewContext is Distributor
    { label: 'Est. End ARR', key: 'end_arr' }, //only used when viewContext is Partner
    { label: 'Churn', key: 'churn' }, //only used when viewContext is Distributor
    { label: 'Est. Churn', key: 'churn' }, //only used when viewContext is Partner
    { label: 'Expansion', key: 'expansion' }, //only used when viewContext is Distributor
    { label: 'Est. Expansion', key: 'expansion' }, //only used when viewContext is Partner
    { label: 'Gross Retention', key: 'gross_rev_retention_percent' },
    { label: 'Net Retention', key: 'net_rev_retention_percent' },
  ],
  'top-and-bottom-performers': [
    { label: 'Partner Account', key: 'reseller_partner_account_name' }, //only used when viewContext is Distributor
    { label: 'Customer Account', key: 'account_name' }, //only used when viewContext is Partner
    { label: 'Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Distributor
    { label: 'Est. Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Partner
    { label: 'Down-Sell', key: 'down_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Down-Sell', key: 'down_sell' }, //only used when viewContext is Partner
    { label: 'Renewal @ Par', key: 'renewal_at_par' }, //only used when viewContext is Distributor
    { label: 'Est. Renewal @ Par', key: 'renewal_at_par' }, //only used when viewContext is Partner
    { label: 'Cross-Sell', key: 'cross_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Cross-Sell', key: 'cross_sell' }, //only used when viewContext is Partner
    { label: 'Up-Sell', key: 'up_sell' }, //only used when viewContext is Distributor
    { label: 'Est. Up-Sell', key: 'up_sell' }, //only used when viewContext is Partner
    { label: 'On-Time', key: 'on_time' }, //only used when viewContext is Distributor
    { label: 'Est. On-Time', key: 'on_time' }, //only used when viewContext is Partner
  ],
  'deal-management': [
    { label: 'Contract #', key: 'opportunity_contract_number' },
    { label: 'Distributor Account', key: 'distributor_partner_account_name' },
    { label: 'Opp #', key: 'opportunity_number' },
    { label: 'Close Date', key: 'close_date' },
    { label: 'Movement Detail', key: 'movement_detail' },
    { label: 'Customer Account', key: 'account_name' },
    { label: 'Partner Account', key: 'reseller_partner_account_name' },
    { label: 'Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Distributor
    { label: 'Est. Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Partner
  ],
  'pipeline-by-account': [
    { label: 'Partner Account', key: 'reseller_partner_account_name' },
    { label: '# of Opps', key: 'opp_count' },
    { label: 'Opp Amount', key: 'total_opp_amount' },
    { label: 'Opp Amount at High Risk', key: 'high_risk_opps' },
    { label: 'Opp Amount With High Cross Sell Probabilities', key: 'high_cross_sell_probability' },
    { label: 'Renewal @ Par % Last Year', key: 'renewal_at_par_last_year' },
    { label: 'Product Category', key: 'product_final_mapping' },
    { label: 'Customer Vertical', key: 'account_vertical_industry_name' },
    { label: 'Deal Size', key: 'deal_size' },
    { label: 'Customer Account', key: 'account_name' }, //only used when viewContext is Partner
    { label: 'Est. Opp Amount', key: 'total_opp_amount' }, //only used when viewContext is Partner
    { label: 'Est. Opp Amount at High Risk', key: 'high_risk_opps' }, //only used when viewContext is Partner
    { label: 'Est. Opp Amount With High Cross Sell Probabilities', key: 'high_cross_sell_probability' }, //only used when viewContext is Partner              //only used when viewContext is Distributor
    { label: 'Est. Renewal @ Par % Last Year', key: 'renewal_at_par_last_year' }, //only used when viewContext is Partner
  ],
  'upcoming-opportunity': [
    { label: 'Close Date', key: 'opportunity_close_date' },
    { label: 'Opps #', key: 'opportunity_number' },
    { label: 'Partner Account', key: 'reseller_partner_account_name' },
    { label: 'PO #', key: 'renewal_source_opportunity_po_number' },
    { label: 'Customer Account', key: 'account_name' },
    { label: 'Risk', key: 'risk' },
    { label: 'Forecast Category', key: 'opportunity_forecast_category' },
    { label: 'Contr #', key: 'opportunity_contract_number' },
    { label: 'Opp Amount', key: 'est_opp_amount' },
    { label: 'Est. Opp Amount', key: 'est_opp_amount' },
  ],
};
