import React from 'react';
import { Link } from 'react-router-dom';
import { Split, SplitItem, Stack, StackItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import ArrowRightIcon from '@patternfly/react-icons/dist/esm/icons/arrow-right-icon';
import ExclamationTriangleIcon from '@patternfly/react-icons/dist/esm/icons/exclamation-triangle-icon';
import pageRoutes from '../../utils/pageRoutes';
import styles from './PlaceholderPageContent.module.scss';

interface PlaceholderPageConentProps {
  pageTitle: string;
}

const renderTextContent = (text: string, variant: TextVariants, textStyle?: string, textContainerStyle?: string) => {
  return (
    <TextContent className={textContainerStyle}>
      <Text component={variant} className={textStyle}>
        {text}
      </Text>
    </TextContent>
  );
};

const PlaceholderPageContent: React.FC<PlaceholderPageConentProps> = (props) => {
  const { pageTitle } = props;
  return (
    <Split className={styles.eiPlaceholderPageContentContainer}>
      <SplitItem className={styles.iconContainer}>
        <ExclamationTriangleIcon className={styles.icon} />
      </SplitItem>
      <SplitItem className={styles.pageDetailsContainer}>
        <Stack className={styles.pageDetailsItems}>
          <StackItem>{renderTextContent(pageTitle, TextVariants.h2, styles.eiTitle, null)}</StackItem>
          <StackItem>{renderTextContent('This page is currently in development. Check back soon!', TextVariants.p, null, null)}</StackItem>
          <StackItem>
            <Link className={styles.pageDetailsLinkText} to={pageRoutes.baseUrl.path}>
              {'Back to home '}
              <ArrowRightIcon />
            </Link>
          </StackItem>
        </Stack>
      </SplitItem>
    </Split>
  );
};

export default PlaceholderPageContent;
