import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  Button,
  Card,
  CardBody,
  Flex,
  FlexItem,
  Select,
  SelectOption,
  SelectVariant,
  Skeleton,
  Text,
  TextInput,
  TextVariants,
  ToolbarItem,
} from '@patternfly/react-core';
import { kebabCase } from 'lodash';
import { fetchDataPostRequest, getSubfilterOptions } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  defaultColumns,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
  tableSortColumnIndex,
} from './PipelineByAccountDetailConfiguration';
import styles from './PipelineByAccountDetail.module.scss';

interface PipelineByAccountDetailProps {
  viewContext: string;
}

const PipelineByAccountDetail: React.FC<PipelineByAccountDetailProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.PIPELINE_BY_ACCOUNT_DATA);
  const [pipelineByAccountDetailData, setPipelineByAccountDetailData] = useState<any>();
  const [responseError, setResponseError] = useState<string | null>(null);
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    tableData,
    isTableDataFetching,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortIndex,
    setActiveSortDirection,
    setTableName,
    setTableResponseError,
    setTableEmptyState,
  } = useTableContext();
  const [maximumOpportunityAmount, setMaximumOpportunityAmount] = useState('');
  const [minimumOpportunityAmount, setMinimumOpportunityAmount] = useState('');
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
  const [isProductCategorySelectOpen, setIsProductCategorySelectOpen] = useState(false);
  const { request: subfiltersRequest } = useFetch(getSubfilterOptions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subfiltersResponseError, setSubfiltersResponseError] = useState(null);
  const [productCategoryFilterOptions, setProductCategoryFilterOptions] = useState([]);
  const [selectedProductCategoryFilterOption, setSelectedProductCategoryFilterOption] = useState(SubFilter.All);
  const [isCustomerVerticalSelectOpen, setIsCustomerVerticalSelectOpen] = useState(false);
  const [customerVerticalFilterOptions, setCustomerVerticalFilterOptions] = useState([]);
  const [selectedCustomerVerticalFilterOption, setSelectedCustomerVerticalFilterOption] = useState(SubFilter.All);
  const [isDealSizeSelectOpen, setIsDealSizeSelectOpen] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isDataReset, setIsDataReset] = useState(false);
  const [dealSizeFilterOptions, setDealSizeFilterOptions] = useState([]);
  const [selectedDealSizeFilterOption, setSelectedDealSizeFilterOption] = useState(SubFilter.All);

  /* This regex is used in the onChange for the inputs to ensure only positive or negative integers are 
  entered. Necessary because the PF textInput type='number' did not function as expected in all browsers */
  const regexTestValue = /^[\\-]?[0-9]*$/;

  // Function to get the filter and sort options
  useEffect(() => {
    setActiveSortDirection('asc');
    setActiveSortIndex(4);
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to call table data API on page load
  useEffect(() => {
    getPipelineByAccountDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSortDirection, activeSortIndex, currentFetchId, filterBy, globalFilterAppliedOrReset, viewContext]);

  // Function to set the table data
  useEffect(() => {
    setTableData(pipelineByAccountDetailData?.pipeline_by_account?.data);
    setTotalRecordsCount(pipelineByAccountDetailData?.pipeline_by_account?.record_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipelineByAccountDetailData, setTableData, setTotalRecordsCount]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
      setTableEmptyState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  // Function to set the table columns
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setTableName('pipeline-by-account');
      setPageLimit(currentPageLimit);
      setDefaultColumns(defaultColumns);

      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        setInitialColumns(initialDistributorColumns);
        setColumns(distributorColumnDetails);
      } else {
        setInitialColumns(initialResellerColumns);
        setColumns(resellerColumnDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  // Function to reset form values on clicking Reset All filters button
  useEffect(() => {
    if (isReset) {
      unstable_batchedUpdates(() => {
        setMinimumOpportunityAmount('');
        setMaximumOpportunityAmount('');
        setSelectedCustomerVerticalFilterOption(SubFilter.All);
        setSelectedDealSizeFilterOption(SubFilter.All);
        setSelectedProductCategoryFilterOption(SubFilter.All);
        setIsResetButtonDisabled(true);
        setIsApplyButtonDisabled(true);
        setIsReset(false);
        if (isApplied) {
          setIsDataReset(true);
          setIsApplied(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  // Function to reset form values and load page on clicking Reset All filters button if the filter values are applied
  useEffect(() => {
    if (isDataReset) {
      getPipelineByAccountDetailData();
      setIsDataReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReset]);

  // Function Validate Minimum Opportunity Amount and Maximum Opportunity Amount input values
  useEffect(() => {
    if (minimumOpportunityAmount !== '' || maximumOpportunityAmount !== '') {
      if (
        minimumOpportunityAmount &&
        maximumOpportunityAmount &&
        (Number(minimumOpportunityAmount) > Number(maximumOpportunityAmount) || Number(maximumOpportunityAmount) < Number(minimumOpportunityAmount))
      ) {
        setIsApplyButtonDisabled(true);
      } else {
        setIsApplyButtonDisabled(false);
      }
      setIsResetButtonDisabled(false);
    }
  }, [minimumOpportunityAmount, maximumOpportunityAmount]);

  // Funtion to call sub filters API
  const getFilterOptions = async () => {
    try {
      const subfiltersResponse = await subfiltersRequest(ApiRoutes.GET_SUBFILTERS, [
        SubFilter.ProductCategory,
        SubFilter.CustomerVertical,
        SubFilter.DealSize,
      ]);
      subfiltersResponse?.subfilters?.forEach((filter) => {
        switch (filter.sub_filter) {
          case SubFilter.ProductCategory:
            setProductCategoryFilterOptions(filter?.value_text.sort());
            break;
          case SubFilter.CustomerVertical:
            setCustomerVerticalFilterOptions(filter?.value_text.sort());
            break;
          case SubFilter.DealSize:
            setDealSizeFilterOptions(filter?.value_text);
            break;
          default:
            break;
        }
      });
    } catch (error) {
      setSubfiltersResponseError(error.message);
    }
  };

  // Function to call API for getting table data
  const getPipelineByAccountTableData = async () => {
    setTableData(null);
    setResponseError(null);
    try {
      const params = {
        fetch_id: currentFetchId,
        order_by: tableSortColumnIndex[activeSortIndex],
        page_limit: pageLimit,
        sort_by: activeSortDirection,
        viewContext: viewContext,
        filter_by: {},
      };

      if (selectedProductCategoryFilterOption) {
        Object.assign(params.filter_by, { [SubFilter.ProductCategory]: selectedProductCategoryFilterOption });
      }

      if (selectedCustomerVerticalFilterOption) {
        Object.assign(params.filter_by, { [SubFilter.CustomerVertical]: selectedCustomerVerticalFilterOption });
      }

      if (selectedDealSizeFilterOption) {
        Object.assign(params.filter_by, { [SubFilter.DealSize]: selectedDealSizeFilterOption });
      }

      if (minimumOpportunityAmount) {
        Object.assign(params.filter_by, { [SubFilter.MinimumOpportunityAmount]: Number(minimumOpportunityAmount) });
      }

      if (maximumOpportunityAmount) {
        Object.assign(params.filter_by, { [SubFilter.MaximumOpportunityAmount]: Number(maximumOpportunityAmount) });
      }

      const selectedFilterValues = getAllSelectedFilterValues(globalState, params);

      if (activeSortDirection !== null && activeSortIndex !== null) {
        const response = await request(selectedFilterValues);

        setPipelineByAccountDetailData(response);
        setTableData(response?.pipeline_by_account?.data);
        setIsTableDataFetching(false);
        setResponseError(null);
      }
    } catch (error) {
      setResponseError(error.message);
      setIsTableDataFetching(false);
    }
  };

  // Function to validate if API needs to be called
  const getPipelineByAccountDetailData = () => {
    setIsTableDataFetching(true);
    if (((globalState || {}).filterState || {}).originalFilterData && activeSortIndex && activeSortDirection) {
      getPipelineByAccountTableData();
    }
  };

  // Funtion to validate Minimum Opportunity Amount and Maximum Opportunity Amount input values
  const isInputValueValid = (value, currentValue) => {
    return (regexTestValue.test(value) && value.length < 14 && value !== currentValue) || value === '';
  };

  const handleChangeMinimumOpportunityAmount = (value) => {
    if (isInputValueValid(value, minimumOpportunityAmount)) {
      setMinimumOpportunityAmount(value);
    }
  };

  const handleChangeMaximumOpportunityAmount = (value) => {
    if (isInputValueValid(value, maximumOpportunityAmount)) {
      setMaximumOpportunityAmount(value);
    }
  };

  //Funtion to validate Minimum Opportunity Amount and Maximum Opportunity Amount input values based on form input
  const validateOpportunityAmount = () => {
    if (
      !(Number(minimumOpportunityAmount) > Number(maximumOpportunityAmount)) &&
      !(Number(maximumOpportunityAmount) < Number(minimumOpportunityAmount))
    ) {
      setIsApplyButtonDisabled(false);
    }
    setIsResetButtonDisabled(false);
  };

  // Function to set Product Category filter
  const onSelectProductCategoryFilter = (event, filterOption) => {
    setIsProductCategorySelectOpen(false);
    if (selectedProductCategoryFilterOption !== filterOption) {
      setSelectedProductCategoryFilterOption(filterOption);
      validateOpportunityAmount();
    }
  };

  // Function to set Customer Filter
  const onSelectCustomerVerticalFilter = (event, filterOption) => {
    setIsCustomerVerticalSelectOpen(false);
    if (selectedCustomerVerticalFilterOption !== filterOption) {
      setSelectedCustomerVerticalFilterOption(filterOption);
      validateOpportunityAmount();
    }
  };

  // Function to set Deal Size filter
  const onSelectDealSizeFilter = (event, filterOption) => {
    setIsDealSizeSelectOpen(false);
    if (selectedDealSizeFilterOption !== filterOption) {
      setSelectedDealSizeFilterOption(filterOption);
      validateOpportunityAmount();
    }
  };

  // Function called when user clicks Apply Button
  const onClickApply = () => {
    setMaximumOpportunityAmount(maximumOpportunityAmount);
    setMinimumOpportunityAmount(minimumOpportunityAmount);
    setSelectedProductCategoryFilterOption(selectedProductCategoryFilterOption);
    setSelectedCustomerVerticalFilterOption(selectedCustomerVerticalFilterOption);
    setSelectedDealSizeFilterOption(selectedDealSizeFilterOption);
    getPipelineByAccountDetailData();
    setIsApplyButtonDisabled(true);
    setIsResetButtonDisabled(false);
    setIsApplied(true);
  };

  // Function called when user clicks Reset All Filters button
  const onClickReset = () => {
    setIsReset(true);
  };

  // Function to render input for Minimum/Maximum Opportunity Amount
  const renderLabelAndInput = (inputLabel, onChange, value) => {
    return (
      <>
        <Text data-test={kebabCase(inputLabel)} component={TextVariants.p}>
          {inputLabel}
        </Text>
        {isFetching ? (
          <Skeleton data-test="skeleton" height={'38px'} screenreaderText={'Loading contents...'} width={'240px'} />
        ) : (
          <TextInput
            data-test={`${kebabCase(inputLabel)}-input`}
            className={styles.eiToolbarSelectWidth}
            aria-label={inputLabel}
            onChange={onChange}
            placeholder={`Any Amount`}
            type="text"
            value={value || ''}
          />
        )}
      </>
    );
  };

  // Function to render apply button
  const renderApplyButton = (buttonText, buttonVariant, onClick) => {
    return (
      <>
        {isFetching ? (
          <Skeleton data-test="skeleton" height={'38px'} screenreaderText={'Loading contents...'} width={'70px'} />
        ) : (
          <Button data-test="apply" isDisabled={isApplyButtonDisabled} onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </>
    );
  };

  // Function to render reset button
  const renderResetButton = (buttonText, buttonVariant, onClick) => {
    return (
      <>
        {isFetching ? (
          <Skeleton data-test="skeleton" height={'38px'} screenreaderText={'Loading contents...'} width={'70px'} />
        ) : (
          <Button data-test="reset" isDisabled={isResetButtonDisabled} onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </>
    );
  };

  // Function to render dropdowns
  const renderLabelAndSelect = (
    selectLabel: string,
    id: string,
    filterOptions: string[],
    isFilterOpen: boolean,
    onSelect: any,
    onToggle: any,
    selectedOption: string
  ) => {
    return (
      <>
        <Text component={TextVariants.p}>{selectLabel}</Text>
        <div data-test={kebabCase(selectLabel)}>
          <Select
            aria-label="Select Input"
            className={styles.eiToolbarSelectWidth}
            id={id}
            isDisabled={(filterOptions?.length > 0 ? false : true) || isTableDataFetching}
            isOpen={isFilterOpen}
            onSelect={onSelect}
            onToggle={() => onToggle(!isFilterOpen)}
            placeholderText={<>All</>}
            selections={selectedOption}
            variant={SelectVariant.single}
          >
            {filterOptions?.map((option, index) => {
              return <SelectOption key={index} value={option} />;
            })}
          </Select>
        </div>
      </>
    );
  };

  // Function to render table filter section
  const renderTableFilter = (
    <ToolbarItem>
      <Flex className={styles.eiToolbarFilters}>
        <FlexItem>
          {' '}
          {renderLabelAndSelect(
            'Product Category',
            'select-product-category',
            productCategoryFilterOptions,
            isProductCategorySelectOpen,
            onSelectProductCategoryFilter,
            setIsProductCategorySelectOpen,
            selectedProductCategoryFilterOption
          )}
        </FlexItem>
        <FlexItem>
          {' '}
          {renderLabelAndSelect(
            'Customer Vertical',
            'customer-vertical',
            customerVerticalFilterOptions,
            isCustomerVerticalSelectOpen,
            onSelectCustomerVerticalFilter,
            setIsCustomerVerticalSelectOpen,
            selectedCustomerVerticalFilterOption
          )}
        </FlexItem>
        <FlexItem>
          {' '}
          {renderLabelAndSelect(
            'Deal Size',
            'deal-size',
            dealSizeFilterOptions,
            isDealSizeSelectOpen,
            onSelectDealSizeFilter,
            setIsDealSizeSelectOpen,
            selectedDealSizeFilterOption
          )}
        </FlexItem>
      </Flex>
      <Flex className={styles.eiInputGroup}>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderLabelAndInput('Minimum Opportunity Amount', handleChangeMinimumOpportunityAmount, minimumOpportunityAmount || '')}
        </FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderLabelAndInput('Maximum Opportunity Amount', handleChangeMaximumOpportunityAmount, maximumOpportunityAmount || '')}
        </FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>{renderApplyButton('Apply', 'primary', onClickApply)}</FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>{renderResetButton('Reset all filters', 'secondary', onClickReset)}</FlexItem>
      </Flex>
    </ToolbarItem>
  );

  return (
    <Card className={styles.eiPipelineByAccountDetail}>
      <CardBody className={styles.eiPipelineByAccountDetail}>
        <Table filter={renderTableFilter}></Table>
      </CardBody>
    </Card>
  );
};

export default PipelineByAccountDetail;
