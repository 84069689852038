const exportReportOptions = {
  EXPORT_BOOKINGS_PERFORMANCE: {
    dateRange: 'Historic data defaulting to a rolling twelve months ending last month.',
    description: 'The data underlying historical bookings performance metrics, down to the opportunity-level.',
    fileName: 'export-bookings-performance',
    path: 'export-bookings-performance',
    title: 'Bookings Performance Insights',
  },
  EXPORT_OPPORTUNITY_DETAILS: {
    dateRange: 'Forward-looking data defaulting to a rolling twelve months starting this month.',
    description: 'The most detailed data, down to the SKU-level, for past and future opportunities.',
    fileName: 'export-opportunity-details',
    path: 'export-opportunity-details',
    title: 'Opportunity Details Insights',
  },
  EXPORT_CUSTOMER_RETENTION: {
    dateRange: 'Historic data defaulting to a rolling twelve months ending last month.',
    description: 'The data underlying the historical retention performance metrics, down to the customer-level.',
    fileName: 'export-retention-performance',
    path: 'export-customer-retention',
    title: 'Retention Performance Insights',
  },
};

const exportFileTypeOptions = {
  CSV: { key: 'csv', title: 'CSV' },
};

export { exportReportOptions, exportFileTypeOptions };
