import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Skeleton } from '@patternfly/react-core';
import { TableComposable, Tbody, Td, Th, Thead, ThProps, Tr } from '@patternfly/react-table';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../../contexts/Table/TableContext';
import numberHelper from '../../../../utils/numberHelper';
import EcoIntelEmptyState from '../../../EcoIntelEmptyState';
import styles from './TableContent.module.scss';

interface TableContentProps {
  actionColumn?: any;
}

const TableContent = ({ actionColumn }: TableContentProps) => {
  const tableContext = useTableContext();
  const {
    tableData,
    currentPage,
    selectedPerPage,
    columns,
    fetchStartIndex,
    fetchEndIndex,
    isTableDataFetching,
    activeSortIndex,
    activeSortDirection,
    selectedColumns,
    tableEmptyState,
    initialColumns,
    tableResponseError,
    setCurrentPage,
    setCurrentPageData,
    setCurrentFetchId,
    setStartIndex,
    setEndIndex,
    setFetchStartIndex,
    setFetchEndIndex,
    setActiveSortIndex,
    setActiveSortDirection,
    setActionRowData,
  } = tableContext;
  const { globalState } = useGlobalContext();
  const [currencyCode, setCurrencyCode] = useState<string>('USD');

  // Initial table data
  const initialData = tableData?.slice(0, selectedPerPage);

  // Set Initial table data
  const [pageState, setPageState] = useState({
    perPage: selectedPerPage,
    page: 1,
    rows: initialData,
  });

  // Update table data
  useEffect(() => {
    const updatedRows = tableData?.slice(fetchStartIndex, fetchEndIndex);
    unstable_batchedUpdates(() => {
      setPageState({
        perPage: selectedPerPage,
        page: currentPage,
        rows: updatedRows,
      });
      setCurrentPageData(updatedRows);
      setCurrencyCode(globalState.otherFilters?.currencyCode);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedPerPage,
    currentPage,
    fetchEndIndex,
    tableData,
    selectedColumns,
    fetchStartIndex,
    tableResponseError,
    tableEmptyState,
    globalState.otherFilters?.currencyCode,
  ]);

  //Function called when user click on table header sort
  const getSortParams = (columnIndex: number): ThProps['sort'] => ({
    sortBy: {
      index: activeSortIndex,
      direction: activeSortDirection,
      defaultDirection: 'asc', // starting sort direction when first sorting a column. Defaults to 'asc'
    },
    onSort: (_event, index, direction) => {
      setActiveSortIndex(index);
      setActiveSortDirection(direction);
      setCurrentFetchId(0);
      setStartIndex(0);
      setEndIndex(selectedPerPage);
      setFetchStartIndex(0);
      setFetchEndIndex(selectedPerPage);
      setCurrentPage(1);
    },
    columnIndex,
  });

  const getClickedRowData = (data) => {
    setActionRowData(data);
  };

  // Function to render skeleton rows on fetch
  const getSkeletonRows = () => {
    let rows = [];
    let count: number = Object.getOwnPropertyNames(columns)?.length;
    for (let i = 0; i < selectedPerPage; i++) {
      rows.push(
        <Tr key={`table-skeleton-row-${i}`}>
          <Td colSpan={count}>
            <Skeleton className={styles.eiLoaderTableSkeleton} width="100%" screenreaderText="Loading contents" />
          </Td>
        </Tr>
      );
    }
    return rows;
  };

  // Function to render Th values in Thead
  const getColumnNames = () => {
    let columnNames = [];
    for (let key in columns) {
      if (selectedColumns?.includes(columns[key].columnName)) {
        const columnName =
          columns[key].isSortable && !tableResponseError && tableData?.length ? (
            <Th key={`table-column-name-${key}`} modifier={columns[key].modifier} sort={getSortParams(columns[key].sortParam)}>
              {columns[key].columnName}
            </Th>
          ) : (
            <Th key={`table-column-name-${key}`} modifier={columns[key].modifier}>
              {!columns[key].isActionColumn ? columns[key].columnName : ''}
            </Th>
          );
        columnNames.push(columnName);
      }
    }
    return columnNames;
  };

  // Function to get Tr
  const getRow = (data) => {
    let row = [];
    for (let key in columns) {
      if (columns[key].isActionColumn) {
        if (selectedColumns?.includes(columns[key].columnName)) {
          const actionRow = (
            <Td onClick={() => getClickedRowData(data)} key={`table-row-${key}`} dataLabel={columns[key].columnName}>
              {actionColumn}
            </Td>
          );
          row.push(actionRow);
        }
      } else {
        const formattedValue: string = columns[key]?.valueInPercentage
          ? numberHelper.formatAsPercentage(data[key])
          : numberHelper.formatAsCompactDollarAmount(data[key], currencyCode);
        if (selectedColumns?.includes(columns[key].columnName)) {
          const formatRow = (
            <Td key={`table-row-${key}`} dataLabel={columns[key].columnName}>
              {columns[key].formattable ? formattedValue : data[key]}
            </Td>
          );
          row.push(formatRow);
        }
      }
    }
    return row;
  };

  const getEmptyState = () => {
    const columnsCount: number = columns?.length ? columns?.length : initialColumns?.length;
    const errorRow = (
      <Tr className="eiNoRowBorder">
        <Td colSpan={columnsCount}>
          <EcoIntelEmptyState emptyStateProps={tableEmptyState} />
        </Td>
      </Tr>
    );
    return errorRow;
  };

  // Function to render Tr list in Tbody
  const getDataRows = () => {
    let rows = [];
    pageState?.rows?.map((data, rowIndex) => rows.push(<Tr key={`table-row-list-${rowIndex}`}>{getRow(data)}</Tr>));
    return rows;
  };

  // Render Table
  return (
    <TableComposable data-test="table-content" className={styles.eiTable} rows={pageState?.rows?.length} variant="compact" aria-label="Table">
      <Thead>
        <Tr className={!selectedColumns?.length && 'eiNoRowBorder'}>{getColumnNames()}</Tr>
      </Thead>
      <Tbody>
        {(tableResponseError || tableEmptyState) && !isTableDataFetching ? getEmptyState() : isTableDataFetching ? getSkeletonRows() : getDataRows()}
      </Tbody>
    </TableComposable>
  );
};

export default TableContent;
