import { SelectedReportInformation } from '../models/exportReportInformation';
import ENV from '../utils/env';
import { exportDataStreamRequest } from '../utils/fetch';

export const getExportData = (
  exportReportOption: SelectedReportInformation,
  callback: Function,
  errorCallback: Function,
  bodyParams?: any,
  abortSignal?: AbortSignal
): Promise<any> => {
  const additionalParams: RequestInit = {};
  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/export-service/${exportReportOption.path}`);

  if (abortSignal) {
    Object.assign(additionalParams, { signal: abortSignal });
  }

  return exportDataStreamRequest(uri, callback, errorCallback, exportReportOption.fileName, bodyParams, additionalParams);
};
