import React, { useEffect, useState } from 'react';
import {
  ChartLabel,
  Chart,
  ChartThemeColor,
  ChartAxis,
  ChartGroup,
  ChartBar,
  ChartLegend,
  ChartTooltip,
  ChartVoronoiContainer,
} from '@patternfly/react-charts';
import { Card, CardBody, Divider, Skeleton } from '@patternfly/react-core';
import { isEmpty } from 'lodash';
import { fetchDataPostRequest } from '../../../api/apiCalls';
import EcoIntelEmptyState from '../../../components/EcoIntelEmptyState';
import { errorStateSummaryDetails } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { generateBarChartAxisTickValues } from '../../../utils/chartHelper';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import numberHelper from '../../../utils/numberHelper';
import styles from './DealManagementSummary.module.scss';

interface DealManagementSummaryProps {
  viewContext: string;
}

const DealManagementSummary: React.FC<DealManagementSummaryProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.DEAL_MANAGEMENT_SUMMARY);
  const [dealManagementSummary, setDealManagementSummary] = useState({} as any);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [currencyCode, setCurrencyCode] = useState<string>('USD');

  useEffect(() => {
    const getDealManagementSummary = async () => {
      try {
        const requestBody = {
          viewContext: viewContext,
        };

        const selectedFilterValues = getAllSelectedFilterValues(globalState, requestBody);
        const response = await request(selectedFilterValues);
        setDealManagementSummary(response);
        setCurrencyCode(globalState.otherFilters?.currencyCode);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
      }
    };

    if (((globalState || {}).filterState || {}).originalFilterData) {
      getDealManagementSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter, globalState.otherFilters?.currencyCode]);

  const HTMLLegendContent = (props) => {
    const { datum, x, y, xPx, yPy } = props;

    return (
      <g>
        <foreignObject height="100%" width="100%" x={x - xPx} y={y - yPy}>
          <table>
            <tbody>
              <tr>
                <td style={{ color: '#f0f0f0', fontSize: '14px', textAlign: 'left', verticalAlign: 'top' }}>
                  <svg width="10" height="10">
                    <rect width="10" height="10" style={{ fill: `${datum.color}` }} />
                  </svg>
                </td>
                <td style={{ color: '#f0f0f0', fontSize: '14px', paddingLeft: '12px', textAlign: 'left', verticalAlign: 'top', fontWeight: '700' }}>
                  {datum.x} <br /> {datum.dealCount} <br /> {numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                </td>
              </tr>
            </tbody>
          </table>
        </foreignObject>
      </g>
    );
  };

  return (
    <Card className={styles.eiDealManagementSummary}>
      <CardBody className={styles.eiDealManagementSummaryCard}>
        {responseError ? (
          <EcoIntelEmptyState emptyStateProps={errorStateSummaryDetails} />
        ) : isFetching ? (
          <Skeleton data-test="skeleton" height={'320px'} screenreaderText={'Loading contents...'} width={'100%'} />
        ) : (
          <>
            {!isEmpty(dealManagementSummary) && (
              <>
                <div style={{ height: '350px', width: '1240px', margin: '0 auto' }}>
                  <Divider component="div" className={styles.eiDivider} />
                  <Chart
                    ariaDesc="Chart that shows the amount of renewed opportunities, original renewals, transferred in, transferred out, closed lost, and renewed opportunities. A series of bar charts shows original renewals, transferred in, transferred out, closed lost, renewed opportunities on the y axis and currency on the x axis."
                    data-test="deal-management-summary-chart"
                    domainPadding={{ x: [30, 25] }}
                    height={350}
                    padding={{
                      bottom: 30, // Adjusted to accommodate legend
                      left: 270,
                      right: 240, // Adjusted to accommodate tooltip
                      top: 70,
                    }}
                    themeColor={ChartThemeColor.multiOrdered}
                    width={1240}
                    containerComponent={
                      <ChartVoronoiContainer
                        labels={({ datum }) => {
                          return ' ';
                        }}
                        labelComponent={<ChartTooltip labelComponent={<HTMLLegendContent xPx={95} yPy={35} />} flyoutHeight={95} flyoutWidth={220} />}
                        voronoiDimension="x"
                      />
                    }
                  >
                    <ChartLabel
                      text={[`${viewContext === finderPartnerTypes.DISTRIBUTOR ? '' : 'Est. '} Renewed Opportunities`]}
                      y={10}
                      style={{
                        fontSize: '16px',
                        fontWeight: 500,
                      }}
                      lineHeight={1.75}
                    />
                    <ChartLabel
                      text={[numberHelper.formatAsNumberWithComma(dealManagementSummary?.deal_management_opps.renewed_opportunities_count)]}
                      y={45}
                      style={{
                        fontSize: '24px',
                        fontWeight: 500,
                      }}
                      lineHeight={1.75}
                    />
                    <ChartLegend
                      data={[
                        {
                          name: `Original Renewals: ${numberHelper.formatAsNumberWithComma(
                            dealManagementSummary?.deal_management_opps.original_renewals_count
                          )}`,
                          symbol: { fill: '#71BF6F' },
                        },
                        {
                          name: `Transferred Out: -${numberHelper.formatAsNumberWithComma(
                            dealManagementSummary?.deal_management_opps.transfer_out_count
                          )}`,
                          symbol: { fill: '#C60011' },
                        },
                        {
                          name: `Renewed Opportunities: ${numberHelper.formatAsNumberWithComma(
                            dealManagementSummary?.deal_management_opps.renewed_opportunities_count
                          )}`,
                          symbol: { fill: '#317631' },
                        },
                        {
                          name: `Transferred In: ${numberHelper.formatAsNumberWithComma(
                            dealManagementSummary?.deal_management_opps.transfer_in_count
                          )}`,
                          symbol: { fill: '#71BF6F' },
                        },
                        {
                          name: `Closed Lost: -${numberHelper.formatAsNumberWithComma(
                            dealManagementSummary?.deal_management_opps.closed_lost_count
                          )}`,
                          symbol: { fill: '#C60011' },
                        },
                      ]}
                      itemsPerRow={3}
                      x={580}
                      y={0}
                    />
                    <ChartAxis
                      key="deal-summary-bar-x-axis"
                      style={{
                        tickLabels: {
                          fontWeight: 500,
                          fill: '#151515',
                          fontSize: '14px',
                        },
                        ticks: {
                          display: 'none',
                        },
                      }}
                      tickLabelComponent={<ChartLabel dx={-70} x={250} />}
                    />
                    <ChartAxis
                      key="deal-summary-bar-y-axis"
                      fixLabelOverlap={true}
                      dependentAxis
                      tickValues={generateBarChartAxisTickValues([
                        dealManagementSummary?.deal_management_opps?.renewed_opportunities_amount,
                        -dealManagementSummary?.deal_management_opps?.closed_lost_amount,
                        -dealManagementSummary?.deal_management_opps?.transfer_out_amount,
                        dealManagementSummary?.deal_management_opps?.transfer_in_amount,
                        dealManagementSummary?.deal_management_opps?.original_renewals_amount,
                        dealManagementSummary?.deal_management_opps?.transferred_between_partners_amount,
                        dealManagementSummary?.deal_management_opps?.transferred_between_distis_amount,
                      ])}
                      tickFormat={(t) => {
                        if (t === 0) {
                          return t;
                        } else {
                          return numberHelper.formatAsCompactDollarAmount(Math.round(t), currencyCode);
                        }
                      }}
                    />

                    <ChartGroup offset={1} horizontal>
                      <ChartBar
                        key="renewed-opp-chart-bar"
                        barWidth={24}
                        colorScale={['#317631']}
                        data={[
                          {
                            name: 'E',
                            x: 'Renewed Opportunities',
                            y: dealManagementSummary?.deal_management_opps?.renewed_opportunities_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.renewed_opportunities_count,
                            color: '#317631',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={292} />}
                      />
                      <ChartBar
                        key="closed-lost-chart-bar"
                        barWidth={24}
                        colorScale={['#C60011']}
                        data={[
                          {
                            name: 'D',
                            x: 'Closed Lost -',
                            y: -dealManagementSummary?.deal_management_opps?.closed_lost_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.closed_lost_count,
                            color: '#C60011',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={242} style={{ fill: '#9C0003' }} />}
                      />
                      <ChartBar
                        key="transfer_out-chart-bar"
                        barWidth={24}
                        colorScale={['#C60011']}
                        data={[
                          {
                            name: 'C',
                            x: 'Transferred Out -',
                            y: -dealManagementSummary?.deal_management_opps?.transfer_out_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.transfer_out_count,
                            color: '#C60011',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={193} style={{ fill: '#9C0003' }} />}
                      />
                      <ChartBar
                        key="transfer_in-chart-bar"
                        barWidth={24}
                        colorScale={['#71BF6F']}
                        data={[
                          {
                            name: 'B',
                            x: 'Transferred In +',
                            y: dealManagementSummary?.deal_management_opps?.transfer_in_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.transfer_in_count,
                            color: '#71BF6F',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={142} />}
                      />
                      <ChartBar
                        key="original_renewals-chart-bar"
                        barWidth={24}
                        colorScale={['#71BF6F']}
                        data={[
                          {
                            name: 'A',
                            x: 'Original Renewals',
                            y: dealManagementSummary?.deal_management_opps?.original_renewals_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.original_renewals_count,
                            color: '#71BF6F',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={93} />}
                      />
                    </ChartGroup>
                  </Chart>
                </div>
                <div style={{ height: '125px', width: '1240px', margin: '0 auto' }}>
                  <Chart
                    ariaDesc="Bar chart shows the currency amounts transferred between distis and transferred between partners."
                    domainPadding={{ x: [30, 25] }}
                    height={125}
                    padding={{
                      bottom: 15, // Adjusted to accommodate legend
                      left: 270,
                      right: 240, // Adjusted to accommodate tooltip
                      top: 25,
                    }}
                    themeColor={ChartThemeColor.multiOrdered}
                    width={1240}
                    containerComponent={
                      <ChartVoronoiContainer
                        labels={({ datum }) => {
                          return ' ';
                        }}
                        labelComponent={
                          <ChartTooltip labelComponent={<HTMLLegendContent xPx={105} yPy={35} />} flyoutHeight={95} flyoutWidth={270} />
                        }
                        voronoiDimension="x"
                      />
                    }
                  >
                    <ChartAxis
                      key="transferred-summary-bar-x-axis"
                      style={{
                        axis: {
                          stroke: 'transparent',
                        },
                        tickLabels: {
                          fontWeight: 500,
                          fill: '#151515',
                          fontSize: '14px',
                        },
                        ticks: {
                          display: 'none',
                        },
                      }}
                      tickLabelComponent={<ChartLabel dx={-30} x={250} />}
                    />
                    <ChartAxis
                      key="transferred-summary-bar-y-axis"
                      fixLabelOverlap={true}
                      dependentAxis
                      style={{
                        axis: {
                          stroke: 'transparent',
                        },
                        ticks: {
                          display: 'none',
                        },
                        tickLabels: {
                          display: 'none',
                        },
                      }}
                      tickValues={generateBarChartAxisTickValues([
                        dealManagementSummary?.deal_management_opps?.renewed_opportunities_amount,
                        -dealManagementSummary?.deal_management_opps?.closed_lost_amount,
                        -dealManagementSummary?.deal_management_opps?.transfer_out_amount,
                        dealManagementSummary?.deal_management_opps?.transfer_in_amount,
                        dealManagementSummary?.deal_management_opps?.original_renewals_amount,
                        dealManagementSummary?.deal_management_opps?.transferred_between_partners_amount,
                        dealManagementSummary?.deal_management_opps?.transferred_between_distis_amount,
                      ])}
                    />
                    <ChartGroup offset={1} horizontal>
                      <ChartBar
                        key="transferred-bet-partners-chart-bar"
                        barWidth={24}
                        colorScale={['#8B8D8F']}
                        data={[
                          {
                            name: 'F',
                            x: 'Transferred Between Partners',
                            y: dealManagementSummary?.deal_management_opps?.transferred_between_partners_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.transferred_between_partners_count,
                            color: '#8B8D8F',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={86} />}
                      />
                      <ChartBar
                        key="transferred-bet-distis-chart-bar"
                        barWidth={24}
                        colorScale={['#8B8D8F']}
                        data={[
                          {
                            name: 'G',
                            x: 'Transferred Between Distis',
                            y: dealManagementSummary?.deal_management_opps?.transferred_between_distis_amount,
                            dealCount: dealManagementSummary?.deal_management_opps.transferred_between_distis_count,
                            color: '#8B8D8F',
                          },
                        ]}
                        labels={({ datum }) => numberHelper.formatAsCompactDollarAmount(datum.y, currencyCode)}
                        labelComponent={<ChartLabel y={44} />}
                      />
                    </ChartGroup>
                  </Chart>
                </div>
              </>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default DealManagementSummary;
