import React, { useEffect, useState } from 'react';
import { Stack, StackItem } from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../api/apiCalls';
import EcoIntelEmptyState from '../../components/EcoIntelEmptyState';
import { errorState } from '../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import EmptyStateSpinner from '../../components/EmptyStateSpinner';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { useUserMetadata } from '../../hooks/useUserMetadata';
import { HomePageData } from '../../models/homePage';
import { ApiRoutes } from '../../utils/apiRoutes';
import Content from './Content/Content';
import styles from './Home.module.scss';

interface HomeProps {
  setShowFilter: (value: boolean) => void;
  setShowNavbar: (value: boolean) => void;
  viewContext: string;
}

const Home: React.FC<HomeProps> = ({ setShowFilter, setShowNavbar }) => {
  const { globalState } = useGlobalContext();
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.HOME);
  const [homePageData, setHomePageData] = useState<HomePageData>({});
  const [responseError, setResponseError] = useState<string | null>(null);
  const initialFinderPartnerType = useUserMetadata().finder_partner_type_name;

  useEffect(() => {
    setShowNavbar(true);
    setShowFilter(false);
    const getHomePageData = async () => {
      try {
        /**
         *  The home page is not filterable, so we should not pass any selected filters and
         *  only use the finderPartnerType of the user themselves.
         *
         *  The requestBody type left as any for now because the request function expects
         *  <T extends any[]>.
         **/
        const requestBody: any = {
          finder_partner_type_name: initialFinderPartnerType,
        };
        if (globalState?.otherFilters?.currencyCode) {
          Object.assign(requestBody, { currency_code: globalState.otherFilters.currencyCode });
        }
        const response = await request(requestBody);
        setHomePageData(response);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
      }
    };
    getHomePageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.filterState?.isApplyFilter]);

  return (
    <Stack>
      <StackItem className={styles.eiOverviewContent} isFilled>
        {isFetching && <EmptyStateSpinner />}
        {!isFetching && (
          <div>
            {responseError && <EcoIntelEmptyState emptyStateProps={errorState} />}
            {!responseError && <Content contentData={homePageData} />}
          </div>
        )}
      </StackItem>
    </Stack>
  );
};

export default Home;
