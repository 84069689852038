import { FilterDataSelection } from '../models/filter';

export type ApiParams = {
  propagateErrors: boolean;
  path: string;
};

export interface BodyParams {
  fetch_id?: number;
  finder_partner_type_name?: string;
  order_by?: string;
  page_limit?: number;
  sort_by?: string;
  view_context?: string;
  filter_by?: FilterDataSelection;
}

/***
 *  Because there is no routing service, some paths
 *  include /data/ to hit the api data services
 ***/
export const ApiRoutes: Record<string, ApiParams> = {
  BOOKING_PERFORMANCE: { propagateErrors: true, path: 'review-service/bookings-performance' },
  BOOKING_PERFORMANCE_DETAIL: { propagateErrors: true, path: 'review-service/data/bookings-performance-amount-opps' },
  DEAL_MANAGEMENT_SUMMARY: { propagateErrors: true, path: 'review-service/data/deal-management-opps-summary' },
  DEAL_MANAGEMENT_DETAIL: { propagateErrors: true, path: 'review-service/data/deal-management-opps-details' },
  GET_FILTERS: { propagateErrors: true, path: 'filters' },
  GET_SUBFILTERS: { propagateErrors: true, path: 'sub-filters' },
  HOME: { propagateErrors: true, path: 'review-service/overview' },
  PIPELINE_BY_ACCOUNT_DATA: { propagateErrors: true, path: 'renew-service/data/pipeline-details' },
  PIPELINE_RISK_PROFILE: { propagateErrors: true, path: 'renew-service/data/risk-profile' },
  OPPORTUNITIES_DATA_DETAIL: { propagateErrors: true, path: 'renew-service/data/opportunity-details' },
  RENEW_OVERVIEW: { propagateErrors: true, path: 'renew-service/overview' },
  RETENTION_PERFORMANCE_SUMMARY: { propagateErrors: true, path: 'review-service/retention-performance' },
  RETENTION_PERFORMANCE_DETAIL: { propagateErrors: true, path: 'review-service/data/customer-retention-rate-details' },
  REVIEW_HERO: { propagateErrors: true, path: 'review-service/review-hero' },
  REVIEW_OVERVIEW: { propagateErrors: true, path: 'review-service/review-overview' },
  TOP_AND_BOTTOM_PERFORMERS: { propagateErrors: true, path: 'review-service/data/top-bottom-performers' },
};
