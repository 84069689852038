import React from 'react';
import { Badge, Banner, Button, Grid, GridItem, Modal, ModalVariant, Text, TextVariants, Title, TitleSizes } from '@patternfly/react-core';
import { ReactComponent as InfraredLogo } from '../../../../assets/IR-mini-logo.svg';
import { finderPartnerTypes } from '../../../../constants/finderPartnerTypes';
import { useTableContext } from '../../../../contexts/Table/TableContext';
import styles from './OpportunitiesDetailModal.module.scss';

interface OpportunitiesDetailModalProps {
  viewContext: string;
  handleModalToggle: any;
  isModalOpen: boolean;
}

const OpportunitiesDetailModal: React.FC<OpportunitiesDetailModalProps> = ({ viewContext, handleModalToggle, isModalOpen }) => {
  const { actionRowData } = useTableContext();

  // Modal header section
  const modalHeader = (
    <>
      <Title
        aria-label="Opportunities detail modal header"
        className={styles.eiOpportunitiesDetailModalTitle}
        headingLevel="h1"
        size={TitleSizes['2xl']}
      >
        <InfraredLogo className={styles.eiModalHeaderLogo} />
        Infrared <span className={styles.eiModalTitleLabel}>Opp#</span>
        <span className={styles.eiModalTitleDetail}>{actionRowData?.opportunity_number}</span>
      </Title>

      <section className={styles.eiOpportunitiesDetailModalHeaderDetails}>
        <Grid span={12}>
          <GridItem span={5}>
            <Text component={TextVariants.p}>
              Close Date <span>{actionRowData?.opportunity_close_date}</span>
            </Text>
          </GridItem>
          <GridItem span={4}>
            <Text component={TextVariants.p}>
              PO # <span>{actionRowData?.renewal_source_opportunity_po_number}</span>
            </Text>
          </GridItem>
          <GridItem span={3}>
            <Text component={TextVariants.p}>
              Contr # <span>{actionRowData?.opportunity_contract_number}</span>
            </Text>
          </GridItem>
          <GridItem span={5}>
            <Text component={TextVariants.p}>
              Partner Account<span>{actionRowData?.reseller_partner_account_name}</span>
            </Text>
          </GridItem>
          <GridItem span={4}>
            <Text component={TextVariants.p}>
              Risk <span>{actionRowData?.risk}</span>
            </Text>
          </GridItem>
          <GridItem span={3}>
            <Text component={TextVariants.p}>
              {viewContext === finderPartnerTypes.DISTRIBUTOR ? 'Opp Amount' : 'Est. Opp Amount'} <span>{actionRowData?.est_opp_amount}</span>
            </Text>
          </GridItem>
          <GridItem span={5}>
            <Text component={TextVariants.p}>
              Customer Account <span>{actionRowData?.account_name}</span>
            </Text>
          </GridItem>
          <GridItem span={4}>
            <Text component={TextVariants.p}>
              Forecast Category <span>{actionRowData?.opportunity_forecast_category}</span>
            </Text>
          </GridItem>
        </Grid>
      </section>
    </>
  );

  // Modal body section
  const modalBody = (
    <>
      <Text className={styles.eiOpportunitiesModalBodyHeading} component={TextVariants.p}>
        Customer Insights
      </Text>
      <Grid className={styles.eiOpportunitiesModalDetails}>
        <GridItem span={3}>
          <Text component={TextVariants.p}>Eli McDonald</Text>
        </GridItem>
        <GridItem span={3}>
          <span>Red Hat AR</span>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            emcdona@redhat.com
          </Button>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            740-555-6949
          </Button>
        </GridItem>
      </Grid>
      <Grid className={styles.eiOpportunitiesModalDetails}>
        <GridItem span={3}>
          <Text component={TextVariants.p}>Darrell Bayes</Text>
        </GridItem>
        <GridItem span={3}>
          <span>End User Contact</span>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            dbayes@dom.gov
          </Button>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            813-555-6922
          </Button>
        </GridItem>
      </Grid>
      <Grid className={styles.eiOpportunitiesModalDetails}>
        <GridItem span={3}>
          <Text component={TextVariants.p}>Donna Dance-Masgay</Text>
        </GridItem>
        <GridItem span={3}>
          <span>Technical Buyer</span>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            ddance-masgay@dom.gov
          </Button>
        </GridItem>
        <GridItem span={3}>
          <Button className="eiOpportunitiesModalDetailsLinkButton" variant="link">
            813-555-6942
          </Button>
        </GridItem>
      </Grid>

      <section className={styles.eiOpportunitiesModalBody}>
        <Text className={styles.eiOpportunitiesModalBodyHeading} component={TextVariants.p}>
          Recommendations
        </Text>
        <Banner className={`${styles.eiModalBanner} ${styles.eiModalBannerGreen}`} variant="success">
          <Text className={styles.eiBannerHeading} component={TextVariants.p}>
            OpenShift
          </Text>
          <Text component={TextVariants.p}>Cross-Sell Next Best Offer</Text>
        </Banner>
        <Banner className={`${styles.eiModalBanner} ${styles.eiModalBannerBlue}`} variant="info">
          <Text className={styles.eiBannerHeading} component={TextVariants.p}>
            Ansible
          </Text>
          <Text component={TextVariants.p}>Cross-Sell High Probability</Text>
        </Banner>
        <Button className={styles.eiModalLinkButton} variant="link">
          View All Recommendations
        </Button>
        <Badge className={styles.eiModalBadge}>1</Badge>
      </section>
    </>
  );

  // Function to render Modal
  const renderModal = () => {
    return (
      <Modal
        aria-label="Opportunities detail modal"
        className={styles.eiTableActionModal}
        variant={ModalVariant.medium}
        header={modalHeader}
        title="Action Modal"
        isOpen={isModalOpen}
        disableFocusTrap={true}
        onClose={handleModalToggle}
        actions={[
          <Button key="confirm" variant="primary" onClick={handleModalToggle}>
            Close
          </Button>,
        ]}
      >
        {modalBody}
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

export default OpportunitiesDetailModal;
