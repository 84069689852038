import React from 'react';
import { Spinner } from '@patternfly/react-core';
import styles from './EcoIntelBackdrop.module.scss';

const EcoIntelBackdrop: React.FC = () => {
  return (
    <div className={styles.eiBackdrop}>
      <Spinner className={styles.eiSpinner} />
    </div>
  );
};

export default EcoIntelBackdrop;
