import pageRoutes from './pageRoutes';

const routeHelper = {
  getRouteObjectFromPath(path) {
    let routeObject = null;

    if (path) {
      for (let route in pageRoutes) {
        const routeItem = pageRoutes[route];

        //because the useLocation()?.pathname in Layout.tsx will return the path with a final '/', we are checking equality with that last '/' substringed out
        if (routeItem.path === path || routeItem.path === path.substring(0, path.length - 1)) {
          routeObject = routeItem;
        }
      }
    }

    return routeObject;
  },

  getGroupRouteObjectFromGroupName(group) {
    let routeObject = null;

    if (group) {
      for (let route in pageRoutes) {
        const routeItem = pageRoutes[route];

        if (routeItem.itemId === group) {
          routeObject = routeItem;
        }
      }
    }

    return routeObject;
  },
};

export default routeHelper;
