export enum LookupTypes {
  partnerParent = 'partner_parent',
  partnerAccount = 'partner_account',
  distributorAccount = 'distributor_account',
  distributorParent = 'distributor_parent',
  geo = 'geo',
  subregion = 'subregion',
  country = 'country',
  accountSegment = 'account_segment',
}
