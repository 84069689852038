import React from 'react';
import { Chart, ChartAxis, ChartGroup, ChartLine, ChartVoronoiContainer } from '@patternfly/react-charts';
import { Skeleton, Text, TextContent, TextVariants } from '@patternfly/react-core';
import dateHelper from '../../../../utils/dateHelper';
import styles from './PerformanceOverTime.module.scss';

interface PerformanceOverTimeProps {
  bookingsPerformanceTime: any;
  isFetching: boolean;
}

const PerformanceOverTime: React.FC<PerformanceOverTimeProps> = ({ bookingsPerformanceTime, isFetching }) => {
  const bookingsPerformanceOverTimeCategoryValues = ['cross_sell_percent', 'performance_percent', 'renewal_at_par_percent', 'up_sell_percent'];

  const bookingsPerformanceOverTimeCategoryMappingValues = {
    performance_percent: 'Performance Percent',
    renewal_at_par_percent: 'Renewal @Par Percent',
    up_sell_percent: 'Up Sell Percent',
    cross_sell_percent: 'Cross Sell Percent',
  };

  /* the data is flat on bookingsPerformanceTime, but we must separate it out into the correct format for the Chart Lines */
  const bookingsPerformanceTimeChartData = {};

  bookingsPerformanceOverTimeCategoryValues.forEach((category, index) => {
    bookingsPerformanceTime.forEach((dataItem) => {
      if (!bookingsPerformanceTimeChartData[category]) {
        bookingsPerformanceTimeChartData[category] = [];
      }

      bookingsPerformanceTimeChartData[category].push({
        name: category,
        x: `${dateHelper.abbreviatedMonthNameLookup[dataItem['month']]} ${dataItem['year']}`,
        y: dataItem[category],
      });
    });
  });

  return (
    <>
      <TextContent className={styles.eiPerformanceOverTimeChartTitle}>
        <Text component={TextVariants.p}>{'Performance Over Time'}</Text>
      </TextContent>
      <div className={styles.eiChart}>
        {isFetching ? (
          <Skeleton height={'250px'} screenreaderText={'Loading contents...'} width={'100%'} />
        ) : (
          <Chart
            ariaDesc="Displays line charts of cross sell percent, up sell percent, renewal at par percent, and performance percent on the y axis and date ranges on the x axis."
            containerComponent={
              <ChartVoronoiContainer
                constrainToVisibleArea
                labels={({ datum }) => `Date: ${datum.x}\n\t\n${bookingsPerformanceOverTimeCategoryMappingValues[datum.name]}: ${datum.y}%`}
              />
            }
            height={250}
            padding={{ bottom: 50, left: 52, right: 52, top: 25 }}
            width={530}
          >
            <ChartAxis fixLabelOverlap={true} style={{ tickLabels: { fontSize: '14px' } }} tickValues={[]} />
            <ChartAxis dependentAxis style={{ tickLabels: { fontSize: '14px' } }} tickFormat={(x) => `${x}%`} tickValues={[100, 200]} />
            <ChartGroup>
              <ChartLine
                data={bookingsPerformanceTimeChartData['performance_percent']}
                style={{ data: { stroke: '#009596' }, labels: { fontSize: 12 } }}
              />
              <ChartLine
                data={bookingsPerformanceTimeChartData['renewal_at_par_percent']}
                style={{ data: { stroke: '#003737' }, labels: { fontSize: 12 } }}
              />
              <ChartLine
                data={bookingsPerformanceTimeChartData['up_sell_percent']}
                style={{ data: { stroke: '#009596', strokeDasharray: '3,3' }, labels: { fontSize: 12 } }}
              />
              <ChartLine
                data={bookingsPerformanceTimeChartData['cross_sell_percent']}
                style={{ data: { stroke: '#003737', strokeDasharray: '3,3' }, labels: { fontSize: 12 } }}
              />
            </ChartGroup>
          </Chart>
        )}
      </div>
    </>
  );
};

export default PerformanceOverTime;
