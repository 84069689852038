import { useMemo } from 'react';
import { FilterOption } from '../models/FilterOption';
import { getFilterDropdownValues, mapStringsToInputOptions } from '../utils/filterHelper';

export const useOptions = (stateValues: Record<string, string[]> | undefined): FilterOption[] | undefined => {
  return useMemo(() => {
    if (stateValues) {
      return mapStringsToInputOptions(getFilterDropdownValues(stateValues));
    }
  }, [stateValues]);
};
