import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Card, CardBody, Select, SelectOption, SelectVariant, Text, TextVariants, ToolbarItem } from '@patternfly/react-core';
import { fetchDataPostRequest, getSubfilterOptions } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  DealManagementTableSortColumnIndex,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
} from './DealManagementTableConfiguration';
import styles from './DealManagementDetail.module.scss';

interface DealManagementDetailProps {
  viewContext: string;
}

const DealManagementDetail: React.FC<DealManagementDetailProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request } = useFetch(fetchDataPostRequest, ApiRoutes.DEAL_MANAGEMENT_DETAIL);
  const [dealManagementDetailData, setDealManagementDetailData] = useState<any>();
  const [responseError, setResponseError] = useState<string | null>(null);
  const [isDealTypeSelectOpen, setIsDealTypeSelectOpen] = useState(false);
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    tableData,
    isTableDataFetching,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortIndex,
    setActiveSortDirection,
    setTableName,
    setTableEmptyState,
    setTableResponseError,
  } = useTableContext();

  const { request: subfiltersRequest } = useFetch(getSubfilterOptions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subfiltersResponseError, setSubfiltersResponseError] = useState(null);
  const [dealTypeFilterOptions, setDealTypeFilterOptions] = useState([]);
  const [selectedDealTypeFilterOption, setSelectedDealTypeFilterOption] = useState(SubFilter.AllDealTypes);

  const onSelectDealTypeFilter = (event, filterOption) => {
    setIsDealTypeSelectOpen(!isDealTypeSelectOpen);
    if (selectedDealTypeFilterOption !== filterOption) {
      setSelectedDealTypeFilterOption(filterOption);
    }
  };

  useEffect(() => {
    setActiveSortDirection('desc');
    setActiveSortIndex(8);

    const getDealManagementDetailFilterOptions = async () => {
      try {
        const subfiltersResponse = await subfiltersRequest(ApiRoutes.GET_SUBFILTERS, SubFilter.DealType);
        setDealTypeFilterOptions(subfiltersResponse?.subfilters[0]?.value_text);
      } catch (error) {
        setSubfiltersResponseError(error.message);
      }
    };

    getDealManagementDetailFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTableDataFetching(true);
    const getDealManagementTableData = async () => {
      try {
        const requestBody = {
          viewContext: viewContext,
          fetch_id: currentFetchId,
          page_limit: pageLimit,
          order_by: DealManagementTableSortColumnIndex[activeSortIndex],
          sort_by: activeSortDirection,
          filter_by: {},
        };

        if (selectedDealTypeFilterOption) {
          Object.assign(requestBody.filter_by, { [SubFilter.DealType]: selectedDealTypeFilterOption });
        }

        const selectedFilterValues = getAllSelectedFilterValues(globalState, requestBody);
        const response = await request(selectedFilterValues);

        setDealManagementDetailData(response);
        setTableData(response?.deal_management_details?.data);
        setIsTableDataFetching(false);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
        setIsTableDataFetching(false);
      }
    };

    if (((globalState || {}).filterState || {}).originalFilterData && activeSortIndex && activeSortDirection) {
      getDealManagementTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSortDirection, activeSortIndex, viewContext, globalFilterAppliedOrReset, currentFetchId, filterBy, selectedDealTypeFilterOption]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  useEffect(() => {
    const defaultColumns: string[] = [];
    unstable_batchedUpdates(() => {
      setPageLimit(currentPageLimit);
      setDefaultColumns(defaultColumns);
      setTableName('deal-management');
      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        setInitialColumns(initialDistributorColumns);
        setColumns(distributorColumnDetails);
      } else {
        setInitialColumns(initialResellerColumns);
        setColumns(resellerColumnDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  useEffect(() => {
    setTableData(dealManagementDetailData?.deal_management_details?.data);
    setTotalRecordsCount(dealManagementDetailData?.deal_management_details?.record_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dealManagementDetailData, setTableData, setTotalRecordsCount]);

  const tableFilter = (
    <ToolbarItem>
      <Text data-test="deal-type-filter-title" component={TextVariants.p}>
        Deal Type
      </Text>
      <div data-test="deal-type-filter">
        <Select
          aria-label="Select Input"
          className={styles.eiToolbarSelectWidth}
          id="select-deal-type"
          isDisabled={(dealTypeFilterOptions?.length > 0 ? false : true) || isTableDataFetching || subfiltersResponseError != null}
          isOpen={isDealTypeSelectOpen}
          onSelect={onSelectDealTypeFilter}
          onToggle={() => setIsDealTypeSelectOpen(!isDealTypeSelectOpen)}
          placeholderText={<>All Deal Types</>}
          selections={selectedDealTypeFilterOption}
          variant={SelectVariant.single}
        >
          {dealTypeFilterOptions?.map((option, index) => {
            return <SelectOption key={index} value={option} />;
          })}
        </Select>
      </div>
    </ToolbarItem>
  );
  return (
    <Card className={styles.eiDealManagementDetail}>
      <CardBody>
        <Table filter={tableFilter}></Table>
      </CardBody>
    </Card>
  );
};

export default DealManagementDetail;
