import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Grid, GridItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { ReactComponent as CrossSellLogo } from '../../assets/infrared-overview-cross-sell.svg';
import { ReactComponent as CustomerInsightsLogo } from '../../assets/infrared-overview-customer-insights.svg';
import CardBodyWithImage from '../../components/CardBodyWithImage';
import pageRoutes from '../../utils/pageRoutes';
import styles from './InfraredOverview.module.scss';

interface InfraredOverviewProps {
  viewContext: string;
}

const InfraredOverview: React.FC<InfraredOverviewProps> = ({ viewContext }) => {
  return (
    <Grid data-test="infrared-overview-content" hasGutter>
      <GridItem sm={12} xl={6} className={styles.eiCardContainer}>
        <Card className={styles.eiCard}>
          <CardTitle className={styles.eiCardTitle}>
            <Button
              data-test="infrared-cross-sell-probabilities"
              isLarge
              variant="link"
              component={(props: any) => <Link className={styles.eiCardTitleLinkText} {...props} to={pageRoutes.crossSellProbabilities.path} />}
            >
              {pageRoutes.crossSellProbabilities.linkText}
            </Button>
          </CardTitle>
          <CardBody>
            <CardBodyWithImage
              imageComponent={<CrossSellLogo />}
              textComponent={
                <TextContent>
                  <Text component={TextVariants.p} className={styles.eiCardText}>
                    Get predictions based on machine learning models developed by Red Hat’s data scientists. Identify product and customer
                    combinations that are most likely to lead to sales.
                  </Text>
                </TextContent>
              }
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={12} xl={6} className={styles.eiCardContainer}>
        <Card className={styles.eiCard}>
          <CardTitle className={styles.eiCardTitle}>
            <Button
              data-test="infrared-customer-insights"
              isLarge
              variant="link"
              component={(props: any) => <Link className={styles.eiCardTitleLinkText} {...props} to={pageRoutes.customerInsights.path} />}
            >
              {pageRoutes.customerInsights.linkText}
            </Button>
          </CardTitle>
          <CardBody>
            <CardBodyWithImage
              imageComponent={<CustomerInsightsLogo />}
              textComponent={
                <TextContent>
                  <Text component={TextVariants.p} className={styles.eiCardText}>
                    Understand customer context and identify growth opportunity by looking at revenue trends, install base, key contacts, and customer
                    engagement.
                  </Text>
                </TextContent>
              }
            />
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};
export default InfraredOverview;
