import { useReducer, useState } from 'react';
import { useGlobalContext } from '../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../contexts/GlobalContext/GlobalContextReducer';
import { ApiParams } from '../utils/apiRoutes';
import { useIsMounted } from './useIsMounted';
import { usePrevious } from './usePrevious';

interface IFetchState<T> {
  data?: T;

  // is loading data
  isFetching?: boolean;

  // error from request function or null
  error?: Error;

  // Abort controller to manually abort the request
  prevAbortSignal?: AbortController;
}

export interface IUseFetch<T, A extends any[]> extends IFetchState<T> {
  // request function to fetch data
  request?: (...args: A) => Promise<T>;
  clearResponse?: () => void;
}

// eslint-disable-next-line
export interface IUseFetchOptions<T> {
  initialData?: any;
  resolve?: boolean;
  resolveCondition?: string[];
  isAbortable?: boolean;
  propagateErrors?: boolean;
}

export const useLegacyFetch = <T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  { initialData = null, resolve = false, resolveCondition = [], isAbortable = false, propagateErrors = false }: IUseFetchOptions<T> = {}
): IUseFetch<T, A> => {
  const { globalState, dispatch } = useGlobalContext();
  const isMounted = useIsMounted();
  const [{ data, isFetching, error }, setState] = useReducer((s: IFetchState<T>, a: IFetchState<T>) => ({ ...s, ...a }), {
    data: initialData,
    isFetching: resolve,
    error: null,
  });
  const [abortSignal, setAbortSignal] = useState<AbortController>(null);
  const prevAbortSignal = usePrevious(abortSignal);
  const clearResponse = () => setState({ data: initialData });
  const request = async (...args: A) => {
    try {
      if (isAbortable && 'AbortController' in window) {
        if (prevAbortSignal) {
          prevAbortSignal.abort();
        }
        const controller = new AbortController();
        setAbortSignal(controller);
        args.push(controller.signal);
      }
      // check if component is mounted, only then update state
      isMounted && setState({ isFetching: true, error: null });
      const response = await fn(...args);
      isMounted && setState({ data: response, isFetching: false, error: null });
      return response;
    } catch (err) {
      // Is AbortError: To ignore abortError
      const isAbortError = err && err.name === 'AbortError';
      isMounted && setState({ data: null, isFetching: false, error: isAbortError ? null : err });
      if (propagateErrors && !isAbortError) {
        // ignore AbortError
        throw err;
      }
    } finally {
      isMounted && setState({ isFetching: false });

      // This code snippet is placed here to make sure to set the isFiltersInProgress flag to false.
      // The purpose is to add it at one place instead of each API call.
      // This will execute only if isFiltersInProgress is true.
      if (globalState.filterState.isFiltersInProgress) {
        dispatch({
          type: StateActions.filterData,
          payload: {
            filterState: {
              ...globalState.filterState,
              isFiltersInProgress: false,
            },
          },
        });
      }
    }
  };

  return {
    clearResponse,
    data,
    isFetching,
    request,
    prevAbortSignal,
    error,
  };
};

export const useFetch = <T, A extends any[]>(
  fn: (...args: A) => Promise<T>,
  apiParams?: ApiParams,
  { initialData = null, resolve = false, resolveCondition = [], isAbortable = false, propagateErrors = false }: IUseFetchOptions<T> = {}
): IUseFetch<T, A> => {
  const { globalState, dispatch } = useGlobalContext();
  const isMounted = useIsMounted();
  const [{ data, isFetching, error }, setState] = useReducer((s: IFetchState<T>, a: IFetchState<T>) => ({ ...s, ...a }), {
    data: initialData,
    isFetching: resolve,
    error: null,
  });
  const [abortSignal, setAbortSignal] = useState<AbortController>(null);
  const prevAbortSignal = usePrevious(abortSignal);
  const clearResponse = () => setState({ data: initialData });
  const request = async (...args: A) => {
    try {
      if (isAbortable && 'AbortController' in window) {
        if (prevAbortSignal) {
          prevAbortSignal.abort();
        }
        const controller = new AbortController();
        setAbortSignal(controller);
        args.push(controller.signal);
      }
      if (apiParams) args.unshift(apiParams);
      // check if component is mounted, only then update state
      isMounted && setState({ isFetching: true, error: null });
      const response = await fn(...args);
      isMounted && setState({ data: response, isFetching: false, error: null });
      return response;
    } catch (err) {
      // Is AbortError: To ignore abortError
      const isAbortError = err && err.name === 'AbortError';
      isMounted && setState({ data: null, isFetching: false, error: isAbortError ? null : err });
      if (apiParams && apiParams.propagateErrors && !isAbortError) {
        // ignore AbortError
        throw err;
      }
    } finally {
      isMounted && setState({ isFetching: false });

      // This code snippet is placed here to make sure to set the isFiltersInProgress flag to false.
      // The purpose is to add it at one place instead of each API call.
      // This will execute only if isFiltersInProgress is true.
      if (globalState.filterState.isFiltersInProgress) {
        dispatch({
          type: StateActions.filterData,
          payload: {
            filterState: { ...globalState.filterState, isFiltersInProgress: false },
          },
        });
      }
    }
  };

  return {
    clearResponse,
    data,
    isFetching,
    request,
    prevAbortSignal,
    error,
  };
};
