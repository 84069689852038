import React, { useEffect, useState } from 'react';
import {
  Masthead,
  MastheadBrand,
  MastheadContent,
  MastheadMain,
  MastheadToggle,
  PageToggleButton,
  Popover,
  PopoverPosition,
  Select,
  SelectOption,
  SelectVariant,
  Split,
  SplitItem,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
} from '@patternfly/react-core';
import { BarsIcon, CoinsIcon } from '@patternfly/react-icons';
import { isEmpty } from 'lodash';
import { ReactComponent as FilterIcon } from '../../../assets/filter-icon.svg';
import { ReactComponent as FiltersModified } from '../../../assets/filters-modified.svg';
import { ReactComponent as Logo } from '../../../assets/redhat_logo.svg';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../contexts/GlobalContext/GlobalContextReducer';
import { useUserMetadata } from '../../../hooks/useUserMetadata';
import pageRoutes from '../../../utils/pageRoutes';
import routeHelper from '../../../utils/routeHelper';
import IconButton from './IconButton';
import UserMenu from './UserMenu';
import styles from './Header.module.scss';

interface HeaderProps {
  currentPath: string;
  toggleFilter: () => void;
  toggleNavbar: () => void;
  viewContext: string;
}

const Header: React.FC<HeaderProps> = ({ currentPath, toggleFilter, toggleNavbar, viewContext }) => {
  const popoverRef = React.useRef();
  const { globalState, dispatch } = useGlobalContext();
  const userMetadata = useUserMetadata();

  /* check the current path's disabledElements to see if the filters should be disabled on the current page */
  const disabledElements = routeHelper.getRouteObjectFromPath(currentPath)?.disabledElements;
  const [isFilterDisabled, setIsFilterDisabled] = useState(true);

  const [isCurrencySelectOpen, setIsCurrencySelectOpen] = useState(false);
  const [selectedCurrencyOption, setSelectedCurrencyOption] = useState('USD');

  useEffect(() => {
    /* using the current path's disabledElements above along with the presence of the viewContext to know if the filters
       API call has returned. If the API call has returned, this check will allow the icon to be clickable */
    setIsFilterDisabled((disabledElements ? disabledElements['filter'] : false) || viewContext === '');
  }, [userMetadata, disabledElements, viewContext]);

  const renderIconButton = (
    icon: React.ReactNode,
    iconContainerStyles: string,
    onClickIcon: (e: React.MouseEvent<HTMLButtonElement>) => void,
    isDisabled: boolean,
    iconName: string,
    dataTestId?: string
  ) => {
    return (
      <IconButton
        icon={icon}
        iconContainerStyles={iconContainerStyles}
        isDisabled={isDisabled}
        onClickIcon={onClickIcon}
        iconName={iconName}
        dataTestId={dataTestId}
      />
    );
  };

  const onCurrencySelect = (event, selection) => {
    setSelectedCurrencyOption(selection);
    setIsCurrencySelectOpen(!isCurrencySelectOpen);
    dispatch({
      type: StateActions.otherFilters,
      payload: {
        otherFilters: { ...globalState.otherFilters, currencyCode: selection || '' },
      },
    });
    dispatch({
      type: StateActions.filterData,
      payload: {
        // eslint-disable-next-line no-new-wrappers
        filterState: { ...globalState.filterState, isApplyFilter: true, isResetFilter: false },
      },
    });
  };

  return (
    <Masthead className={styles.eiHeader}>
      <MastheadToggle className={styles.eiMastheadToggle}>
        <Split className={styles.eiMastheadToggleIcons}>
          <SplitItem>
            <PageToggleButton
              className={styles.eiPageToggleButton}
              variant="plain"
              aria-label="Global navigation"
              onNavToggle={toggleNavbar}
              id="vertical-nav-toggle"
              data-test="vertical-nav-toggle"
            >
              <BarsIcon className={styles.eiFABars} />
            </PageToggleButton>
          </SplitItem>
          <SplitItem>
            {renderIconButton(
              /* this terenary is deciding between showing the filter-icon and the filters-modified icon depending on if the isApplyFilter flag
              is set. Sometimes it's undefined, and must be coerced to false. These are properties to the renderSplitItemIconButtom function */
              (globalState?.filterState?.isApplyFilter?.valueOf() ? true : false) ? (
                <FiltersModified className={isFilterDisabled ? styles.eiFAFilterDisabledSVG : styles.eiFAFilterActiveSVG} />
              ) : (
                <FilterIcon className={isFilterDisabled ? styles.eiFAFilterDisabledSVG : styles.eiFAFilterActiveSVG} />
              ),
              isFilterDisabled ? styles.eiFAFilterContainerDisabled : styles.eiFAFilterContainerActive,
              isFilterDisabled ? null : toggleFilter,
              isFilterDisabled,
              'Filter',
              'filter'
            )}
          </SplitItem>
        </Split>
      </MastheadToggle>
      <MastheadMain>
        <MastheadBrand aria-label="Red Hat Renewals Intelligence" href={pageRoutes.baseUrl.path}>
          <Logo />
        </MastheadBrand>
      </MastheadMain>
      <MastheadContent>
        <Toolbar className={styles.eiToolbar}>
          <ToolbarGroup alignment={{ default: 'alignRight' }}>
            <ToolbarItem>
              <>
                <span ref={popoverRef}>
                  {renderIconButton(
                    <CoinsIcon
                      className={isEmpty(globalState?.filterState?.originalFilterData) ? styles.eiFAFilterDisabledSVG : styles.eiFAFilterActiveSVG}
                    />,
                    isEmpty(globalState?.filterState?.originalFilterData) ? styles.eiFAFilterContainerDisabled : '',
                    null,
                    isEmpty(globalState?.filterState?.originalFilterData),
                    'Currency',
                    'currency'
                  )}
                </span>
                <Popover
                  aria-label="currency popover"
                  hasAutoWidth
                  position={PopoverPosition.bottom}
                  showClose={false}
                  headerComponent="h2"
                  headerContent={<div className={styles.eiCurrencyHeader}>Currency</div>}
                  bodyContent={
                    <div data-test="currency-selector">
                      <Select
                        className={styles.eiCurrencySelect}
                        id="select-currency"
                        variant={SelectVariant.single}
                        aria-label="Select Input"
                        placeholderText={<>Select option</>}
                        isOpen={isCurrencySelectOpen}
                        onToggle={() => setIsCurrencySelectOpen(!isCurrencySelectOpen)}
                        onSelect={onCurrencySelect}
                        selections={selectedCurrencyOption}
                      >
                        {(globalState?.filterState?.originalFilterData?.currency || []).map((option, index) => (
                          <SelectOption key={`currency-${index}`} value={option} data-test={option} />
                        ))}
                      </Select>
                    </div>
                  }
                  reference={!isEmpty(globalState?.filterState?.originalFilterData) ? popoverRef : null}
                />
              </>
            </ToolbarItem>
            <ToolbarItem>
              <UserMenu />
            </ToolbarItem>
          </ToolbarGroup>
        </Toolbar>
      </MastheadContent>
    </Masthead>
  );
};

export default Header;
