import React, { useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Stack, StackItem, Text, TextVariants } from '@patternfly/react-core';
import { useGlobalContext } from '../../../../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../../../../contexts/GlobalContext/GlobalContextReducer';
import { useOptions } from '../../../../../hooks/useOptions';
import { FilterOption } from '../../../../../models/FilterOption';
import { VirtualizedSelect } from '../../../../VirtualizedSelect/VirtualizedSelect';
import styles from './PartnerFilter.module.scss';

const PartnerFilter: React.FC = () => {
  const { globalState, dispatch } = useGlobalContext();
  const [selectedDistributorParentOptions, setSelectedDistributorParentOptions] = useState<FilterOption[]>([]);
  const [selectedDistributorAccountOptions, setSelectedDistributorAccountOptions] = useState<FilterOption[]>([]);
  const [selectedPartnerParentOptions, setSelectedPartnerParentOptions] = useState<FilterOption[]>([]);
  const [selectedPartnerAccountOptions, setSelectedPartnerAccountOptions] = useState<FilterOption[]>([]);

  const [selectedIncumbentDistributorOption, setSelectedIncumbentDistributorOption] = useState<FilterOption[]>();
  const [selectedIncumbentPartnerOption, setSelectedIncumbentPartnerOption] = useState<FilterOption[]>();

  const incumbentDistributorOptions = useOptions(objectsToMap(globalState.filterState.originalFilterData['incumbent_distributor']));
  const incumbentPartnerOptions = useOptions(objectsToMap(globalState.filterState.originalFilterData['incumbent_partner']));

  // TODO - Refactor to use .reduce :] / make it easier to understand
  function objectsToMap(objects: Record<string, string>[]): Record<string, string[]> {
    let result = {};
    for (let obj of objects) {
      const values = Object.entries(obj);
      values.forEach(([_, val]) => {
        if (val) {
          result[val] = [val];
        }
      });
    }
    return result;
  }

  useEffect(() => {
    unstable_batchedUpdates(() => {
      if (globalState.lookupState.distributorParentLookup) {
        const lookups = globalState.lookupState;
        const selectedFilters = globalState?.filterState?.selectedFilterData;

        setSelectedDistributorParentOptions(
          selectedFilters?.distributorParent?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.distributorParentLookup
        );
        setSelectedDistributorAccountOptions(
          selectedFilters?.distributorAccount?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.distributorAccountLookup
        );
        setSelectedPartnerParentOptions(
          selectedFilters?.partnerParent?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.partnerParentLookup
        );
        setSelectedPartnerAccountOptions(
          selectedFilters?.partnerAccount?.map((selectedFilter) => {
            return { label: selectedFilter, value: selectedFilter };
          }) || lookups.partnerAccountLookup
        );

        setSelectedIncumbentDistributorOption(
          selectedFilters?.incumbentDistributor
            ? [{ label: selectedFilters?.incumbentDistributor, value: selectedFilters?.incumbentDistributor }]
            : [{ label: 'All', value: 'All' }]
        );
        setSelectedIncumbentPartnerOption(
          selectedFilters?.incumbentPartner
            ? [{ label: selectedFilters?.incumbentPartner, value: selectedFilters?.incumbentPartner }]
            : [{ label: 'All', value: 'All' }]
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lookups = globalState.lookupState;
    unstable_batchedUpdates(() => {
      if (globalState.filterState.isResetFilter) {
        setSelectedDistributorParentOptions(lookups.distributorParentLookup);
        setSelectedDistributorAccountOptions(lookups.distributorAccountLookup);
        setSelectedPartnerParentOptions(lookups.partnerParentLookup);
        setSelectedPartnerAccountOptions(lookups.partnerAccountLookup);
        setSelectedIncumbentDistributorOption([{ label: 'All', value: 'All' }]);
        setSelectedIncumbentPartnerOption([{ label: 'All', value: 'All' }]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState.filterState.isResetFilter]);

  const onDistributorParentSelect = (selection: FilterOption[]) => {
    const allSelected = selection.length === globalState.lookupState.distributorParentLookup.length;

    setSelectedDistributorParentOptions(selection);
    dispatch({
      type: StateActions.SetDistributorParent,
      payload: {
        distributorParent: allSelected ? undefined : selection?.map((it) => it.value),
      },
    });
  };

  const onDistributorAccountSelect = (selection: FilterOption[]) => {
    const allSelected = selection.length === globalState.lookupState.distributorAccountLookup.length;

    setSelectedDistributorAccountOptions(selection);
    dispatch({
      type: StateActions.SetDistributorAccount,
      payload: {
        distributorAccount: allSelected ? undefined : selection?.map((it) => it.value),
      },
    });
  };

  const onIncumbentDistributorSelect = (selection: FilterOption[]) => {
    setSelectedIncumbentDistributorOption(selection);
    dispatch({
      type: StateActions.SetIncumbentDistributor,
      payload: {
        incumbentDistributor: selection?.map((it) => it.value).toString(),
      },
    });
  };

  const onIncumbentPartnerSelect = (selection: FilterOption[]) => {
    setSelectedIncumbentPartnerOption(selection);
    dispatch({
      type: StateActions.SetIncumbentPartner,
      payload: {
        incumbentPartner: selection?.map((it) => it.value).toString(),
      },
    });
  };

  const onPartnerParentSelect = (selection: FilterOption[]) => {
    const allSelected = selection.length === globalState.lookupState.partnerParentLookup.length;

    setSelectedPartnerParentOptions(selection);
    dispatch({
      type: StateActions.SetPartnerParent,
      payload: {
        partnerParent: allSelected ? undefined : selection?.map((it) => it.value),
      },
    });
  };

  //Partner Account Filter actions

  const onPartnerAccountSelect = (selection: FilterOption[]) => {
    const allSelected = selection.length === globalState.lookupState.partnerAccountLookup.length;

    setSelectedPartnerAccountOptions(selection);
    dispatch({
      type: StateActions.SetPartnerAccount,
      payload: {
        partnerAccount: allSelected ? undefined : selection?.map((it) => it.value),
      },
    });
  };

  const dataIsLoaded = useMemo(() => {
    return (
      selectedDistributorAccountOptions &&
      selectedDistributorParentOptions &&
      selectedIncumbentDistributorOption &&
      selectedPartnerParentOptions &&
      selectedPartnerAccountOptions &&
      selectedIncumbentPartnerOption
    );
  }, [
    selectedDistributorAccountOptions,
    selectedDistributorParentOptions,
    selectedIncumbentDistributorOption,
    selectedIncumbentPartnerOption,
    selectedPartnerAccountOptions,
    selectedPartnerParentOptions,
  ]);

  return (
    <>
      {dataIsLoaded ? (
        <Stack hasGutter className={styles.eiFilterMenu}>
          <StackItem>
            <Text component={TextVariants.small} id="distributor-parent-select">
              Distributor Parent
            </Text>
            <VirtualizedSelect
              placeHolder={'Select Distributor Parent'}
              isMulti
              isSearchable
              remainOpenOnSelect={true}
              options={globalState.lookupState.distributorParentLookup}
              selections={selectedDistributorParentOptions}
              onChange={onDistributorParentSelect}
              dataTest="distributor-parent-filter"
            />
          </StackItem>
          <StackItem>
            <Text component={TextVariants.small} id="distributor-account-select">
              Distributor Account
            </Text>
            <VirtualizedSelect
              placeHolder={'Select Distributor Account'}
              isMulti
              isSearchable
              remainOpenOnSelect={true}
              options={globalState.lookupState.distributorAccountLookup}
              selections={selectedDistributorAccountOptions}
              onChange={onDistributorAccountSelect}
            />
          </StackItem>

          <StackItem>
            <Text component={TextVariants.small} id="incumbent-distributor-select">
              Incumbent Distributor
            </Text>
            <VirtualizedSelect
              ariaLabel={'Incumbent Distributor Select'}
              placeHolder={'Select Incumbent Distributor'}
              options={incumbentDistributorOptions}
              selections={selectedIncumbentDistributorOption}
              onChange={onIncumbentDistributorSelect}
            />
          </StackItem>

          <StackItem>
            <Text component={TextVariants.small} id="partner-parent-select">
              Partner Parent
            </Text>
            <VirtualizedSelect
              placeHolder={'Select Partner Parent'}
              isMulti
              isSearchable
              remainOpenOnSelect={true}
              options={globalState.lookupState.partnerParentLookup}
              selections={selectedPartnerParentOptions}
              onChange={onPartnerParentSelect}
              dataTest="partner-filter"
            />
          </StackItem>

          <StackItem>
            <Text component={TextVariants.small} id="partner-account-select">
              Partner Account
            </Text>
            <VirtualizedSelect
              placeHolder={'Select Partner Account'}
              options={globalState.lookupState.partnerAccountLookup}
              className={styles.eiSelectDropdown} // can pass a className in like this
              isMulti
              isSearchable
              remainOpenOnSelect={true}
              selections={selectedPartnerAccountOptions}
              onChange={onPartnerAccountSelect}
            />
          </StackItem>

          <StackItem>
            <Text component={TextVariants.small} id="incumbent-partner-select">
              Incumbent Partner
            </Text>
            <VirtualizedSelect
              ariaLabel={'Incumbent Partner Select'}
              placeHolder={'Select Incumbent Partner'}
              options={incumbentPartnerOptions}
              selections={selectedIncumbentPartnerOption}
              onChange={onIncumbentPartnerSelect}
            />
          </StackItem>
        </Stack>
      ) : null}
    </>
  );
};

export default PartnerFilter;
