import React, { useEffect, useState } from 'react';
import { Accordion, AccordionItem, AccordionToggle, AccordionContent } from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../api/apiCalls';
import TableWrapper from '../../components/TableWrapper';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../utils/filterHelper';
import RetentionPerformanceDetail from './RetentionPerformanceDetails';
import RetentionPerformanceSummary from './RetentionPerformanceSummary';
import styles from './RetentionPerformance.module.scss';

interface RetentionPerformanceProps {
  viewContext: string;
}

const RetentionPerformance: React.FC<RetentionPerformanceProps> = (props) => {
  const { globalState } = useGlobalContext();
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.RETENTION_PERFORMANCE_SUMMARY);
  const [retentionPerformanceData, setRetentionPerformanceData] = useState({} as any);
  const [responseError, setResponseError] = useState(null);
  const [expanded, setExpanded] = useState(['retention-performance-summary', 'retention-performance-details']);

  useEffect(() => {
    const getRetentionPerformanceDetails = async () => {
      try {
        const requestBody = {
          viewContext: props.viewContext,
        };

        const selectedFilterValues = getAllSelectedFilterValues(globalState, requestBody);
        const response = await request(selectedFilterValues);
        setRetentionPerformanceData(response);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
      }
    };

    if (((globalState || {}).filterState || {}).originalFilterData) {
      getRetentionPerformanceDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  const toggleRetentionPerformanceTabs = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  return (
    <div className={styles.eiRetentionPerformance}>
      <Accordion asDefinitionList={false} headingLevel="h2">
        <AccordionItem>
          <AccordionToggle
            data-test="retention-performance-summary-toggle"
            onClick={() => toggleRetentionPerformanceTabs('retention-performance-summary')}
            isExpanded={expanded.includes('retention-performance-summary')}
            id="retention-performance-summary"
          >
            Retention Performance Summary
          </AccordionToggle>
          <AccordionContent id="ex2-expand1" isHidden={!expanded.includes('retention-performance-summary')} isFixed tabIndex={0}>
            {retentionPerformanceData && (
              <RetentionPerformanceSummary
                responseError={responseError}
                summaryData={retentionPerformanceData?.customer_retention}
                isFetching={isFetching}
              />
            )}
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordionToggle
            data-test="retention-performance-detail-toggle"
            onClick={() => toggleRetentionPerformanceTabs('retention-performance-details')}
            isExpanded={expanded.includes('retention-performance-details')}
            id="retention-performance-details"
          >
            Retention Performance Detail
          </AccordionToggle>
          <AccordionContent id="ex2-expand2" isHidden={!expanded.includes('retention-performance-details')} isFixed tabIndex={0}>
            <TableWrapper>
              <RetentionPerformanceDetail viewContext={props.viewContext} />
            </TableWrapper>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default RetentionPerformance;
