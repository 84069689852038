import React from 'react';
import { Chart, ChartAxis, ChartBar, ChartGroup } from '@patternfly/react-charts';
import { Skeleton } from '@patternfly/react-core';
import styles from './DealManagementChart.module.scss';

interface DealManagementChartProps {
  dealManagement: any;
  dealManagementCategoryMappings: any;
  isFetching: boolean;
  viewContext: any;
}

const DealManagementChart: React.FC<DealManagementChartProps> = ({ dealManagement, dealManagementCategoryMappings, isFetching, viewContext }) => {
  const getChartDataValueOrZero = (chartValue) => {
    let returnValue = 0;

    if (chartValue && !isNaN(chartValue)) {
      returnValue = chartValue;
    }

    return returnValue;
  };

  return (
    <div className={styles.eiDealManagementChartContainer}>
      {isFetching ? (
        <Skeleton height={'320px'} screenreaderText={'Loading contents...'} width={'100%'} />
      ) : (
        <Chart
          ariaDesc="This is a visual representation of the table Performance Vs. Peers. It consists of bar charts showing original renewals, transferred in, transferred out, closed lost, renewed opportunities, and transferred internally."
          height={320}
          padding={{
            top: 64,
            right: 0,
            bottom: 24,
            left: 0,
          }}
          horizontal={true}
        >
          <ChartAxis axisValue={-2} dependentAxis tickCount={1} tickFormat={[]} tickValues={[0]} />
          <ChartAxis tickCount={1} tickFormat={[]} tickValues={[-1]} />
          <ChartGroup offset={63}>
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#8B8D8F' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.transferredInternally.name,
                  x: 3.1,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.transferredInternally.dealManagementCountColumnData]),
                },
              ]}
            />
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#317631' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.renewedOpportunities.name,
                  x: 2.1,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.renewedOpportunities.dealManagementCountColumnData]),
                },
              ]}
            />
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#C60011' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.closedLost.name,
                  x: 1.1,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.closedLost.dealManagementCountColumnData] * -1),
                },
              ]}
            />
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#C60011' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.transferredOut.name,
                  x: 0,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.transferredOut.dealManagementCountColumnData] * -1),
                },
              ]}
            />
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#71BF6F' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.transferredIn.name,
                  x: -1,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.transferredIn.dealManagementCountColumnData]),
                },
              ]}
            />
            <ChartBar
              barWidth={24}
              style={{ data: { fill: '#71BF6F' } }}
              data={[
                {
                  name: dealManagementCategoryMappings.originalRenewals.name,
                  x: -2,
                  y: getChartDataValueOrZero(dealManagement[dealManagementCategoryMappings.originalRenewals.dealManagementCountColumnData]),
                },
              ]}
            />
          </ChartGroup>
        </Chart>
      )}
    </div>
  );
};

export default DealManagementChart;
