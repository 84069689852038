export const currentPageLimit: number = 500;
export const defaultColumns: string[] = []; // defaultColumns is empty as all the columns can be selected/deselected.

export const tableSortColumnIndex = {
  1: 'opportunity_close_date',
  2: 'opportunity_number',
  3: 'reseller_partner_account_name',
  4: 'renewal_source_opportunity_po_number',
  5: 'account_name',
  6: 'risk',
  7: 'opportunity_forecast_category',
  8: 'opportunity_contract_number',
  9: 'est_opp_amount',
};
export const initialDistributorColumns: string[] = [
  'Close Date',
  'Opps #',
  'Partner Account',
  'PO #',
  'Customer Account',
  'Risk',
  'Forecast Category',
  'Contr #',
  'Opp Amount',
  // 'Action'
];

export const initialResellerColumns: string[] = [
  'Close Date',
  'Opps #',
  'Partner Account',
  'PO #',
  'Customer Account',
  'Risk',
  'Forecast Category',
  'Contr #',
  'Est. Opp Amount',
  // 'Action'
];

export const distributorColumnDetails = {
  opportunity_close_date: {
    columnName: 'Close Date',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  opportunity_number: {
    columnName: 'Opps #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  reseller_partner_account_name: {
    columnName: 'Partner Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  renewal_source_opportunity_po_number: {
    columnName: 'PO #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 5,
    formattable: false,
  },
  risk: {
    columnName: 'Risk',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: false,
  },
  opportunity_forecast_category: {
    columnName: 'Forecast Category',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
  },
  opportunity_contract_number: {
    columnName: 'Contr #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
  },
  est_opp_amount: {
    columnName: 'Opp Amount',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 9,
    formattable: true,
  },
  // action:{
  //   columnName:'Action',
  //   modifier:'nowrap',
  //   isSortable:false,
  //   formattable:false,
  //   isActionColumn:true
  // }
};
export const resellerColumnDetails = {
  opportunity_close_date: {
    columnName: 'Close Date',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  opportunity_number: {
    columnName: 'Opps #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  reseller_partner_account_name: {
    columnName: 'Partner Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 3,
    formattable: false,
  },
  renewal_source_opportunity_po_number: {
    columnName: 'PO #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 5,
    formattable: false,
  },
  risk: {
    columnName: 'Risk',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: false,
  },
  opportunity_forecast_category: {
    columnName: 'Forecast Category',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 7,
    formattable: false,
  },
  opportunity_contract_number: {
    columnName: 'Contr #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: false,
  },
  est_opp_amount: {
    columnName: 'Est. Opp Amount',
    modifier: 'wrap',
    isSortable: true,
    sortParam: 9,
    formattable: true,
  },
  // action:{
  //   columnName:'Action',
  //   modifier:'nowrap',
  //   isSortable:false,
  //   formattable:false,
  //   isActionColumn:true
  // }
};
