import React from 'react';
import { Button, Modal, ModalVariant, Split, SplitItem } from '@patternfly/react-core';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-circle-icon';
import { ReactComponent as FiltersModified } from '../../../../assets/filters-modified.svg';
import { useDefinitionsContext } from '../../../../contexts/Definitions/DefinitionsContext';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import routeHelper from '../../../../utils/routeHelper';
import Breadcrumbs from './Breadcrumbs';
import Definitions from './Definitions';
import styles from './BreadcrumbsAndDefinitions.module.scss';

interface BreadcrumbsAndDefinitionsProps {
  currentPath: any;
  definitions: any;
}

const BreadcrumbsAndDefinitions: React.FC<BreadcrumbsAndDefinitionsProps> = ({ currentPath, definitions }) => {
  const { globalState } = useGlobalContext();
  const currentRouteObject = routeHelper.getRouteObjectFromPath(currentPath);

  const { isModalOpen, setIsModalOpen, alternateDefinitions, setAlternateDefinitions, alternateDefinitionsTitle, setAlternateDefinitionsTitle } =
    useDefinitionsContext();

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
    setAlternateDefinitions(null);
    setAlternateDefinitionsTitle(null);
  };

  const renderModal = () => {
    return (
      <Modal
        actions={[
          <Button key="Close" variant="primary" onClick={handleModalToggle}>
            Close
          </Button>,
        ]}
        isOpen={isModalOpen}
        onClose={handleModalToggle}
        /* the title calculation below is as follows: use the alternative definitions title if available, otherwise, use the currentRoute
        link text unless that link text is 'Overview'. If the link text is 'Overview' then we must also display the group id as well to
        indicate which OverView page definitions are being viewed since there will be three different overview pages */
        title={`${
          alternateDefinitionsTitle !== null
            ? alternateDefinitionsTitle
            : currentRouteObject?.linkText === 'Overview'
            ? `${routeHelper.getGroupRouteObjectFromGroupName(currentRouteObject?.groupId)?.linkText} ${currentRouteObject?.linkText}`
            : currentRouteObject?.linkText
        } Definitions`}
        variant={ModalVariant.medium}
        data-test="definitions-modal"
        tabIndex={0}
      >
        <Definitions
          definitions={alternateDefinitions ? alternateDefinitions : definitions}
          setAlternateDefinitions={setAlternateDefinitions}
          setAlternateDefinitionsTitle={setAlternateDefinitionsTitle}
        />
      </Modal>
    );
  };

  return (
    <Split className={styles.eiBreadcrumbsAndDefinitionsSplit}>
      <SplitItem>
        <Breadcrumbs currentPath={currentPath} />
      </SplitItem>
      <SplitItem isFilled></SplitItem>
      <SplitItem className={styles.eiBreadcrumbsAndDefinitionsButtonContainer}>
        {globalState?.filterState?.isApplyFilter?.valueOf() && (
          <Button data-test="filters-modified" className={styles.eiFiltersModifiedButton} key="filtersModified" onClick={null} variant="plain">
            <FiltersModified className={styles.eiFiltersModifiedIcon} />
            Filters Modified
          </Button>
        )}
        {definitions !== null && definitions.length > 0 && (
          <Button data-test="definitions" icon={<InfoCircleIcon />} key="definitions" onClick={handleModalToggle} variant="link">
            Definitions
          </Button>
        )}
        {renderModal()}
      </SplitItem>
    </Split>
  );
};

export default BreadcrumbsAndDefinitions;
