import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Skeleton, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { cloneDeep } from 'lodash';
import { fetchDataPostRequest } from '../../../api/apiCalls';
import EcoIntelEmptyState from '../../../components/EcoIntelEmptyState';
import { errorStateSummaryDetails } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import numberHelper from '../../../utils/numberHelper';
import TopAndBottomPerformersSummaryChart from './TopAndBottomPerformersSummaryChart';
import styles from './TopAndBottomPerformersSummary.module.scss';

interface TopAndBottomPerformersSummaryProps {
  viewContext: string;
}

const TopAndBottomPerformersSummary: React.FC<TopAndBottomPerformersSummaryProps> = ({ viewContext }) => {
  //#region VARIABLES
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.TOP_AND_BOTTOM_PERFORMERS);
  const [topAndBottomPerformersData, setTopAndBottomPerformersData] = useState(null);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();
  // Comment this back in if dropdown turned back on
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // Remove disable next line if dropdown turned back on
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSummaryCategory, setSelectedSummaryCategory] = useState('Down-Sell');
  const [cumulativeTotal, setCumulativeTotal] = useState(0);
  const [cumulativeRunningTotals, setCumulativeRunningTotals] = useState<number[]>([]);

  const summaryDropdownCategories = {
    'Down-Sell': { displayName: 'Down-Sell', dataCategory: 'down_sell' },
    /* commented out until the Product Owners figure out how other options should behave */
    // 'Up-Sell': {displayName: 'Up-Sell', dataCategory: 'up_sell'},
    // 'Cross-Sell': {displayName: 'Cross-Sell', dataCategory: 'cross_sell'},
  };
  //#endregion

  //#region HELPER FUNCTIONS
  // Comment this back in if dropdown turned on
  /*
  const onDropdownFocus = () => {
    const element = document.getElementById('toggle-selected-summary-category');
    element.focus();
  };

  const onDropdownSelect = (event) => {
    const selectedDropdownItem = event.target.text;
    setIsDropdownOpen(false);

    //avoid triggering a sever hit if the user selects the already selected category
    if (event.target.text !== selectedSummaryCategory) {
      setSelectedSummaryCategory(selectedDropdownItem);
    }

    onDropdownFocus();
  };*/

  const getCumulateValueByCategory = (category) => {
    let data = topAndBottomPerformersData?.top_bottom_performers_details?.data;
    let categoryCumulativeTotal = 0;

    if (data && data.length > 0) {
      data.forEach((detail) => {
        categoryCumulativeTotal += detail[summaryDropdownCategories[category].dataCategory];
      });
    }

    return categoryCumulativeTotal;
  };

  const getRunningCumulateValuesByCategoryAcrossAccounts = (category) => {
    let data = cloneDeep(topAndBottomPerformersData?.top_bottom_performers_details?.data);
    let runningCategoryCumulativeTotal = 0;
    const chartData = [];

    if (data && data.length > 0) {
      data.forEach((detail, index) => {
        runningCategoryCumulativeTotal += detail[summaryDropdownCategories[category].dataCategory];
        Object.assign(detail, { cumulativeCategoryTotal: runningCategoryCumulativeTotal });
        chartData.push({
          name: viewContext === finderPartnerTypes.DISTRIBUTOR ? detail.reseller_partner_account_name : detail.account_name,
          incremental: detail[summaryDropdownCategories[category].dataCategory],
          x: index + 1,
          y: runningCategoryCumulativeTotal,
        });
      });
    }

    return chartData;
  };
  //#endregion

  //#region DATA FETCH
  useEffect(() => {
    requestTopAndBottomPerformersData(request);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter, selectedSummaryCategory]);

  useEffect(() => {
    setCumulativeTotal(getCumulateValueByCategory(selectedSummaryCategory));
    setCumulativeRunningTotals(getRunningCumulateValuesByCategoryAcrossAccounts(selectedSummaryCategory));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topAndBottomPerformersData]);

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  const requestTopAndBottomPerformersData = async (requestData) => {
    /* reset the state variables once new data is being requested */
    setResponseError(null);
    setTopAndBottomPerformersData(null);
    setCumulativeTotal(0);
    try {
      let selectedFilterValues = {};
      const params = {
        fetch_id: 0,
        page_limit: 100,
        order_by: summaryDropdownCategories[selectedSummaryCategory].dataCategory,
        sort_by: 'desc',
        viewContext: viewContext, //view context may be added here
      };

      selectedFilterValues = getAllSelectedFilterValues(globalState, params);

      const response = await requestData(selectedFilterValues);
      setTopAndBottomPerformersData(response);
      setResponseError(null);
    } catch (error) {
      setResponseError(error.message);
    }
  };
  //#endregion

  //#region RENDER FUNCTIONS
  const renderChartHeader = (category: string, cumulativeValue: number) => {
    return (
      <>
        {isFetching ? (
          <Skeleton data-test="skeleton" height={'38px'} screenreaderText={'Loading contents...'} width={'260px'} />
        ) : (
          <TextContent className={styles.eiChartHeaderContent}>
            <Text component={TextVariants.p} className={styles.eiChartHeaderContentItemHeading}>
              {numberHelper.formatAsCompactDollarAmount(cumulativeValue, currencySelector)}
            </Text>
            <Text component={TextVariants.p} className={styles.eiChartHeaderContentItem}>
              {category} Amount
            </Text>
          </TextContent>
        )}
      </>
    );
  };

  // Commenting out to fix linter issue - comment back in if dropdown is turned back on
  /* const renderDropdown = (categories) => {
    const dropdownItems = [];

    for (let category in summaryDropdownCategories) {
      dropdownItems.push(
        <DropdownItem key={category}>{categories[category].displayName}</DropdownItem>
      )
    }

    return (
      <>
        {
          isFetching ?
            <Skeleton height={'38px'} screenreaderText={"Loading contents..."} width={'130px'} />
            :
            <Dropdown
              dropdownItems={dropdownItems}
              isOpen={isDropdownOpen}
              onSelect={onDropdownSelect}
              toggle={
                <DropdownToggle id='toggle-selected-summary-category' onToggle={(isOpen: boolean) => { setIsDropdownOpen(isOpen) }}>
                  {summaryDropdownCategories[selectedSummaryCategory].displayName}
                </DropdownToggle>
              }
            />
        }
      </>
    )
  }*/
  //#endregion

  return (
    <>
      {!responseError && (
        <Card className={styles.eiTopAndBottomPerformersSummary}>
          <CardTitle className={styles.eiTopAndBottomPerformersSummaryContainer}>
            {renderChartHeader(summaryDropdownCategories[selectedSummaryCategory].displayName, cumulativeTotal)}
            {/* Commented for now since there is only one option in the dropdown
            {renderDropdown(summaryDropdownCategories)} */}
          </CardTitle>
          <CardBody>
            {isFetching ? (
              <Skeleton data-test="skeleton" height={'375px'} screenreaderText={'Loading contents...'} width={'100%'} />
            ) : (
              <TopAndBottomPerformersSummaryChart
                cumulativeRunningTotals={cumulativeRunningTotals}
                cumulativeTotal={cumulativeTotal}
                selectedSummaryCategoryDisplayName={summaryDropdownCategories[selectedSummaryCategory].displayName}
                viewContext={viewContext}
              />
            )}
          </CardBody>
        </Card>
      )}
      {responseError && (
        <Card className={styles.eiTopAndBottomPerformersSummary}>
          <CardBody>
            <EcoIntelEmptyState emptyStateProps={errorStateSummaryDetails} />
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default TopAndBottomPerformersSummary;
