import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle } from '@patternfly/react-core';
import TableWrapper from '../../components/TableWrapper';
import PipelineByAccountDetail from './PipelineByAccountDetail';
import PipelineByAccountSummary from './PipelineByAccountSummary';
import styles from './PipelineByAccount.module.scss';

interface PipelineByAccountProps {
  viewContext: string;
}

const PipelineByAccount: React.FC<PipelineByAccountProps> = ({ viewContext }) => {
  const [expanded, setExpanded] = useState(['pipeline-by-account-summary', 'pipeline-by-account-detail']);

  const toggle = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  return (
    <div className={styles.eiPipelineByAccount}>
      <Accordion asDefinitionList={false} headingLevel="h2">
        <AccordionItem>
          <AccordionToggle
            data-test="pipeline-by-account-summary-toggle"
            onClick={() => toggle('pipeline-by-account-summary')}
            isExpanded={expanded.includes('pipeline-by-account-summary')}
            id="pipeline-by-account-summary"
          >
            Pipeline by Account Summary
          </AccordionToggle>
          <AccordionContent id="pipeline-by-account-summary-expand" isHidden={!expanded.includes('pipeline-by-account-summary')} isFixed>
            <PipelineByAccountSummary viewContext={viewContext} />
          </AccordionContent>
        </AccordionItem>
        <AccordionItem>
          <AccordionToggle
            data-test="pipeline-by-account-detail-toggle"
            onClick={() => toggle('pipeline-by-account-detail')}
            isExpanded={expanded.includes('pipeline-by-account-detail')}
            id="pipeline-by-account-detail"
          >
            Pipeline by Account Detail
          </AccordionToggle>
          <AccordionContent id="pipeline-by-account-detail-expand" isHidden={!expanded.includes('pipeline-by-account-detail')} isFixed>
            {/* // Render table as a child of table context provider */}
            <TableWrapper>
              <PipelineByAccountDetail viewContext={viewContext} />
            </TableWrapper>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default PipelineByAccount;
