import React from 'react';
import { ChartDonutUtilization, ChartLabel } from '@patternfly/react-charts';
import { Card, CardBody, CardTitle, Skeleton, Split, SplitItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import pageRoutes from '../../../utils/pageRoutes';
import styles from './RetentionPerformanceOverview.module.scss';

interface RetentionPerformanceOverviewProps {
  customerRetention: any;
  getCardTitleContent: Function;
  isFetching: boolean;
}

const RetentionPerformanceOverview: React.FC<RetentionPerformanceOverviewProps> = ({ customerRetention, getCardTitleContent, isFetching }) => {
  const renderDonutChart = () => {
    return (
      <div style={{ height: '150px', marginRight: '45px', width: '150px' }}>
        <ChartDonutUtilization
          ariaDesc={`Donut chart showing ${
            customerRetention?.gross_rev_retention_percent !== undefined && customerRetention?.gross_rev_retention_percent !== null
              ? customerRetention?.gross_rev_retention_percent
              : '0'
          }% gross revenue retention`}
          colorScale={['#71BF6F', '#F0F0F0']}
          constrainToVisibleArea={false}
          data={{ x: 'Gross Revenue Retention', y: customerRetention?.gross_rev_retention_percent }}
          height={150}
          innerRadius={70}
          labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
          padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
          subTitle={'\nGross Revenue\nRetention'}
          subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
          title={`${
            customerRetention?.gross_rev_retention_percent !== undefined && customerRetention?.gross_rev_retention_percent !== null
              ? customerRetention?.gross_rev_retention_percent
              : ''
          }%`}
          titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
          width={150}
        />
      </div>
    );
  };

  return (
    <Card className={styles.eiRetentionPerformanceOverviewContainer}>
      <CardTitle className={styles.eiTitle}>
        {getCardTitleContent(pageRoutes.retentionPerformanceUrl.path, pageRoutes.retentionPerformanceUrl.linkText)}
      </CardTitle>
      <CardBody>
        <Split className={styles.eiChartAndTextContainer}>
          <SplitItem className={styles.eiChartAndTextContainerSplitItem}>
            {isFetching ? (
              <Skeleton height={'150px'} screenreaderText={'Loading contents...'} shape={'circle'} style={{ marginRight: '45px' }} width={'150px'} />
            ) : (
              renderDonutChart()
            )}
          </SplitItem>
          <SplitItem className={styles.eiChartAndTextContainerSplitItem}>
            <TextContent>
              <Text className={styles.eiCardContent} component={TextVariants.p}>
                Understand revenue retention (and growth) and see which customers contracted vs. expanded.
              </Text>
            </TextContent>
          </SplitItem>
        </Split>
      </CardBody>
    </Card>
  );
};

export default RetentionPerformanceOverview;
