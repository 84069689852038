import React, { useEffect } from 'react';
import PlaceholderPageContent from '../../components/PlaceholderPageContent';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../contexts/GlobalContext/GlobalContextReducer';
import pageRoutes from '../../utils/pageRoutes';

interface CrossSellProbabilitiesProps {
  viewContext: string;
}

const CrossSellProbabilities: React.FC<CrossSellProbabilitiesProps> = ({ viewContext }) => {
  const { globalState, dispatch } = useGlobalContext();

  /*
  The below useEffect should be removed when you make an API call to display the data to the page.
  Currently it is not developed hence we need to make isFiltersInProgress flag to false manually.
  Note: If your page is not having any API call and yet you are going to show the filters, you need this useEffect.
  */
  useEffect(() => {
    if (globalState.filterState.isFiltersInProgress) {
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: { ...globalState.filterState, isFiltersInProgress: false },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter]);

  return <PlaceholderPageContent pageTitle={pageRoutes.crossSellProbabilities.linkText} />;
};

export default CrossSellProbabilities;
