import React from 'react';
import { ChartDonutUtilization, ChartLabel, Chart, ChartThemeColor, ChartAxis, ChartGroup, ChartBar } from '@patternfly/react-charts';
import { Card, CardBody, SplitItem, Split, Stack, StackItem, Skeleton } from '@patternfly/react-core';
import EcoIntelEmptyState from '../../../components/EcoIntelEmptyState';
import { errorStateSummaryDetails } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { generateBarChartAxisTickValues } from '../../../utils/chartHelper';
import numberHelper from '../../../utils/numberHelper';
import styles from './RetentionPerformanceSummary.module.scss';

interface RetentionPerformanceSummaryProps {
  summaryData: any;
  isFetching: boolean;
  responseError: any;
}

const RetentionPerformanceSummary: React.FC<RetentionPerformanceSummaryProps> = (props) => {
  return (
    <Card className={styles.eiRetentionPerformanceSummary}>
      <CardBody className={styles.eiRetentionSummaryCardBody}>
        {props.responseError ? (
          <EcoIntelEmptyState emptyStateProps={errorStateSummaryDetails} />
        ) : (
          <Split className={styles.eiRetentionSummarySplit}>
            <SplitItem className={styles.eiRetentionSummaryDonutChart}>
              <Stack>
                <StackItem>
                  <Split className={styles.eiRetentionChart}>
                    <SplitItem>
                      <div className={styles.eiChartsInRow}>
                        {props.isFetching ? (
                          <Skeleton data-test="skeleton" shape="circle" width="100%" screenreaderText="Loading small circle contents" />
                        ) : (
                          <ChartDonutUtilization
                            data-test="customer-retention-performance-summary-chart"
                            ariaDesc={`Donut chart illustrating ${props.summaryData?.customer_retention_percent}% customer retention`}
                            padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                            constrainToVisibleArea={false}
                            data={{
                              x: 'Customer Retention',
                              y: props.summaryData?.customer_retention_percent >= 100 ? 100 : props.summaryData?.customer_retention_percent,
                            }}
                            height={120}
                            innerRadius={54}
                            labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
                            subTitle={'Customer \n Retention'}
                            colorScale={['#71BF6F', '#F0F0F0']}
                            title={props.summaryData?.customer_retention_percent + '% \n'}
                            width={120}
                            titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                            subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                          />
                        )}
                      </div>
                    </SplitItem>
                    <SplitItem>
                      <div className={styles.eiChartsInRow}>
                        {props.isFetching ? (
                          <Skeleton data-test="skeleton" shape="circle" width="100%" screenreaderText="Loading small circle contents" />
                        ) : (
                          <ChartDonutUtilization
                            data-test="gross-revenue-retention-performance-summary-chart"
                            ariaDesc={`Donut chart illustrating ${
                              props.summaryData?.gross_rev_retention_percent >= 100 ? 100 : props.summaryData?.gross_rev_retention_percent
                            }% gross revenue retention`}
                            padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                            constrainToVisibleArea={false}
                            data={{
                              x: 'Gross Revenue Retention',
                              y: props.summaryData?.gross_rev_retention_percent >= 100 ? 100 : props.summaryData?.gross_rev_retention_percent,
                            }}
                            height={120}
                            innerRadius={54}
                            labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
                            subTitle={'Gross Revenue \n Retention'}
                            colorScale={['#71BF6F', '#F0F0F0']}
                            title={props.summaryData?.gross_rev_retention_percent + '% \n'}
                            width={120}
                            titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                            subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                          />
                        )}
                      </div>
                    </SplitItem>
                  </Split>
                </StackItem>
                <StackItem>
                  <div style={{ height: '120px', width: '120px' }} className={styles.eiNetRevenueRetentionRateChart}>
                    {props.isFetching ? (
                      <Skeleton data-test="skeleton" shape="circle" width="100%" screenreaderText="Loading small circle contents" />
                    ) : (
                      <ChartDonutUtilization
                        data-test="net-revenue-retention-rate-retention-performance-summary-chart"
                        ariaDesc={`Donut chart illustrating ${props.summaryData?.net_rev_retention_percent}% net revenue retention rate`}
                        padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
                        constrainToVisibleArea={false}
                        data={{
                          x: 'Net Revenue Retention Rate',
                          y: props.summaryData?.net_rev_retention_percent >= 100 ? 100 : props.summaryData?.net_rev_retention_percent,
                        }}
                        height={120}
                        innerRadius={54}
                        labels={({ datum }) => (datum.x ? `${datum.x}: ${datum.y}%` : null)}
                        subTitle={'Net Revenue \n Retention Rate'}
                        colorScale={['#317631', '#F0F0F0']}
                        title={props.summaryData?.net_rev_retention_percent + '% \n'}
                        width={120}
                        titleComponent={<ChartLabel style={[{ fontSize: 24, fill: '#151515', display: 'block', margin: '10px' }]} />}
                        subTitleComponent={<ChartLabel style={[{ fontSize: 12, fill: '#151515', padding: '10px' }]} />}
                      />
                    )}
                  </div>
                </StackItem>
              </Stack>
            </SplitItem>
            <SplitItem className={styles.eiRetentionSummaryBarChart}>
              <div style={{ height: '320px', width: '750px' }}>
                {props.isFetching ? (
                  <Skeleton data-test="skeleton" height={'300px'} screenreaderText={'Loading contents...'} width={'100%'} />
                ) : (
                  <>
                    {props.summaryData && (
                      <Chart
                        data-test="retention-performance-summary-bar-chart"
                        ariaDesc={`Bar chart illustrating ${props.summaryData?.retained_customers} retained customers, ${props.summaryData?.lost_customers} lost customers, ${props.summaryData?.churn_customers} customers with churn, ${props.summaryData?.expansion_customers} customers with expansion, and ${props.summaryData?.no_change_customers} customers with no change on one axis against currency values`}
                        domainPadding={{ x: [30, 25] }}
                        height={320}
                        padding={{
                          bottom: 70, // Adjusted to accommodate legend
                          left: 250,
                          right: 50, // Adjusted to accommodate tooltip
                          top: 30,
                        }}
                        themeColor={ChartThemeColor.multiOrdered}
                        width={750}
                      >
                        <ChartAxis
                          key="retention-summary-bar-x-axis"
                          style={{
                            tickLabels: {
                              fontWeight: 500,
                              fill: '#151515',
                              fontSize: '14px',
                            },
                            ticks: {
                              stroke: '#D2D2D2',
                              strokeWidth: '2.5px',
                              strokeOpacity: '1',
                              strokeLinecap: 'square',
                              strokeLinejoin: 'unset',
                            },
                          }}
                          tickLabelComponent={<ChartLabel dx={-70} x={250} />}
                        />
                        <ChartAxis
                          key="retention-summary-bar-y-axis"
                          dependentAxis
                          tickValues={generateBarChartAxisTickValues([
                            props.summaryData?.no_change_customers,
                            props.summaryData?.expansion_customers,
                            -props.summaryData?.lost_customers,
                            -props.summaryData?.churn_customers,
                            props.summaryData?.retained_customers,
                          ])}
                          tickFormat={(t) => {
                            if (t === 0) {
                              return t;
                            } else {
                              return `${Math.round(t) / 1000}k`;
                            }
                          }}
                        />
                        <ChartGroup offset={1} horizontal>
                          <ChartBar
                            key="no-change-customers-chart-bar"
                            barWidth={24}
                            colorScale={['#71BF6F']}
                            data={[
                              {
                                name: 'E',
                                x: 'Customers with No Change',
                                y: props?.summaryData?.no_change_customers,
                              },
                            ]}
                            labels={({ datum }) => numberHelper.formatAsNumberWithComma(datum.y)}
                            labelComponent={<ChartLabel y={222} />}
                          />
                          <ChartBar
                            key="expansion-customers-chart-bar"
                            barWidth={24}
                            colorScale={['#71BF6F']}
                            data={[
                              {
                                name: 'D',
                                x: 'Customers with Expansion',
                                y: props?.summaryData?.expansion_customers,
                              },
                            ]}
                            labels={({ datum }) => numberHelper.formatAsNumberWithComma(datum.y)}
                            labelComponent={<ChartLabel y={180} />}
                          />
                          <ChartBar
                            key="churn-customers-chart-bar"
                            barWidth={24}
                            colorScale={['#C60011']}
                            data={[
                              {
                                name: 'C',
                                x: 'Customers with Churn',
                                y: -props?.summaryData?.churn_customers,
                              },
                            ]}
                            labels={({ datum }) => numberHelper.formatAsNumberWithComma(datum.y)}
                            labelComponent={<ChartLabel y={137} style={{ fill: '#9C0003' }} />}
                          />
                          <ChartBar
                            key="lost-customers-chart-bar"
                            barWidth={24}
                            colorScale={['#C60011']}
                            data={[
                              {
                                name: 'B',
                                x: 'Lost Customers',
                                y: -props?.summaryData?.lost_customers,
                              },
                            ]}
                            labels={({ datum }) => numberHelper.formatAsNumberWithComma(datum.y)}
                            labelComponent={<ChartLabel y={95} style={{ fill: '#9C0003' }} />}
                          />
                          <ChartBar
                            key="retained-customers-chart-bar"
                            barWidth={24}
                            colorScale={['#71BF6F']}
                            data={[
                              {
                                name: 'A',
                                x: 'Retained Customers',
                                y: props?.summaryData?.retained_customers,
                              },
                            ]}
                            labels={({ datum }) => numberHelper.formatAsNumberWithComma(datum.y)}
                            labelComponent={<ChartLabel y={53} />}
                          />
                        </ChartGroup>
                      </Chart>
                    )}
                  </>
                )}
              </div>
            </SplitItem>
          </Split>
        )}
      </CardBody>
    </Card>
  );
};

export default RetentionPerformanceSummary;
