export const currentPageLimit: number = 500;

export const initialDistributorColumns: string[] = [
  'Opp #',
  'Customer Account',
  'Down-Sell',
  'Renewal @ Par',
  'Up-Sell',
  'Cross-Sell',
  'Performance',
];

export const initialResellerColumns: string[] = [
  'Opp #',
  'Customer Account',
  'Est. Down-Sell',
  'Est. Renewal @ Par',
  'Est. Up-Sell',
  'Est. Cross-Sell',
  'Est. Performance',
];

export const defaultColumns: string[] = []; // defaultColumns is empty as all the columns can be selected/deselected.
export const distributorColumnDetails = {
  opportunity_number: {
    columnName: 'Opp #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  down_sell: {
    columnName: 'Down-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: true,
  },
  renewal_at_par: {
    columnName: 'Renewal @ Par',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  up_sell: {
    columnName: 'Up-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  cross_sell: {
    columnName: 'Cross-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  performance: {
    columnName: 'Performance',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: true,
  },
  renewal_available: {
    columnName: 'Renewal Available',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: true,
  },
  total_opp_amount: {
    columnName: 'Total Opp Amount',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 9,
    formattable: true,
  },
  on_time: {
    columnName: 'On-Time',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 10,
    formattable: true,
  },
};

export const resellerColumnDetails = {
  opportunity_number: {
    columnName: 'Opp #',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 1,
    formattable: false,
  },
  account_name: {
    columnName: 'Customer Account',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 2,
    formattable: false,
  },
  down_sell: {
    columnName: 'Est. Down-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 3,
    formattable: true,
  },
  renewal_at_par: {
    columnName: 'Est. Renewal @ Par',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 4,
    formattable: true,
  },
  up_sell: {
    columnName: 'Est. Up-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 5,
    formattable: true,
  },
  cross_sell: {
    columnName: 'Est. Cross-Sell',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 6,
    formattable: true,
  },
  performance: {
    columnName: 'Est. Performance',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 7,
    formattable: true,
  },
  renewal_available: {
    columnName: 'Est. Renewal Available',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 8,
    formattable: true,
  },
  total_opp_amount: {
    columnName: 'Est. Total Opp Amount',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 9,
    formattable: true,
  },
  on_time: {
    columnName: 'Est. On-Time',
    modifier: 'nowrap',
    isSortable: true,
    sortParam: 10,
    formattable: true,
  },
};

export const tableSortColumnIndex = {
  1: 'opportunity_number',
  2: 'account_name',
  3: 'down_sell',
  4: 'renewal_at_par',
  5: 'up_sell',
  6: 'cross_sell',
  7: 'performance',
  8: 'renewal_available',
  9: 'total_opp_amount',
  10: 'on_time',
};
