import React, { useEffect } from 'react';
import { Button } from '@patternfly/react-core';
import { AngleLeftIcon, AngleRightIcon } from '@patternfly/react-icons';
import { css } from '@patternfly/react-styles';
import styles from '@patternfly/react-styles/css/components/CalendarMonth/calendar-month';
import dateHelper from '../../../utils/dateHelper';
import customStyles from '../MonthPicker.module.scss';

export interface CalendarInlineProps {
  /** Component wrapping the calendar month when used inline. Recommended to be 'article'. */
  component?: keyof JSX.IntrinsicElements;
  /** Title of the calendar rendered above the inline calendar month. Recommended to be a 'title' component. */
  title?: React.ReactNode;
  /** Id of the accessible label of the calendar month. Recommended to map to the title. */
  ariaLabelledby?: string;
}

/** Additional properties that extend from and can be passed to the main component. These
 * properties allow customizing the calendar formatting and aria-labels.
 */
export interface CalendarFormat {
  /** Accessible label for the previous month button. */
  prevMonthAriaLabel?: string;
  /** Accessible label for the next month button. */
  nextMonthAriaLabel?: string;
  /** Which date to start range styles from. */
  rangeStart?: Date;
  /** Props used to ensure accessibility when displaying the calendar month inline. */
  inlineProps?: CalendarInlineProps;
}

export interface CalendarProps extends CalendarFormat, Omit<React.HTMLProps<HTMLDivElement>, 'onChange'> {
  /** Additional classes to add to the outer div of the calendar month. */
  className?: string;
  /** Month/year to base other dates around. */
  date: { month?: number; year?: number };
  /** Flag to set browser focus on the passed date. **/
  isDateFocused?: boolean;
  /** Callback when date is selected. */
  onChange?: (newValue: { month: number; year: number }) => void;
  /** Callback when month or year is changed. */
  onMonthChange?: (newDate?: string, event?: React.MouseEvent | React.ChangeEvent | React.FormEvent<HTMLInputElement>) => void;
  /** Range of months to display */
  months: string[];
  /** Range of years to display */
  years?: number[];
}

/** The main calendar month component. */
const Calendar = ({
  date: dateProp,
  onChange,
  className,
  onMonthChange = () => {},
  rangeStart,
  prevMonthAriaLabel = 'Previous month',
  nextMonthAriaLabel = 'Next month',
  isDateFocused = false,
  inlineProps,
  months,
  years,
  ...props
}: CalendarProps) => {
  // eslint-disable-next-line prefer-const
  const [focusedDate, setFocusedDate] = React.useState<Date>(null);
  const [currentYear, setCurrentYear] = React.useState(dateProp.year || new Date().getFullYear());
  const focusRef = React.useRef<HTMLButtonElement>();
  const [shouldFocus, setShouldFocus] = React.useState(false);

  useEffect(() => {
    // Calendar month should not be focused on page load
    // Datepicker should place focus in calendar month when opened
    if ((shouldFocus || isDateFocused) && focusRef.current) {
      focusRef.current.focus();
    } else {
      setShouldFocus(true);
    }
  }, [focusedDate, isDateFocused, focusRef, shouldFocus]);

  const onYearClick = (newDate: number, ev: React.MouseEvent) => {
    setFocusedDate(new Date(`${newDate} ${currentYear}`));
    setCurrentYear(newDate);
    setShouldFocus(false);
    onMonthChange(`${newDate} ${currentYear}`, ev);
  };

  const getPrevYear = (currentYear: number): number => {
    const yearIndex = years.indexOf(currentYear);
    if (yearIndex === -1 || yearIndex + 1 > years.length) {
      return years[years.length - 1];
    }
    return years[yearIndex + 1];
  };

  const getNextYear = (currentYear: number): number => {
    const yearIndex = years.indexOf(currentYear);
    if (!currentYear) {
      return years[yearIndex - 1];
    }
    if (yearIndex === -1 || yearIndex + 1 < 0) {
      return years[0];
    }
    return years[yearIndex - 1];
  };

  const isNextDisabled = (currentYear: number): boolean => {
    if (!currentYear) {
      return true;
    }

    if (!years) {
      return false;
    }

    const yearIndex = years.indexOf(currentYear);
    if (yearIndex + 1 >= years.length) {
      return true;
    }
    return false;
  };

  const isPrevDisabled = (currentYear: number): boolean => {
    if (!currentYear) {
      return true;
    }

    if (!years) {
      return false;
    }

    const yearIndex = years.indexOf(currentYear);
    if (yearIndex === 0) {
      return true;
    }
    return false;
  };

  const prevYear = years ? getPrevYear(currentYear) : currentYear + 1;
  const nextYear = years ? getNextYear(currentYear) : currentYear - 1;

  const isSelected = (month: number): boolean => {
    return dateProp.month === month;
  };

  const onClick = (month: string) => {
    const date = new Date(`${month} ${currentYear}`);
    setFocusedDate(date);
    onChange && onChange({ month: dateHelper['abbreviatedMonthIndexLookup'][month], year: currentYear });
    setShouldFocus(false);
  };

  const renderCalendar = (
    <div className={css(styles.calendarMonth, className)} {...props}>
      <div className={customStyles.eiCalendarHeader}>
        <div className={css(styles.calendarMonthHeaderNavControl, styles.modifiers.prevMonth)}>
          <Button
            isDisabled={isPrevDisabled(currentYear)}
            variant="plain"
            aria-label={prevMonthAriaLabel}
            onClick={(ev: React.MouseEvent) => onYearClick(nextYear, ev)}
            data-test="month-picker-prev-button"
          >
            <AngleLeftIcon aria-hidden={true} />
          </Button>
        </div>
        <div className={customStyles.eiCalendarHeaderYear} data-test="month-picker-year">
          {currentYear}
        </div>
        <div className={css(styles.calendarMonthHeaderNavControl, styles.modifiers.nextMonth)}>
          <Button
            isDisabled={isNextDisabled(currentYear)}
            variant="plain"
            aria-label={nextMonthAriaLabel}
            onClick={(ev: React.MouseEvent) => onYearClick(prevYear, ev)}
            data-test="month-picker-next-button"
          >
            <AngleRightIcon aria-hidden={true} />
          </Button>
        </div>
      </div>
      <ul className={customStyles.eiCalendarMonthCalendar}>
        {months.map((currMonth, index) => (
          <li
            key={index}
            className={css(
              styles.calendarMonthDatesCell,
              isSelected(dateHelper['abbreviatedMonthIndexLookup'][currMonth]) && styles.modifiers.selected
            )}
          >
            <button onClick={() => onClick(currMonth)} className={styles.calendarMonthDate} type="button" data-test={`month-picker-${currMonth}`}>
              {currMonth}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  if (inlineProps !== undefined) {
    const Component = (inlineProps.component ? inlineProps.component : 'article') as any;
    return (
      <Component {...(inlineProps.ariaLabelledby && { 'aria-labelledby': inlineProps.ariaLabelledby })}>
        {inlineProps.title}
        {renderCalendar}
      </Component>
    );
  }
  return renderCalendar;
};

export default Calendar;
