export default class pageRoutes {
  //base app url
  static baseUrl = {
    disabledElements: { filter: true, breadcrumbs: true },
    groupId: 'home',
    itemId: 'home',
    linkText: 'Home',
    path: '/',
  };

  //review urls
  static reviewUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'review',
    linkText: 'Review',
    path: `${pageRoutes.baseUrl.path}review/`,
  };

  //review subpages
  static reviewOverviewUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'review-overview',
    linkText: 'Overview',
    path: `${pageRoutes.reviewUrl.path}review-overview`,
  };
  static retentionPerformanceUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'retention-performance',
    linkText: 'Retention Performance',
    path: `${pageRoutes.reviewUrl.path}retention-performance`,
  };
  static bookingsPerformanceUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'bookings-performance',
    linkText: 'Bookings Performance',
    path: `${pageRoutes.reviewUrl.path}bookings-performance`,
  };
  static topAndBottomPerformersUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'top-and-bottom-performers',
    linkText: 'Top & Bottom Performers',
    path: `${pageRoutes.reviewUrl.path}top-and-bottom-performers`,
  };
  static dealManagementUrl = {
    disabledElements: {},
    groupId: 'review',
    itemId: 'deal-management',
    linkText: 'Deal Management',
    path: `${pageRoutes.reviewUrl.path}deal-management`,
  };

  //renew urls
  static renewUrl = {
    disabledElements: {},
    groupId: 'renew',
    itemId: 'renew',
    linkText: 'Renew',
    path: `${pageRoutes.baseUrl.path}renew/`,
  };

  //renew subpages
  static renewOverviewUrl = {
    disabledElements: {},
    groupId: 'renew',
    itemId: 'renew-overview',
    linkText: 'Overview',
    path: `${pageRoutes.renewUrl.path}renew-overview`,
  };

  static pipelineByAccountUrl = {
    disabledElements: {},
    groupId: 'renew',
    itemId: 'pipeline-by-account',
    linkText: 'Pipeline by Account',
    path: `${pageRoutes.renewUrl.path}pipeline-by-account`,
  };

  static opportunitiesUrl = {
    disabledElements: {},
    groupId: 'renew',
    itemId: 'opportunities',
    linkText: 'Opportunities',
    path: `${pageRoutes.renewUrl.path}opportunities`,
  };

  //infrared urls
  static infraredUrl = {
    disabledElements: {},
    groupId: 'infrared',
    itemId: 'infrared',
    linkText: 'Infrared',
    path: `${pageRoutes.baseUrl.path}infrared/`,
  };
  //infrared subpages
  static infraredOverview = {
    disabledElements: {},
    groupId: 'infrared',
    itemId: 'infrared-overview',
    linkText: 'Overview',
    path: `${pageRoutes.infraredUrl.path}infrared-overview`,
  };
  static crossSellProbabilities = {
    disabledElements: {},
    groupId: 'infrared',
    itemId: 'cross-sell-probabilities',
    linkText: 'Cross-Sell Probabilities',
    path: `${pageRoutes.infraredUrl.path}cross-sell-probabilities`,
  };
  static customerInsights = {
    disabledElements: {},
    groupId: 'infrared',
    itemId: 'customer-insights',
    linkText: 'Customer Insights',
    path: `${pageRoutes.infraredUrl.path}customer-insights`,
  };

  //export urls
  static exportUrl = {
    disabledElements: {},
    groupId: 'export',
    itemId: 'export',
    linkText: 'Export',
    path: `${pageRoutes.baseUrl.path}export`,
  };
}
