import React, { createContext, Dispatch, useContext, useState } from 'react';

export interface DefinitionsState {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<React.SetStateAction<boolean>>;

  alternateDefinitions: any;
  setAlternateDefinitions: Dispatch<React.SetStateAction<any>>;

  alternateDefinitionsTitle: string;
  setAlternateDefinitionsTitle: Dispatch<React.SetStateAction<string>>;
}

export const initialTableState: DefinitionsState = {
  isModalOpen: false,
  setIsModalOpen: () => {},

  alternateDefinitions: [],
  setAlternateDefinitions: () => {},

  alternateDefinitionsTitle: '',
  setAlternateDefinitionsTitle: () => {},
};

const DefinitionsContext = createContext(initialTableState);
export const useDefinitionsContext = () => useContext(DefinitionsContext);

const DefinitionsContextProvider = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [alternateDefinitions, setAlternateDefinitions] = useState(null);
  const [alternateDefinitionsTitle, setAlternateDefinitionsTitle] = useState(null);

  return (
    <DefinitionsContext.Provider
      value={{
        isModalOpen,
        setIsModalOpen,

        alternateDefinitions,
        setAlternateDefinitions,

        alternateDefinitionsTitle,
        setAlternateDefinitionsTitle,
      }}
    >
      {props.children}
    </DefinitionsContext.Provider>
  );
};

export default DefinitionsContextProvider;
