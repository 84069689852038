import React, { useEffect, useState } from 'react';
import {
  ChartAxis,
  ChartBar,
  ChartBullet,
  ChartBulletComparativeWarningMeasure,
  ChartBulletPrimarySegmentedMeasure,
  ChartLegend,
} from '@patternfly/react-charts';
import { Text, TextContent } from '@patternfly/react-core';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import numberHelper from '../../../utils/numberHelper';
import styles from './OpportunitiesBulletChart.module.scss';

interface OpportunitiesBulletChartProps {
  opportunities?: any;
}

const OpportunitiesBulletChart: React.FC<OpportunitiesBulletChartProps> = ({ opportunities }) => {
  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  return (
    <>
      <TextContent>
        <Text className={styles.eiRenewOpportunitiesText}>
          Get insight into the {numberHelper.formatAsNumberWithComma(opportunities?.opp_count)} pipeline opportunites worth{' '}
          {numberHelper.formatAsCompactDollarAmount(opportunities?.total_opp_amount, currencySelector)} in order to reach your full potential.
        </Text>
      </TextContent>
      <div className={styles.eiRenewOpportunitiesBulletChart}>
        <ChartBullet
          data-test="renew-overview-opportunities-bullet-chart"
          ariaDesc="Opportunities Bullet chart"
          constrainToVisibleArea
          height={150}
          width={580}
          padding={{
            bottom: 50,
            left: 9,
            right: 40,
            top: 0, // Adjusted to accommodate labels
          }}
          maxDomain={{ y: 100 }}
          legendPosition="bottom"
          legendOrientation="horizontal"
          legendComponent={
            <ChartLegend
              colorScale={[opportunities.renewal_at_par_percent >= opportunities.par_goal ? '#009596' : '#C60011', '#EE6B1E', '#317631']}
            />
          }
          axisComponent={
            <ChartAxis
              tickValues={[0, 25, 50, 75, 100]}
              tickFormat={(val) => {
                switch (val) {
                  case 0:
                    return '0%';
                  case 25:
                    return '25%';
                  case 50:
                    return '50%';
                  case 75:
                    return '75%';
                  case 100:
                    return '100%';
                }
              }}
            />
          }
          comparativeWarningMeasureData={[
            //if the goal and potential are ever the same, drop the goal slightly so that both indicators may be seen in the chart
            { name: 'Par % Goal', y: opportunities.par_goal !== opportunities.par_potential ? opportunities.par_goal : opportunities.par_goal - 0.4 },
          ]}
          comparativeWarningMeasureLegendData={[{ name: `Par % Goal ${opportunities.par_goal}%` }]}
          comparativeWarningMeasureComponent={
            <ChartBulletComparativeWarningMeasure
              theme={{
                bar: {
                  style: {
                    data: {
                      color: '#EE6B1E',
                      fill: '#EE6B1E',
                      stroke: '#EE6B1E',
                      strokeWidth: '2px',
                    },
                  },
                },
              }}
            />
          }
          comparativeErrorMeasureData={[{ name: 'Par % Potential', y: opportunities.par_potential }]}
          comparativeErrorMeasureLegendData={[{ name: `Par % Potential ${opportunities.par_potential}%` }]}
          comparativeErrorMeasureComponent={
            <ChartBulletComparativeWarningMeasure
              theme={{
                bar: {
                  style: {
                    data: {
                      color: '#317631',
                      fill: '#317631',
                      stroke: '#317631',
                      strokeWidth: '2px',
                    },
                  },
                },
              }}
            />
          }
          primarySegmentedMeasureComponent={
            <ChartBulletPrimarySegmentedMeasure
              measureComponent={
                <ChartBar style={{ data: { fill: opportunities.renewal_at_par_percent >= opportunities.par_goal ? '#009596' : '#C60011' } }} />
              }
            />
          }
          primarySegmentedMeasureData={[{ name: 'Par % YTD', y: opportunities.renewal_at_par_percent }]}
          primarySegmentedMeasureLegendData={[{ name: `Par % YTD ${opportunities.renewal_at_par_percent}%` }]}
          qualitativeRangeData={[
            { name: 'Range', y: 100 },
            { name: 'Range', y: 75 },
            { name: 'Range', y: 50 },
            { name: 'Range', y: 25 },
          ]}
        />
      </div>
    </>
  );
};
export default OpportunitiesBulletChart;
