import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Grid,
  GridItem,
  Skeleton,
  Split,
  SplitItem,
  Text,
  TextContent,
  TextVariants,
} from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../api/apiCalls';
import { ReactComponent as RenewPipelineLogo } from '../../assets/renew-overview-pipeline.svg';
import CardBodyWithImage from '../../components/CardBodyWithImage';
import EcoIntelEmptyState from '../../components/EcoIntelEmptyState';
import { errorState } from '../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../utils/filterHelper';
import pageRoutes from '../../utils/pageRoutes';
import OpportunitiesBulletChart from './OpportunitiesBulletChart';
import styles from './RenewOverview.module.scss';

interface RenewOverviewProps {
  viewContext: string;
}

const RenewOverview: React.FC<RenewOverviewProps> = ({ viewContext }) => {
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.RENEW_OVERVIEW);
  const [renewOverviewData, setRenewOverviewData] = useState(null);
  const [responseError, setResponseError] = useState<string | null>(null);
  const { globalState } = useGlobalContext();

  useEffect(() => {
    const getRenewOverviewDetails = async () => {
      setResponseError(null);
      try {
        const selectedFilterValues = getAllSelectedFilterValues(globalState, { viewContext: viewContext });
        const response = await request(selectedFilterValues);
        response.par_goal = 85; //This needs to be removed. Added a fixed data as per designs till we get the clarity from backend team
        setResponseError(null);
        setRenewOverviewData(response);
      } catch (error) {
        setResponseError(error.message);
      }
    };

    getRenewOverviewDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewContext, globalState.filterState.isApplyFilter, globalState.filterState.isResetFilter, globalState.otherFilters?.currencyCode]);

  return (
    <Grid data-test="renew-overview-content" hasGutter>
      <GridItem sm={12} xl={6} className={styles.eiCardContainer}>
        <Card className={styles.eiRenewOverviewCard}>
          <CardTitle className={styles.eiRenewCardTitle}>
            <Button
              data-test="renew-overview-opportunities"
              variant="link"
              component={(props: any) => <Link className={styles.eiCardTitleLinkText} {...props} to={pageRoutes.opportunitiesUrl.path} />}
            >
              {pageRoutes.opportunitiesUrl.linkText}
            </Button>
          </CardTitle>
          <CardBody>
            {responseError ? (
              <EcoIntelEmptyState isCardVersion emptyStateProps={errorState} />
            ) : (
              <div style={{ height: '200px', width: '100%' }}>
                {isFetching ? (
                  <Skeleton data-test="skeleton" width="100%" height="200px" screenreaderText="Loading content" />
                ) : (
                  <Split>
                    <SplitItem>{renewOverviewData && <OpportunitiesBulletChart opportunities={renewOverviewData} />}</SplitItem>
                  </Split>
                )}
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem sm={12} xl={6} className={styles.eiCardContainer}>
        <Card className={styles.eiRenewOverviewCard}>
          <CardTitle className={styles.eiRenewCardTitle}>
            <Button
              data-test="renew-overview-pipeline-by-account"
              isLarge
              variant="link"
              component={(props: any) => <Link className={styles.eiCardTitleLinkText} {...props} to={pageRoutes.pipelineByAccountUrl.path} />}
            >
              {pageRoutes.pipelineByAccountUrl.linkText}
            </Button>
          </CardTitle>
          <CardBody>
            <CardBodyWithImage
              imageComponent={<RenewPipelineLogo />}
              textComponent={
                <TextContent>
                  <Text component={TextVariants.p} className={styles.eiRenewPipelineText}>
                    View future deals for partners to help facilitate team planning. Target concentrated down-sell impacts to make the most of each
                    opportunity.
                  </Text>
                </TextContent>
              }
            />
          </CardBody>
        </Card>
      </GridItem>
    </Grid>
  );
};
export default RenewOverview;
