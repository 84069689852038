import React from 'react';
import { Stack, StackItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import styles from './HeroCard.module.scss';

interface HeroCardProps {
  bodyText: string;
  titleText: string;
}

const HeroCard: React.FC<HeroCardProps> = (props) => {
  const { bodyText, titleText } = props;

  return (
    <Stack className={styles.eiHeroCardContainer}>
      <StackItem>
        <TextContent className={styles.eiHeroCardTitleContainer}>
          <Text component={TextVariants.p} className={styles.eiHeroCardTitle}>
            {titleText}
          </Text>
        </TextContent>
      </StackItem>
      <StackItem>
        <TextContent>
          <Text component={TextVariants.p}>{bodyText}</Text>
        </TextContent>
      </StackItem>
    </Stack>
  );
};

export default HeroCard;
