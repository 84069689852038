import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionToggle } from '@patternfly/react-core';
import TableWrapper from '../../components/TableWrapper';
import DealManagementDetail from './DealManagementDetail';
import DealManagementSummary from './DealManagementSummary';
import styles from './DealManagement.module.scss';

interface DealManagementProps {
  viewContext: string;
}

const DealManagement: React.FC<DealManagementProps> = (props) => {
  const [expanded, setExpanded] = useState(['deal-management-summary', 'deal-management-details']);

  const toggleDealManagementTabs = (id: string) => {
    const index = expanded.indexOf(id);
    const newExpanded: string[] = index >= 0 ? [...expanded.slice(0, index), ...expanded.slice(index + 1, expanded.length)] : [...expanded, id];
    setExpanded(newExpanded);
  };

  return (
    <>
      <div className={styles.eiDealManagement}>
        <Accordion asDefinitionList={false} headingLevel="h2">
          <AccordionItem>
            <AccordionToggle
              data-test="deal-management-summary-toggle"
              onClick={() => toggleDealManagementTabs('deal-management-summary')}
              isExpanded={expanded.includes('deal-management-summary')}
              id="deal-management-summary"
            >
              Deal Management Summary
            </AccordionToggle>
            <AccordionContent id="ex2-expand1" isHidden={!expanded.includes('deal-management-summary')} isFixed tabIndex={0}>
              <DealManagementSummary viewContext={props.viewContext} />
            </AccordionContent>
          </AccordionItem>
          <AccordionItem>
            <AccordionToggle
              data-test="deal-management-detail-toggle"
              onClick={() => toggleDealManagementTabs('deal-management-details')}
              isExpanded={expanded.includes('deal-management-details')}
              id="deal-management-details"
            >
              Deal Management Opportunity Detail
            </AccordionToggle>
            <AccordionContent id="ex2-expand2" isHidden={!expanded.includes('deal-management-details')} isFixed tabIndex={0}>
              <TableWrapper>
                <DealManagementDetail viewContext={props.viewContext} />
              </TableWrapper>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default DealManagement;
