import { default as React, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Card, CardBody } from '@patternfly/react-core';
import { ArrowRightIcon } from '@patternfly/react-icons';
import { useUserMetadata } from '../../../hooks/useUserMetadata';
import pageRoutes from '../../../utils/pageRoutes';
import routeHelper from '../../../utils/routeHelper';
import EcoIntelEmptyState from '../../EcoIntelEmptyState';
import { pageNotFoundState } from '../../EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import {
  BookingsPerformance,
  CrossSellProbabilities,
  CustomerInsights,
  DealManagement,
  Export,
  Home,
  InfraredOverview,
  Opportunities,
  PipelineByAccount,
  RenewOverview,
  RetentionPerformance,
  ReviewOverview,
  TopAndBottomPerformers,
} from './../../../pages';
import BreadcrumbsAndDefinitions from './BreadcrumbsAndDefinitions';
import styles from './PageContent.module.scss';

interface PageContentProps {
  currentPath: string;
  infraredCustomerInsightsSelectedCustomer: any;
  setViewContext: Function;
  setInfraredCustomerInsightsSelectedCustomer: Function;
  setShowFilter: (value: boolean) => void;
  setShowNavbar: (value: boolean) => void;
  viewContext: string;
  definitions: string[];
}

const PageContent: React.FC<PageContentProps> = ({
  currentPath,
  definitions,
  infraredCustomerInsightsSelectedCustomer,
  setInfraredCustomerInsightsSelectedCustomer,
  setViewContext,
  setShowFilter,
  setShowNavbar,
  viewContext,
}) => {
  const disabledElements = routeHelper.getRouteObjectFromPath(currentPath)?.disabledElements;
  const isBreadcrumbsAndDefinitionsDisabled = disabledElements ? disabledElements['breadcrumbs'] : false;

  const userMetadata = useUserMetadata();

  pageNotFoundState.emptyStateActionIcon = <ArrowRightIcon />; // Add action icon to empty state
  pageNotFoundState.emptyStateActionPath = pageRoutes.baseUrl.path; // Add action path to empty state

  useEffect(() => {
    setViewContext(userMetadata ? userMetadata.finder_partner_type_name : '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMetadata]);

  return (
    <div className={styles.eiRoutePageContainer}>
      <div
        className={`${styles.eiBreadcrumbsAndDefinitionsContainer} ${
          isBreadcrumbsAndDefinitionsDisabled ? styles.eiHideBreadcrumbsAndDefinitionsContainer : null
        }`}
      >
        <BreadcrumbsAndDefinitions currentPath={currentPath} definitions={definitions} />
      </div>
      <Routes>
        <Route
          path={pageRoutes.baseUrl.path}
          element={<Home setShowFilter={setShowFilter} setShowNavbar={setShowNavbar} viewContext={viewContext} />}
        />
        <Route path={pageRoutes.reviewOverviewUrl.path} element={<ReviewOverview viewContext={viewContext} />} />
        <Route path={pageRoutes.retentionPerformanceUrl.path} element={<RetentionPerformance viewContext={viewContext} />} />
        <Route path={pageRoutes.bookingsPerformanceUrl.path} element={<BookingsPerformance viewContext={viewContext} />} />
        <Route path={pageRoutes.topAndBottomPerformersUrl.path} element={<TopAndBottomPerformers viewContext={viewContext} />} />
        <Route path={pageRoutes.dealManagementUrl.path} element={<DealManagement viewContext={viewContext} />} />

        <Route path={pageRoutes.renewOverviewUrl.path} element={<RenewOverview viewContext={viewContext} />} />
        <Route path={pageRoutes.pipelineByAccountUrl.path} element={<PipelineByAccount viewContext={viewContext} />} />
        <Route path={pageRoutes.opportunitiesUrl.path} element={<Opportunities viewContext={viewContext} />} />

        <Route path={pageRoutes.infraredOverview.path} element={<InfraredOverview viewContext={viewContext} />} />
        <Route path={pageRoutes.crossSellProbabilities.path} element={<CrossSellProbabilities viewContext={viewContext} />} />
        <Route
          path={pageRoutes.customerInsights.path}
          element={
            <CustomerInsights
              infraredCustomerInsightsSelectedCustomer={infraredCustomerInsightsSelectedCustomer}
              setInfraredCustomerInsightsSelectedCustomer={setInfraredCustomerInsightsSelectedCustomer}
              viewContext={viewContext}
            />
          }
        />

        <Route path={pageRoutes.exportUrl.path} element={<Export viewContext={viewContext} />} />

        <Route
          path="*"
          element={
            <Card>
              <CardBody>
                <EcoIntelEmptyState emptyStateProps={pageNotFoundState} />
              </CardBody>
            </Card>
          }
        />
      </Routes>
    </div>
  );
};

export default PageContent;
