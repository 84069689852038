import numberHelper from './numberHelper';

const getDivideByNumber = (value: number) => {
  if (Math.abs(value) >= 1000000) {
    return 1000000;
  }
  if (Math.abs(value) >= 10000) {
    return 10000;
  }
  if (Math.abs(value) >= 1000) {
    return 1000;
  }
  return 1000;
};

export const generateBarChartAxisTickValues = (dataValues: Array<number>) => {
  const maxValue: number = Math.max(...dataValues);
  const minValue: number = Math.min(...dataValues);

  const upValue: number = Math.ceil(maxValue / getDivideByNumber(maxValue)) * getDivideByNumber(maxValue);
  const downValue: number = numberHelper.getNearestRoundNumber(minValue, getDivideByNumber(minValue));
  const tickDistance: number = Math.abs(Math.round((downValue + upValue) / 2));
  let tickValue: number = downValue - tickDistance / dataValues.length;
  let tickValues: Array<number> = [tickValue];

  while (tickValue < upValue) {
    let oldTickValue = tickValue;
    tickValue = tickValue + tickDistance;
    if (oldTickValue < 0 && tickValue >= 0) {
      if (tickDistance / 2 > 0 - oldTickValue && tickValues.length > 1) {
        tickValues.pop();
      }
      tickValues.push(0);
    }
    if (tickValues.indexOf(tickValue) < 0) {
      tickValues.push(tickValue);
    }
  }
  return tickValues;
};
