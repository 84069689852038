import React from 'react';
import { Button, Flex, FlexItem, Stack, StackItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { useDefinitionsContext } from '../../../contexts/Definitions/DefinitionsContext';
import pageDefinitions from '../../../utils/pageDefinitions';
import pageRoutes from '../../../utils/pageRoutes';
import Subfooter from './Subfooter';
import styles from './Footer.module.scss';

interface FooterProps {
  viewContext: string;
}

const Footer: React.FC<FooterProps> = ({ viewContext }) => {
  const { setIsModalOpen, setAlternateDefinitions, setAlternateDefinitionsTitle } = useDefinitionsContext();

  const toggleDefinitionModal = (definitions, definitionsTitle: string) => {
    setIsModalOpen(true);
    setAlternateDefinitions(definitions);
    setAlternateDefinitionsTitle(definitionsTitle);
  };

  const renderLinkItem = (groupName: string, linkText: string, linkDestination: string, linkTarget: string, dataTestId?: string) => {
    return (
      <StackItem key={`${groupName}${linkText}`}>
        <Button
          variant="link"
          component="a"
          href={linkDestination}
          target={linkTarget ? linkTarget : null}
          aria-label={linkText}
          data-test={dataTestId}
        >
          {linkText}
        </Button>
      </StackItem>
    );
  };

  const renderFunctionItem = (
    groupName: string,
    linkText: string,
    onClick: React.MouseEventHandler | null,
    additionalStyles: string,
    dataTestId?: string
  ) => {
    return (
      <StackItem key={`${groupName}${linkText}`}>
        <Button
          className={`${additionalStyles ? additionalStyles : ''} ${!onClick || viewContext === '' ? styles.isDisabled : null}`}
          isDisabled={!onClick || viewContext === ''}
          onClick={onClick && viewContext !== '' ? onClick : null}
          variant="plain"
          aria-label={linkText}
          data-test={dataTestId}
        >
          {linkText}
        </Button>
      </StackItem>
    );
  };

  const renderGroup = (groupTitle: string, groupItems: React.ReactNode) => {
    return (
      <FlexItem spacer={{ default: 'spacerNone' }}>
        <Stack>
          <StackItem>
            <TextContent>
              <Text className={styles.eiFooterGroupHeading} component={TextVariants.p}>
                {groupTitle}
              </Text>
            </TextContent>
          </StackItem>
          {groupItems}
        </Stack>
      </FlexItem>
    );
  };

  const communicateFooterGroupName = 'COMMUNICATE';
  const communicateGroup = renderGroup(communicateFooterGroupName, [
    renderLinkItem(
      communicateFooterGroupName,
      'Book a live demo',
      'https://docs.google.com/forms/d/e/1FAIpQLScGJmITqbPzNxH0cHHTCn8cqu6jXjT2Xb_raILUWZ37-b959Q/viewform',
      '_blank',
      'book-a-live-demo'
    ),
    renderLinkItem(communicateFooterGroupName, 'Contact Us', 'mailto: intelligence@redhat.com', null, 'contact-us'),
  ]);

  const resourcesFooterGroupName = 'RESOURCES';
  const resourcesGroup = renderGroup(resourcesFooterGroupName, [
    renderLinkItem(
      resourcesFooterGroupName,
      'Renewals Intelligence Guide',
      'https://redhat-partner.highspot.com/items/5f1083c6c714334915d15c7f?lfrm=shp.0',
      '_blank',
      'renewals-intelligence-guide'
    ),
    renderLinkItem(resourcesFooterGroupName, 'FAQ', 'https://redhat-partner.highspot.com/items/5f060d57429d7b664a7e6917', '_blank', 'faq'),
  ]);

  const definitionsFooterGroupName = 'DEFINITIONS';
  const definitionsGroup = renderGroup(definitionsFooterGroupName, [
    renderFunctionItem(
      definitionsFooterGroupName,
      'Bookings Performance',
      () => toggleDefinitionModal(pageDefinitions.bookingsPerformanceDefinitions, pageRoutes.bookingsPerformanceUrl.linkText),
      null,
      'footer-bookings-performance'
    ),
    renderFunctionItem(
      definitionsFooterGroupName,
      'Retention Performance',
      () => toggleDefinitionModal(pageDefinitions.retentionPerformanceDefinitions, pageRoutes.retentionPerformanceUrl.linkText),
      null,
      'footer-retention-performance'
    ),
  ]);

  const untitledFooterGroupName = '';
  const noHeaderGroup = renderGroup(untitledFooterGroupName, [
    renderFunctionItem(
      untitledFooterGroupName,
      'Deal Management',
      () => toggleDefinitionModal(pageDefinitions.dealManagementDefinitions, pageRoutes.dealManagementUrl.linkText),
      `${styles.eiNoHeaderGroup}`,
      'footer-deal-management'
    ),
    renderFunctionItem(untitledFooterGroupName, 'Infrared', null, null),
  ]);

  return (
    <>
      <footer className={styles.eiFooter}>
        <Flex className={styles.eiFooterContainer} justifyContent={{ default: 'justifyContentSpaceAround' }}>
          {communicateGroup}
          {resourcesGroup}
          {definitionsGroup}
          {noHeaderGroup}
        </Flex>
        <Subfooter />
      </footer>
      <div id="consent_blackbar" style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 5, padding: '10px' }}></div>
    </>
  );
};

export default Footer;
