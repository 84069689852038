import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Button, ButtonVariant, Card, CardBody, Flex, FlexItem, Text, TextInput, TextVariants, ToolbarItem } from '@patternfly/react-core';
import { fetchDataPostRequest } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  defaultColumns,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
  tableSortColumnIndex,
} from './TopAndBottomPerformersDetailTableConfiguration';
import styles from './TopAndBottomPerformersDetail.module.scss';

interface TopAndBottomPerformersDetailProps {
  viewContext: string;
}

const TopAndBottomPerformersDetail: React.FC<TopAndBottomPerformersDetailProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request } = useFetch(fetchDataPostRequest, ApiRoutes.TOP_AND_BOTTOM_PERFORMERS);
  const [topAndBottomPerformersDetailData, setTopAndBottomPerformersDetailData] = useState<any>();
  const [responseError, setResponseError] = useState(null);
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    isTableDataFetching,
    tableData,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortIndex,
    setActiveSortDirection,
    setTableName,
    setTableResponseError,
    setTableEmptyState,
  } = useTableContext();
  const [maximumOpportunityAmount, setMaximumOpportunityAmount] = useState('');
  const [minimumOpportunityAmount, setMinimumOpportunityAmount] = useState('');
  const [appliedMaximumOpportunityAmount, setAppliedMaximumOpportunityAmount] = useState('');
  const [appliedMinimumOpportunityAmount, setAppliedMinimumOpportunityAmount] = useState('');
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(false);
  const [doFilterValuesExist, setDoFilterValuesExist] = useState(false);

  /* this regex is used in the onChange for the inputs to ensure only positive or negative integers are 
  entered. Necessary because the PF textInput type='number' did not function as expected in all browsers */
  const regexTestValue = /^[\\-]?[0-9]*$/;

  useEffect(() => {
    setActiveSortDirection('desc');
    setActiveSortIndex(4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTableDataFetching(true);
    const getTopAndBottomPerformersTableData = async () => {
      setTableData(null);
      setResponseError(null);
      try {
        const params = {
          fetch_id: currentFetchId,
          order_by: tableSortColumnIndex[activeSortIndex],
          page_limit: pageLimit,
          sort_by: activeSortDirection,
          viewContext: viewContext,
          filter_by: {},
        };

        if (appliedMinimumOpportunityAmount) {
          Object.assign(params.filter_by, { [SubFilter.MinimumOpportunityAmount]: Number(appliedMinimumOpportunityAmount) });
        }

        if (appliedMaximumOpportunityAmount) {
          Object.assign(params.filter_by, { [SubFilter.MaximumOpportunityAmount]: Number(appliedMaximumOpportunityAmount) });
        }

        const selectedFilterValues = getAllSelectedFilterValues(globalState, params);

        if (activeSortDirection !== null && activeSortIndex !== null) {
          const response = await request(selectedFilterValues);

          setTopAndBottomPerformersDetailData(response);
          setTableData(response?.top_bottom_performers_details?.data);
          setIsTableDataFetching(false);
          setResponseError(null);
        }
      } catch (error) {
        setResponseError(error.message);
        setIsTableDataFetching(false);
      }
    };

    if (((globalState || {}).filterState || {}).originalFilterData && activeSortIndex && activeSortDirection) {
      getTopAndBottomPerformersTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeSortDirection,
    activeSortIndex,
    appliedMaximumOpportunityAmount,
    appliedMinimumOpportunityAmount,
    currentFetchId,
    filterBy,
    globalFilterAppliedOrReset,
    viewContext,
  ]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
      setTableEmptyState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  useEffect(() => {
    unstable_batchedUpdates(() => {
      setTableName('top-and-bottom-performers');
      setPageLimit(currentPageLimit);
      setDefaultColumns(defaultColumns);

      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        setInitialColumns(initialDistributorColumns);
        setColumns(distributorColumnDetails);
      } else {
        setInitialColumns(initialResellerColumns);
        setColumns(resellerColumnDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  useEffect(() => {
    setTableData(topAndBottomPerformersDetailData?.top_bottom_performers_details?.data);
    setTotalRecordsCount(topAndBottomPerformersDetailData?.top_bottom_performers_details?.record_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topAndBottomPerformersDetailData, setTableData, setTotalRecordsCount]);

  const isInputValueValid = (value: string, currentValue: string) => {
    return (regexTestValue.test(value) && value.length < 14 && value !== currentValue) || value === '';
  };

  const handleChangeMinimumOpportunityAmount = (value: string) => {
    if (isInputValueValid(value, minimumOpportunityAmount)) {
      setMinimumOpportunityAmount(value);
    }
  };

  const handleChangeMaximumOpportunityAmount = (value: string) => {
    if (isInputValueValid(value, maximumOpportunityAmount)) {
      setMaximumOpportunityAmount(value);
    }
  };

  const onClickApply = () => {
    setAppliedMinimumOpportunityAmount(minimumOpportunityAmount);
    setAppliedMaximumOpportunityAmount(maximumOpportunityAmount);
  };

  const onClickReset = () => {
    setMinimumOpportunityAmount('');
    setAppliedMinimumOpportunityAmount('');
    setMaximumOpportunityAmount('');
    setAppliedMaximumOpportunityAmount('');
  };

  const isNotNullorEmpty = (value) => {
    return value !== null && value !== '';
  };

  const areFilterValuesSet = () => {
    return (
      isNotNullorEmpty(minimumOpportunityAmount) ||
      isNotNullorEmpty(maximumOpportunityAmount) ||
      isNotNullorEmpty(appliedMinimumOpportunityAmount) ||
      isNotNullorEmpty(appliedMaximumOpportunityAmount)
    );
  };

  useEffect(() => {
    setDoFilterValuesExist(areFilterValuesSet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minimumOpportunityAmount, maximumOpportunityAmount, appliedMaximumOpportunityAmount, appliedMinimumOpportunityAmount]);

  useEffect(() => {
    let isDisabled = false;

    if (
      minimumOpportunityAmount &&
      maximumOpportunityAmount &&
      (Number(minimumOpportunityAmount) > Number(maximumOpportunityAmount) || Number(maximumOpportunityAmount) < Number(minimumOpportunityAmount))
    ) {
      isDisabled = true;
    }

    setIsApplyButtonDisabled(isDisabled);
  }, [minimumOpportunityAmount, maximumOpportunityAmount]);

  const renderLabelAndInput = (inputLabel: string, onChange: (value: string) => void, value: string, dataTestId: string) => {
    return (
      <>
        <Text data-test={dataTestId} component={TextVariants.p}>
          {inputLabel}
        </Text>
        {
          <TextInput
            data-test={`${dataTestId}-input`}
            aria-label={inputLabel}
            isDisabled={isTableDataFetching}
            onChange={onChange}
            placeholder={`Any Amount`}
            type="text"
            value={value || ''}
          />
        }
      </>
    );
  };

  const renderApplyButton = (buttonText: string, buttonVariant: ButtonVariant, onClick: () => void) => {
    return (
      <>
        {
          <Button
            data-test="apply"
            isDisabled={
              isApplyButtonDisabled ||
              isTableDataFetching ||
              (appliedMaximumOpportunityAmount === maximumOpportunityAmount && appliedMinimumOpportunityAmount === minimumOpportunityAmount)
            }
            onClick={onClick}
            variant={buttonVariant}
          >
            {buttonText}
          </Button>
        }
      </>
    );
  };

  const renderResetButton = (buttonText: string, buttonVariant: ButtonVariant, onClick: () => void) => {
    return (
      <>
        {
          <Button data-test="reset" isDisabled={isTableDataFetching || !doFilterValuesExist} onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        }
      </>
    );
  };

  const renderTableFilter = (
    <ToolbarItem>
      <Flex className={styles.eiInputGroup}>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderLabelAndInput(
            'Minimum Opportunity Amount',
            handleChangeMinimumOpportunityAmount,
            minimumOpportunityAmount || '',
            'minimum-opportunity-amount'
          )}
        </FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderLabelAndInput(
            'Maximum Opportunity Amount',
            handleChangeMaximumOpportunityAmount,
            maximumOpportunityAmount || '',
            'maximum-opportunity-amount'
          )}
        </FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>{renderApplyButton('Apply', ButtonVariant.primary, onClickApply)}</FlexItem>
        <FlexItem alignSelf={{ default: 'alignSelfFlexEnd' }}>{renderResetButton('Reset', ButtonVariant.secondary, onClickReset)}</FlexItem>
      </Flex>
    </ToolbarItem>
  );

  return (
    <Card className={styles.eiTopAndBottomPerformersDetail}>
      <CardBody className={styles.eiTopAndBottomPerformersDetail}>
        {/* there needs to be some use of resposeError here to display an error if the table data API fails */}
        <Table filter={renderTableFilter}></Table>
      </CardBody>
    </Card>
  );
};

export default TopAndBottomPerformersDetail;
