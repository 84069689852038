import React, { useEffect, useState } from 'react';
import { Skeleton } from '@patternfly/react-core';
import { TableComposable, Tbody, Td, Th, Thead, Tr } from '@patternfly/react-table';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import numberHelper from '../../../../utils/numberHelper';
import styles from './DealManagementTable.module.scss';

interface DealManagementTableProps {
  dealManagement: any;
  dealManagementCategoryMappings: any;
  isFetching: boolean;
  viewContext: any;
}

const DealManagementTable: React.FC<DealManagementTableProps> = ({ dealManagement, dealManagementCategoryMappings, isFetching, viewContext }) => {
  const dealManagementColumnNames = {
    plusOrMinusIndicator: '',
    renewedOpportunities: 'Renewed Opportunities',
    opportunities: '# of Opps',
    opportunityAmount: 'Opp Amount',
  };

  const [currencySelector, setCurrencySelector] = useState<string>('USD');
  const { globalState } = useGlobalContext();

  useEffect(() => {
    setCurrencySelector(globalState.otherFilters?.currencyCode);
  }, [globalState.otherFilters?.currencyCode]);

  const renderSquare = (style) => {
    return (
      <div className={styles.categoryGraphicContainer}>
        <div className={`${styles.categoryGraphicSquare} ${style}`} />
      </div>
    );
  };

  const renderCategoryGraphic = (categoryName) => {
    let categoryGraphic = null;

    switch (categoryName) {
      case dealManagementCategoryMappings.originalRenewals.name:
        categoryGraphic = renderSquare(styles.mediumSeaGreen);
        break;
      case dealManagementCategoryMappings.transferredIn.name:
        categoryGraphic = renderSquare(styles.mediumSeaGreen);
        break;
      case dealManagementCategoryMappings.transferredOut.name:
        categoryGraphic = renderSquare(styles.alternateRed);
        break;
      case dealManagementCategoryMappings.closedLost.name:
        categoryGraphic = renderSquare(styles.alternateRed);
        break;
      case dealManagementCategoryMappings.renewedOpportunities.name:
        categoryGraphic = renderSquare(styles.seaGreen);
        break;
      case dealManagementCategoryMappings.transferredInternally.name:
        categoryGraphic = renderSquare(styles.grey);
        break;
      default:
        break;
    }

    return categoryGraphic;
  };

  const renderCategoryIcon = (categoryName) => {
    let categoryGraphic = '';

    switch (categoryName) {
      case dealManagementCategoryMappings.originalRenewals.name:
        break;
      case dealManagementCategoryMappings.transferredIn.name:
        categoryGraphic = '+';
        break;
      case dealManagementCategoryMappings.transferredOut.name:
        categoryGraphic = '-';
        break;
      case dealManagementCategoryMappings.closedLost.name:
        categoryGraphic = '-';
        break;
      case dealManagementCategoryMappings.renewedOpportunities.name:
        categoryGraphic = '=';
        break;
      case dealManagementCategoryMappings.transferredInternally.name:
        break;
      default:
        break;
    }

    return categoryGraphic;
  };

  const renderCategoryInformation = (categoryName, value) => {
    let returnValue = value;

    switch (categoryName) {
      case dealManagementCategoryMappings.transferredOut.name:
        returnValue = `-${value}`;
        break;
      case dealManagementCategoryMappings.closedLost.name:
        returnValue = `-${value}`;
        break;
      default:
        break;
    }

    return returnValue;
  };

  const renderdealManagementTableRows = () => {
    const tableRows = [];

    for (let category in dealManagementCategoryMappings) {
      let isNegativeValueRow =
        dealManagementCategoryMappings[category].name === dealManagementCategoryMappings.transferredOut.name ||
        dealManagementCategoryMappings[category].name === dealManagementCategoryMappings.closedLost.name;

      tableRows.push(
        <Tr
          className={
            dealManagementCategoryMappings[category].name === dealManagementCategoryMappings.renewedOpportunities.name
              ? styles.renewedOpportunitiesRowStyle
              : ''
          }
          key={category}
        >
          <Td noPadding={true} dataLabel={dealManagementColumnNames.plusOrMinusIndicator}>
            {renderCategoryIcon(dealManagementCategoryMappings[category].name)}
          </Td>
          <Td noPadding={true} dataLabel={dealManagementColumnNames.renewedOpportunities}>
            {renderCategoryGraphic(dealManagementCategoryMappings[category].name)}
            {dealManagementCategoryMappings[category].name}
          </Td>
          <Td
            noPadding={true}
            className={`${styles.dealManagementTableCellAlignment} ${isNegativeValueRow ? styles.eiNegativeValueColumn : ''}`}
            dataLabel={dealManagementColumnNames.opportunityAmount}
          >
            {isFetching ? (
              <Skeleton screenreaderText={'Loading contents...'} />
            ) : (
              renderCategoryInformation(
                dealManagementCategoryMappings[category].name,
                numberHelper.formatAsCompactDollarAmount(
                  dealManagement[dealManagementCategoryMappings[category].dealManagementCountColumnData],
                  currencySelector
                )
              )
            )}
          </Td>
          <Td
            noPadding={true}
            className={`${styles.dealManagementTableCellAlignment} ${isNegativeValueRow ? styles.eiNegativeValueColumn : ''}`}
            dataLabel={dealManagementColumnNames.opportunities}
          >
            {isFetching ? (
              <Skeleton screenreaderText={'Loading contents...'} />
            ) : (
              renderCategoryInformation(
                dealManagementCategoryMappings[category].name,
                numberHelper.formatAsCompactDollarAmount(
                  dealManagement[dealManagementCategoryMappings[category].dealManagementAmountColumnData],
                  currencySelector
                )
              )
            )}
          </Td>
        </Tr>
      );
    }

    return tableRows;
  };

  return (
    <section className={styles.eiDealManagementTableContainer}>
      <TableComposable aria-label={'Performance Vs. Peers'} variant={'compact'} borders={false}>
        <Thead>
          <Tr>
            <Th aria-label="Operand">{dealManagementColumnNames.plusOrMinusIndicator}</Th>
            <Th>{dealManagementColumnNames.renewedOpportunities}</Th>
            <Th className={styles.dealManagementTableCellAlignment}>{dealManagementColumnNames.opportunities}</Th>
            <Th className={styles.dealManagementTableCellAlignment}>{dealManagementColumnNames.opportunityAmount}</Th>
          </Tr>
        </Thead>
        <Tbody>{renderdealManagementTableRows()}</Tbody>
      </TableComposable>
    </section>
  );
};

export default DealManagementTable;
