import React from 'react';
import { Virtuoso } from 'react-virtuoso';
import CheckIcon from '@patternfly/react-icons/dist/esm/icons/check-icon';
import clsx from 'clsx';
import { FilterOption, selectAllString } from './VirtualizedSelect';
import styles from './VirtualizedSelect.module.scss';

interface Props {
  isSearchable?: boolean;
  isSearching?: boolean;
  isMulti?: boolean;
  selectedDisplayText: string;
  options: FilterOption[];
  onOptionClick: (option: FilterOption) => void;
  isSelected: (option: FilterOption) => boolean;
  setShowMenu: (state: boolean) => void;
  selectedOptionsCount?: number;
}

/**
 * Generates the "dropdown" menu for the options, including the search menu
 *
 * @param isSearchable  - optional boolean to add in search component
 * @param isSearching  - optional boolean to determine searching state
 * @param isMulti  - optional flag to render clearAll and selectAll
 * @param selectedDisplayText  - string to display X of Y selected
 * @param options  - required array of FilterOptions as items for the virtualized list
 * @param onOptionClick  - required callback to handle selection
 * @param isSelected  - required callback to check selection state for options
 * @param setShowMenu  - required callback to handle menu visibility state
 * @param selectedOptionsCount  - optional number of currently selected options
 * @returns {JSX.Element}
 */
export function DropdownMenu({
  isSearchable,
  isSearching,
  isMulti,
  selectedDisplayText,
  options,
  onOptionClick,
  isSelected,
  setShowMenu,
  selectedOptionsCount,
}: Props): JSX.Element {
  const ref = React.useRef(null);
  const [currentItemIndex, setCurrentItemIndex] = React.useState(-1);
  const listRef = React.useRef(null);

  // Determines the height for the option menu
  const dropdownHeight = (): number => {
    const baseOptionHeight = 40;
    const maxOptionsToShow = 10;
    return options.length < maxOptionsToShow ? options.length * baseOptionHeight : maxOptionsToShow * baseOptionHeight;
  };

  // Callback to handle key input while focused on the options, stopPropagation is needed to keep focus
  const keyDownCallback = React.useCallback(
    (event) => {
      let nextIndex = null;
      event.preventDefault();
      event.stopPropagation();

      if (event.code === 'ArrowUp') {
        nextIndex = Math.max(0, currentItemIndex - 1);
      } else if (event.code === 'ArrowDown') {
        nextIndex = Math.min(options.length - 1, currentItemIndex + 1);
      } else if (event.code === 'Enter' || event.code === 'Space') {
        onOptionClick(options[currentItemIndex]);
      } else if (event.code === 'Escape') {
        setShowMenu(false);
      }

      if (nextIndex !== null) {
        ref.current.scrollIntoView({
          index: nextIndex,
          behavior: 'auto',
          done: () => {
            setCurrentItemIndex(nextIndex);
          },
        });
      }
    },
    [currentItemIndex, onOptionClick, options, setShowMenu]
  );

  const scrollerRef = React.useCallback(
    (element) => {
      if (element) {
        element.addEventListener('keydown', keyDownCallback);

        listRef.current = element;
      } else {
        listRef.current.removeEventListener('keydown', keyDownCallback);
      }
    },
    [keyDownCallback]
  );

  return (
    <div className={`${styles.eiFilterDropdownMenu} ${!isSearchable && styles.eiNoSearchDropdownItem}`}>
      {(isMulti && options.length >= 1) || (!isMulti && options.length > 0) ? (
        <Virtuoso
          role={'menu'}
          ref={ref}
          style={{ height: dropdownHeight(), outline: currentItemIndex === -1 ? 'inset 1px solid blue' : 'none' }}
          totalCount={options.length}
          data={options}
          className={`${styles.eiDropdownScroller} ${!isSearchable && styles.eiNoSearchDropdown}`}
          scrollerRef={scrollerRef}
          itemContent={(index, option) => (
            <>
              {option.value === selectAllString && !isSearching && (
                <div
                  role={'menuitem'}
                  key={option.value}
                  onClick={() => {
                    onOptionClick(option);
                    if (!isMulti) {
                    }
                  }}
                  className={`${clsx(
                    styles.eiFilterDropdownItem,
                    (isSelected(option) || selectedOptionsCount === options.length) && styles.eiFilterDropdownItemSelected,
                    index === currentItemIndex && styles.eiFilterDropdownCurrentSelection
                  )}`}
                >
                  {isMulti ? (
                    <section className={styles.eiFilterCustomCheckbox}>
                      <input
                        readOnly
                        data-test="select-all"
                        aria-label="select all"
                        tabIndex={-1}
                        className={styles.eiFilterCheckboxInput}
                        type="checkbox"
                        checked={isSelected(option) || (option.value === selectAllString && selectedOptionsCount === options.length - 1)}
                      />
                      {/* // options.length - 1 is to remove Select All option from evaluation */}
                      <label
                        className={`${styles.eiFilterCustomCheckboxLabel} ${
                          selectedOptionsCount === options.length - 1 ? styles.eiFilterSelectedAllOption : ''
                        }`}
                      >
                        {option.label}
                      </label>
                    </section>
                  ) : (
                    <section className={styles.eiFilterCustomSelect} data-test={option.label}>
                      <label>{option.label}</label>
                      {isSelected(option) && <CheckIcon />}
                    </section>
                  )}
                </div>
              )}

              {option.value !== selectAllString && (
                <div
                  role={'menuitem'}
                  key={option.value}
                  onClick={() => {
                    onOptionClick(option);
                    if (!isMulti) {
                    }
                  }}
                  className={`${clsx(
                    styles.eiFilterDropdownItem,
                    isSelected(option) && styles.eiFilterDropdownItemSelected,
                    index === currentItemIndex && styles.eiFilterDropdownCurrentSelection
                  )}`}
                >
                  {isMulti ? (
                    <section className={styles.eiFilterCustomCheckbox} data-test={option.label}>
                      <input
                        className={styles.eiFilterCheckboxInput}
                        readOnly
                        aria-label={option.label}
                        tabIndex={-1}
                        type="checkbox"
                        checked={isSelected(option)}
                      />
                      <label className={styles.eiFilterCustomCheckboxLabel}>{option.label}</label>
                    </section>
                  ) : (
                    <section className={styles.eiFilterCustomSelect} data-test={option.label}>
                      <label>{option.label}</label>
                      {isSelected(option) && <CheckIcon />}
                    </section>
                  )}
                </div>
              )}
            </>
          )}
        />
      ) : (
        <div className={styles.noResultsFound}>No results found</div>
      )}
    </div>
  );
}
