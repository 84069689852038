enum SubFilter {
  All = 'All',
  AllCustomers = 'All Customers',
  AllDealTypes = 'All Deal Types',
  CustomerGroup = 'customer_group',
  CustomerVertical = 'customer_vertical',
  DealType = 'deal_type',
  DealSize = 'deal_size',
  LateVsOnTime = 'late_vs_on_time',
  MaximumOpportunityAmount = 'maximum_opportunity_amount',
  MinimumOpportunityAmount = 'minimum_opportunity_amount',
  ProductCategory = 'product_category',
  Search = 'search',
}

export default SubFilter;
