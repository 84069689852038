import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  getInfraredCrossSellProbabilitiesHeroData,
  getInfraredCustomerInsightsHeroData,
  getRenewHeroData,
  getReviewHeroData,
} from '../../../../api/hero';
import { ReactComponent as AnalyticsLogo } from '../../../../assets/analytics_logo_white_type.svg';
import { finderPartnerTypes } from '../../../../constants/finderPartnerTypes';
import { useGlobalContext } from '../../../../contexts/GlobalContext/GlobalContext';
import { useLegacyFetch } from '../../../../hooks/useFetch';
import { getAllSelectedFilterValues } from '../../../../utils/filterHelper';
import numberHelper from '../../../../utils/numberHelper';
import pageRoutes from '../../../../utils/pageRoutes';
import routeHelper from '../../../../utils/routeHelper';
import HeroContent from './HeroContent';
import HeroStatic from './HeroStatic';
import InfraredHero from './InfraredHero';
import styles from './Hero.module.scss';

interface HeroProps {
  currentPath: string;
  handleToggleViewContextClick: (isSelected: boolean, e: React.MouseEvent<HTMLButtonElement>) => void;
  infraredCustomerInsightsSelectedCustomer: any;
  isViewContextToggleVisible: boolean;
  viewContext: string;
}

const Hero: React.FC<HeroProps> = ({
  currentPath,
  handleToggleViewContextClick,
  infraredCustomerInsightsSelectedCustomer,
  isViewContextToggleVisible,
  viewContext,
}) => {
  const [heroData, setHeroData] = useState<any | null>(null);
  const [responseError, setResponseError] = useState<string | null>(null);
  const [localIsFetching, setLocalIsFetching] = useState<boolean>(true);
  const [currencyCode, setCurrencyCode] = useState<string>('USD');
  const { globalState } = useGlobalContext();
  let apiPath,
    content = null;

  //#region HELPER METHODS
  const clearHeroData = () => {
    setResponseError(null);

    if (heroData) {
      setHeroData(null);
    }
  };

  const getHeroData = async (requestData) => {
    if (apiPath) {
      try {
        const bodyParams = getAllSelectedFilterValues(globalState, { viewContext: viewContext });

        //for CustomerInsights, we must pass an additional customer parameter and remove the parameter for other cases
        if (infraredCustomerInsightsSelectedCustomer) {
          Object.assign(bodyParams, { infraredCustomerInsightsSelectedCustomer: infraredCustomerInsightsSelectedCustomer });
        } else {
          delete bodyParams['infraredCustomerInsightsSelectedCustomer'];
        }

        const response = await requestData(bodyParams);

        setResponseError(null);
        setHeroData(response);
      } catch (error) {
        setResponseError(error.message);
      }

      setLocalIsFetching(false);
    }
  };
  //#endregion

  //#region HERO RENDER METHODS
  const renderHeroHome = (): React.ReactNode => {
    return (
      <HeroStatic
        title="Renewals Intelligence"
        text="Unlock your Red Hat business potential with a spectrum of actionable retention and growth insights from analytics on renewals performance and pipeline to Infrared's AI-powered predictive and advanced intelligence."
      />
    );
  };

  const renderHeroReview = (): React.ReactNode => {
    const carouselData: { bodyText: string; titleText: string }[] = [];

    if (heroData != null) {
      carouselData.push({
        bodyText: `Overall ${viewContext === finderPartnerTypes.RESELLER ? 'Estimated' : ''} Opportunity Amount`,
        titleText: numberHelper.formatAsCompactDollarAmount(heroData?.total_opp_amount, currencyCode),
      });
      carouselData.push({ bodyText: 'Renewed Opportunities', titleText: numberHelper.formatAsNumberWithComma(heroData?.count_opps) });
      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        carouselData.push({ bodyText: 'Partners', titleText: numberHelper.formatAsNumberWithComma(heroData?.count_partners) });
      }
      carouselData.push({ bodyText: 'Customers', titleText: numberHelper.formatAsNumberWithComma(heroData?.count_customers) });
    }

    return (
      <HeroContent
        carouselData={carouselData}
        carouselTitle={'Portfolio Summaries'}
        heroBodyText={'Multiple Partners'}
        heroFooterContent={<AnalyticsLogo className={styles.eiReviewFooter} />}
        heroTitleText={routeHelper?.getRouteObjectFromPath(currentPath)?.linkText}
        isFetching={localIsFetching}
        onClickToggleViewContext={handleToggleViewContextClick}
        isViewContextToggleVisible={isViewContextToggleVisible}
        viewContext={viewContext}
        noOfLoaders={4}
        responseError={responseError}
      />
    );
  };

  const renderHeroRenew = () => {
    const carouselData = [];

    if (heroData != null) {
      carouselData.push({
        bodyText: `Overall ${viewContext === finderPartnerTypes.RESELLER ? 'Estimated' : ''} Opportunity Amount`,
        titleText: numberHelper.formatAsCompactDollarAmount(heroData?.total_opp_amount, currencyCode),
      });
      carouselData.push({ bodyText: 'Opportunities', titleText: numberHelper.formatAsNumberWithComma(heroData?.opp_count) });
      carouselData.push({ bodyText: 'Customers', titleText: numberHelper.formatAsNumberWithComma(heroData?.customer_count) });
    }

    return (
      <HeroContent
        carouselData={carouselData}
        carouselTitle={'Portfolio Summaries'}
        heroBodyText={'Multiple Partners'}
        heroFooterContent={<AnalyticsLogo className={styles.eiReviewFooter} />}
        heroTitleText={routeHelper?.getRouteObjectFromPath(currentPath)?.linkText}
        isFetching={localIsFetching}
        onClickToggleViewContext={handleToggleViewContextClick}
        isViewContextToggleVisible={isViewContextToggleVisible}
        viewContext={viewContext}
        noOfLoaders={3}
        responseError={responseError}
      />
    );
  };

  const renderHeroInfraredOverview = (): React.ReactNode => {
    return <InfraredHero />;
  };

  // const renderHeroInfraredCustomerInsightsNoCustomer = (): React.ReactNode => {
  //   return <HeroStatic title="This is the customer insights hero WITHOUT a customer" text="This is the customer insights hero WITHOUT a customer" />;
  // };

  // const renderHeroInfraredCustomerInsightsCustomer = (): React.ReactNode => {
  //   return <HeroStatic title="This is the customer insights hero" text="This is the customer insights hero" />;
  // };

  const renderHeroInfraredCrossSellProbabilities = (): React.ReactNode => {
    const carouselData = [];

    if (heroData != null) {
      carouselData.push({
        bodyText: `${viewContext === finderPartnerTypes.RESELLER ? 'Estimated' : ''} Customers with Cross-Sell Probability`,
        titleText: numberHelper.formatAsNumberWithComma(heroData?.cross_sell_hero?.customer_count),
      });
      carouselData.push({
        bodyText: `${viewContext === finderPartnerTypes.RESELLER ? 'Estimated' : ''} Renewal Opps with Cross-Sell Probability`,
        titleText: numberHelper.formatAsNumberWithComma(heroData?.cross_sell_hero?.opp_count),
      });
    }

    return (
      <HeroContent
        carouselData={carouselData}
        carouselTitle="Current Quarter + 3"
        heroBodyText="Multiple Partners"
        heroTitleText={routeHelper?.getRouteObjectFromPath(currentPath)?.linkText}
        isFetching={localIsFetching}
        onClickToggleViewContext={handleToggleViewContextClick}
        isViewContextToggleVisible={isViewContextToggleVisible}
        viewContext={viewContext}
        noOfLoaders={2}
        responseError={responseError}
      />
    );
  };

  const renderHeroExport = (): React.ReactNode => {
    return (
      <HeroStatic
        title="Export"
        hasPopover={false}
        text="Use our handy export tool to download custom data sets. Select the type of report, date range, and file type. Download your file in one easy step!"
      />
    );
  };
  //#endregion

  //#region USEFETCH LOGIC
  /* because you cannot call a hook inside a callback, you cannot conditionally call the useLegacyFetch
  hook inside a switch statement based on the currentPath. But we can conditonally store the api 
  we should call. This way, we can know which api to call in the useLegacyFetch. Additionally, pathMatchToken
  is used to prevent the hero from rerendering within a category */
  // const pathParts = currentPath.split('/');
  // const pathMatchToken = pathParts[1] ? pathParts[1] : '';
  switch (currentPath) {
    /* HOME */
    case pageRoutes.baseUrl.path:
      /* must clear data or it persists */
      apiPath = '';
      content = renderHeroHome();
      break;

    case pageRoutes.reviewOverviewUrl.path:
    case pageRoutes.bookingsPerformanceUrl.path:
    case pageRoutes.retentionPerformanceUrl.path:
    case pageRoutes.topAndBottomPerformersUrl.path:
    case pageRoutes.dealManagementUrl.path:
      apiPath = getReviewHeroData;
      content = renderHeroReview();
      break;

    /* REVIEW */
    case pageRoutes.renewOverviewUrl.path:
    case pageRoutes.pipelineByAccountUrl.path:
    case pageRoutes.opportunitiesUrl.path:
      apiPath = getRenewHeroData;
      content = renderHeroRenew();
      break;

    /* INFRARED */
    case pageRoutes.infraredOverview.path:
      /* must clear data or it persists */
      apiPath = '';
      content = renderHeroInfraredOverview();
      break;
    case pageRoutes.crossSellProbabilities.path:
      /* must clear data or it persists */
      apiPath = getInfraredCrossSellProbabilitiesHeroData;
      content = renderHeroInfraredCrossSellProbabilities();
      break;
    case pageRoutes.customerInsights.path:
      if (infraredCustomerInsightsSelectedCustomer !== null) {
        /* must clear data or it persists */
        apiPath = getInfraredCustomerInsightsHeroData;
        content = renderHeroHome();
      } else {
        /* must clear data or it persists */
        apiPath = '';
        content = renderHeroHome();
      }
      break;

    /* EXPORT */
    case pageRoutes.exportUrl.path:
      /* must clear data or it persists */
      apiPath = '';
      content = renderHeroExport();
      break;

    /* DEFAULT */
    default:
      apiPath = '';
      content = renderHeroHome();
      break;
  }

  let { request } = useLegacyFetch(apiPath, { propagateErrors: true });
  /* the implicit isFetchint variable that can be created with the request above (but isn't here) cannot
    be used to call the dynamic request function since a reference cannot be made before the variable is
    created, and the reference must be created dynamically here at the bottom of this component for the 
    hero to render. Instead, the local state variable localIsFetchind is used since it can be defined beforehand */
  useEffect(() => {
    unstable_batchedUpdates(() => {
      clearHeroData();
      setLocalIsFetching(true);
      getHeroData(request);
      setCurrencyCode(globalState.otherFilters?.currencyCode);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPath,
    globalState.filterState?.isApplyFilter,
    globalState.filterState?.isResetFilter,
    globalState.otherFilters?.currencyCode,
    infraredCustomerInsightsSelectedCustomer,
    viewContext,
  ]);

  return (
    <div id="main" className={styles.eiHeroContainer}>
      {content}
    </div>
  );
};

export default Hero;
