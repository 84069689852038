import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, GridItem, Split, SplitItem, Text, TextContent, TextVariants } from '@patternfly/react-core';
import { ReactComponent as FooterLogo } from '../../../../assets/redhat_logo.svg';
import { subfooterLinks } from '../../../../constants/externalLinks';
import pageRoutes from '../../../../utils/pageRoutes';
import styles from './Subfooter.module.scss';

interface SubfooterProps {}

const Subfooter: React.FC<SubfooterProps> = () => {
  return (
    <div className={styles.eiFooter}>
      <Grid className={styles.eiFooterCopyright}>
        <GridItem lgOffset={1} sm={12} md={12} lg={10}>
          <Split hasGutter className={styles.eiSubfooterButtonContainer}>
            <SplitItem>
              <Button
                aria-label="Renewals Intelligence"
                className={styles.eiFooterIconButton}
                variant="plain"
                component={(props: any) => (
                  <Link {...props} to={pageRoutes.baseUrl.path}>
                    <FooterLogo className={styles.eiRedhatIcon} />
                  </Link>
                )}
              ></Button>
            </SplitItem>
            <SplitItem>
              <TextContent>
                <Text component={TextVariants.p}>Copyright Ⓒ {new Date().getFullYear()} Red Hat, Inc.</Text>
              </TextContent>
            </SplitItem>
            <SplitItem>
              <Split className={styles.eiSubfooterLinks}>
                {subfooterLinks.map((linkItem, index) => {
                  return (
                    <SplitItem className={styles.eiLinkContainer} key={`${index}${linkItem.linkText}`}>
                      <TextContent>
                        <span className={styles.eiLink}>
                          {linkItem.component === 'div' ? (
                            <div id={linkItem.id}>{linkItem.linkText}</div>
                          ) : (
                            <a id={linkItem.id} href={linkItem.link} target={linkItem.link && '_blank'} rel="noreferrer">
                              {linkItem.linkText}
                            </a>
                          )}
                        </span>
                      </TextContent>
                    </SplitItem>
                  );
                })}
              </Split>
            </SplitItem>
          </Split>
        </GridItem>
      </Grid>
    </div>
  );
};

export default Subfooter;
