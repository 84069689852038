import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { assign, includes, isEmpty } from 'lodash';
import WorkerBuilder from './worker-builder';

interface Error {
  message: string;
  stack?: string;
  extra?: {
    url: string;
    params: object;
  };
}

// This method fetches the access token from the cookie
export const getAuthToken = () => {
  const cookie = Cookies.get('ecointel_sso');
  return cookie ? cookie : null;
};

export const getDecodedAuthToken = () => {
  return jwt_decode(getAuthToken());
};

const baseParams: RequestInit = {
  credentials: 'omit',
  headers: {
    Accept: 'application/json',
  },
  signal: null,
};

/***
 * This method prepares Request Params object ready.
 * This includes Authorization token in base params.
 * extraParams: This parameter includes the request options needs to be passed for api call. Refer RequestInit interface to check available options
 * body: This parameter is optional.
 * **/

const prepareRequestParams = (requestMethod: string, extraParams: RequestInit, body?: any) => {
  const params = assign({ method: requestMethod }, baseParams);
  const { headers, ...otherParams } = extraParams;
  const token = !isEmpty(getAuthToken()) ? `Bearer ${getAuthToken()}` : '';
  const headerParams = { ...headers, Authorization: token };

  Object.assign(params.headers, headerParams);
  Object.assign(params, { ...otherParams });

  if (body) {
    if (!params.headers['content-type']) {
      Object.assign(params.headers, { 'Content-Type': 'application/json' });
    }
    Object.assign(params, { body: JSON.stringify(body) });
  }

  return params;
};

/***
 * This method parse the error coming from api
 * response: This is the error response from API
 * **/
const errorHandler = (response: Response) => {
  return response.text().then((body) => {
    if (body == null || body === '') {
      const error = new Error(body);
      Object.assign(error, { status: response.status }, { statusText: response.statusText });
      throw error;
    }
    let parsedError;
    try {
      if (body && typeof body === 'object') {
        parsedError = JSON.parse(body);
      }
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error(e);
    }
    if (parsedError) {
      const error = new Error((parsedError && parsedError.message) || body);
      Object.assign(error, parsedError, { isApiError: true }, { status: response.status }, { statusText: response.statusText });
      throw error;
    } else {
      const error = new Error(body);
      Object.assign(error, { status: response.status }, { statusText: response.statusText });
      throw error;
    }
  });
};

/***
 * This method prepares response of API calls.
 * Based on the 'content-type' header, it returns JSON/text response body
 * response: This parameter is success response from API
 * **/
const responseHandler = <T>(response: Response): Promise<T | string> => {
  try {
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && includes(contentType, 'json')) {
        return response
          .json()
          .then((j) => Promise.resolve(j))
          .catch(() => Promise.resolve({}));
      } else if (contentType && includes(contentType, 'text')) {
        return response.text();
      } else {
        // Defaulting to text if content type cannot be determined
        // https://github.com/github/fetch/issues/268#issuecomment-176544728
        return response
          .text()
          .then((j) => Promise.resolve(j ? JSON.parse(j) : {}))
          .catch(() => Promise.resolve({}));
      }
    } else {
      return errorHandler(response);
    }
  } catch {
    return errorHandler(response);
  }
};

/***
 * This method adds extra information about the API error such as url and params.
 *
 * **/

const processCaughtError = (url: string, params: RequestInit, error: Error) => {
  try {
    if (error && error.message != null) {
      error.extra = {
        url: url.toString(),
        params,
      };
    }
  } catch (e) {
    throw e;
  }
};

/***
 * This method fetch the data using Fetch API by sending the API request.
 * This is async await method and returns a Promise of generic type.
 * url: This parameter is the API request URL
 * params: This is the object of Request headers to be passed to API request.
 * **/

const fetchAPIRequest = async <T>(url: string, params: RequestInit): Promise<T> => {
  try {
    const response = await fetch(url.toString(), params);
    return responseHandler<T>(response) as Promise<T>;
  } catch (error) {
    processCaughtError(url, params, error);
    return Promise.reject(error);
  }
};

/***
 * This method appends the query params which needs to be passed to the API request.
 * url: This parameter is the API request URL
 * params: This is the object of Request headers to be passed to API request.
 * includeEmptyString: This parameter accepts boolean value. It is used to send an empty string based on the value true/false
 * **/
export const addQueryParams = (url, params, includeEmptyString = false) => {
  const uri = new URL(url);

  if (Array.isArray(params)) {
    params.forEach((item) => {
      Object.keys(item).forEach((k) => {
        // add false and 0 to query params
        if (item[k] || item[k] === false || item[k] === 0 || (includeEmptyString && item[k] === '')) {
          uri.searchParams.append(k, item[k]);
        }
      });
    });
  } else {
    params &&
      Object.keys(params).forEach((k) => {
        // add false and 0 to query params
        if (params[k] || params[k] === false || params[k] === 0 || (includeEmptyString && params[k] === '')) {
          uri.searchParams.append(k, params[k]);
        }
      });
  }

  return uri;
};

/***
 * This is GET API request
 * url: This parameter is the API request URL
 * extraParams: This is the object of Request headers to be passed to API request.
 * body: This parameter is optional. If body is present then only use this param
 * **/
export const getRequest = <T>(url: string, extraParams?: RequestInit, body?: any): Promise<T> => {
  const params: RequestInit = prepareRequestParams('GET', extraParams, body);

  return fetchAPIRequest(url, params);
};

/***
 * This is POST API request
 * url: This parameter is the API request URL
 * body: This parameter is mandatory for POST request.
 * extraParams: This parameter is optional. This is the object of Request headers to be passed to API request.
 *
 * **/
export const postRequest = <T>(url: string, body: any, extraParams?: RequestInit): Promise<T> => {
  const params: RequestInit = prepareRequestParams('POST', extraParams, body);

  return fetchAPIRequest(url, params);
};

/***
 * This is POST API request which sends formdata
 * url: This parameter is the API request URL
 * formData: This is form data
 * extraParams: This parameter is optional. This is the object of Request headers to be passed to API request.
 *
 * **/
export const postFormRequest = <T>(url: string, formData: FormData, extraParams?: RequestInit): Promise<T> => {
  Object.assign(extraParams.headers, { 'Content-Type': 'application/x-www-form-urlencoded' });
  Object.assign(extraParams, { body: formData });

  const params: RequestInit = prepareRequestParams('POST', extraParams);
  return fetchAPIRequest(url, params);
};

/***
 * This is PUT API request
 * url: This parameter is the API request URL
 * body: This parameter is mandatory for PUT request.
 * extraParams: This is the object of Request headers to be passed to API request.
 * **/
export const putRequest = <T>(url, body: any, extraParams?: RequestInit): Promise<T> => {
  const params: RequestInit = prepareRequestParams('PUT', extraParams, body);

  return fetchAPIRequest(url, params);
};

/***
 * This is PATCH API request
 * url: This parameter is the API request URL
 * body: This parameter is mandatory for PATCH request.
 * extraParams: This is the object of Request headers to be passed to API request.
 * **/
export const patchRequest = <T>(url, body: any, extraParams?: RequestInit): Promise<T> => {
  const params: RequestInit = prepareRequestParams('PATCH', extraParams, body);

  return fetchAPIRequest(url, params);
};

/***
 * This is DELETE API request
 * url: This parameter is the API request URL
 * extraParams: This parameter is optional. This is the object of Request headers to be passed to API request.
 * body: This parameter is optional. If body is present then only use this param
 * **/
export const deleteRequest = <T>(url, extraParams?: RequestInit, body?: any): Promise<T> => {
  const params: RequestInit = prepareRequestParams('DELETE', extraParams, body);

  return fetchAPIRequest(url, params);
};

/***** Export Data Stream API request */
const exportDataStreamAPIRequest = async <T>(
  url: string,
  callback: Function,
  errorCallback: Function,
  fileName: string,
  params: RequestInit
): Promise<T> => {
  let stream: string = '';
  const instance = new WorkerBuilder();
  const dt = new Date();
  instance.onmessage = (message) => {
    if (message) {
      const url = window.URL.createObjectURL(message.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}-${dt.getMonth() + 1}-${dt.getDate()}-${dt.getFullYear()}.csv`;
      a.click();
      URL.revokeObjectURL(a.href);
      instance.terminate();
      callback();
    }
  };
  try {
    await fetch(url.toString(), params)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  instance.postMessage('done-worker');
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                stream = new TextDecoder().decode(value);
                instance.postMessage(stream);
                return pump();
              });
            }
          },
        });
      })
      .catch((err) => errorCallback(err));
  } catch (error) {
    processCaughtError(url, params, error);
    errorCallback(error);
    return Promise.reject(error);
  }
};

export const exportDataStreamRequest = <T>(
  url: string,
  callback: Function,
  errorCallback: Function,
  fileName: string,
  body: any,
  extraParams?: RequestInit
): Promise<T> => {
  const params: RequestInit = prepareRequestParams('POST', extraParams, body);

  return exportDataStreamAPIRequest(url, callback, errorCallback, fileName, params);
};
