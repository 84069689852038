import React, { useEffect, useState } from 'react';
import { Card, CardBody, Select, SelectOption, SelectVariant, Text, TextVariants, ToolbarItem } from '@patternfly/react-core';
import { fetchDataPostRequest, getSubfilterOptions } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { BookingsPerformanceDetailDataOpps } from '../../../models/bookingsPerfomance';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  defaultColumns,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
  tableSortColumnIndex,
} from './BookingsPerformanceTableConfiguration';
import styles from './BookingsPerformanceDetail.module.scss';

interface BookingsPerformanceDetailProps {
  viewContext: string;
}

const BookingsPerformanceDetail: React.FC<BookingsPerformanceDetailProps> = ({ viewContext }) => {
  const [isOnTimeLateSelectOpen, setIsOnTimeLateSelectOpen] = useState(false);
  const [detailData, setDetailData] = useState<any | BookingsPerformanceDetailDataOpps>();
  const [responseError, setResponseError] = useState(null);
  const { request } = useFetch(fetchDataPostRequest, ApiRoutes.BOOKING_PERFORMANCE_DETAIL);
  const { globalState } = useGlobalContext();
  const tableContext = useTableContext();
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    isTableDataFetching,
    tableData,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableName,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortDirection,
    setActiveSortIndex,
    setTableEmptyState,
    setTableResponseError,
  } = tableContext;

  const { request: subfiltersRequest } = useFetch(getSubfilterOptions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subfiltersResponseError, setSubfiltersResponseError] = useState(null);
  const [lateVsOnTimeFilterOptions, setLateVsOnTimeFilterOptions] = useState([]);
  const [selectedLateVsOnTimeFilterOption, setSelectedLateVsOnTimeFilterOption] = useState(SubFilter.All);

  const onSelectLateVsOnTimeFilter = (event, filterOption) => {
    setIsOnTimeLateSelectOpen(false);
    if (selectedLateVsOnTimeFilterOption !== filterOption) {
      setSelectedLateVsOnTimeFilterOption(filterOption);
    }
  };

  // Function to set default sort column and direction
  useEffect(() => {
    setActiveSortDirection('desc');
    setActiveSortIndex(3); // default column to be sorted is Down-sell

    const getBookingsPerformanceDetailFilterOptions = async () => {
      try {
        const subfiltersResponse = await subfiltersRequest(ApiRoutes.GET_SUBFILTERS, SubFilter.LateVsOnTime);
        setLateVsOnTimeFilterOptions(subfiltersResponse?.subfilters[0]?.value_text);
      } catch (error) {
        setSubfiltersResponseError(error.message);
      }
    };

    getBookingsPerformanceDetailFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsTableDataFetching(true);
    let abortController = new AbortController();
    const getBookingsPerformanceDetailPageData = async () => {
      try {
        const requestBody = {
          fetch_id: currentFetchId,
          page_limit: pageLimit,
          order_by: tableSortColumnIndex[activeSortIndex],
          sort_by: activeSortDirection,
          viewContext: viewContext,
          filter_by: {},
        };

        if (selectedLateVsOnTimeFilterOption) {
          Object.assign(requestBody.filter_by, { [SubFilter.LateVsOnTime]: selectedLateVsOnTimeFilterOption });
        }

        const selectedFilterValues = getAllSelectedFilterValues(globalState, requestBody);

        const response = await request(selectedFilterValues);
        setDetailData(response);
        setTableData(response?.bookings_performance_details_opps?.data);
        setIsTableDataFetching(false);
        setResponseError(null);
      } catch (error) {
        setResponseError(error.message);
        setIsTableDataFetching(false);
      }
      return () => {
        abortController.abort();
      };
    };

    if (((globalState || {}).filterState || {}).originalFilterData && activeSortDirection && activeSortIndex) {
      getBookingsPerformanceDetailPageData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSortDirection, activeSortIndex, viewContext, globalFilterAppliedOrReset, currentFetchId, filterBy, selectedLateVsOnTimeFilterOption]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
      setTableEmptyState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  // Function to send column names
  useEffect(() => {
    setTableName('bookings-performance');
    setPageLimit(currentPageLimit);
    setDefaultColumns(defaultColumns);

    if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
      setInitialColumns(initialDistributorColumns);
      setColumns(distributorColumnDetails);
    } else {
      setInitialColumns(initialResellerColumns);
      setColumns(resellerColumnDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  //Function called when the next set of data is fetched
  useEffect(() => {
    setTableData(detailData?.bookings_performance_details_opps?.data);
    setTotalRecordsCount(detailData?.bookings_performance_details_opps?.record_count);
  }, [detailData, setTableData, setTotalRecordsCount]);

  // Table filter
  const tableFilter = (
    <ToolbarItem>
      <Text data-test="late-vs-on-time-filter-title" component={TextVariants.p}>
        Late vs. On-Time
      </Text>
      <div data-test="late-vs-on-time-filter">
        <Select
          aria-label="Select Input"
          className={styles.eiToolbarSelectWidth}
          id="select-on-time"
          isDisabled={(lateVsOnTimeFilterOptions?.length > 0 ? false : true) || isTableDataFetching}
          isOpen={isOnTimeLateSelectOpen}
          onSelect={onSelectLateVsOnTimeFilter}
          onToggle={() => setIsOnTimeLateSelectOpen(!isOnTimeLateSelectOpen)}
          placeholderText={<>All</>}
          selections={selectedLateVsOnTimeFilterOption}
          variant={SelectVariant.single}
        >
          {lateVsOnTimeFilterOptions?.map((option, index) => {
            return <SelectOption key={index} value={option} data-test={option.toLowerCase()} />;
          })}
        </Select>
      </div>
    </ToolbarItem>
  );

  return (
    <Card className={styles.eiBookingsDetailTable}>
      <CardBody>
        {/* Render table inside card */}
        <Table filter={tableFilter}></Table>
      </CardBody>
    </Card>
  );
};

export default BookingsPerformanceDetail;
