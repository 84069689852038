import React from 'react';
import styles from './CardBodyWithImage.module.scss';

type CardBodyWithImageProps = {
  textComponent: React.ReactNode;
  imageComponent: React.ReactNode;
};

const CardBodyWithImage: React.FC<CardBodyWithImageProps> = ({ imageComponent, textComponent, ...props }) => {
  return (
    <div className={styles.eiCardBodyWithImage} {...props}>
      <div className={styles.eiCardImage}>{imageComponent}</div>
      <div>{textComponent}</div>
    </div>
  );
};

export default CardBodyWithImage;
