import React, { useEffect, useState } from 'react';
import { getDataPostRequest } from '../../api/apiCalls';
import { LookupTypes } from '../../constants/filterLookupTypes';
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import { StateActions } from '../../contexts/GlobalContext/GlobalContextReducer';
import { useFetch } from '../../hooks';
import { ApiRoutes } from '../../utils/apiRoutes';
import { convertToFilterOption, getFilterLookupTypes } from '../../utils/filterHelper';

export interface EcoIntelWrapperProp {
  children?: any;
}

const EcoIntelWrapper: React.FC<EcoIntelWrapperProp> = (props) => {
  const [filterData, setFilterData] = useState({});
  const [responseError, setError] = useState<string | null>(null);
  const [filterLookups, setFilterLookups] = useState<Record<string, string[] | undefined>>();
  const { request } = useFetch(getDataPostRequest, ApiRoutes.GET_FILTERS);
  const { globalState, dispatch } = useGlobalContext();

  useEffect(() => {
    const getFilterData = async () => {
      try {
        const response = await request();

        const allFilterValuesByTypeLookup = getFilterLookupTypes(response.data);

        setFilterLookups(allFilterValuesByTypeLookup);
        setFilterData(response);
      } catch (error) {
        dispatch({
          type: StateActions.filterData,
          payload: {
            error: error,
          },
        });
        if (error.status && [400, 500].includes(error.status)) {
          setError('Error occured while fetching filter data');
        } else if (error.status && error.status === 404) {
          setError('Filter data not found');
        } else if (error.status && error.status === 403) {
          setError('Looks like there is a permission issue');
        } else if (error.toString() === 'TypeError: Failed to fetch') {
          setError('We are facing some issues while fetching filter data. Please try again in sometime');
        } else {
          setError(error.message);
        }
      }
    };
    getFilterData().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterData && filterLookups) {
      dispatch({
        type: StateActions.filterData,
        payload: {
          filterState: {
            ...globalState.filterState,
            originalFilterData: filterData,
          },
          error: responseError,
        },
      });

      dispatch({
        type: StateActions.filterLookups,
        payload: {
          lookupState: {
            partnerParentLookup: convertToFilterOption(filterLookups[LookupTypes.partnerParent]),
            partnerAccountLookup: convertToFilterOption(filterLookups[LookupTypes.partnerAccount]),
            distributorAccountLookup: convertToFilterOption(filterLookups[LookupTypes.distributorAccount]),
            distributorParentLookup: convertToFilterOption(filterLookups[LookupTypes.distributorParent]),
            geoLookup: convertToFilterOption(filterLookups[LookupTypes.geo]),
            subregionLookup: convertToFilterOption(filterLookups[LookupTypes.subregion]),
            countryLookup: convertToFilterOption(filterLookups[LookupTypes.country]),
            customerSegmentLookup: convertToFilterOption(filterLookups[LookupTypes.accountSegment]),
          },
          error: responseError,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, filterLookups]);

  return <>{props.children}</>;
};

export default EcoIntelWrapper;
