import { ApiParams, BodyParams } from '../utils/apiRoutes';
import ENV from '../utils/env';
import { addQueryParams, getRequest, postRequest } from '../utils/fetch';

const AdditionalParams: RequestInit = {};

export const fetchDataPostRequest = (apiParams?: ApiParams, bodyParams?: BodyParams, abortSignal?: AbortSignal): Promise<any> => {
  const { path } = apiParams;
  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/${path}`);

  if (abortSignal) {
    Object.assign(AdditionalParams, { signal: abortSignal });
  }

  return postRequest(uri, bodyParams, AdditionalParams);
};

export const getDataPostRequest = (filterParams?: ApiParams, data?: any, abortSignal?: AbortSignal): Promise<any> => {
  const { path } = filterParams;
  const uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/${path}`);

  if (abortSignal) {
    Object.assign(AdditionalParams, { signal: abortSignal });
  }

  return getRequest(uri, AdditionalParams);
};

export const getSubfilterOptions = (apiParams?: ApiParams, filterParams?: string | object, abortSignal?: AbortSignal): Promise<any> => {
  const { path } = apiParams;
  let uri = ENV.apiURL.clone().setPath(`${ENV.apiPrefix}/${ENV.apiVersion}/${path}`);

  if (Array.isArray(filterParams)) {
    if (filterParams) {
      filterParams = filterParams.map((queryParam) => {
        return { sub_filters: queryParam };
      });
    }
  } else filterParams = { sub_filters: filterParams };

  uri = addQueryParams(uri, filterParams);

  if (abortSignal) {
    // this will be coming from useFetch hook
    Object.assign(AdditionalParams, { signal: abortSignal });
  }

  return getRequest(uri, AdditionalParams);
};
