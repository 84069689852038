import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  Button,
  Card,
  CardBody,
  Flex,
  FlexItem,
  Select,
  SelectOption,
  SelectVariant,
  Skeleton,
  Text,
  TextInput,
  TextVariants,
  ToolbarItem,
} from '@patternfly/react-core';
import { kebabCase } from 'lodash';
import { fetchDataPostRequest, getSubfilterOptions } from '../../../api/apiCalls';
import { errorStateSummaryDetails, noDataState } from '../../../components/EcoIntelEmptyState/EcoIntelEmptyStateConfiguration';
import Table from '../../../components/TableWrapper/Table';
import { finderPartnerTypes } from '../../../constants/finderPartnerTypes';
import SubFilter from '../../../constants/subFilter';
import { useGlobalContext } from '../../../contexts/GlobalContext/GlobalContext';
import { useTableContext } from '../../../contexts/Table/TableContext';
import { useFetch } from '../../../hooks';
import { ApiRoutes } from '../../../utils/apiRoutes';
import { getAllSelectedFilterValues } from '../../../utils/filterHelper';
import {
  currentPageLimit,
  defaultColumns,
  distributorColumnDetails,
  initialDistributorColumns,
  initialResellerColumns,
  resellerColumnDetails,
  tableSortColumnIndex,
} from './OpportunitiesDetailConfiguration';
import OpportunitiesDetailModal from './OpportunitiesDetailModal';
// Turn back on if action column is turned back on
// import { ReactComponent as InfraredLogo } from '../../../assets/IR-mini-logo.svg';
import styles from './OpportunitiesDetail.module.scss';

interface OpportunitiesDetailProps {
  viewContext: string;
}

const OpportunitiesDetail: React.FC<OpportunitiesDetailProps> = ({ viewContext }) => {
  const { globalState } = useGlobalContext();
  const { request, isFetching } = useFetch(fetchDataPostRequest, ApiRoutes.OPPORTUNITIES_DATA_DETAIL);
  const [opportunityDetailData, setOpportunityDetailData] = useState<any>();
  const [responseError, setResponseError] = useState(null);
  const {
    currentFetchId,
    activeSortDirection,
    activeSortIndex,
    filterBy,
    pageLimit,
    tableData,
    isTableDataFetching,
    globalFilterAppliedOrReset,
    setPageLimit,
    setTableData,
    setColumns,
    setIsTableDataFetching,
    setTotalRecordsCount,
    setDefaultColumns,
    setInitialColumns,
    setActiveSortIndex,
    setActiveSortDirection,
    setTableName,
    setTableResponseError,
    setTableEmptyState,
  } = useTableContext();
  const [searchValue, setSearchValue] = useState('');
  const [isApplyButtonDisabled, setIsApplyButtonDisabled] = useState(true);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
  const [isProductCategorySelectOpen, setIsProductCategorySelectOpen] = useState(false);
  const { request: subfiltersRequest } = useFetch(getSubfilterOptions);
  // You can remove the eslint line below when action column is turned back on
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subfiltersResponseError, setSubfiltersResponseError] = useState(null);
  const [productCategoryFilterOptions, setProductCategoryFilterOptions] = useState([]);
  const [selectedProductCategoryFilterOption, setSelectedProductCategoryFilterOption] = useState(SubFilter.All);
  const [isCustomerVerticalSelectOpen, setIsCustomerVerticalSelectOpen] = useState(false);
  const [customerVerticalFilterOptions, setCustomerVerticalFilterOptions] = useState([]);
  const [selectedCustomerVerticalFilterOption, setSelectedCustomerVerticalFilterOption] = useState(SubFilter.All);
  const [isReset, setIsReset] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isDataReset, setIsDataReset] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false); // For Table Action

  // Function to get the filter options
  useEffect(() => {
    setActiveSortDirection('asc');
    setActiveSortIndex(9);
    getFilterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to call table data API on page load
  useEffect(() => {
    getOpportunitiesDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSortDirection, activeSortIndex, currentFetchId, filterBy, globalFilterAppliedOrReset, viewContext]);

  // Function to set the table data
  useEffect(() => {
    setTableData(opportunityDetailData?.opportunity_details?.data);
    setTotalRecordsCount(opportunityDetailData?.opportunity_details?.record_count);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityDetailData, setTableData, setTotalRecordsCount]);

  // Function to set table error
  useEffect(() => {
    if (responseError) {
      setTableResponseError(responseError);
      setTableEmptyState(errorStateSummaryDetails);
    } else if (!tableData?.length) {
      setTableEmptyState(noDataState);
    } else {
      setTableResponseError(null);
      setTableEmptyState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseError, tableData]);

  // Function to set the table columns
  useEffect(() => {
    unstable_batchedUpdates(() => {
      setTableName('upcoming-opportunity');
      setPageLimit(currentPageLimit);
      setDefaultColumns(defaultColumns);

      if (viewContext === finderPartnerTypes.DISTRIBUTOR) {
        setInitialColumns(initialDistributorColumns);
        setColumns(distributorColumnDetails);
      } else {
        setInitialColumns(initialResellerColumns);
        setColumns(resellerColumnDetails);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setColumns, setPageLimit, setDefaultColumns, setInitialColumns, viewContext]);

  // Function to reset form values on clicking Reset All filters button
  useEffect(() => {
    if (isReset) {
      unstable_batchedUpdates(() => {
        setSelectedCustomerVerticalFilterOption(SubFilter.All);
        setSelectedProductCategoryFilterOption(SubFilter.All);
        setSearchValue('');
        setIsResetButtonDisabled(true);
        setIsApplyButtonDisabled(true);
        setIsReset(false);
        if (isApplied) {
          setIsDataReset(true);
          setIsApplied(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset]);

  // Function to reset form values and load page on clicking Reset All filters button if the filter values are applied
  useEffect(() => {
    if (isDataReset) {
      getOpportunitiesDetailData();
      setIsDataReset(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReset]);

  // Function to handle modal toggle state
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  // Function to enable apply button
  const enableApplyButton = () => {
    if (isApplyButtonDisabled) {
      setIsApplyButtonDisabled(false);
    }
  };

  // Function to enable reset button
  const enableResetButton = () => {
    if (isResetButtonDisabled) {
      setIsResetButtonDisabled(false);
    }
  };

  // Function to call sub filters API
  const getFilterOptions = async () => {
    try {
      const subfiltersResponse = await subfiltersRequest(ApiRoutes.GET_SUBFILTERS, [SubFilter.ProductCategory, SubFilter.CustomerVertical]);
      subfiltersResponse?.subfilters?.forEach((filter) => {
        switch (filter.sub_filter) {
          case SubFilter.ProductCategory:
            setProductCategoryFilterOptions(filter?.value_text.sort());
            break;
          case SubFilter.CustomerVertical:
            setCustomerVerticalFilterOptions(filter?.value_text.sort());
            break;
          default:
            break;
        }
      });
    } catch (error) {
      setSubfiltersResponseError(error.message);
    }
  };

  // Function to call API for getting table data
  const getOpportunitiesTableData = async () => {
    setTableData(null);
    setResponseError(null);
    try {
      const params = {
        fetch_id: currentFetchId,
        order_by: tableSortColumnIndex[activeSortIndex],
        page_limit: pageLimit,
        sort_by: activeSortDirection,
        viewContext: viewContext,
        filter_by: {},
      };

      if (selectedProductCategoryFilterOption) {
        Object.assign(params.filter_by, { [SubFilter.ProductCategory]: selectedProductCategoryFilterOption });
      }

      if (selectedCustomerVerticalFilterOption) {
        Object.assign(params.filter_by, { [SubFilter.CustomerVertical]: selectedCustomerVerticalFilterOption });
      }

      if (searchValue) {
        Object.assign(params.filter_by, { [SubFilter.Search]: searchValue });
      }

      const selectedFilterValues = getAllSelectedFilterValues(globalState, params);

      if (activeSortDirection !== null && activeSortIndex !== null) {
        const response = await request(selectedFilterValues);

        setOpportunityDetailData(response);
        setTableData(response?.opportunity_details?.data);
        setIsTableDataFetching(false);
        setResponseError(null);
      }
    } catch (error) {
      setResponseError(error.message);
      setIsTableDataFetching(false);
    }
  };

  // Function to validate if API needs to be called
  const getOpportunitiesDetailData = () => {
    setIsTableDataFetching(true);
    if (((globalState || {}).filterState || {}).originalFilterData && activeSortIndex && activeSortDirection) {
      getOpportunitiesTableData();
    }
  };

  // Function to handle search value input
  const handleSearchValue = (value) => {
    setSearchValue(value);
    enableApplyButton();
    enableResetButton();
  };

  // Function to set Product Category filter
  const onSelectProductCategoryFilter = (event, filterOption) => {
    setIsProductCategorySelectOpen(false);
    if (selectedProductCategoryFilterOption !== filterOption) {
      setSelectedProductCategoryFilterOption(filterOption);
      enableApplyButton();
      enableResetButton();
    }
  };

  // Function to set Customer Filter
  const onSelectCustomerVerticalFilter = (event, filterOption) => {
    setIsCustomerVerticalSelectOpen(false);
    if (selectedCustomerVerticalFilterOption !== filterOption) {
      setSelectedCustomerVerticalFilterOption(filterOption);
      enableApplyButton();
      enableResetButton();
    }
  };

  // Function called when user clicks Apply Button
  const onClickApply = () => {
    setSearchValue(searchValue);
    setSelectedProductCategoryFilterOption(selectedProductCategoryFilterOption);
    setSelectedCustomerVerticalFilterOption(selectedCustomerVerticalFilterOption);
    getOpportunitiesDetailData();
    setIsApplyButtonDisabled(true);
    enableResetButton();
    setIsApplied(true);
  };

  // Function called when user clicks Reset All Filters button
  const onClickReset = () => {
    setIsReset(true);
  };

  // Function to render input for Minimum/Maximum Opportunity Amount
  const renderLabelAndInput = (inputLabel, onChange, value) => {
    return (
      <>
        <Text data-test="search-input-title" component={TextVariants.p}>
          {inputLabel}
        </Text>
        {isFetching ? (
          <Skeleton height={'33px'} screenreaderText={'Loading contents...'} width={'240px'} />
        ) : (
          <TextInput
            data-test="search-input"
            className={styles.eiToolbarSelectWidth}
            aria-label={inputLabel}
            onChange={onChange}
            placeholder={`Search for Opp#, PO#, or Contr#`}
            type="text"
            maxLength={20}
            value={value || ''}
          />
        )}
      </>
    );
  };

  // Function to render apply button
  const renderApplyButton = (buttonText, buttonVariant, onClick) => {
    return (
      <>
        {isFetching ? (
          <Skeleton height={'33px'} screenreaderText={'Loading contents...'} width={'70px'} />
        ) : (
          <Button data-test="apply" isDisabled={isApplyButtonDisabled} onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </>
    );
  };

  // Function to render reset button
  const renderResetButton = (buttonText, buttonVariant, onClick) => {
    return (
      <>
        {isFetching ? (
          <Skeleton height={'33px'} screenreaderText={'Loading contents...'} width={'70px'} />
        ) : (
          <Button data-test="reset" isDisabled={isResetButtonDisabled} onClick={onClick} variant={buttonVariant}>
            {buttonText}
          </Button>
        )}
      </>
    );
  };

  // Function to render dropdowns
  const renderLabelAndSelect = (
    selectLabel: string,
    id: string,
    filterOptions: string[],
    isFilterOpen: boolean,
    onSelect: any,
    onToggle: any,
    selectedOption: string
  ) => {
    return (
      <>
        <Text data-test={`${kebabCase(selectLabel)}-filter-title`} component={TextVariants.p}>
          {selectLabel}
        </Text>
        <div data-test={`${kebabCase(selectLabel)}-filter`}>
          <Select
            aria-label="Select Input"
            className={styles.eiToolbarSelectWidth}
            id={id}
            isDisabled={(filterOptions?.length > 0 ? false : true) || isTableDataFetching}
            isOpen={isFilterOpen}
            onSelect={onSelect}
            onToggle={() => onToggle(!isFilterOpen)}
            placeholderText={<>All</>}
            selections={selectedOption}
            variant={SelectVariant.single}
          >
            {filterOptions?.map((option, index) => {
              return <SelectOption key={index} value={option} />;
            })}
          </Select>
        </div>
      </>
    );
  };

  // Function to render table filter section
  const renderTableFilter = (
    <ToolbarItem>
      <Flex className={styles.eiOpportunitiesDetailFilter}>
        <FlexItem className={styles.eiToolbarFilters}>
          {' '}
          {renderLabelAndSelect(
            'Product Category',
            'select-product-category',
            productCategoryFilterOptions,
            isProductCategorySelectOpen,
            onSelectProductCategoryFilter,
            setIsProductCategorySelectOpen,
            selectedProductCategoryFilterOption
          )}
        </FlexItem>
        <FlexItem className={styles.eiToolbarFilters}>
          {' '}
          {renderLabelAndSelect(
            'Customer Vertical',
            'customer-vertical',
            customerVerticalFilterOptions,
            isCustomerVerticalSelectOpen,
            onSelectCustomerVerticalFilter,
            setIsCustomerVerticalSelectOpen,
            selectedCustomerVerticalFilterOption
          )}
        </FlexItem>
        <FlexItem className={styles.eiToolbarFilters} alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderLabelAndInput('Search', handleSearchValue, searchValue || '')}
        </FlexItem>
        <FlexItem className={styles.eiToolbarFilters} alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderApplyButton('Apply', 'primary', onClickApply)}
        </FlexItem>
        <FlexItem className={styles.eiToolbarFilters} alignSelf={{ default: 'alignSelfFlexEnd' }}>
          {renderResetButton('Reset all filters', 'secondary', onClickReset)}
        </FlexItem>
      </Flex>
    </ToolbarItem>
  );

  // Function to render action column content
  // Comment me back in if action column is turned back on
  /* const renderActionColumnContent = (
    <div className={styles.eiActionColumn}>
      <Button
        variant="link"
        onClick={handleModalToggle}
        className='eiOpportunitiesDetailAction'
        icon={<InfraredLogo />}
      >
      </Button>
      <Badge className={styles.eiActionBadge}>1</Badge>
    </div>
  )*/

  // Function to render Modal from child component
  const renderModal = () => {
    return <OpportunitiesDetailModal handleModalToggle={handleModalToggle} isModalOpen={isModalOpen} viewContext={viewContext} />;
  };

  return (
    <>
      {renderModal()}
      <Card className={styles.eiOpportunitiesDetail}>
        <CardBody className={styles.eiOpportunitiesDetail}>
          {/* *** Commented action column *** */}
          {/* <Table actionColumn={renderActionColumnContent} filter={renderTableFilter}></Table> */}
          <Table filter={renderTableFilter}></Table>
        </CardBody>
      </Card>
    </>
  );
};

export default OpportunitiesDetail;
