import React, { useEffect, useState } from 'react';
import '@patternfly/pfe-avatar';
import { Dropdown, DropdownGroup, DropdownToggle, DropdownItem, DropdownSeparator } from '@patternfly/react-core';
import { useKeycloak } from '@react-keycloak/web';
import { userTypes } from '../../../../constants/userTypes';
import { useUserMetadata } from '../../../../hooks/useUserMetadata';
import { getDecodedAuthToken } from '../../../../utils/fetch';

/*import { getUserAvatarSource } from '../../../../api/user';
import { useLegacyFetch } from '../../../../hooks';*/
import styles from './UserMenu.module.scss';

const UserMenu: React.FC<any> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  //*** Commented as rhn-account-name not added in the token
  /*const [avatarSource, setAvatarSource] = useState<any>();
  const { request, isFetching } = useLegacyFetch(getUserAvatarSource, { propagateErrors: true });
  const [responseError, setResponseError] = useState(null);*/
  const decodedData = getDecodedAuthToken();
  const userMetadata = useUserMetadata();
  const { keycloak } = useKeycloak();
  let userType = '',
    name = '',
    preferredUsername = '';

  /* because the type of decodeData is dynamic and unknown, must reference members using bracket notation */
  if (decodedData && decodedData['name']) {
    name = decodedData['name'];
  }

  if (decodedData && decodedData['preferred_username']) {
    preferredUsername = decodedData['preferred_username'];
  }

  if (userMetadata) {
    userType = userMetadata.user_type;
  }

  useEffect(() => {
    //*** Commented as rhn-account-name is not added in the token
    // getUserAvatar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //*** Commented as rhn-account-name not added in the token
  /*const getUserAvatar = () => {
    const rhUsername: string = 'rhn-account-name'; 
    const getAvatarData = async () => {
      try {
        const response = await request(rhUsername);
        setAvatarSource(response);
      } catch (error) {
        setResponseError(error.message);
      }

    }
    getAvatarData();
  }*/

  const onToggle = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const onFocus = () => {
    const element = document.getElementById('toggle-image-and-text');
    element.focus();
  };

  const onSelect = (event) => {
    setIsOpen(false);
    onFocus();
  };

  const ecoIntelLogout = () => {
    if (keycloak.authenticated) {
      keycloak.logout();
    }
  };

  /**
   * Renders dropdown items
   * @param {string} text - The dropdown item text
   * @param {string} component - The dropdown item component
   * @param {string} link - Link for the dropdown item
   * @param {boolean} isPlainText - Whether the dropdown item is plain text
   * @param {string} itemStyles - Styles on the dropdown item
   * @param {function} onClick - The dropdown item's onClick callback
   * @param {string} testId - Will be used as the dropdown item's data-test attribute for use in Cypress testing
   */
  const renderDropdownItem = (
    text: string,
    component: React.ReactNode,
    link: string,
    isPlainText: boolean,
    itemStyles: string,
    onClick: Function,
    testId?: string | null
  ) => {
    /* using object.assign to conditionally render object props to spread; otherwise, prop tags are added without data which causes errors */
    const itemProps = {};
    if (component) {
      Object.assign(itemProps, { component: component });
      if (link) {
        Object.assign(itemProps, { href: link });
      }
    }
    if (isPlainText) {
      Object.assign(itemProps, { isPlainText: isPlainText });
    }
    if (onClick) {
      Object.assign(itemProps, { onClick: onClick });
    }

    Object.assign(itemProps, { key: `${text}_${component ? component : 'nocomp'}_${isPlainText ? 'plaintext' : ''}` });

    return (
      <DropdownItem className={itemStyles} data-test={testId} {...itemProps}>
        {text}
      </DropdownItem>
    );
  };

  const dropdownItems = [
    <DropdownGroup key="group 1">
      {renderDropdownItem('Username:', 'div', null, true, styles.eiUserMenuLabel, null)}
      {renderDropdownItem(preferredUsername, 'div', null, true, styles.eiUserMenuItem, null)}
      {/* {renderDropdownItem("Account number:", 'div', null, true, styles.eiUserMenuLabel, null)} */}
      {/* {renderDropdownItem("504987 (?)", 'div', null, true, styles.eiUserMenuItem, null)} */}
      {userType === userTypes.INTERNAL ? renderDropdownItem('Internal user', 'div', null, true, styles.eiUserMenuLabel, null) : <></>}
      {<DropdownSeparator key="separator" />}
    </DropdownGroup>,
    <DropdownGroup key="group 2">
      {/* {renderDropdownItem("My profile", 'a', '#', false, styles.eiUserMenuLinkItem)}
      {renderDropdownItem("User preferences", "a", '#', false, styles.eiUserMenuLinkItem)}
      {renderDropdownItem("Internal", "a", '#', false, styles.eiUserMenuLinkItem)} */}
      {renderDropdownItem('Logout', 'a', '#', false, styles.eiUserMenuLinkItem, ecoIntelLogout, 'logout')}
    </DropdownGroup>,
  ];

  return (
    <Dropdown
      className={styles.eiPFDropdown}
      data-test="userMenu"
      dropdownItems={dropdownItems}
      isOpen={isOpen}
      isPlain
      onSelect={onSelect}
      isGrouped
      toggle={
        <DropdownToggle
          className={styles.eiDropdownToggle}
          icon={
            <span className={styles.eiAvatarWrapper}>
              <pfe-avatar
                shape="circle"
                pattern="squares"
                full-name={`${decodedData['given_name']} ${decodedData['family_name']}`}
                name={decodedData['name']}
                //src={avatarSource} *** Commented as rhn-account-name not added in the token
              ></pfe-avatar>
            </span>
          }
          id="toggle-image-and-text"
          onToggle={onToggle}
        >
          {name}
        </DropdownToggle>
      }
    />
  );
};

export default UserMenu;
