import * as React from 'react';
import { useEffect } from 'react';
import { Button, Popover, PopoverProps } from '@patternfly/react-core';
import OutlinedCalendarAltIcon from '@patternfly/react-icons/dist/esm/icons/outlined-calendar-alt-icon';
import { css } from '@patternfly/react-styles';
import buttonStyles from '@patternfly/react-styles/css/components/Button/button';
import styles from '@patternfly/react-styles/css/components/DatePicker/date-picker';
import dateHelper from '../../utils/dateHelper';
import Calendar, { CalendarFormat } from './Calendar/Calendar';
import customStyles from './MonthPicker.module.scss';

export const KeyTypes = {
  Tab: 'Tab',
  Space: ' ',
  Escape: 'Escape',
  Enter: 'Enter',
  ArrowUp: 'ArrowUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
};

/** The main date picker component. */

export interface DatePickerProps
  extends CalendarFormat,
    Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'onFocus' | 'onBlur' | 'disabled' | 'ref' | 'value'> {
  /** The container to append the menu to. Defaults to 'parent'.
   * If your menu is being cut off you can append it to an element higher up the DOM tree.
   * Some examples:
   * menuAppendTo={() => document.body};
   * menuAppendTo={document.getElementById('target')}
   */
  appendTo?: HTMLElement | ((ref?: HTMLElement) => HTMLElement) | 'parent';
  /** Accessible label for the date picker. */
  'aria-label'?: string;
  /** Accessible label for the button to open the date picker. */
  buttonAriaLabel?: string;
  /** Additional classes added to the date picker. */
  className?: string;
  /** Flag indicating the date picker is disabled. */
  isDisabled?: boolean;
  /** Props to pass to the popover that contains the calendar month component. */
  popoverProps?: Partial<Omit<PopoverProps, 'appendTo'>>;
  /** Value of the text input. */
  value?: { month?: number; year?: number };
  /** Range of months to display */
  months?: string[];
  /** Range of years to display */
  years?: number[];
  /** Function to call when the picker changes */
  onChange?: (year: number, month: number) => void;
  /** Test selector */
  dataTest?: string;
}

/** Allows finer control over the calendar's open state when a React ref is passed into the
 * date picker component. Accessed via ref.current[property], e.g. ref.current.toggleCalendar().
 */
export interface DatePickerRef {
  /** Current calendar open status. */
  isCalendarOpen: boolean;
  /** Sets the calendar open status. */
  setCalendarOpen: (isOpen: boolean) => void;
  /** Toggles the calendar open status. If no parameters are passed, the calendar will simply
   * toggle its open status.
   * If the isOpen parameter is passed, that will set the calendar open status to the value
   * of the isOpen parameter.
   * If the eventKey parameter is set to 'Escape', that will invoke the date pickers
   * onEscapePress event to toggle the correct control appropriately.
   */
  toggleCalendar: (isOpen?: boolean, eventKey?: string) => void;
}

export const MonthPicker = ({
  className,
  isDisabled = false,
  value: valueProp,
  'aria-label': ariaLabel = 'Date picker',
  buttonAriaLabel = 'Toggle date picker',
  appendTo = 'parent',
  popoverProps,
  rangeStart,
  style: styleProps = {},
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  years,
  onChange,
  dataTest,
  ...props
}: DatePickerProps) => {
  const [value, setValue] = React.useState(`${dateHelper['abbreviatedMonthNameLookup'][valueProp?.month]} ${valueProp?.year}`);
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [isSelectOpen, setIsSelectOpen] = React.useState(false);
  const widthChars = 'Mar 2022'.length;
  const style = { '--pf-c-date-picker__input--c-form-control--width-chars': widthChars, ...styleProps };
  const buttonRef = React.useRef<HTMLButtonElement>();
  const datePickerWrapperRef = React.useRef<HTMLDivElement>();

  useEffect(() => {
    setValue(`${dateHelper['abbreviatedMonthNameLookup'][valueProp?.month]} ${valueProp?.year}`);
  }, [valueProp]);

  const onDateClick = (newValue: { month: number; year: number }) => {
    const newValueAsString: string = `${dateHelper['abbreviatedMonthNameLookup'][newValue.month]} ${newValue.year}`;
    setValue(newValueAsString);
    setIsPopoverOpen(false);
    onChange && onChange(newValue.year, newValue.month);
  };

  const onKeyDown = (event) => {
    if (event.key === KeyTypes.Escape && isPopoverOpen) {
      setIsPopoverOpen(!isPopoverOpen);
    }
  };

  const getParentElement = () => (datePickerWrapperRef && datePickerWrapperRef.current ? datePickerWrapperRef.current : null);

  return (
    <div className={css(styles.datePicker, className)} ref={datePickerWrapperRef} style={style} {...props}>
      <Popover
        data-test="calendar-popover"
        position="bottom"
        bodyContent={<Calendar date={valueProp} onChange={onDateClick} rangeStart={rangeStart} isDateFocused months={months} years={years} />}
        showClose={false}
        isVisible={isPopoverOpen}
        shouldClose={(_1, _2, event) => {
          event = event as KeyboardEvent;
          if (event.key === KeyTypes.Escape && isSelectOpen) {
            event.stopPropagation();
            setIsSelectOpen(false);
            return false;
          }
          // Let our button handle toggling
          if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
            return false;
          }
          setIsPopoverOpen(false);
          if (event.key === KeyTypes.Escape && isPopoverOpen) {
            event.stopPropagation();
          }
          return true;
        }}
        withFocusTrap
        hasNoPadding
        hasAutoWidth
        appendTo={appendTo === 'parent' ? getParentElement() : appendTo}
        {...popoverProps}
      >
        <div className={styles.datePickerInput}>
          <Button
            ref={buttonRef}
            className={css(buttonStyles.button, buttonStyles.modifiers.control, customStyles.eiMonthPickerButton)}
            aria-label={buttonAriaLabel}
            type="button"
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            onKeyDown={onKeyDown}
            isDisabled={isDisabled}
            data-test={dataTest}
            variant="control"
          >
            {value}
            <OutlinedCalendarAltIcon className={customStyles.eiCalendar} />
          </Button>
        </div>
      </Popover>
    </div>
  );
};
